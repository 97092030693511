import { sessionContext } from "../../../_hook/useSession";
import { ILocalisation, TAccessType } from "../../../_types/generalTypes";
import DEFAULT_BANNER from "../../../assets/images/dafault-banner.png";
import DEFAULT_LOGO from "../../../assets/images/default-logo.png";
import accessTypeArray from "../../../constants/accessType";
import { DIMENSION_PHONE } from "../../../constants/dimensions.constants";
import { urlOrEmpty } from "../../../constants/regex";
import subscriptionPlanArray from "../../../constants/subscriptionPlan";
import useWindowDimensions from "../../../helpers/getWindowDimensions";
import { encodeImageFileAsURL } from "../../../helpers/Tools";
import SuccessButton from "../../generic/SuccessButton";
import TagList from "../../generic/TagList";
import YesNoModal, { Sizes } from "../../generic/YesNoModal";
import SelectField from "../SelectField";
import "./index.scss";
import TutoCommunityCreated from "./TutoCommunityCreated";
import { IconButton, TextField, Typography } from "@material-ui/core";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPhoneSquareAlt,
  FaSnapchatGhost,
  FaTiktok
} from "react-icons/fa";
import { FaGlobe, FaXTwitter } from "react-icons/fa6";
import { MdBusinessCenter } from "react-icons/md";
import { ArrowBack, Flag, GroupWork, LocationOn, SupervisedUserCircle } from "@material-ui/icons";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { noop } from "lodash";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";
import validation from "validate.js";
import { createChannel as createChannelApi, deleteChannel as deleteChannelApi } from "../../../_api/channelApi";
import DangerButton from "../../generic/DangerButton";
import useGroup from "../../../_hook/useGroup";
import { PRIMARY_COLOR } from "../../../constants/colors.constants";
import GoogleMaps from "../GoogleMaps";
import { useNavigate } from "react-router-dom";

interface CommunityGroupFormProps {
  formParams: any;
  item: any;
  isGroup: boolean;
  isPublish: boolean;
  isEdit: boolean;
  onClickBackButton?: () => void;
}

interface State {
  accessType?: TAccessType | null;
  activitySector: string | null;
  appName: string | null;
  category: string | null;
  coverPicture: string | null;
  description: string | null;
  displayDirectory: boolean;
  facebookUrl: string | null;
  hasDiscussionGroup: boolean;
  instagramUrl: string | null;
  linkedinUrl: string | null;
  localisation: any;
  logo: string | null;
  mergeInvitations: boolean;
  messagingEnabled: true;
  name: string | null;
  nuid: string | null;
  phoneNumber: string | null;
  public: boolean;
  published: boolean;
  size: string | null;
  skills: any[];
  snapchatUrl: string | null;
  subscriptionPlan?: string;
  templatePicture: string | null;
  tiktokUrl: string | null;
  type: string | null;
  url: string | null;
  whiteLabel: 'yes' | 'no';
  whiteLabelName: string | null;
  xUrl: string | null;
}

interface ErrorState {
  url: Array<string> | null;
  facebookUrl: Array<string> | null;
  instagramUrl: Array<string> | null;
  xUrl: Array<string> | null;
  tiktokUrl: Array<string> | null;
  snapchatUrl: Array<string> | null;
  linkedinUrl: Array<string> | null;
}

const CLASSNAME = 'communityGroupForm';

const CommunityGroupForm: React.FC<CommunityGroupFormProps> = ({
  formParams,
  item,
  isGroup,
  isPublish,
  isEdit,
  onClickBackButton = () => {
    noop();
  },
}) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation(['words', 'error', 'layout', 'register', 'home', 'message']);
  const navigate = useNavigate();

  const { edit } = formParams;
  const isMaster = item && item?.masterCommunity === 1;

  // Modal
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [isChannelDeleteConfirmationOpen, setIsChannelDeleteConfirmationOpen] = React.useState(false);

  // useSession
  const {
    sessionState,
    setDictionaries,
    getDictionaryValue,
    createCommunityOrGroup,
    updateCommunityOrGroup,
    setPageIsLoading,
    setMessage,
    setError,
    setGroup,
    getAdminCommunities,
  } = React.useContext(sessionContext);
  const { dictionaries, selectedGroup, selectedCommunity, user } = sessionState;
  const isSuperAdmin = user.roles.find((item: string) => item === 'ROLE_SUPER_ADMIN');
  const { getGroups, deleteGroup } = useGroup();

  // Form state
  const initialState: State = {
    accessType: null,
    activitySector: null,
    appName: null,
    category: null,
    coverPicture: null,
    description: null,
    displayDirectory: true,
    facebookUrl: null,
    hasDiscussionGroup: false,
    instagramUrl: null,
    linkedinUrl: null,
    localisation: null,
    logo: null,
    mergeInvitations: false,
    messagingEnabled: true,
    name: null,
    nuid: null,
    phoneNumber: null,
    public: true,
    published: false,
    size: null,
    skills: [],
    snapchatUrl: null,
    subscriptionPlan: 'pro',
    templatePicture: null,
    tiktokUrl: null,
    type: null,
    url: null,
    whiteLabel: 'no',
    whiteLabelName: null,
    xUrl: null,
  };

  const initialErrorState: ErrorState = {
    url: null,
    facebookUrl: null,
    instagramUrl: null,
    xUrl: null,
    tiktokUrl: null,
    snapchatUrl: null,
    linkedinUrl: null,
  };

  const [states, setStates] = React.useState<any>(initialState);
  const [errors, setErrorStates] = React.useState(initialErrorState);
  const [totalError, setTotalError] = React.useState(0);
  const [localisation, setLocalisation] = React.useState<string|null>(null);
  const [logo, setLogo] = React.useState(null);
  const [coverPicture, setCoverPicture] = React.useState(null);
  const [templatePicture, setTemplatePicture] = React.useState(null);
  const [openInfoModal, setOpenInfoModal] = React.useState(!!edit);

  const constraints = {
    name: { presence: { allowEmpty: false } },
    category: { presence: { allowEmpty: false } },
    activitySector: { presence: { allowEmpty: false } },
    size: { presence: { allowEmpty: false } },
    skills: { presence: { allowEmpty: false } },
    url: { format: urlOrEmpty },
    facebookUrl: { format: urlOrEmpty },
    instagramUrl: { format: urlOrEmpty },
    xUrl: { format: urlOrEmpty },
    tiktokUrl: { format: urlOrEmpty },
    snapchatUrl: { format: urlOrEmpty },
    linkedinUrl: { format: urlOrEmpty },
    logo: !isGroup ? { presence: { allowEmpty: false } } : undefined,
    coverPicture: !isGroup ? { presence: { allowEmpty: false } } : undefined,
    accessType: isGroup ? { presence: { allowEmpty: false } } : undefined,
  };

  // ACTIONS
  const create = async (states: any) => {
    const newState = isGroup
      ? {
          ...states,
          parentCommunityNuid: selectedCommunity?.nuid,
          type: 'group',
          fromDashboard: true,
        }
      : {
          ...states,
          type: 'community',
          fromDashboard: true,
          accessType: 'public_validation',
        };
    await createCommunityOrGroup(newState);
    onClickBackButton();
  };

  const createAndPublish = async (states: any) => {
    const newState = isGroup
      ? {
          ...states,
          parentCommunityNuid: selectedCommunity?.nuid,
          type: 'group',
          published: true,
          fromDashboard: true,
        }
      : {
          ...states,
          published: true,
          type: 'community',
          fromDashboard: true,
          accessType: 'public_validation',
        };
    await createCommunityOrGroup(newState, true);
    onClickBackButton();
  };

  const createChannel = async () => {
    setPageIsLoading(true);
    try {
      await createChannelApi({containerNuid: isGroup ? selectedGroup.nuid : selectedCommunity.nuid});
      if (selectedCommunity && !selectedGroup) {
        await getAdminCommunities();
      }
      if (selectedGroup) {
        const data = await getGroups(
          {communityNuid: selectedCommunity.nuid, groupNuid: selectedGroup.nuid, maxPerPage: 1, page: 1},
          false,
          true,
        );
        setGroup(data.content.groups[0]);
      }
      setMessage(t('message:groupChannelCreated'));
      setStates({...states, hasDiscussionGroup: true});
    } catch (error: any) {
      setError(error?.response?.data?.fail);
    }
    setPageIsLoading(false);
  }

  const deleteGroupAction = async () => {
    setPageIsLoading(true);
    deleteGroup(selectedGroup.nuid).then(() => {
      setMessage(t('message:groupDeleted'));
      setGroup(null);
      navigate('/');
    }).catch(() => {
      setPageIsLoading(false);
      setError(t('error:genericError'))
    })
  }

  const deleteChannel = async () => {
    setPageIsLoading(true);
    try {
      await deleteChannelApi({containerNuid: isGroup ? selectedGroup.nuid : selectedCommunity.nuid});
      if (selectedCommunity && !selectedGroup) {
        await getAdminCommunities();
      }
      if (selectedGroup) {
        const data = await getGroups(
          {communityNuid: selectedCommunity.nuid, groupNuid: selectedGroup.nuid, maxPerPage: 1, page: 1},
          false,
          true,
        );
        setGroup(data.content.groups[0]);
      }
      setMessage(t('message:groupChannelDeleted'))
      setStates({...states, hasDiscussionGroup: false});
    } catch (error: any) {
      setError(error?.response?.data?.fail);
    }
    setPageIsLoading(false);
  }

  const save = async (states: any) => {
    await updateCommunityOrGroup(states);
  };

  const publish = async (states: any) => {
    await updateCommunityOrGroup({ ...states, published: true }, true, false, true);
  };

  // Information about edition/creation
  const information: {
    title: string;
    yesAction: (states: any) => void;
    yesLabel: string;
    specialAction: (states: any) => void;
    specialActionLabel: string;
  } = {
    title: '',
    yesAction: () => {
      noop();
    },
    yesLabel: '',
    specialAction: () => {
      noop();
    },
    specialActionLabel: '',
  };
  if (!isEdit) {
    information.title = isGroup ? t('layout:createGroup') : t('layout:createCommunity');
    information.yesAction = createAndPublish;
    information.yesLabel = t('words:publish');
    information.specialAction = create;
    information.specialActionLabel = t('words:save');
  } else {
    information.title = isGroup ? t('layout:editGroup') : t('layout:editCommunity');
    information.yesAction = selectedGroup?.published ? save : publish;
    information.yesLabel = selectedGroup?.published ? t('words:save') : t('words:publish');
    information.specialAction = save;
    information.specialActionLabel = t('words:save');
  }

  const onSelect = (newValue: any, field: any) => {
    setStates({ ...states, [field]: newValue.key });
  };

  const handleChange = (event: any) => {
    const result = event.target.value === '' ? null : event.target.value;
    setStates({ ...states, [event.target.id]: result });
  };

  const handleChangeSkills = (_event: any, newValue: any) => {
    if (newValue.length > 0) {
      const [lastItem] = newValue.slice(-1);
      if (typeof lastItem !== 'object') {
        const newEntry = { key: lastItem, value: lastItem };
        let skills = dictionaries.skill;
        skills = Object.assign([], skills);
        skills.push(newEntry);
        setDictionaries({ ...dictionaries, skills });
        setStates({ ...states, skills: [...states.skills, newEntry] });
      } else {
        setStates({ ...states, skills: newValue });
      }
    } else {
      setStates({ ...states, skills: [] });
    }
  };

  const handleChangePicture = async (picture: any, field: any) => {
    setStates({ ...states, [field]: picture });
    if (field === 'logo') {
      setLogo(await encodeImageFileAsURL(picture[0]));
    }
    if (field === 'coverPicture') {
      setCoverPicture(await encodeImageFileAsURL(picture[0]));
    }
    if (field === 'templatePicture') {
      setTemplatePicture(await encodeImageFileAsURL(picture[0]));
    }
  };

  const googleMapsHandler = (localisation: ILocalisation | null) => {
    setStates({ ...states, localisation })
    setLocalisation(
      localisation?.town
        ? `${localisation.town}, ${localisation.country}`
        : localisation?.country ?? null
    );
  }

  // Effects
  React.useEffect(() => {
    if (item !== null) {
      setStates({
        ...item,
        accessType: item.accessType,
        activitySector: item.activitySector ? item.activitySector.key : null,
        category: item.category ? item.category.key : null,
        skills: item.skills
          ? item.skills.map((elt: any) => ({ key: elt.key, value: elt.translation }))
          : [],
        size: item.size ? item.size.key : null,
      });
      if (item.localisation) {
        const country = typeof item.localisation.country === 'string'
          ? item.localisation.country
          : item.localisation.country?.translations[0]?.translation ?? '';
        setLocalisation(
          !isEmpty(item.localisation.town)
            ? `${item.localisation.town[0].slice(3)}, ${country}`
            : country
        )
      }
      if (item.logo) {
        setLogo(item.logo);
      }
      if (item.coverPicture) {
        setCoverPicture(item.coverPicture);
      }
      if (item.templatePicture) {
        setTemplatePicture(item.templatePicture);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  React.useEffect(() => {
    const errors = validation.validate(states, constraints);
    if (errors) {
      setTotalError(Object.keys(errors).length);
    } else {
      setTotalError(0);
    }
    setErrorStates({ ...initialErrorState, ...errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  const isSpecialActionDisabled = () => {
    let ids: string[];
    if (isGroup) {
      ids = [
        'accessType',
        'activitySector',
        'category',
        'coverPicture',
        'logo',
        'name',
        'size',
        'skills',
      ];
    } else if (states.whiteLabel === 'yes') {
      ids = [
        'activitySector',
        'appName',
        'category',
        'coverPicture',
        'logo',
        'name',
        'size',
        'skills',
        'whiteLabelName',
      ];
    } else {
      ids = [
        'activitySector',
        'category',
        'coverPicture',
        'logo',
        'name',
        'size',
        'skills',
      ];
    }
    const flag: any[] = [];
    ids.forEach((id) => {
      if (!states[id] || states[id] === null || states[id] === undefined || isEmpty(states[id])) {
        flag.push(id);
      } else if (typeof states[id] === 'string') {
        if (states[id].trim().length === 0 || states[id] === null || states[id] === undefined) {
          flag.push(id);
        }
      }
    });
    return flag.length !== 0;
  };

  const genericUrlProps = {
    fullWidth: true,
    margin: "dense" as 'none' | 'dense' | 'normal' | undefined,
    size: "small" as "small" | "medium" | undefined,
    variant: "outlined" as "outlined",
    id:"facebookUrl",
    type:"url"
  };

  return (
    <div className={CLASSNAME}>
      <div className={`${CLASSNAME}_titleContainer`}>
        <div>
          {!isEdit && (
            <IconButton onClick={() => setIsConfirmationOpen(true)}>
              <ArrowBack />
            </IconButton>
          )}
          <h1>{information.title}</h1>
          {' - '}
          <p>{isGroup ? t('layout:requiredFieldsGroup') : t('layout:requiredFieldsCommunity')}</p>
        </div>
        <div>
          <SuccessButton
            outlined
            label={information.specialActionLabel}
            onClick={() => information.specialAction(states)}
            disabled={states.name?.trim().length === 0 || isEmpty(states.name) || totalError > 0}
          />
          {(!isEdit || !item?.published) && (
            <SuccessButton
              label={information.yesLabel}
              onClick={() => information.yesAction(states)}
              disabled={isSpecialActionDisabled()}
            />
          )}
          {(item?.published && selectedCommunity?.chatApplicationId && !states.hasDiscussionGroup) && (
            <SuccessButton
              label={t('layout:createChannel')}
              onClick={() => createChannel()}
            />
          )}
          {(item?.published && selectedCommunity?.chatApplicationId && states.hasDiscussionGroup) && (
            <>
              <DangerButton
                label={t('layout:deleteChannel')}
                onClick={() => setIsChannelDeleteConfirmationOpen(true)}
              />
            </>
          )}
          {selectedGroup && (
            <>
              <DangerButton
                label={t('words:delete')}
                onClick={() => setDeleteConfirmationOpen(true)}
              />
            </>
          )}
        </div>
      </div>
      <div className={`${CLASSNAME}_container`}>
        <div className={`${CLASSNAME}_form`}>
          {isSuperAdmin && !isGroup && (
            <SelectField
              id="subscriptionPlan"
              label={t('words:subscriptionPlan')}
              data={subscriptionPlanArray.map((e) => {
                return { key: e.key, value: capitalize(t(e.value)) };
              })}
              value={states.subscriptionPlan || 'pro'}
              onSelect={onSelect}
            />
          )}
          {!isEdit && !isGroup && !item?.published && (
            <>
              <SelectField
                isRequired
                id="whiteLabel"
                label={t('words:isWhiteLabel')}
                data={[
                  { key: 'yes', value: t('words:yes') },
                  { key: 'no', value: t('words:no') },
                ]}
                value={states.whiteLabel || ''}
                onSelect={onSelect}
              />
              {states.whiteLabel === 'yes' && (
                <TextField
                  required
                  fullWidth
                  margin="none"
                  size="small"
                  variant="outlined"
                  id="whiteLabelName"
                  type="text"
                  label={t('words:whiteLabelName')}
                  value={states.whiteLabelName || ''}
                  onChange={handleChange}
                  disabled={item?.published}
                />
              )}
              {states.whiteLabel === 'yes' && (
                <TextField
                  required
                  fullWidth
                  margin="none"
                  size="small"
                  variant="outlined"
                  id="appName"
                  type="text"
                  label={t('words:appName')}
                  value={states.appName || ''}
                  onChange={handleChange}
                  // disabled={item?.published}
                />
              )}
            </>
          )}
          <TextField
            required
            disabled={!isGroup && states.whiteLabel === 'yes' && states.published}
            fullWidth
            margin="none"
            size="small"
            variant="outlined"
            id="name"
            type="text"
            label={isGroup ? t('layout:groupName') : t('home:communityName')}
            value={states.name || ''}
            onChange={handleChange}
          />
          <div className={`${CLASSNAME}_uploaders`}>
            <div>
              {width <= DIMENSION_PHONE && (
                <div className={`${CLASSNAME}_coverPicture`}>
                  <img src={logo || DEFAULT_LOGO} alt={t('words:logo')} />
                </div>
              )}
              {/* @ts-ignore */}
              <ImageUploader
                className={`${CLASSNAME}_uploader`}
                maxFileSize={10000000}
                imgExtension={['.jpeg', '.jpg', '.gif', '.png']}
                singleImage
                withIcon={false}
                buttonText={t('layout:selectLogoRequired')}
                label={`${t('layout:sizeMax', {
                  size: '10 Mo',
                })}, ${t('layout:sizeImage')} / ${t('layout:recommendedSize', {
                  size: '500 x 500 px',
                })}`}
                fileSizeError={t('error:fileSizeError')}
                fileTypeError={t('error:fileTypeError')}
                onChange={async (event: any) => {
                  await handleChangePicture(event, 'logo');
                }}
              />
            </div>
            <div>
              {width <= DIMENSION_PHONE && (
                <div className={`${CLASSNAME}_coverPicture`}>
                  <img src={coverPicture || DEFAULT_BANNER} alt={t('layout:coverPicture')} />
                </div>
              )}
              {/* @ts-ignore */}
              <ImageUploader
                className={`${CLASSNAME}_uploader`}
                maxFileSize={10000000}
                imgExtension={['.jpeg', '.jpg', '.gif', '.png']}
                singleImage
                withIcon={false}
                buttonText={t('layout:selectCoverPictureRequired')}
                label={`${t('layout:sizeMax', {
                  size: '10 Mo',
                })}, ${t('layout:sizeImage')} / ${t('layout:recommendedSize', {
                  size: '1200 x 600 px',
                })}`}
                fileSizeError={t('error:fileSizeError')}
                fileTypeError={t('error:fileTypeError')}
                onChange={async (event: any) => {
                  await handleChangePicture(event, 'coverPicture');
                }}
              />
            </div>
            {(states.whiteLabel === 'yes' || isMaster) && !isGroup && (
              <div>
                {width <= DIMENSION_PHONE && templatePicture && (states.whiteLabel || isMaster) && (
                  <div className={`${CLASSNAME}_bannerEmail`}>
                    <img src={templatePicture} alt={t('layout:templatePicture')} />
                  </div>
                )}
                {/*// @ts-ignore*/}
                <ImageUploader
                  className={`${CLASSNAME}_uploader`}
                  maxFileSize={10000000}
                  imgExtension={['.jpeg', '.jpg', '.gif', '.png']}
                  singleImage
                  withIcon={false}
                  buttonText={t('layout:selectTemplatePicture')}
                  label={`${t('layout:sizeMax', {
                    size: '10 Mo',
                  })}, ${t('layout:sizeImage')} / ${t('layout:recommendedSize', {
                    size: '1200 x 600 px',
                  })}`}
                  fileSizeError={t('error:fileSizeError')}
                  fileTypeError={t('error:fileTypeError')}
                  onChange={async (event: any) => {
                    await handleChangePicture(event, 'templatePicture');
                  }}
                />
              </div>
            )}
          </div>
          <SelectField
            isRequired
            id="category"
            label={t('words:category')}
            data={dictionaries?.communityCategory}
            value={states.category || ''}
            onSelect={onSelect}
          />
          <SelectField
            isRequired
            id="activitySector"
            label={t('register:activitySector')}
            data={dictionaries?.activitySector}
            value={states.activitySector || ''}
            onSelect={onSelect}
          />
          <SelectField
            isRequired
            id="size"
            label={isGroup ? t('layout:groupSize') : t('layout:communitySize')}
            data={dictionaries?.communitySize}
            value={states.size || ''}
            onSelect={onSelect}
          />
          {isGroup && (
            <SelectField
              isRequired
              id="accessType"
              label={t('layout:accessType')}
              data={accessTypeArray.map((e) => {
                return { key: e.key, value: capitalize(t(`words:${e.value}`)) };
              })}
              value={(states.accessType as unknown as string) || ''}
              onSelect={onSelect}
            />
          )}
          <Autocomplete
            filterSelectedOptions
            freeSolo
            multiple
            size="small"
            id="skills"
            options={dictionaries?.skill || []}
            getOptionLabel={(option: any) => option.value}
            getOptionSelected={(option: any, value: any) => option.key === value.key}
            value={states.skills}
            onChange={handleChangeSkills}
            renderInput={(defaultParams: AutocompleteRenderInputParams) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...defaultParams}
                required
                margin="dense"
                variant="outlined"
                label={t('layout:skillAndFocus')}
              />
            )}
          />
          <TextField
            fullWidth margin="normal" multiline size="small" variant="outlined" id="description" type="text"
            label={t(isGroup ? 'home:groupPresentation' : 'home:CommunityPresentation')}
            value={states.description || ''}
            onChange={handleChange}
          />
          <p className={'font-paragraph-semiBold'}>{t('layout:contactDetails')}</p>
          <GoogleMaps
            searchTitle={t(isGroup ? 'layout:whereIsGroup' : 'layout:whereIsCommunity')}
            localisation={states.localisation}
            updateHandler={googleMapsHandler}
          />
          <div className={`${CLASSNAME}_info`}>
            <FaPhoneSquareAlt color={PRIMARY_COLOR} size={30} />
            <TextField
              fullWidth margin="dense" size="small" variant="outlined" id="phoneNumber" type="phoneNumber"
              name="phoneNumber" placeholder="" label={t('home:phone')} value={states.phoneNumber || ''}
              onChange={handleChange}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaGlobe color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="url" name="url" placeholder="https://www.mywebsite.com" label={t('home:urlWebSite')} value={states.url || ''}
              error={Array.isArray(errors.url)}
              onChange={handleChange}
            />
          </div>
          <p className={'font-paragraph-semiBold'}>{t('layout:socialNetworks')}</p>
          <div className={`${CLASSNAME}_info`}>
            <FaFacebookSquare color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="facebookUrl" name="facebookUrl" label={t('home:urlFacebook')} value={states.facebookUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.facebookUrl)}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaInstagramSquare color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="instagramUrl" name="instagramUrl" label={t('home:urlInstagram')} value={states.instagramUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.instagramUrl)}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaXTwitter color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="xUrl" name="xUrl" label={t('home:urlX')} value={states.xUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.xUrl)}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaTiktok color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="tiktokUrl" name="tiktokUrl" label={t('home:urlTikTok')} value={states.tiktokUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.tiktokUrl)}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaSnapchatGhost color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="snapchatUrl" name="snapchatUrl" label={t('home:urlSnapChat')} value={states.snapchatUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.snapchatUrl)}
            />
          </div>
          <div className={`${CLASSNAME}_info`}>
            <FaLinkedin color={PRIMARY_COLOR} size={30} />
            <TextField
              {...genericUrlProps}
              id="linkedinUrl" name="linkedinUrl" label={t('home:urlLinkedin')} value={states.linkedinUrl || ''}
              onChange={handleChange}
              error={Array.isArray(errors.linkedinUrl)}
            />
          </div>
        </div>
        {width >= DIMENSION_PHONE && (
          <div className={`${CLASSNAME}_preview`}>
            <div className={`${CLASSNAME}_logo`}>
              <p className={'font-paragraph-semiBold'}>{capitalize(t('layout:myLogo'))} :</p>
              <img src={logo || DEFAULT_LOGO} alt={t('words:logo')} />
            </div>
            <div className={`${CLASSNAME}_coverPicture`}>
              <p className={'font-paragraph-semiBold'}>{capitalize(t('layout:myBanner'))} :</p>
              <img src={coverPicture || DEFAULT_BANNER} alt={t('layout:coverPicture')} />
            </div>
            {templatePicture && (states.whiteLabel || isMaster) && (
              <div className={`${CLASSNAME}_bannerEmail`}>
                <p className={'font-paragraph-semiBold'}>{t('layout:templatePicture')} :</p>
                <img src={templatePicture} alt={t('layout:templatePicture')} />
              </div>
            )}
            <div className={`${CLASSNAME}_description`}>
              <p className={'font-paragraph-semiBold'}>
                {isGroup
                  ? capitalize(t('layout:groupPresentation'))
                  : capitalize(t('layout:communityPresentation'))}
                {' :'}
              </p>
              <Typography variant="subtitle1">
                {/* eslint-disable-next-line no-nested-ternary */}
                {states.description
                  ? truncate(states.description, {
                      length: 200,
                    })
                  : isGroup
                  ? t('home:groupPresentation')
                  : t('words:description')}
              </Typography>
            </div>
            <div className={`${CLASSNAME}_info`}>
              <SupervisedUserCircle color="primary" />
              <p className={'font-paragraph-semiBold'}>
                {isGroup
                  ? states.name || t('layout:groupName')
                  : states.name || t('layout:communityName')}
              </p>
            </div>
            <div className={`${CLASSNAME}_info`}>
              <LocationOn color="primary" />
              <p className={'font-paragraph-semiBold'}>
                {states.localisation && states.localisation.country !== null
                  ? localisation
                  : capitalize(t('words:localisation'))}
              </p>
            </div>
            <div className={`${CLASSNAME}_info`}>
              <GroupWork color="primary" />
              <p className={'font-paragraph-semiBold'}>
                {states.category
                  ? getDictionaryValue('communityCategory', states.category)
                  : t('words:category')}
              </p>
            </div>
            <div className={`${CLASSNAME}_info`}>
              <Flag color="primary" />
              <p className={'font-paragraph-semiBold'}>
                {states.activitySector
                  ? getDictionaryValue('activitySector', states.activitySector)
                  : t('register:activitySector')}
              </p>
            </div>
            <div className={`${CLASSNAME}_info`}>
              <MdBusinessCenter color={PRIMARY_COLOR} size={22} />
              <p className={'font-paragraph-semiBold'}>{t('layout:skillAndFocus')}</p>
              <TagList center negative items={states.skills ? states.skills : []} />
            </div>
            {states.phoneNumber &&
              <div className={`${CLASSNAME}_info`}>
                <FaPhoneSquareAlt color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.phoneNumber}</p>
              </div>
            }
            {states.url &&
              <div className={`${CLASSNAME}_info`}>
                <FaGlobe color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.url}</p>
              </div>
            }
            {states.facebookUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaFacebookSquare color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.facebookUrl}</p>
              </div>
            }
            {states.instagramUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaInstagramSquare color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.instagramUrl}</p>
              </div>
            }
            {states.xUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaXTwitter color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.xUrl}</p>
              </div>
            }
            {states.tiktokUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaTiktok color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.tiktokUrl}</p>
              </div>
            }
            {states.snapchatUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaSnapchatGhost color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.snapchatUrl}</p>
              </div>
            }
            {states.linkedinUrl &&
              <div className={`${CLASSNAME}_info`}>
                <FaLinkedin color={PRIMARY_COLOR} size={20} />
                <p className={'font-paragraph-semiBold'}>{states.linkedinUrl}</p>
              </div>
            }
          </div>
        )}
        <YesNoModal
          open={openInfoModal}
          title={`${t('words:welcome')} ${sessionState.user.firstName}`}
          yesLabel={t('layout:iUnderstand')}
          yesAction={() => {
            setOpenInfoModal(false);
          }}
          text={<TutoCommunityCreated />}
        />
      </div>
      <YesNoModal
        size={Sizes.md}
        yesAction={() => {
          setDeleteConfirmationOpen(false);
          deleteGroupAction().then();
        }}
        yesLabel={t('words:yes')}
        noAction={() => setDeleteConfirmationOpen(false)}
        noLabel={t('words:no')}
        text={<p>{capitalize(t('layout:confirmGroupDelete'))}</p>}
        title={capitalize(t('layout:confirmGroupDeleteTitle'))}
        open={deleteConfirmationOpen}
      />
      <YesNoModal
        size={Sizes.md}
        yesAction={() => {
          setIsConfirmationOpen(false);
          onClickBackButton();
        }}
        yesLabel={t('words:yes')}
        noAction={() => setIsConfirmationOpen(false)}
        noLabel={t('words:no')}
        text={<p>{capitalize(t('layout:confirmBack'))}</p>}
        title={capitalize(t('layout:deleteConfirmation'))}
        open={isConfirmationOpen}
      />
      <YesNoModal
        size={Sizes.md}
        yesAction={() => {
          setIsChannelDeleteConfirmationOpen(false);
          deleteChannel().then();
        }}
        yesLabel={t('words:yes')}
        noAction={() => setIsChannelDeleteConfirmationOpen(false)}
        noLabel={t('words:no')}
        text={<p>{capitalize(t('layout:confirmGroupChannelDeletion'))}</p>}
        title={capitalize(t('layout:deleteConfirmation'))}
        open={isChannelDeleteConfirmationOpen}
      />
    </div>
  );
};

CommunityGroupForm.displayName = 'CommunityGroupForm';
export default CommunityGroupForm;
