import ScPaginator from '../paginator/index';
import './index.scss';
import * as PropTypes from 'prop-types';
import React, { createElement, Fragment, useEffect, useState } from 'react';

const CLASSNAME = 'itemsList';

const ItemsList = (props) => {
  const {
    showRole,
    items,
    totalItems,
    updateData,
    currentPage,
    maxPerPage,
    selected,
    handleSelectedItems,
    component,
    categories,
    handleUpdateItem,
    handleDeleteItem,
  } = props;
  const [selectedItems, setSelectedItems] = useState(selected);
  const [itemsPerPage, setItemsPerPage] = useState(maxPerPage);

  const handleItemSelect = (value) => {
    if (value !== null) {
      if (selectedItems.find((item) => item.nuid === value.nuid) === undefined) {
        setSelectedItems((prevState) => [...prevState, value]);
        handleSelectedItems([...selectedItems, value]);
      } else {
        setSelectedItems(selectedItems.filter((item) => item.nuid !== value.nuid));
        handleSelectedItems(selectedItems.filter((item) => item.nuid !== value.nuid));
      }
    }
  };

  const handleChangePage = (newPage) => {
    updateData(newPage, itemsPerPage, '');
  };

  const handleChangeItemsPerPage = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    updateData(1, newItemsPerPage, '');
  };

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  return (
    <div className={CLASSNAME}>
      <div className={`${CLASSNAME}_cards`}>
        {items &&
          items.map((item) => {
            const selectedItem = selectedItems.find((elt) => elt.nuid === item.nuid) !== undefined;
            return (
              <Fragment key={item.nuid}>
                {createElement(component, {
                  showRole,
                  item,
                  color:
                    categories && categories.length > 0
                      ? categories[categories.findIndex((c) => c.key === item.actuality.category)]
                          ?.color
                      : '',
                  selected: selectedItem,
                  handleSelected: () => handleItemSelect(item),
                  handleUpdate: handleUpdateItem && (() => handleUpdateItem(item)),
                  handleDelete: () => handleDeleteItem(item),
                })}
              </Fragment>
            );
          })}
      </div>
      <ScPaginator
        totalItems={totalItems}
        returnChangePage={handleChangePage}
        returnChangeRowsPerPage={handleChangeItemsPerPage}
        page={currentPage}
        rowsPerPage={itemsPerPage}
      />
    </div>
  );
};

ItemsList.defaultProps = {
  items: [],
  selected: [],
  maxPerPage: 10,
  handleSelectedItems: null,
  handleUpdateItem: null,
  handleDeleteItem: null,
  showRole: false,
  categories: [],
};

ItemsList.propTypes = {
  showRole: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.arrayOf(PropTypes.any),
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
  handleSelectedItems: PropTypes.func,
  handleUpdateItem: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  maxPerPage: PropTypes.number,
  component: PropTypes.func.isRequired,
  categories: PropTypes.any,
};

export default ItemsList;
