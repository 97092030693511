import { sessionContext } from '../../_hook/useSession';
import CancelButton from '../../components/generic/CancelButton';
import InputCode from '../../components/generic/InputCode';
import { POST_SECURITY_VALIDATE_EMAIL } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import getDefaultStyles from '../getDefaultStyles';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ValidateEmailStep = (props) => {
  const { setToken } = React.useContext(sessionContext);
  const { nextStep, backStep, prevData } = props;
  const { t } = useTranslation(['words', 'register']);
  const [waiting, setWaiting] = useState(false);
  const { email } = prevData;

  const checkEmailCode = (code) => {
    setWaiting(true);
    apiRest()
      .post(POST_SECURITY_VALIDATE_EMAIL, { email, key: code })
      .then((response) => {
        setToken(response.data.content.token);
        toast.info(t('register:accountValidated'));
        nextStep({
          code,
          user: response.data.content.user,
          token: response.data.content.token,
          refreshToken: response.data.content.refreshToken,
        });
      })
      .catch((error = null) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case 0:
            key = 'register:codeNotMatching';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
        setWaiting(false);
      });
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    content: {
      textAlign: 'center',
      margin: 'auto',
      width: '35%',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        {waiting ? <CircularProgress /> : <InputCode checkValue={checkEmailCode} />}
      </div>
      <div className={classes.buttons}>
        <CancelButton size="large" onClick={() => backStep({})} label={t('previous')} />
      </div>
    </>
  );
};

ValidateEmailStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
  prevData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ValidateEmailStep;
