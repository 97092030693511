import SelectField from '../../components/form/SelectField';
import SuccessButton from '../../components/generic/SuccessButton';
import { GET_SECURITY_DICTIONARY } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { isDev } from '../../helpers/Tools';
import getDefaultStyles from '../getDefaultStyles';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityCategoryStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep, data } = props;
  const { t } = useTranslation(['words', 'register']);
  const [category, setCategory] = useState(data.category || (isDev() && 'enterprise') || null);
  const [categoryItems, setCategoryItems] = useState(
    data.categoryItems ? data.categoryItems.sort((a, b) => a.key > b.key) : []
  );
  const getDictionary = () => {
    const headers = {
      'X-Device': 'desktop',
    };
    apiRest()
      .get(`${GET_SECURITY_DICTIONARY}/communityCategory`, { headers })
      .then((response) => {
        const categories = response.data.content;
        setCategoryItems(categories);
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'register:errorLoadingDictionary';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
      });
  };

  const handleChange = (newValue) => {
    setCategory(newValue.key);
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getDictionary();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.question}>{t('register:selectCategory')}</div>
      <div className={classes.content}>
        <SelectField
          style={{ marginTop: 10 }}
          value={category || ''}
          label={t('words:category')}
          id="category"
          onSelect={handleChange}
          data={categoryItems}
        />
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => backStep({ category, categoryItems })}
          >
            {t('words:previous')}
          </Button>
        )}
        {!lastStep && (
          <SuccessButton
            size="large"
            label={t('words:next')}
            onClick={() => nextStep({ category, categoryItems })}
            disabled={!category}
          />
        )}
      </div>
    </>
  );
};

CommunityCategoryStep.defaultProps = {
  data: {},
};

CommunityCategoryStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityCategoryStep;
