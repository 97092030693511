import theme from '../../pages/theme';
import Loader from '../generic/Loader';
import { makeStyles, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';

const LinkPreview = ({ urlData, isLoading, urlInContent }) => {
  const useStyles = makeStyles(() => ({
    containerGlobal: {
      width: '100%',
      backgroundColor: theme.palette.secondary.light,
      marginBottom: theme.spacing(2),
    },
    imgScrapped: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    containerImgScrapped: {
      height: 200,
      width: '100%',
    },
    containerTextScrapped: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    containerLoader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.containerGlobal}>
      {isLoading ? (
        <div className={classes.containerLoader}>
          <Loader color={theme.palette.primary.main} />
        </div>
      ) : (
        <>
          <div className={classes.containerImgScrapped}>
            {urlData.images && !isEmpty(urlData.images[0]) &&
              <img
                src={urlData?.images[0]}
                className={classes.imgScrapped}
                alt="img scrapped"
              />
            }
          </div>
          <div className={classes.containerTextScrapped}>
            <Typography variant="body2" color="textSecondary" component="p">
              {urlData?.siteName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {urlInContent}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

LinkPreview.propTypes = {
  urlData: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  urlInContent: PropTypes.string.isRequired,
};

export default LinkPreview;
