import './index.scss';
import React, {useEffect, useState} from 'react';
import FileCard from "../fileCard";
import {filesList} from "../types";

interface GalleryProps {
  files: filesList[];
  parentSelect: (files: filesList[]) => void;
  displayEmpty?: boolean;
}

const CLASSNAME = 'Gallery';

const Gallery: React.FC<GalleryProps> = ({files, parentSelect, displayEmpty}) => {
  const select = (id: number) => {
    if (id !== null) {
      files.map((elt) => {
        if (elt.id === id) {
          elt.selected = !elt.selected;
        }
      });
      parentSelect(files);
    }
  }

  return (
    <div className={`${CLASSNAME}`}>
      {files.map((item: filesList, key:number) => {
        if (displayEmpty || item.url) {
          return (
            <FileCard
              key={key}
              id={item.id}
              url={item.url}
              selected={item.selected}
              select={select}
              size={'medium'}
              mimeType={item.mimeType}
            />
          )
        }
      })}
    </div>
  )
}

Gallery.displayName = 'Gallery';

export default Gallery;

