import { GetGroupParams } from '../_types/groupTypes';
import apiRest from '../helpers/apiRest';

const groupSearchApi = '/api/free/community/group/search';
const groupDeleteApi = '/api/admin/group/delete';

const getGroups = async (params: GetGroupParams) => {
  const response = await apiRest().post(groupSearchApi, params);
  const { data } = response;

  return data;
};

const deleteGroup = async (groupNuid: string) => {
  const response = await apiRest().post(groupDeleteApi, {groupNuid});
  const { data } = response;

  return data;
};

export { getGroups, deleteGroup };
