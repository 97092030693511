import { User } from '../../../_types/memberTypes';
import defaultAvatar from '../../../assets/images/default_avatar.png';
import { DIMENSION_HEIGHT } from '../../../constants/dimensions.constants';
import useWindowDimensions from '../../../helpers/getWindowDimensions';
import theme from '../../../pages/theme';
import { makeStyles } from '@material-ui/core';
import React from 'react';

interface ProfileProps {
  user: User;
  collapsed: boolean;
}

const Profile: React.FC<ProfileProps> = ({ user, collapsed }) => {
  // responsive
  const { height } = useWindowDimensions();

  const img = user && user.img ? user.img : defaultAvatar;
  const initials =
    (user?.firstName ? user?.firstName.charAt(0).toUpperCase() : '?') +
    (user?.lastName ? user?.lastName.charAt(0).toUpperCase() : '?');

  const useStyles = makeStyles(() => ({
    fullName: {
      textAlign: 'center',
      fontSize: '1em',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
    },
    profilePhoto: {
      borderRadius: '50%',
      display: 'block',
      height: '50px',
      width: '50px',
      overflow: 'hidden',
      objectFit: 'cover',
      margin: 'auto',
    },
    photoCollapsed: {
      height: '50px',
      width: '50px',
    },
    profileName: {
      fontWeight: 600,
    },
  }));
  const classes = useStyles();

  return (
    <>
      {height >= DIMENSION_HEIGHT && (
        <img
          className={`${classes.profilePhoto} ${collapsed ? classes.photoCollapsed : ''}`}
          src={img}
          alt="user avatar"
        />
      )}
      <span className={classes.profileName}>
        <p className={classes.fullName}>
          {!collapsed ? `${user?.firstName} ${user?.lastName}` : initials}
        </p>
      </span>
    </>
  );
};

Profile.displayName = 'Profile';
export default Profile;
