import React, { useState } from "react";
import "./index.scss";
import Table from "../../components/table";
import {
  GridFilterModel, GridRowModel,
  GridSelectionModel,
  GridSortModel
} from "@mui/x-data-grid";
import EmptyState from "../../components/EmptyState";
import ICON_EVENT from "../../assets/images/icon_event.png";
import { useTranslation } from "react-i18next";
import {
  DeleteRecos, ListReco, RecoStates, ResultSearchReco, SearchRecoParams, UpdateReco
} from "../../_types/recoTypes";
import { searchRecos, updateReco, deleteRecos } from "../../_api/recoApi";
import { sessionContext } from "../../_hook/useSession";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import ScPaginator from "../../components/generic/paginator";
import ToolBarSearchField from "../../components/generic/ToolBarSearchField";
import { ToolBarItemButton } from "../../components/generic/buttons/ToolBarItemsButton";
import YesNoModal, { Sizes } from "../../components/generic/YesNoModal";
import { getColumnsDef } from "./columnDefs";

const RecoPage: React.FC = () => {
  const initialSortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];
  const initialFilterModel: GridFilterModel = { items: [{ id: 1, columnField: 'status', operatorValue: 'is', value: 'not_processed' }]};
  const [states, setStates] = React.useState<RecoStates>({
    total: 0,
    sum: 0,
    searchParams: {
      page: 1,
      maxPerPage: 10,
      sortModel: initialSortModel,
      filterModel: undefined,
      searchString: undefined
    }
  })
  const [rows, setRows] = React.useState([] as ListReco[]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  const { t } = useTranslation(['layout, words, message']);
  const { sessionState, setPageIsLoading, setMessage, setError } = React.useContext(sessionContext);
  const { selectedCommunity, selectedGroup, selectedEvent } = sessionState;

  if (selectedEvent) {
    return null;
  }

  const handleMaxPerPage = async (newItemsPerPage: number) => {
    setStates({
      ...states,
      searchParams: { ...states.searchParams, maxPerPage: newItemsPerPage }
    });
  };

  const handleChangePage = async (newPage: number) => {
    setStates({
      ...states,
      searchParams: { ...states.searchParams, page: newPage }
    });
  };

  const updateRecoData = (result: any) => {
    const data: ResultSearchReco[] = result.data;
    const recos: ListReco[] = [];
    data.map((item: ResultSearchReco) => {
      recos.push({
        id: item.id,
        authorName: `${item.authorLastName} ${item.authorFirstName}`,
        targetName: `${item.targetLastName} ${item.targetFirstName}`,
        date: new Date(item.date),
        object: item?.object,
        status: item?.status,
        amount: item.amount
      });
    })
    setRows(recos);
    setStates({...states, total: result.total, sum: result.sum})
  }

  const handleSearch = (searchString: string) => {
    setStates({
      ...states,
      searchParams: { ...states.searchParams, searchString }
    });
  }

  const search = (params?:SearchRecoParams) => {
    setPageIsLoading(true);
    const value = states.searchParams?.filterModel?.items[0]?.value;
    const filterValue: string[] = Array.isArray(value) ? value : [value];
    const finalParams: SearchRecoParams = {
      communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
      searchString: params?.searchString || states.searchParams.searchString,
      page: params?.page || states.searchParams.page,
      maxPerPage: params?.maxPerPage || states.searchParams.maxPerPage,
      orderBy: params?.orderBy || states.searchParams.sortModel[0].field,
      desc: params?.desc || states.searchParams.sortModel[0].sort === 'desc',
      filterField: params?.filterField || states.searchParams?.filterModel?.items[0]?.columnField,
      filterOperator : params?.filterOperator || states.searchParams?.filterModel?.items[0]?.operatorValue,
      filterValue: params?.filterValue || filterValue,
    };
    searchRecos(finalParams)
      .then((result: any) => {
        updateRecoData(result.content);
        setPageIsLoading(false);
      })
      .catch(() => setPageIsLoading(false));

  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const { id, object, status, amount } = newRow;
    handleUpdateReco({id, object, status, amount}).then();
    const newRows = rows.map((item) => id === item.id ? {...item, object, status, amount} : item);
    setRows(newRows);
    return newRow;
  }



  const handleUpdateReco = async (params: UpdateReco) => {
    const { id, object, status, amount } = params;
    await updateReco({id, object, status, amount}).then(() => {
      search();
    });
  }

  const handleSortModelChange = async (model: GridSortModel) => {
    if (isEmpty(model)) {
      model = initialSortModel;
    }
    setStates({
      ...states,
      searchParams: { ...states.searchParams, sortModel: model }
    });
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    if (isEmpty(model)) {
      model = initialFilterModel;
    }
    const statusItem = model.items[0];
    if (statusItem !== undefined) {
      const { value } = statusItem;
      if (!isEmpty(value)) {
        setStates({
          ...states,
          searchParams: { ...states.searchParams, filterModel: model }
        });
      } else {
        setStates({
          ...states,
          searchParams: { ...states.searchParams, filterModel: undefined }
        });
      }
    }
  };

  const handleDelete = () => {
    setModalIsOpen(false);
    setPageIsLoading(true);
    deleteRecos({
      communityNuid: selectedGroup?.nuid || selectedCommunity.nuid,
      nuids: selectionModel
    } as DeleteRecos).then(() => {
      search();
      setPageIsLoading(false);
      setMessage(t('message:recoDeleted', { count: selectionModel.length }))
      setSelectionModel([]);
    }).catch(() => {
      setPageIsLoading(false);
      setError(t('message:recoDeletedError'))
    });
  }

  React.useEffect(() => {
    if (selectedGroup || selectedCommunity) {
      search();
    }
  }, [selectedGroup, selectedCommunity, btoa(JSON.stringify(states.searchParams))]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      // options supplémentaires ici si nécessaire
    }).format(value);
  }

  const CLASSNAME = 'reco'

  return (
    <div className={`${CLASSNAME}`}>
      <h1>{capitalize(t("words:recos"))}</h1>
      <div className={`${CLASSNAME}_topBar`}>
        <ToolBarSearchField
          onSearch={handleSearch}
          value={states.searchParams.searchString}
          label={'recoSearch'}
        />
        <div className={`${CLASSNAME}_horizontalMarge`}>
          <ToolBarItemButton
            label={capitalize(t('layout:recoDelete'))}
            action={() => setModalIsOpen((prevState) => !prevState)}
            isDisabled={selectionModel.length === 0}
          />
        </div>
      </div>
      <div className={`${CLASSNAME}_table`}>
        <Table
          disableSelectionOnClick={true}
          columns={getColumnsDef()}
          onPageChange={() => {}}
          onPageSizeChange={() => {}}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          processRowUpdate={processRowUpdate}
          noRowsMessage={
            <EmptyState
              image={<img src={ICON_EVENT} alt={"icon_event"} />}
              message={t("event:noEventYet")}
            />
          }
          page={states.searchParams.page}
          rowCount={10}
          rows={rows}
          sortableColumns={["authorName", "targetName", "date", "status", "amount"]}
          sortModel={states.searchParams.sortModel}
          onSelectionModelChange={(newSelectionModel:GridSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableColumnFilter={false}
          disableColumnMenu={false}
        />
      </div>
      <div className={`${CLASSNAME}_footer`}>
        <p>{`${capitalize(t('word:total'))} : ${formatCurrency(states.sum)}`}</p>
      </div>
      <ScPaginator
        totalItems={states.total}
        returnChangePage={handleChangePage}
        returnChangeRowsPerPage={handleMaxPerPage}
        page={states.searchParams.page}
        rowsPerPage={states.searchParams.maxPerPage}
      />
      <YesNoModal
        noAction={() => setModalIsOpen(false)}
        open={modalIsOpen}
        size={Sizes.sm}
        // @ts-ignore
        title={t('message:recoModalTitle', {count: selectionModel.length})}
        text={t('message:recoModalConfirmation', {count: selectionModel.length})}
        yesAction={handleDelete}
        yesLabel={t('words:yes')}
        noLabel={t('words:no')}
      />
    </div>
  )
}


export default RecoPage;
