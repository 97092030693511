import { InputAdornment, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import capitalize from 'lodash/capitalize';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SearchField = (props) => {
  const { t } = useTranslation(['layout']);
  const { onSearch, label, color, value } = props;
  const [searchValue, setSearchValue] = useState(value);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(searchValue);
    }
  };

  const handleDelete = () => {
    setSearchValue('');
    onSearch('');
  };

  const useStyles = makeStyles(() => ({
    input: {
      width: 650,
    },
  }));
  const classes = useStyles();

  return (
    <TextField
      className={classes.input}
      id="searchField"
      type="text"
      margin="dense"
      size="small"
      color={color}
      value={searchValue}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      variant="outlined"
      label={capitalize(t(`layout:${label}`))}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CloseIcon onClick={handleDelete} style={{ cursor: 'pointer' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchField.defaultProps = {
  value: '',
  color: 'secondary',
  label: null,
};

SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string,
};

export default SearchField;
