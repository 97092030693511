import AppToolTip from '../../../helpers/AppToolTip';
import './index.scss';
import { IconButton } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WelcomeProps {
  userName: string;
  communityName: string | undefined;
  groupName: string | undefined;
  validationCode: string;
}

const CLASSNAME = 'welcome';

const Welcome: React.FC<WelcomeProps> = ({
  userName,
  communityName,
  groupName,
  validationCode,
}) => {
  const { t } = useTranslation(['home']);

  const [isCopied, setIsCopied] = React.useState(false);

  return (
    <div className={CLASSNAME}>
      <span className="font-title">
        {t('welcomeMessage')[0].toUpperCase() + t('welcomeMessage').slice(1)} {userName}
      </span>
      <span className="font-paragraph-semiBold" style={{ fontWeight: 600 }}>
        {t('lastNewsOn')[0].toUpperCase() + t('lastNewsOn').slice(1) + ' '}
        <span className={`${CLASSNAME}_messageHighlight`}>{communityName}</span>
        {groupName && <span className={`${CLASSNAME}_messageHighlight`}>{`-${groupName}`}</span>}
      </span>
      {validationCode && (
        <span className="font-paragraph-semiBold">
          {t('validationCode')}{' '}
          <IconButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(validationCode);
              setIsCopied(true);
            }}
          >
            {/*@ts-ignore*/}
            <AppToolTip title={isCopied ? <>{t('copied')}</> : <>{t('copy')}</>}>
              <div className={`${CLASSNAME}_copy`}>
                <span className={`font-paragraph-semiBold ${CLASSNAME}_messageHighlight`}>
                  {validationCode}
                </span>
                <ContentCopyIcon width={10} className={`${CLASSNAME}_copy_icon`} />
              </div>
            </AppToolTip>
          </IconButton>
        </span>
      )}
    </div>
  );
};

Welcome.displayName = 'Welcome';
export default Welcome;
