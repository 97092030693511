import { sessionContext } from '../../../_hook/useSession';
import theme from '../../../pages/theme';
import TagList from '../../generic/TagList';
import TagCommunities from '../TagCommunities';
import './index.scss';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import Place from '@material-ui/icons/Place';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'profile';

interface ProfileProps {
  handleSelected: Function;
  item: any;
  isRoleVisible: boolean;
  isSelected: boolean;
}

const Profile: React.FC<ProfileProps> = ({ handleSelected, item, isRoleVisible, isSelected }) => {
  const { t } = useTranslation('members');
  // useSession
  const { sessionState } = React.useContext(sessionContext);
  const { selectedGroup, selectedCommunity } = sessionState;
  const { communityLeaders } = selectedCommunity;
  const groupLeaders = selectedGroup?.communityLeaders;
  const isCL =
    isRoleVisible &&
    communityLeaders?.find((elt: { nuid: string }) => elt.nuid === item.nuid) !== undefined;
  const isGL =
    isRoleVisible &&
    groupLeaders?.find((elt: { nuid: string }) => elt.nuid === item.nuid) !== undefined &&
    !isCL;

  const [isExpanded, setIsExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const getRequestType = () => {
    if (item.memberShip) {
      return t(`members:${item.memberShip.requestType}`);
    }
    return '';
  };

  const getBadge = () => {
    if (isCL) {
      return ' (CL)';
    }
    if (isGL) {
      return ' (GL)';
    }
    return '';
  };

  return (
    <Card
      className={CLASSNAME}
      variant="outlined"
      style={{ border: isSelected ? 'solid 3px orange' : 'solid 1px lightGrey' }}
    >
      {item.img ? (
        <CardMedia
          image={item.img}
          className={`${CLASSNAME}_media`}
          onClick={() => handleSelected(item)}
        />
      ) : (
        <div
          aria-hidden="true"
          className={`${CLASSNAME}_media`}
          onClick={() => handleSelected(item)}
        >
          <PersonIcon fontSize="large" />
        </div>
      )}
      <CardHeader
        onClick={() => handleSelected(item)}
        title={
          <div className={`${CLASSNAME}_title`}>
            {`${item.firstName || ''} ${item.lastName || ''} ${getBadge()}`}
            <p className={`${CLASSNAME}_title_company`}>{item.company}</p>
          </div>
        }
        subheader={
          <div className={`${CLASSNAME}_subHeader`}>
            <p className={`${CLASSNAME}_subHeader_position`}>{item.currentPosition}</p>
            {item.localisation && (
              <i>
                <Place color="secondary" fontSize="small" />
                <p>{`${item.localisation.town}, ${item.localisation.country}`}</p>
              </i>
            )}
            {item.activitySector && (
              <i>
                <BusinessIcon color="secondary" fontSize="small" />
                <p>{item.activitySector.value}</p>
              </i>
            )}
            <Collapse
              className={`${CLASSNAME}_collapse`}
              in={isExpanded}
              timeout="auto"
              unmountOnExit
            >
              <CardContent onClick={() => handleSelected(item)} />
              {item.email && (
                <i>
                  <MailOutlineIcon color="secondary" fontSize="small" />
                  <p>{item.email}</p>
                </i>
              )}
              {item.memberShip && (
                <div className={`${CLASSNAME}_memberShip`}>
                  <p>{t('members:requestMembership')}</p>
                  <p>{`${t('words:type')}: ${getRequestType()}`}</p>
                  <p>{t('words:message')}</p>
                  <p>{item.memberShip.message}</p>
                </div>
              )}
              <p>{item.biography ?? t('noDescription')}</p>
              {item.skills && item.skills.length > 0 && (
                <div className={`${CLASSNAME}_content`}>
                  <p>{t('words:skills')}</p>
                  <TagList
                    bold
                    negative
                    items={item.skills.map((elt: { key: any; value: any }) => ({
                      key: elt.key,
                      value: elt.value,
                    }))}
                    small
                    color={theme.palette.primary.main}
                  />
                </div>
              )}
              {item.objectives && item.objectives.length > 0 && (
                <div className={`${CLASSNAME}_content`}>
                  <p>{t('words:objectives')}</p>
                  <TagList
                    bold
                    negative
                    items={item.objectives.map((elt: { key: any; value: string }) => ({
                      key: elt.key,
                      value: elt.value.split('@')[1],
                    }))}
                    small
                  />
                </div>
              )}
            </Collapse>
          </div>
        }
      />
      <CardContent className={`${CLASSNAME}_cardContent`}>
        <TagCommunities items={item.communities} />
      </CardContent>
      <CardActions className={`${CLASSNAME}_expandedIcon`}>
        <IconButton
          style={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

Profile.displayName = 'Profile';
export default Profile;
