import { Community } from '../_types/communityTypes';
import { Group } from '../_types/groupTypes';

const getEnabledStatus = (event: any, group: Group | null, community: Community | null) => {
  if (event) {
    return event?.enabled;
  } else if (group) {
    return group?.enabled;
  } else {
    return community?.enabled;
  }
};

export default getEnabledStatus;
