import './index.scss';
import React from 'react';
import ToolBarItemButton from "../../generic/ToolBarItemButton";

enum Directions {
  row,
  column,
}

interface ActionPanelProps {
  direction: Directions;
  actions: {
    label: string;
    disabled: boolean;
    action: (...args:any) => any;
    type?: string;
    acceptedFiles?: string[];
    multiple?: boolean;
  }[];
}

const CLASSNAME = 'ActionPanel';

const ActionPanel: React.FC<ActionPanelProps> = ({
  direction, actions
}) => {
  let classes = `${CLASSNAME} `;
  if (direction === Directions.row) {
    classes += `${CLASSNAME}_row`;
  } else {
    classes += `${CLASSNAME}_column`;
  }

  return (
    <div className={classes}>
      {actions.map((action, index) => (
         <ToolBarItemButton
           key={index}
           type={action.type}
           action={action.action}
           acceptedFiles={action.acceptedFiles}
           multiple={action.multiple}
           label={action.label}
           disabled={action.disabled}
           style={{paddingBottom: 5}}
         />
      ))}
    </div>
  )
}

ActionPanel.displayName = 'ActionPanel';

export {ActionPanel, Directions};
