import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AppTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(Tooltip);

export default AppTooltip;
