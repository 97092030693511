import theme from '../../pages/theme';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActualityActionsProps {
  status: string;
  firstName: string;
  lastName?: string;
  editAction?: Function | null;
  publishAction?: Function | null;
  deleteAction?: Function | null;
}

const ActualityActions: React.FC<ActualityActionsProps> = ({
  status,
  firstName = '',
  lastName,
  editAction = null,
  publishAction = null,
  deleteAction = null,
}) => {
  const { t } = useTranslation(['words']);

  // @ts-ignore
  const useStyles = makeStyles(() => ({
    container: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid lightGrey',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    status: {
      fontSize: 12,
      color: theme.palette.secondary,
    },
    name: {
      color: 'black',
      fontWeight: '600',
      fontSize: 12,
    },
    textZone: {
      paddingLeft: theme.spacing(1),
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      cursor: 'pointer',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    activeDelete: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      cursor: 'pointer',
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: theme.spacing(1),
    },
    inactive: {
      backgroundColor: theme.palette.secondary.light,
      color: 'white',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textZone}>
        <Typography variant="subtitle2" className={classes.name}>
          {`${t('publisher')}: ${firstName} ${lastName}`}
        </Typography>
        <Typography variant="subtitle2" className={classes.status}>
          {`${t('status')}: ${t(status)}`}
        </Typography>
      </div>
      <div className={classes.actions}>
        {editAction && (
          // @ts-ignore
          <Avatar className={editAction ? classes.active : classes.inactive} onClick={editAction}>
            <EditIcon />
          </Avatar>
        )}
        {publishAction && (
          // @ts-ignore
          <Avatar
            className={status !== 'published' && publishAction ? classes.active : classes.inactive}
            onClick={publishAction}
          >
            <PublishIcon />
          </Avatar>
        )}
        {deleteAction && (
          // @ts-ignore
          <Avatar
            className={deleteAction ? classes.activeDelete : classes.inactive}
            onClick={deleteAction}
          >
            <DeleteIcon />
          </Avatar>
        )}
      </div>
    </div>
  );
};

ActualityActions.displayName = 'ActualityActions';
export default ActualityActions;
