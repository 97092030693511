import { DIMENSION_PHONE } from '../../../constants/dimensions.constants';
import useWindowDimensions from '../../../helpers/getWindowDimensions';
import getGrades from './helper/getGrades';
import './index.scss';
import capitalize from 'lodash/capitalize';
import React from 'react';

export interface CardSingleNumberProps {
  action?: () => void;
  color?: string;
  isLinkforce?: boolean;
  label: string;
  subLabel?: string;
  value: number;
}

const CLASSNAME = 'card';

export const CardSingleNumber: React.FC<CardSingleNumberProps> = ({
  action,
  color,
  isLinkforce,
  label,
  subLabel,
  value,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div className={CLASSNAME}>
      <div className={`${CLASSNAME}_labels`}>
        {action ? (
          <button className="font-paragraph-bold" onClick={action}>
            {capitalize(label)}
          </button>
        ) : (
          <p className="font-paragraph-bold">{capitalize(label)}</p>
        )}
        {subLabel && width >= DIMENSION_PHONE && (
          <span className={`${CLASSNAME}_subLabel font-caption-medium-light`}>
            {`(${capitalize(subLabel)})`}
          </span>
        )}
      </div>

      <span className={`${CLASSNAME}_value font-title-variant`} style={{ color }}>
        {value} {isLinkforce && getGrades(value)}
      </span>
    </div>
  );
};

CardSingleNumber.displayName = 'CardNumberInfo';
