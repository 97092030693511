import ImportMembersFormFooter from './ImportMembersFormFooter';
import './index.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import validation from 'validate.js';
import { sessionContext } from "../../../../_hook/useSession";
import { DictionaryType } from "../../../../_types/sessionTypes";

const CLASSNAME = 'importMembersForm';

interface ImportedUser {
  phoneNumber?: string,
  professionalActivity?: string;
  entryDate?: string;
  customDate?: string;
  groups?: string;
  company?: string;
  currentPosition?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

interface UsersWithErrors extends ImportedUser {
  errors: ImportedUser;
}

interface ImportMembersFormProps {
  data: string[][];
  exportStates?: (p: {
    isValid: boolean;
    users: (UsersWithErrors | null)[];
    sendEmail: string;
  }) => void;
}

validation.validators.date = (value: string) => {
  // Regex pour valider une date au format jj/mm/aaaa
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  if (!value) {
    return;
  }
  if (!dateRegex.test(value)) {
    return "doit être au format jj/mm/aaaa";
  }
};

validation.validators.semiColonSeparated = (value: string): string | undefined => {
  if (!value) {
    return;
  }
  const elements = value.split(';');
  if (elements.some(e => e.trim() === "")) {
    return "doit contenir des éléments non vides séparés par ';'";
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ImportMembersForm: React.FC<ImportMembersFormProps> = ({ data, exportStates}) => {
  const { t } = useTranslation(['words', 'members']);

  const [users, setUsers] = React.useState<(UsersWithErrors | null)[]>([]);
  const [sendEmail, setSendEmail] = React.useState('default');

  const { sessionState } = React.useContext(sessionContext);
  const status = sessionState.dictionaries?.professionalActivity;

  React.useEffect(() => {
    const constraints = {
      email: { presence: true, email: true },
      firstName: { presence: { allowEmpty: false } },
      lastName: { presence: { allowEmpty: false } },
      company: {},
      currentPosition: {},
      professionalActivity: {
        inclusion: {
          within: status.map((item:DictionaryType) => item.value.toLowerCase())
        }
      },
      phoneNumber: {},
      entryDate: { date: true },
      customDate: { date: true },
      groups: { semiColonSeparated: true },
    };

    const checkedData =
      data &&
      data.map((row) => {
        if (row && row.join('').trim().length > 0) {
          const cleanData: string[] = [];

          row.forEach((value, index) => {
            cleanData[index] = String(value).replace(/"/g, '').trim();
          });

          const firstName = 0 in cleanData ? cleanData[0].charAt(0).toUpperCase() + cleanData[0].slice(1).toLowerCase(): undefined;
          const lastName = 1 in cleanData ? cleanData[1].charAt(0).toUpperCase() + cleanData[1].slice(1).toLowerCase() : undefined;
          const email = 2 in cleanData ? cleanData[2] : undefined;
          const company = 3 in cleanData ? cleanData[3] : undefined;
          const currentPosition = 4 in cleanData ? cleanData[4] : undefined;
          const professionalActivity = 5 in cleanData ? cleanData[5] : undefined;
          const groups = 6 in cleanData ? cleanData[6] : undefined;
          const phoneNumber = 7 in cleanData ? cleanData[7] : undefined;
          const entryDate = 8 in cleanData ? cleanData[8] : undefined;
          const customDate = 9 in cleanData ? cleanData[9] : undefined;

          const user: ImportedUser = {
            firstName, lastName, email, company, currentPosition, professionalActivity, groups, phoneNumber, entryDate, customDate,
          };

          const normalizedUser: ImportedUser = {
            firstName,
            lastName,
            email,
            company,
            currentPosition,
            professionalActivity: professionalActivity?.toLowerCase(),
            groups,
            phoneNumber,
            entryDate,
            customDate,
          };

          const errors = validation.validate(normalizedUser, constraints);
          return { ...user, errors };
        }
        return null;
      });

    const testValidity = checkedData.every((elt) => !elt || (elt && elt.errors === undefined));
    setUsers(checkedData);
    if (exportStates) {
      exportStates({ users: checkedData, isValid: testValidity, sendEmail: sendEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sendEmail]);

  return (
    <>
      <Table className={CLASSNAME} stickyHeader size="small" aria-label="user list">
        <TableHead>
          <TableRow>
            <TableCell key={0}>#</TableCell>
            <TableCell key={1}>{t('firstName')}</TableCell>
            <TableCell key={2}>{t('lastName')}</TableCell>
            <TableCell key={3}>{t('email')}</TableCell>
            <TableCell key={4}>{t('company')}</TableCell>
            <TableCell key={5}>{t('currentPosition')}</TableCell>
            <TableCell key={6}>{t('status')}</TableCell>
            <TableCell key={7}>{t('groups')}</TableCell>
            <TableCell key={8}>{t('phoneNumber')}</TableCell>
            <TableCell key={9}>{t('entryDate')}</TableCell>
            <TableCell key={10}>{t('customDate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users.map(
              (row, index) =>
                row && (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow hover key={`${index}_${row.email}`}>
                    <TableCell className={row.errors ? `${CLASSNAME}_error` : ''}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className={row.errors && row.errors.firstName ? `${CLASSNAME}_error` : ''}
                    >
                      {row.firstName}
                    </TableCell>
                    <TableCell
                      className={row.errors && row.errors.lastName ? `${CLASSNAME}_error` : ''}
                    >
                      {row.lastName}
                    </TableCell>
                    <TableCell
                      className={row.errors && row.errors.email ? `${CLASSNAME}_error` : ''}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      className={row.errors && row.errors.company ? `${CLASSNAME}_error` : ''}
                    >
                      {row.company}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.currentPosition ? `${CLASSNAME}_error` : ''}
                    >
                      {row.currentPosition}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.professionalActivity ? `${CLASSNAME}_error` : ''}
                    >
                      {row.professionalActivity}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.groups ? `${CLASSNAME}_error` : ''}
                    >
                      {row.groups}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.phoneNumber ? `${CLASSNAME}_error` : ''}
                    >
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.entryDate ? `${CLASSNAME}_error` : ''}
                    >
                      {row.entryDate}
                    </TableCell>
                    <TableCell
                      className={
                        row.errors && row.errors.customDate ? `${CLASSNAME}_error` : ''}
                    >
                      {row.customDate}
                    </TableCell>
                  </TableRow>
                )
            )}
        </TableBody>
      </Table>
      <ImportMembersFormFooter sendEmail={sendEmail} setSendEmail={setSendEmail} />
    </>
  );
};

ImportMembersForm.displayName = 'ImportMembersForm';

export default ImportMembersForm;
