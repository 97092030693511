import { MembersType } from '../../_api/membersApi';
import useMember from '../../_hook/useMember';
import SelectField from '../../components/form/SelectField';
import LinkPreview from '../../components/links/LinkPreview';
import categoriesWithTarget from '../../constants/categoriesWithTarget';
import { regexHttpsHard, regexHttpsLight } from '../../constants/regex';
import theme from '../theme';
import './index.scss';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getLinkPreview } from 'link-preview-js';
import { isEmpty, replace } from 'lodash';
import capitalize from 'lodash/capitalize';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validation from 'validate.js';
import FilesUploader from '../../components/filesUploader';

const ActualityForm = (props) => {
  const { exportStates, fields, item, communityOrGroupId } = props;
  const [currentTarget, setCurrentTarget] = useState(null);
  const { t } = useTranslation(['words', 'error', 'layout', 'actuality']);

  // useMember
  const { membersState, getMembers } = useMember();
  const initialState = {
    message: null,
    category: null,
    picture: null,
    pictures: [],
    documents: [],
    videos: [],
    targetMember: null,
    status: 'ready',
    nuid: null,
  };
  const [states, setStates] = useState(initialState);
  const [errorStates, setErrorStates] = useState(initialState);

  const constraints = {
    message: {
      presence: { allowEmpty: false },
      length: {
        minimum: 1,
        message: 'actualityMessageTooShort',
      },
    },
    category: { presence: { allowEmpty: false } },
    pictures: {},
    targetMember: {},
  };
  const [urlInContent, setUrlInContent] = useState([]);
  const [currentScrappedUrl, setCurrentScrappedUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const BASE_URL = 'https://iqqda8f3kh.execute-api.eu-west-3.amazonaws.com/dev?url=';
  const expHttp = regexHttpsLight;
  const regexHttps = regexHttpsHard;

  const handleChange = (event) => {
    const result = event.target.value === '' ? null : event.target.value;
    setStates({
      ...states,
      [event.target.id]: result,
    });
  };

  const handleSearchSelect = (event, newValue) => {
    if (newValue) {
      setCurrentTarget(newValue);
      setStates({
        ...states,
        targetMember: newValue.email,
      });
    } else {
      setStates({
        ...states,
        targetMember: null,
      });
      setCurrentTarget(null);
    }
  };

  const onSelectCategory = (data) => {
    if (!categoriesWithTarget().includes(data?.key)) {
      setStates({
        ...states,
        targetMember: null,
        category: data.key,
      });
      setCurrentTarget(null);
    } else {
      setStates({
        ...states,
        category: data.key,
      });
    }
  };

  const handleChangePictures = (pictures) => {
    setStates({
      ...states,
      pictures,
    });
  };

  const handleChangeDocuments = (documents) => {
    setStates({
      ...states,
      documents,
    });
  };

  const handleChangeVideos = (videos) => {
    setStates({
      ...states,
      videos,
    });
  };

  useEffect(() => {
    const cleanText = replace(states.message, /<[^>]+>/g, '');
    const httpArray = cleanText.split(expHttp);
    setUrlInContent([]);
    httpArray.map((row) => {
      if (row.match(regexHttps)) {
        setUrlInContent((oldArray) => [...oldArray, row]);
      }

      return urlInContent;
    });
    if (isEmpty(urlInContent) && currentScrappedUrl) {
      setCurrentScrappedUrl(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.message]);

  React.useEffect(() => {
    async function parseLink() {
      try {
        if (!isEmpty(urlInContent) && !currentScrappedUrl) {
          setIsLoading(true);
          getLinkPreview(`${BASE_URL}${urlInContent[0]}`, {
            headers: {
              apiKey: '',
            },
          })
            .then((data) => {
              setCurrentScrappedUrl(data);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      } catch (e) {
        setCurrentScrappedUrl(null);
        setIsLoading(false);
      }
    }

    parseLink().then();
  }, [urlInContent]);

  useEffect(() => {
    getMembers(
      communityOrGroupId,
      MembersType.members,
      1,
      membersState.membersParams.maxPerPage,
      ''
    ).then();

    if (item) {
      const { actuality, target } = item;
      setStates({
        message: actuality.message,
        category: actuality.category,
        picture: actuality.picture,
        pictures: actuality.pictures,
        documents: actuality.document ? [actuality.document] : [],
        videos: actuality.video ? [actuality.video] : [],
        targetMember: target ? target.email : null,
        status: 'published',
        nuid: item.nuid,
      });
      setCurrentTarget(target);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [item]);

  useEffect(() => {
    const checkValidity = (errors) => errors === undefined;
    const errors = validation.validate(states, constraints);
    setErrorStates({ ...initialState, ...errors });
    exportStates({
      ...states,
      isValid: checkValidity(errors),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  const useStyles = makeStyles(() => ({
    containerForm: {
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const data = () => {
    const returnFields = [];
    for (let i = 0; i < fields?.length; i += 1) {
      if (fields[i].key !== 'new member' && fields[i].key !== 'new event' && fields[i].key !== 'new attendee') {
        returnFields.push({
          key: fields[i].key,
          value: capitalize(fields[i].translation),
        });
      }
    }
    return returnFields;
  };

  return (
    <div className={classes.containerForm}>
      <SelectField
        id="category"
        label={t('category')}
        value={states.category || ''}
        onSelect={onSelectCategory}
        data={data()}
      />
      <Autocomplete
        ListboxProps={{
          onScroll: async (event) => {
            const node = event.currentTarget;
            if (node.scrollTop + node.clientHeight === node.scrollHeight) {
              await getMembers(
                communityOrGroupId,
                MembersType.members,
                1,
                membersState.membersParams.maxPerPage * 2,
                ''
              );
            }
          },
        }}
        id="targetMember"
        size="small"
        options={membersState?.members?.users || []}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionSelected={(option, value) => option.email === value.email}
        value={currentTarget}
        onChange={handleSearchSelect}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            variant="outlined"
            margin="dense"
            label={t('actuality:targetMember')}
            onChange={async (event) => {
              await getMembers(
                communityOrGroupId,
                MembersType.members,
                1,
                membersState.membersParams.maxPerPage,
                event.target.value
              );
            }}
          />
        )}
      />
      <TextField
        variant="outlined"
        margin="normal"
        error={states.message && errorStates.message !== null}
        required
        fullWidth
        value={states.message || ''}
        id="message"
        label={t('actuality:message')}
        multiline
        type="text"
        helperText={
          states.message && (errorStates.message ? t('error:actualityMessageTooShort') : ' ')
        }
        style={{ minHeight: '50px' }}
        onChange={handleChange}
      />
      {urlInContent && currentScrappedUrl && Object.keys(urlInContent).length > 0 && (
        <LinkPreview
          urlData={currentScrappedUrl}
          isLoading={isLoading}
          urlInContent={urlInContent[0]}
        />
      )}
      {!states.link && isEmpty(states.documents) && (
        <FilesUploader
          title={t('layout:addPictures')}
          files={states?.pictures || [states?.picture] || []}
          onChange={handleChangePictures}
          mimeTypes={['image/png', 'image/jpeg', 'image/gif']}
          maxFiles={10}
          maxSize={10000000}
        />
      )}
      {!states.link && isEmpty(states.pictures) && isEmpty(states.picture) && (
        <FilesUploader
          title={t('layout:addDocument')}
          files={states?.documents || []}
          onChange={handleChangeDocuments}
          mimeTypes={['application/pdf']}
          maxFiles={1}
          maxSize={10000000}
        />
      )}
      {!states.link && isEmpty(states.pictures) && isEmpty(states.picture) && isEmpty(states.documents) && (
        <FilesUploader
          title={t('layout:addVideo')}
          files={states?.videos || []}
          onChange={handleChangeVideos}
          mimeTypes={['video/mp4', 'video/quicktime', 'video/mpeg']}
          maxFiles={1}
          maxSize={200000000}
        />
      )}
    </div>
  );
};

ActualityForm.defaultProps = {
  communityOrGroupId: '',
  exportStates: () => {},
  item: null,
  fields: null,
  total: 0,
};

ActualityForm.propTypes = {
  communityOrGroupId: PropTypes.string,
  exportStates: PropTypes.func,
  fields: PropTypes.any,
  item: PropTypes.objectOf(PropTypes.any),
  total: PropTypes.number,
};

export default ActualityForm;
