import { Community } from '../_types/communityTypes';
import { EventCompletedInformation } from '../_types/eventTypes';
import { Group } from '../_types/groupTypes';
import { CommunityObjectives, Dictionaries, SessionState } from '../_types/sessionTypes';
import WebStorageManagement from '../helpers/WebStorageManager';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: SessionState = {
  error: null,
  message: null,
  result: null,

  pageIsLoading: false,
  mapsIsLoading: false,

  user: WebStorageManagement.getValue('user'),
  token: WebStorageManagement.getValue('token'),
  refreshToken: WebStorageManagement.getValue('refreshToken'),
  email: '',
  password: '',

  dictionaries: [],
  communities: [],

  selectedEvent: null,
  selectedGroup: null,
  selectedCommunity: null,

  linkforce: 0,
  objectives: {},
  lastOnboardedUsers: [],
  lastGuestedUsers: [],
};

const reducers = {
  setError: (state: any, action: PayloadAction<string | null>): void => {
    set(state, 'error', action.payload);
  },
  setMessage: (state: any, action: PayloadAction<string | null>): void => {
    set(state, 'message', action.payload);
  },
  setResult: (state: any, action: PayloadAction<string | null>): void => {
    set(state, 'result', action.payload);
  },

  setPageIsLoading: (state: any, action: PayloadAction<boolean>): void => {
    set(state, 'pageIsLoading', action.payload);
  },
  setMapsIsLoading: (state: any, action: PayloadAction<boolean>): void => {
    set(state, 'mapsIsLoading', action.payload);
  },

  setUser: (state: any, action: PayloadAction<any>): void => {
    set(state, 'user', action.payload);
  },
  setToken: (state: any, action: PayloadAction<string | null>): void => {
    set(state, 'token', action.payload);
  },
  setRefreshToken: (state: any, action: PayloadAction<string | null>): void => {
    set(state, 'refreshToken', action.payload);
  },
  setEmail: (state: any, action: PayloadAction<string>): void => {
    set(state, 'email', action.payload);
  },
  setPassword: (state: any, action: PayloadAction<string>): void => {
    set(state, 'password', action.payload);
  },

  setDictionaries: (state: any, action: PayloadAction<Dictionaries[]>): void => {
    set(state, 'dictionaries', action.payload);
  },
  setCommunities: (state: any, action: PayloadAction<Community[] | null>): void => {
    set(state, 'communities', action.payload);
  },

  setSelectedEvent: (state: any, action: PayloadAction<EventCompletedInformation | null>): void => {
    set(state, 'selectedEvent', action.payload);
  },
  setSelectedGroup: (state: any, action: PayloadAction<Group | null>): void => {
    set(state, 'selectedGroup', action.payload);
  },
  setSelectedCommunity: (state: any, action: PayloadAction<Community | null>): void => {
    set(state, 'selectedCommunity', action.payload);
  },

  setLinkforce: (state: any, action: PayloadAction<number>): void => {
    set(state, 'linkforce', action.payload);
  },
  setObjectives: (state: any, action: PayloadAction<CommunityObjectives>): void => {
    set(state, 'objectives', action.payload);
  },
  setLastOnboardedUsers: (state: any, action: PayloadAction<any[]>): void => {
    set(state, 'lastOnboardedUsers', action.payload);
  },
  setLastGuestedUsers: (state: any, action: PayloadAction<any[]>): void => {
    set(state, 'lastGuestedUsers', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'session',
  initialState,
  reducers,
});
