const actualityFr = {
  // A
  actualityCreated: 'Votre actualité est publiée',
  actualityDeleted: "L'actualité à bien été supprimée.",
  actualityImageLabel:
    'Taille max : 10Mo, extensions: GIF, JPG, PNG / Format recommandé : 1200 x 900 px',
  actualityUpdated: "L'actualité à été mise à jour",
  // C
  comments_zero: 'aucun commentaire',
  comments_one: 'un commentaire',
  comments_other: '{{count}} commentaires',
  createActuality: 'Publier une actualité',
  // D
  deleteActuality: 'Supprimer une actualité',
  deleteActualityConfirm: 'Voulez-vous vraiment supprimer cette actualité ?',
  dropZone: 'Déposez un fichier ici pour l\'ajouter, ou cliquez sur le bouton \'ajouter\'',
  // E
  emptyActualitiesAuthor: 'Raymond Quenneau',
  emptyActualitiesQuote: "Les actualités d'aujourd'hui, c'est l'histoire de demain.",
  emptyAgoraAuthor: 'Grand Corps Malade',
  emptyActualitiesMessage:
    "Votre communauté n'à pas encore d'actualité, mais ça ne vas pas tarder...",
  emptyAgoraQuote: "Apprendre, partager, tant qu'y à de l'échange, y'à de l'espoir.",
  // F
  filterActualities: 'filtrer par tag :',
  filterActualitiesReset: 'réinitialiser',
  filterButtonApply: 'appliquer',
  // M
  message: 'Rédigez votre publication',
  'mutual aid': 'Entraide',
  // P
  publication: 'Publication',
  publishActuality: 'publier une actualité',
  publishLive: 'publier un Live',
  // R
  requiredFields: 'publiez votre {{entity}} pour commencer à publier des actualités',
  // T
  toComment: 'COMMENTER',
  toLike: "J'AIME",
  targetMember: 'Membre à mettre en avant',
  // U
  updateActuality: 'Modifier une actualité',
  // W
  waitingActuality: "La création de l'actualité est en cours.\nMerci de patienter",
  webLink: 'Lien URL vers un site web',
};

export default actualityFr;
