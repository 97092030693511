import {
  createCommunityOrGroup as createCommunityOrGroupAPI,
  getClCommunities as getClCommunitiesAPI,
  getDictionaries as getDictionariesAPI,
  getDictionary,
  getLastGuestedUsers as getLastGuestedUsersAPI,
  getLastOnboardedUsers as getLastOnboardedUsersAPI,
  getLinkforce as getLinkforceAPI,
  getObjectives as getObjectivesAPI,
  login as loginAPI,
  searchLocalisation as searchLocalisationAPI,
  updateCommunityOrGroup as updateCommunityOrGroupAPI,
} from '../_api/sessionApi';
import {
  actions as sessionActions,
  initialState as sessionInitialState,
  reducer as sessionReducer,
} from '../_slice/sessionSlice';
import { Community } from '../_types/communityTypes';
import { EventCompletedInformation } from '../_types/eventTypes';
import { Group } from '../_types/groupTypes';
import { LoginParams } from '../_types/sessionTypes';
import displayError from '../helpers/displayError';
import { encodeImageFileAsURL } from '../helpers/Tools';
import WebStorageManagement from '../helpers/WebStorageManager';
import useCommunity from './useCommunity';
import crypto from 'crypto';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import React from 'react';
import { useTranslation } from 'react-i18next';

const sessionContext = React.createContext<any>(sessionInitialState);
const { Provider } = sessionContext as any;

const Session: React.FC<{ children: any }> = ({ children }) => {
  const { t } = useTranslation(['connect', 'error', 'message']);

  const [sessionState, dispatch] = React.useReducer(sessionReducer, sessionInitialState, undefined);

  // useCommunity
  const { getCommunities, setCommunitiesInformation } = useCommunity();

  // Loading states
  const setPageIsLoading = (isLoading: boolean) => {
    dispatch(sessionActions.setPageIsLoading(isLoading));
  };
  const setMapsIsLoading = (isLoading: boolean) => {
    dispatch(sessionActions.setMapsIsLoading(isLoading));
  };
  // message
  const setMessage = (message: string | null, option?: any) => {
    dispatch(sessionActions.setMessage(message && capitalize(t(message, option))));
  };
  const setError = (message: string | null, option?: any) => {
    dispatch(sessionActions.setError(message && capitalize(t(message, option))));
  };
  const setResult = (result: any) => {
    dispatch(sessionActions.setResult(result));
  };
  const resetSessionMessages = () => {
    setError(null);
    setMessage(null);
    setResult(null);
  };

  // user
  const setToken = (token: string) => {
    dispatch(sessionActions.setToken(token));
  };
  const setUser = (payload: any) => {
    WebStorageManagement.setValue('user', payload.user);
    WebStorageManagement.setValue('token', payload.token);
    WebStorageManagement.setValue('refreshToken', payload.refreshToken);
    dispatch(sessionActions.setUser(payload.user));
    setToken(payload.token);
    dispatch(sessionActions.setRefreshToken(payload.refreshToken));
  };
  const setEmail = (email: string) => {
    dispatch(sessionActions.setEmail(email));
  };
  const setPassword = (password: string) => {
    dispatch(sessionActions.setPassword(password));
  };
  const login = async () => {
    const d: LoginParams = {
      email: sessionState.email.toLowerCase(),
      password: sessionState.password,
      fromDashboard: true,
    };
    loginAPI(d)
      .then((response: any) => {
        if (response.content) {
          const { user } = response.content;
          if (
            user.roles.find(
              (item: string) => item === 'ROLE_ADMIN' || item === 'ROLE_SUPER_ADMIN'
            ) === undefined
          ) {
            setError('connect:onlyAdmin');
            setEmail('');
            setPassword('');
            return;
          }
          setUser(response.content);
          getAdminCommunities();
        }
      })
      .catch((error: any) => {
        if (error?.response?.data) {
          const { fail } = error.response.data;
          setError(`${fail}`);
        } else {
          setError('connect:unknownError');
        }
      });
  };
  const logout = () => {
    WebStorageManagement.removeKey('token');
    WebStorageManagement.removeKey('refreshToken');
    WebStorageManagement.removeKey('user');
    WebStorageManagement.removeKey('password');
    dispatch(sessionActions.setToken(null));
    dispatch(sessionActions.setRefreshToken(null));
    dispatch(sessionActions.setUser(null));
    dispatch(sessionActions.setPassword(''));
    setCommunitiesInformation(null);
    dispatch(sessionActions.setSelectedGroup(null));
    dispatch(sessionActions.setSelectedCommunity(null));
    dispatch(sessionActions.setSelectedEvent(null));
    dispatch(sessionActions.setDictionaries([]));
  };

  // dictionary
  const setDictionaries = (newDict: any) => {
    dispatch(sessionActions.setDictionaries(newDict));
  };
  const getDictionaries = async () => {
    try {
      const data = await getDictionariesAPI();
      dispatch(sessionActions.setDictionaries(data.content));
    } catch {
      setError('error:connexionError');
    }
  };
  const getDictionaryValue = (dictionary: any, key: any) => {
    const { dictionaries } = sessionState;
    if (dictionaries) {
      if (dictionaries[dictionary]) {
        // @ts-ignore
        const item = dictionaries[dictionary].filter((elt: any) => elt.key === key);
        if (item && item.length > 0) {
          return item[0].value;
        }
        return key;
      }
    }
    return undefined;
  };

  // getLocalisation
  const getLocalisation = (countryCode: string, search: string, updateList: any) => {
    try {
      searchLocalisationAPI(countryCode, search).then((response) => {
        const localisations = response.content.map((result: any) => ({
          postalCode: result.postalCode,
          title: `${result.postalCode}, ${result.town}`,
          town: result.town,
          longitude: result.longitude,
          latitude: result.latitude,
          googleUrl: result.googleUrl,
        }));
        updateList(localisations);
      });
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // communities
  const choseCommunity = async (email: string) => {
    const shasum = crypto.createHash('sha1');
    shasum.update(email.toLowerCase() + process.env.REACT_APP_SYSTEM_API_KEY);
    const d = {
      key: shasum.digest('hex'),
      email: email.toLowerCase(),
    };
    return getClCommunitiesAPI(d);
  };

  const getCommunityData = async (data: Community | Group) => {
    let logo = null;
    let coverPicture = null;
    let templatePicture = null;
    try {
      if (Array.isArray(data?.logo)) {
        logo = await encodeImageFileAsURL(data.logo[0]);
      } else {
        logo = data?.logo;
      }
      if (Array.isArray(data?.coverPicture)) {
        coverPicture = await encodeImageFileAsURL(data.coverPicture[0]);
      } else {
        coverPicture = data?.coverPicture;
      }
      if ('templatePicture' in data && data.templatePicture) {
        if (Array.isArray(data?.templatePicture)) {
          templatePicture = await encodeImageFileAsURL(data.templatePicture[0]);
        } else {
          templatePicture = data?.templatePicture;
        }
      }

      return {
        accessType: data?.accessType,
        activitySector: data?.activitySector,
        appName: 'appName' in data ? data?.appName : null,
        category: data?.category,
        coverPicture: coverPicture === '' ? null : coverPicture,
        createNewMemberActuality: data?.createNewMemberActuality ?? null,
        description: data?.description?.trim() ?? null,
        displayDirectory: data?.displayDirectory ?? null,
        enabled: data?.enabled ?? null,
        facebookUrl: 'facebookUrl' in data && data.facebookUrl ? data?.facebookUrl?.trim() : null,
        instagramUrl: 'instagramUrl' in data && data.instagramUrl ? data?.instagramUrl?.trim() : null,
        linkedinUrl: 'linkedinUrl' in data && data.linkedinUrl ? data?.linkedinUrl?.trim() : null,
        localisation: {
          countryCode: data?.localisation?.countryCode,
          postalCode: data?.localisation?.postalCode,
          town: split(data?.localisation?.town, ':')[1] || split(data?.localisation?.town, ':')[0],
          longitude: data?.localisation?.longitude ?? null,
          latitude: data?.localisation?.latitude ?? null,
          address1: data?.localisation?.address1,
          address2: data?.localisation?.address2,
          googleUrl: data?.localisation?.googleUrl,
        },
        logo: logo === '' ? null : logo,
        membersCommentEnabled: data?.membersCommentEnabled ?? null,
        membersDiscussionGroupStatus: data?.membersDiscussionGroupStatus ?? null,
        membersPublicationStatus: data?.membersPublicationStatus ?? null,
        mergeInvitations: data?.mergeInvitations ?? null,
        messagingEnabled: data?.messagingEnabled ?? null,
        name: data.name.trim(),
        nuid: data?.nuid,
        phoneNumber: 'phoneNumber' in data && data.phoneNumber ? data?.phoneNumber?.trim() : null,
        public: true,
        published: data?.published,
        size: data?.size,
        skills: data?.skills?.map((elt) => elt.key),
        snapchatUrl: 'snapchatUrl' in data && data.snapchatUrl ? data?.snapchatUrl?.trim() : null,
        subscriptionPlan: data?.subscriptionPlan,
        templatePicture: templatePicture === '' ? null : templatePicture,
        tiktokUrl: 'tiktokUrl' in data && data.tiktokUrl ? data?.tiktokUrl?.trim() : null,
        type: data?.type,
        url: 'url' in data && data.url ? data?.url?.trim() : null,
        whiteLabel: data?.whiteLabel ?? null,
        whiteLabelName: data?.whiteLabelName ?? null,
        xUrl: 'xUrl' in data && data.xUrl ? data?.xUrl?.trim() : null,
      } as unknown as Community;
    } catch {
      setError('error:unknownError');
      return null;
    }
  };
  const getAdminCommunities = async (resetSelected = false) => {
    try {
      const communities = await getCommunities(true);
      dispatch(sessionActions.setCommunities(communities));
      const currentCommunity = sessionState.selectedCommunity;
      let newSelectedCommunity;
      if (resetSelected || currentCommunity == null || isEmpty(currentCommunity)) {
        newSelectedCommunity = communities[0];
      } else {
        const targetCommunity = communities.filter(
          (item: any) => item.nuid === currentCommunity.nuid
        );
        if (targetCommunity.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          newSelectedCommunity = targetCommunity[0];
        } else {
          newSelectedCommunity = null;
        }
      }
      WebStorageManagement.setValue('selectedCommunity', newSelectedCommunity);
      dispatch(sessionActions.setSelectedCommunity(newSelectedCommunity));
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // selected community/group/event
  const setEvent = (event: EventCompletedInformation | null) => {
    dispatch(sessionActions.setSelectedEvent(event));
  };
  const setCommunity = (community: Community | null) => {
    dispatch(sessionActions.setSelectedCommunity(community));
  };
  const setGroup = (group: Group | null) => {
    dispatch(sessionActions.setSelectedGroup(group));
  };

  const updateSelectedCommunity = async (communityId: string) => {
    try {
      const communities = await getCommunities(true);
      let selectedCommunity: any;
      communities?.forEach((community: Community) => {
        if (community.nuid === communityId) {
          selectedCommunity = community;
        }
      });
      dispatch(sessionActions.setSelectedCommunity(selectedCommunity));
      WebStorageManagement.setValue('selectedCommunity', selectedCommunity);
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // CU community/group
  const createCommunityOrGroup = async (d: any, published = false) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete d.isValid;
    const data = await getCommunityData(d);
    // @ts-ignore
    delete data?.nuid;
    if (data) {
      setPageIsLoading(true);
      const newData = {
        ...data,
        parentCommunityNuid: d.type === 'community' ? null : sessionState.selectedCommunity?.nuid,
      };
      createCommunityOrGroupAPI(newData)
        .then(() => {
          getAdminCommunities(d.type === 'community');
          let message;
          if (published) {
            message =
              d.type === 'community' ? 'message:communityPublished' : 'message:groupPublished';
          } else {
            message = d.type === 'community' ? 'message:communityCreated' : 'message:groupCreated';
          }
          setMessage(message);
        })
        .then(() => setPageIsLoading(false))
        .catch((error = null) => {
          let errorMessage = error?.response?.data?.fail;
          if (!errorMessage) {
            errorMessage = 'error:unknownError';
          }
          setError(errorMessage);
          setPageIsLoading(false)
        });
    }
  };
  const updateCommunityOrGroup = async (
    data: any,
    changeData = true,
    onlyGroup = false,
    published = false,
    fromSettings = false
  ) => {
    const type = data?.type;
    if (data !== null) {
      setPageIsLoading(true);
      if (!onlyGroup) {
        if (data?.isValid) {
          // eslint-disable-next-line no-param-reassign
          delete data?.isValid;
        }
        if (data?.type) {
          // eslint-disable-next-line no-param-reassign
          delete data?.type;
        }
        if (data?.accessType === '') {
          // eslint-disable-next-line no-param-reassign
          delete data?.accessType;
        }
      }
      const newData = changeData ? await getCommunityData(data) : data;
      updateCommunityOrGroupAPI(newData)
        .then((result) => {
          getAdminCommunities(false);
          let message;
          if (published && !fromSettings) {
            message =
              type === 'community' ? 'message:communityPublished' : 'message:groupPublished';
          } else {
            message = type === 'community' ? 'message:communityUpdated' : 'message:groupUpdated';
          }
          setMessage(message);
          if (type === 'group') {
             setGroup(result.content);
          }
          setPageIsLoading(false);
        })
        .catch((error: any) => {
          displayError(error, setError);
          setPageIsLoading(false);
        });
    }
  };

  // Infos
  const getLastGuestedUsers = async (nuid: string) => {
    try {
      const data = await getLastGuestedUsersAPI(nuid, !!sessionState.selectedEvent);
      dispatch(sessionActions.setLastGuestedUsers(data.content.lastGuestedUsers.data));
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const getLastOnboardedUsers = async (nuid: string) => {
    try {
      const data = await getLastOnboardedUsersAPI(nuid, !!sessionState.selectedEvent);
      dispatch(sessionActions.setLastOnboardedUsers(data.content.lastOnboardedUsers.data));
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const getObjectives = async (nuid: string) => {
    try {
      const data = await getObjectivesAPI(nuid, !!sessionState.selectedEvent);
      dispatch(sessionActions.setObjectives(data.content.objectivesOfTheMeetings));
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const getLinkforce = async (nuid: string) => {
    try {
      const data = await getLinkforceAPI(nuid, !!sessionState.selectedEvent);
      dispatch(sessionActions.setLinkforce(data.content.averageLinkForce));
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // Effects
  const loadHomePageSessionData = async ({ nuid, isAdmin }: { nuid: string; isAdmin: boolean }) => {
    if (nuid && isAdmin) {
      await getLastGuestedUsers(nuid);
      await getLastOnboardedUsers(nuid);
      await getObjectives(nuid);
      await getLinkforce(nuid);
    }
  };

  React.useLayoutEffect(() => {
    (async () => {
      if (sessionState.user && sessionState.dictionaries.length === 0) {
        await getDictionaries();
        await getAdminCommunities();
      }
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sessionState?.user?.nuid]);

  return (
    <Provider
      value={{
        sessionState,
        // Loading states
        setPageIsLoading,
        setMapsIsLoading,
        // message,
        setMessage,
        setError,
        setResult,
        resetSessionMessages,
        // user
        setToken,
        setUser,
        setEmail,
        setPassword,
        login,
        logout,
        // dictionary
        setDictionaries,
        getDictionary,
        getDictionaryValue,
        // getLocalisation
        getLocalisation,
        // communities
        choseCommunity,
        getAdminCommunities,
        // selected community/group/event
        setEvent,
        setCommunity,
        setGroup,

        updateSelectedCommunity,
        // CU community/group
        createCommunityOrGroup,
        updateCommunityOrGroup,
        // Effects
        loadHomePageSessionData,
      }}
    >
      {children}
    </Provider>
  );
};

export { sessionContext, Session };
