import { MembersType } from "../../_api/membersApi";
import useEvent from "../../_hook/useEvent";
import useGroup from "../../_hook/useGroup";
import useMember from "../../_hook/useMember";
import { sessionContext } from "../../_hook/useSession";
import { IEvent } from "../../_types/eventTypes";
import { Group } from "../../_types/groupTypes";
import { Guest, Member } from "../../_types/memberTypes";
import TutoCommunityCreated from "../../components/form/CommunityGroupForm/TutoCommunityCreated";
import WaitingForm from "../../components/form/WaitingForm";
import { a11yTabProps } from "../../components/generic/tabs/helper";
import ToolBarItemButton from "../../components/generic/ToolBarItemButton";
import ToolBarItemInfo from "../../components/generic/ToolBarItemInfo";
import ToolBarItemUpload from "../../components/generic/ToolBarItemUpload";
import ToolBarSearchField from "../../components/generic/ToolBarSearchField";
import YesNoModal, { Sizes } from "../../components/generic/YesNoModal";
import { DIMENSION_PHONE } from "../../constants/dimensions.constants";
import AppTooltip from "../../helpers/AppToolTip";
import getPublishedStatus from "../../helpers/getPublishedStatus";
import useWindowDimensions from "../../helpers/getWindowDimensions";
import { CSVToArray, removeDuplicates } from "../../helpers/Tools";
import EventHeader from "../events/EventHeader";
import ImportMembersForm from "./components/ImportMembersForm";
import ImportMembersInForm from "./components/ImportMembersInForm";
import MemberHelpZone from "./components/MemberHelpZone";
import MembersList from "./components/MembersList";
import NewMemberForm from "./components/NewMembersForm/NewMemberForm";
import "./index.scss";
import { MembersButton, MembersTab, MembersUploadButton, ModalButton, ModalType, DisabledAction } from "./types";
import { AppBar, Badge, IconButton, Tab, Tabs } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Close, Menu } from "@material-ui/icons";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const CLASSNAME = 'members';

const MembersPage: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation(['members', 'words', 'layout', 'message']);

  // Responsive
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = React.useState(false);

  // useSession
  const { sessionState, getAdminCommunities, setError, setPageIsLoading } =
    React.useContext(sessionContext);
  const { user, selectedCommunity, selectedEvent, selectedGroup, pageIsLoading } = sessionState;
  const selectedNuid = selectedEvent?.nuid || selectedGroup?.nuid || selectedCommunity?.nuid;
  const selectedCommunityOrGroupIsPublished =
    selectedGroup?.published || selectedCommunity?.published;
  const selectedEntity = selectedEvent ? 'event' : selectedGroup ? 'group' : 'community';

  // useGroup
  const { groupState, getGroups } = useGroup();
  const { groupsInformation } = groupState;

  // useEvent
  const { eventState, getEvents } = useEvent();
  const { eventsInformation } = eventState;

  // useMember
  const {
    membersState,
    acceptMembership,
    deleteInvitations,
    getMembers,
    inviteMembers,
    loadMembersPageMemberData,
    refuseMembership,
    removeMembers,
    deleteMembers,
    blockMembers,
    setMembersPage,
    setMembersParams,
    updateMembersRole,
    detachUserEvent
  } = useMember();
  const { membersStats } = membersState;

  // Loading states
  const [invitationsInProgress, setInvitationsInProgress] = React.useState(false);

  // Tabs
  const [membersTab, setMembersTab] = React.useState(0);
  const tabs: MembersTab[] = [
    {
      badgeTitle: selectedEvent ? t('participants') : t('memberList'),
      stat: membersStats.totalMembers,
      title: selectedEvent ? capitalize(t('infoParticipantsTab')) : t('infoMemberTab'),
    },
    {
      badgeTitle: t('invitationList'),
      stat: membersStats.totalInvitations,
      title: selectedEvent ? capitalize(t('infoInvitationEventTab')) : t('infoInvitationTab'),
    },
    {
      badgeTitle: t('membershipsList'),
      stat: membersStats.totalMemberships,
      title: selectedEvent ? capitalize(t('infoMembershipEventTab')) : t('infoMembershipTab'),
    },
  ];
  const currentTabType = (tab: number) => {
    switch (tab) {
      case 0:
        return MembersType.members;
      case 1:
        return MembersType.guests;
      case 2:
        return MembersType.memberships;
      default:
        return MembersType.members;
    }
  };

  // View states
  const [isTableView, setIsTableView] = React.useState(true);

  // Selected items
  const [selectedGroupsForInvitation, setSelectedGroupsForInvitation] =
    React.useState<Group | null>(null);
  const [selectedEventsForInvitation, setSelectedEventsForInvitation] =
    React.useState<IEvent | null>(null);
  const [selectedMembers, setSelectedMembers] = React.useState<Member[]>([]);
  const totalSelectedMembers = selectedMembers.length || 0;

  const handleSelectMember = (
    member: Member | undefined,
    members?: Member[] | number[],
    currentList?: any[]
  ) => {
    let newSelectedMembers = [...selectedMembers];
    if (members && currentList) {
      if (isEmpty(members)) {
        currentList.forEach((element) => {
          newSelectedMembers = newSelectedMembers.filter((m) => m?.nuid !== element?.nuid);
        });
        setSelectedMembers(newSelectedMembers);
      } else {
        const membersToAdd: any[] = [];
        const membersToRemove: any[] = [];

        members.forEach((element) => {
          if (!Number.isNaN(element)) {
            membersToAdd.push(currentList[element as number]);
          }
        });

        currentList.forEach((element) => {
          if (membersToAdd.indexOf(element) === -1) {
            membersToRemove.push(element);
          }
        });

        let newSelectedMembersWithAdd = newSelectedMembers.concat(membersToAdd);
        const uniqueIds: any[] = [];

        newSelectedMembersWithAdd = newSelectedMembersWithAdd.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.nuid);

          if (!isDuplicate) {
            uniqueIds.push(element.nuid);

            return true;
          }

          return false;
        });

        membersToRemove.forEach((element) => {
          newSelectedMembersWithAdd = newSelectedMembersWithAdd.filter(
            (e) => e.nuid !== element.nuid
          );
        });

        setSelectedMembers(newSelectedMembersWithAdd);
      }
    } else if (member !== null && member) {
      if (selectedMembers.find((m: Member) => m.nuid === member.nuid) === undefined) {
        setSelectedMembers([...selectedMembers, member]);
      } else {
        setSelectedMembers(selectedMembers.filter((m: Member) => m.nuid !== member.nuid));
      }
    }
  };

  // Modal states
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState<ModalType>('removeModal');

  // Actions
  const exportMembers = async () => {
    const rows = await getMembers(
      selectedNuid,
      currentTabType(membersTab),
      1,
      membersState[currentTabType(membersTab)].total,
      '',
      false
    );
    const dataColumns =
      membersTab === 0
        ? [
            'img',
            'status',
            'email',
            'firstName',
            'lastName',
            'currentPosition',
            'company',
            'activitySector',
            'professionalActivity',
            'groups',
            'skills',
            'objectives',
            'localisation',
            'createdDate',
            'entryDate',
            'customDate',
          ]
        : ['img', 'email', 'firstName', 'lastName', 'currentPosition', 'company', 'professionalActivity'];

    const info: any[] = [];
    for (let i = 0; i < rows.length; i++) {
      info[i] = new Array(dataColumns.length).fill('');
    }

    rows.map((e: any, index: number) => {
      Object.entries(e).forEach((data: [string, any]) => {
        dataColumns.map((column, i) => {
          if (data[0] === column) {
            switch (column) {
              case 'activitySector':
                info[index][i] = `"${data[1]?.value?.replace(',', '')}"`;
                break;
              case 'groups':
                const groupsNames: any[] = [];
                data[1].forEach((element: any) => {
                  groupsNames.push(element.name.replace(',', ''));
                });
                if (groupsNames.length !== 0) {
                  info[index][i] = `"${groupsNames.join('-')}"`;
                }
                break;
              case 'localisation':
                const localisation: any[] = [];
                for (const [key, value] of Object.entries(data[1])) {
                  // @ts-ignore
                  localisation.push(`${key}: ${value.replace(',', '')}`);
                }
                if (localisation.length !== 0) {
                  info[index][i] = `"${localisation.join(' ')}"`;
                }
                break;
              case 'objectives':
                const objectives: any[] = [];
                data[1].forEach((element: any) => {
                  objectives.push(element.value?.replace(',', ''));
                });
                if (objectives.length !== 0) {
                  info[index][i] = `"${objectives.join('-')}"`;
                }
                break;
              case 'professionalActivity':
                info[index][i] = `"${data[1]?.value?.replace(',', '')}"`;
                break;
              case 'skills':
                const skillsName: any[] = [];
                data[1].forEach((element: any) => {
                  skillsName.push(element.value?.replace(',', ''));
                });
                if (skillsName.length !== 0) {
                  info[index][i] = `"${skillsName.join('-')}"`;
                }
                break;
              default:
                info[index][i] = `"${data[1].replace(',', '')}"`;
                break;
            }
          }
        });
      });
    });

    const csvContent =
      dataColumns.map((e) => {
        return capitalize(t(`api:member_${e}`));
      }) +
      '\n' +
      info.map((e) => e.join(',')).join('\n');
    const universalBOM = '\uFEFF';
    const encodedUri = encodeURIComponent(universalBOM + csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodedUri);
    link.setAttribute('download', `${currentTabType(membersTab)}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleAcceptMembers = async () => {
    const nuids: string[] = [];
    selectedMembers.map((member: any) => nuids.push(member.memberShip.nuid));
    await acceptMembership({
      communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
      eventNuid: selectedEvent?.nuid,
      membershipNuids: nuids,
    });
    setSelectedMembers([]);
    setModalIsOpen(false);
  };

  const handleDeleteInvitations = async () => {
    const nuids: string[] = [];
    selectedMembers.map((member: any) => nuids.push(member?.invitation?.nuid));
    await deleteInvitations({
      communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
      eventNuid: selectedEvent?.nuid,
      invitationNuids: nuids,
    });
    setMembersTab(1);
    setSelectedMembers([]);
    setModalIsOpen(false);
  };

  const handleInviteInEvent = async () => {
    setInvitationsInProgress(true);
    // eslint-disable-next-line no-restricted-syntax
    await inviteMembers(
      {
        guests: selectedMembers,
        eventNuid: selectedEventsForInvitation?.nuid,
        communityNuid: selectedCommunity?.nuid,
        groupNuid: selectedGroup?.nuid,
      },
      false
    );
    setInvitationsInProgress(false);
    setModalIsOpen(false);
  };

  const handleInviteInGroup = async () => {
    setInvitationsInProgress(true);
    // eslint-disable-next-line no-restricted-syntax
    await inviteMembers(
      {
        guests: selectedMembers,
        communityNuid: selectedCommunity?.nuid,
        groupNuid: selectedGroupsForInvitation?.nuid,
      },
      false
    );
    setInvitationsInProgress(false);
    setModalIsOpen(false);
  };

  const handleInviteMembers = async (states: any) => {
    setInvitationsInProgress(true);
    await inviteMembers({
      guests: states.users || ([states] as unknown as Guest[]),
      eventNuid: selectedEvent?.nuid,
      communityNuid: selectedCommunity?.nuid,
      groupNuid: selectedGroup?.nuid,
      sendNotification: states.sendEmail,
    });
    setInvitationsInProgress(false);
    setInviteModalOpen(false);
    setModalIsOpen(false);
  };

  const handleUpdateMembersRole = async (toAdmin: boolean) => {
    const nuids: string[] = [];
    selectedMembers.map((member: any) => nuids.push(member.nuid));
    if (nuids.find((nuid: string) => nuid === user.nuid)) {
      setError('error:youCannotChangeYourRole');
    } else {
      await updateMembersRole({
        userNuids: nuids,
        communityNuid: selectedCommunity?.nuid,
        groupNuid: selectedGroup?.nuid,
        admin: toAdmin,
        referent: toAdmin,
      });
      await getAdminCommunities(false);
      setSelectedMembers([]);
    }
    setModalIsOpen(false);
  };

  const handleRefuseMembers = async () => {
    const nuids: string[] = [];
    selectedMembers.map((member: any) => nuids.push(member.memberShip.nuid));
    await refuseMembership({
      communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
      eventNuid: selectedEvent?.nuid,
      membershipNuids: nuids,
    });
    setSelectedMembers([]);
    setModalIsOpen(false);
  };

  const handleReInviteMembers = async () => {
    const members: any[] = [];
    selectedMembers.map((member: Member) => members.push(member));
    setInvitationsInProgress(true);
    await inviteMembers({
      guests: members,
      eventNuid: selectedEvent?.nuid,
      communityNuid: selectedCommunity?.nuid,
      groupNuid: selectedGroup?.nuid,
    });
    setSelectedMembers([]);
    setInvitationsInProgress(false);
    setModalIsOpen(false);
  };

  const handleRemoveMembers = async () => {
    setPageIsLoading(true);
    setModalIsOpen(false);
    const nuids: string[] = [];
    selectedMembers.map((member: Member) => nuids.push(member.nuid));
    if (nuids.find((nuid: string) => nuid === user.nuid)) {
      setError('error:youCannotRemoveYourself');
    } else {
      if (selectedEvent) {
        await detachUserEvent(
          {
            eventNuid: selectedEvent?.nuid,
            userNuids: nuids,
          }
        );
        await getEvents({
          communityNuid: selectedCommunity?.nuid,
          eventNuid: selectedEvent?.nuid,
          maxPerPage: eventState.eventsParams.maxPerPage,
          page: eventState.eventsParams.page,
        }, true);
      } else if (selectedGroup) {
        await removeMembers(
          {
            userNuids: nuids,
            communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
            eventNuid: selectedEvent?.nuid,
          },
          membersTab,
          !!selectedGroup
        );
      } else {
        await deleteMembers({
          userNuids: nuids,
          communityNuid: selectedCommunity?.nuid,
        })
      }
      setSelectedMembers([]);
    }
    setPageIsLoading(false);
  };

  const handleBlockMembers = async () => {
    setPageIsLoading(true);
    setModalIsOpen(false);
    const nuids: string[] = [];
    selectedMembers.map((member: Member) => nuids.push(member.nuid));
    if (nuids.find((nuid: string) => nuid === user.nuid)) {
      setError('error:youCannotBlockYourself');
    } else {
      await blockMembers(
        {
          usersNuid: nuids,
          action: 'block'
        },
        membersTab,
      );
      setSelectedMembers([]);
    }
    setPageIsLoading(false);
  };

  const handleUnblockMembers = async () => {
    setPageIsLoading(true);
    setModalIsOpen(false);
    const nuids: string[] = [];
    selectedMembers.map((member: Member) => nuids.push(member.nuid));
    await blockMembers(
      {
        usersNuid: nuids,
        action: 'unblock'
      },
      membersTab,
    );
    setSelectedMembers([]);
    setPageIsLoading(false);
  };

  // Files
  const [memberList, setMemberList] = React.useState([]);
  const readMembersFile = async (importFile: any) => {
    if (importFile.type === 'text/csv') {
      const csv = removeDuplicates(
        await CSVToArray(importFile, 'firstName, lastName, email, company, currentPosition, groups, phone, entryDate, birthDate'),
        2
      );
      if (csv.every((entry: any) => entry.length === 1)) {
        setError('error:badCsvError');
      } else {
        setMemberList(csv);
        setModalType('inviteMembersListModal');
        setModalIsOpen(true);
      }
    } else {
      setError('error:badCsvError');
    }
  };

  // Modal
  const modal: { [id in ModalType]: ModalButton } = {
    userUnblockModal: {
      yesAction: handleUnblockMembers,
      yesLabel: t('words:accept'),
      noLabel: t('words:cancel'),
      text: t('members:unblockMemberText', { count: totalSelectedMembers }),
      title: t('members:unblockMemberTitle'),
    },
    userBlockModal: {
      yesAction: handleBlockMembers,
      yesLabel: t('words:accept'),
      noLabel: t('words:cancel'),
      text: t('members:blockMemberText', { count: totalSelectedMembers }),
      title: t('members:blockMemberTitle'),
    },
    acceptMembersModal: {
      yesAction: handleAcceptMembers,
      yesLabel: t('words:accept'),
      noLabel: t('words:cancel'),
      text: t('members:acceptMembership', { count: totalSelectedMembers }),
      title: t('words:accept'),
    },
    deleteInvitationsModal: {
      yesAction: handleDeleteInvitations,
      yesLabel: t('words:delete'),
      noLabel: t('words:cancel'),
      text: t('members:deleteInvitations', { count: totalSelectedMembers }),
      title: t('members:removeInvitations'),
    },
    inviteMembersInEventModal: {
      yesAction: handleInviteInEvent,
      yesLabel: t('words:invite'),
      noLabel: t('words:cancel'),
      title: t('members:inviteMembersInEvent'),
      waiting: invitationsInProgress,
      waitingForm: <WaitingForm message={t('members:waitingInvitation')} />,
      form: (
        <ImportMembersInForm
          selectedCommunity={selectedGroup || selectedCommunity}
          setItem={setSelectedEventsForInvitation}
          isEvent={true}
        />
      ),
      yesIsDisabledManually: !selectedEventsForInvitation,
    },
    inviteMembersInGroupModal: {
      yesAction: handleInviteInGroup,
      yesLabel: t('words:invite'),
      noLabel: t('words:cancel'),
      title: t('members:inviteMembersInGroup'),
      waiting: invitationsInProgress,
      waitingForm: <WaitingForm message={t('members:waitingInvitation')} />,
      form: (
        <ImportMembersInForm
          selectedCommunity={selectedCommunity}
          setItem={setSelectedGroupsForInvitation}
        />
      ),
      yesIsDisabledManually: !selectedGroupsForInvitation,
    },
    inviteMembersListModal: {
      yesAction: handleInviteMembers,
      yesLabel: t('words:invite'),
      noLabel: t('words:cancel'),
      title: t('members:inviteMembers', { count: totalSelectedMembers }),
      form: <ImportMembersForm data={memberList} />,
      waiting: invitationsInProgress,
      waitingForm: <WaitingForm message={t('members:waitingInvitation')} />,
      yesActionDisabled: DisabledAction.allStates,
    },
    refuseMembersModal: {
      yesAction: handleRefuseMembers,
      yesLabel: t('words:refuse'),
      noLabel: t('words:cancel'),
      text: t('members:refuseMembership', { count: totalSelectedMembers }),
      title: t('layout:deleteConfirmation'),
    },
    reInviteMembersModal: {
      yesAction: handleReInviteMembers,
      yesLabel: t('words:invite'),
      noLabel: t('words:cancel'),
      text: t('members:reInviteMembersText', { count: totalSelectedMembers }),
      title: t('members:reInviteMembersTitle', { count: totalSelectedMembers }),
      waiting: invitationsInProgress,
      waitingForm: <WaitingForm message={t('members:waitingInvitation')} />,
    },
    removeModal: {
      yesAction: handleRemoveMembers,
      yesLabel: t('words:yes'),
      noLabel: t('words:no'),
      text: selectedEvent? t('members:removeProfilFromEvent', {count: totalSelectedMembers})
          : selectedGroup ? t('members:removeProfilFromEntity', {count: totalSelectedMembers, entity: t(`layout:${selectedEntity}`)})
          : t('members:deleteProfilFromCommunity', {count: totalSelectedMembers}),
      title: selectedEvent ? t('layout:confirmRemoveAttendee')
           : selectedGroup ? t('layout:confirmRemoveUser')
           : t('layout:confirmDeleteUser')
    },
    updateMembersRoleToAdminModal: {
      yesAction: () => handleUpdateMembersRole(true),
      yesLabel: t('words:yes'),
      noLabel: t('words:no'),
      text: t('members:promoteMember', { count: totalSelectedMembers }),
      title: t('members:promote'),
    },
    updateMembersRoleToMemberModal: {
      yesAction: () => handleUpdateMembersRole(false),
      yesLabel: t('words:yes'),
      noLabel: t('words:no'),
      text: t('members:revokeMember', { count: totalSelectedMembers }),
      title: t('members:revoke'),
    },
  };

  // Buttons
  const membersButtons: MembersButton[] = [
    {
      action: () => {
        setModalType('userBlockModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0,
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: t('words:block'),
      membersTab: (!selectedGroup && !selectedEvent) ? 0 : -1,
      type: 'danger',
    },
    {
      action: () => {
        setModalType('userUnblockModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0,
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: t('words:unblock'),
      membersTab: (!selectedGroup && !selectedEvent) ? 0 : -1,
      type: 'danger',
    },
    {
      action: () => {
        setModalType('removeModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0,
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: (selectedEvent || selectedGroup) ? t('words:unsubscribe') : t('words:delete'),
      membersTab: 0,
      type: 'danger',
    },
    {
      action: () => {
        setModalType('updateMembersRoleToAdminModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || (selectedGroup && selectedGroup.enabled === false),
      help: totalSelectedMembers === 0 ? t('members:whyInactiveSelectButton') : '',
      label: selectedGroup ? t('members:actionPromoteGroupLeader') : t('members:actionPromote'),
      membersTab: selectedEvent ? -1 : 0,
    },
    {
      action: () => {
        setModalType('updateMembersRoleToMemberModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || (selectedGroup && selectedGroup.enabled === false),
      help: totalSelectedMembers === 0 ? t('members:whyInactiveSelectButton') : '',
      label: t('members:actionRevoke'),
      membersTab: selectedEvent ? -1 : 0,
    },
    {
      action: () => {
        setInviteModalOpen(true);
      },
      disabled: selectedEvent
        ? selectedEvent.status === 'draft'
        : totalSelectedMembers > 0 || !selectedCommunityOrGroupIsPublished || (selectedGroup && selectedGroup.enabled === false),
      help:
        (selectedEvent && selectedEvent.status === 'draft') || !selectedCommunityOrGroupIsPublished
          ? selectedEvent
            ? t('members:whyInactiveInvitationButtonParticipant')
            : t('members:whyInactiveInvitationButton')
          : '',
      label: selectedEvent ? capitalize(t('actionInviteParticipants')) : t('actionInvite'),
      membersTab: 0,
    },
    {
      action: () => {
        setModalType('inviteMembersInEventModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || eventsInformation?.total === 0 || (selectedGroup && selectedGroup.enabled === false),
      help: totalSelectedMembers === 0 ? t('members:whyInactiveInviteInEventSelectButton') : '',
      label: t('words:inviteInEvent'),
      membersTab: selectedEvent ? -1 : 0,
    },
    {
      action: () => {
        setModalType('inviteMembersInGroupModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || groupsInformation?.total === 0,
      help: totalSelectedMembers === 0 ? t('members:whyInactiveInviteInGroupSelectButton') : '',
      label: t('words:inviteInGroup'),
      membersTab: selectedEvent || selectedGroup ? -1 : 0,
    },
    {
      action: () => {
        setModalType('deleteInvitationsModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0,
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: t('deleteInvitation'),
      membersTab: 1,
      type: 'danger',
    },
    {
      action: () => {
        setModalType('reInviteMembersModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || !selectedCommunityOrGroupIsPublished || (selectedGroup && selectedGroup.enabled === false),
      help: !selectedCommunityOrGroupIsPublished ? t('members:whyInactiveInvitationButton') : '',
      label: t('actionReInvite'),
      membersTab: 1,
    },
    {
      action: () => {
        setModalType('acceptMembersModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || (selectedGroup && selectedGroup.enabled === false),
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: t('actionMembershipAccept'),
      membersTab: 2,
    },
    {
      action: () => {
        setModalType('refuseMembersModal');
        setModalIsOpen(true);
      },
      disabled: totalSelectedMembers === 0 || (selectedGroup && selectedGroup.enabled === false),
      help:
        totalSelectedMembers === 0
          ? (selectedEvent && capitalize(t('whyInactiveSelectButtonParticipant'))) ||
            t('members:whyInactiveSelectButton')
          : '',
      label: t('actionMembershipRefuse'),
      membersTab: 2,
    },
    {
      action: exportMembers,
      disabled: totalSelectedMembers > 0 || tabs[membersTab].stat === 0,
      help:
        tabs[membersTab].stat === 0
          ? (selectedEvent && capitalize(t('whyInactiveExportButtonParticipant'))) ||
            capitalize(t('members:whyInactiveExportButton'))
          : '',
      label: t('words:export'),
      membersTab: isTableView ? membersTab : -1,
    },
  ];
  const membersUploadButtons: MembersUploadButton[] = [
    {
      action: readMembersFile,
      disabled: selectedEvent
        ? selectedEvent.status === 'draft'
        : totalSelectedMembers > 0 || !selectedCommunityOrGroupIsPublished || (selectedGroup && selectedGroup.enabled === false),
      help:
        (selectedEvent && selectedEvent.status === 'draft') || !selectedCommunityOrGroupIsPublished
          ? selectedEvent
            ? t('members:whyInactiveInvitationButtonParticipant')
            : t('members:whyInactiveInvitationButton')
          : '',
      helpZone: <MemberHelpZone isInvite />,
      label: t('actionInviteList'),
      membersTab: 0,
    },
  ];

  // Change tab with other page action
  React.useEffect(() => {
    if (location?.state) {
      // @ts-ignore
      setMembersTab(location.state.tab);
    }
  }, [location]);

  // Change all states when changing entity
  React.useEffect(() => {
    setPageIsLoading(true);
    // Reset all
    setSelectedMembers([]);
    setMembersParams({ page: 1, maxPerPage: 50, search: '' });
    if (selectedNuid) {
      (async () => {
        // Get infos for inviteInEvents/inviteInGroups actions
        if (!selectedEvent) {
          await getEvents({
            communityNuid: selectedNuid,
            maxPerPage: eventState.eventsParams.maxPerPage,
            page: eventState.eventsParams.page,
          });
        }
        if (!selectedGroup && !selectedEvent) {
          await getGroups(
            {
              communityNuid: selectedCommunity.nuid,
              page: 1,
              maxPerPage: 6,
              searchString: '',
              isAdmin: !selectedCommunity.parameters?.admin,
            },
            true
          );
        }

        await loadMembersPageMemberData(
          selectedNuid,
          selectedCommunity?.parameters?.admin || selectedGroup?.isAdmin
        );
        setPageIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNuid]);

  return (
    <div className={CLASSNAME}>
      <YesNoModal
        open={infoModalOpen}
        text={<TutoCommunityCreated />}
        size={Sizes.lg}
        title={`${t('words:welcome')} ${user.firstName}`}
        yesAction={() => {
          setInfoModalOpen(false);
        }}
        yesLabel={t('layout:iUnderstand')}
      />
      <YesNoModal
        form={<NewMemberForm />}
        noAction={() => setInviteModalOpen(false)}
        noLabel={t('words:cancel')}
        open={inviteModalOpen}
        text={<p>{t('members:inviteNewMember')}</p>}
        title={t('members:inviteMember')}
        waiting={invitationsInProgress}
        waitingForm={<WaitingForm message={t('members:waitingInvitation')} />}
        yesAction={handleInviteMembers}
        yesActionDisabled={DisabledAction.allStates}
        yesLabel={t('words:invite')}
      />
      <YesNoModal
        customActions={modal[modalType].customActions}
        form={modal[modalType].form}
        noAction={() => setModalIsOpen(false)}
        noLabel={modal[modalType].noLabel}
        open={modalIsOpen}
        size={Sizes.lg}
        // @ts-ignore
        text={modal[modalType].nodeText || <p>{modal[modalType].text}</p>}
        title={modal[modalType].title}
        waiting={modal[modalType].waiting}
        waitingForm={modal[modalType].waitingForm}
        yesAction={modal[modalType].yesAction}
        yesIsDisabledManually={modal[modalType].yesIsDisabledManually}
        yesActionDisabled={modal[modalType].yesActionDisabled}
        yesLabel={modal[modalType].yesLabel}
      />
      {width >= DIMENSION_PHONE && (
        <>
          {selectedEvent && <EventHeader event={selectedEvent} />}
          <div className={`${CLASSNAME}_title`}>
            <h1>
              {selectedEvent ? capitalize(t('words:participants')) : capitalize(t('words:members'))}
            </h1>
            {!getPublishedStatus(selectedEvent, selectedGroup, selectedCommunity) && (
              <>
                {' - '}
                <p>
                  {capitalize(
                    selectedEvent
                      ? t('members:requiredFieldsEvent')
                      : t('members:requiredFields', {
                          entity: selectedGroup ? 'sous-communauté' : 'communauté',
                        })
                  )}
                </p>
              </>
            )}
          </div>
        </>
      )}
      <div className={`${CLASSNAME}_toolBox`}>
        <div className={`${CLASSNAME}_topBar`}>
          <div className={`${CLASSNAME}_appBar`}>
            <AppBar
              position="static"
              color="transparent"
              elevation={0}
              className={`${CLASSNAME}_appBar`}
            >
              <Tabs
                className={`${CLASSNAME}_tabs`}
                value={membersTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={async (_, newTab: number) => {
                  setSelectedMembers([]);
                  setMembersParams({ page: 1, maxPerPage: 50, search: '' });
                  await getMembers(selectedNuid, currentTabType(newTab)).then(() =>
                    setMembersTab(newTab)
                  );
                }}
                aria-label="members menu"
              >
                {tabs.map(
                  (tab: MembersTab, index: number) =>
                    !tab.isInvisible && (
                      <Tab
                        className={`${CLASSNAME}_tab`}
                        key={(Math.random() + 1).toString(36).substring(index)}
                        label={
                          // @ts-ignore
                          <AppTooltip title={tab.title} arrow>
                            {width >= DIMENSION_PHONE ? (
                              <Badge
                                badgeContent={tab.stat}
                                color="primary"
                                showZero
                                max={9999}
                                overlap={'rectangular'}
                              >
                                {tab.badgeTitle}
                              </Badge>
                            ) : (
                              <div>{tab.badgeTitle}</div>
                            )}
                          </AppTooltip>
                        }
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...a11yTabProps(index)}
                      />
                    )
                )}
              </Tabs>
            </AppBar>
            {!isOpen && width <= DIMENSION_PHONE && (
              <Menu
                color="primary"
                fontSize="medium"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            )}
          </div>
          {width >= DIMENSION_PHONE && (
            <IconButton
              onClick={() => {
                setIsTableView(!isTableView);
                setMembersPage(membersState.membersParams.page);
              }}
            >
              {isTableView ? (
                <GridViewIcon sx={{ color: '#f29c49' }} />
              ) : (
                <ViewListIcon sx={{ color: '#f29c49' }} />
              )}
            </IconButton>
          )}
        </div>
        {(isOpen || width >= DIMENSION_PHONE) && (
          <div className={`${CLASSNAME}_actions`}>
            <div className={`${CLASSNAME}_actions_top`}>
              <ToolBarItemInfo
                colorLabel={theme.palette.secondary.main}
                colorValue={theme.palette.primary.main}
                label={t('layout:selectedProfilsWithCount', {
                  count: totalSelectedMembers,
                })}
                value={totalSelectedMembers.toString()}
              />
              {isOpen && (
                <Close
                  className={`${CLASSNAME}_closeIcon`}
                  color="primary"
                  fontSize="medium"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              )}
            </div>
            {membersButtons.map(
              (button: MembersButton, index: number) =>
                button.membersTab === membersTab && (
                  <ToolBarItemButton
                    action={button.action}
                    disabled={button.disabled}
                    help={button.help}
                    key={(Math.random() + 1).toString(36).substring(index)}
                    label={button.label}
                    type={button.type}
                  />
                )
            )}
            {membersUploadButtons.map(
              (button: MembersUploadButton, index: number) =>
                button.membersTab === membersTab && (
                  <ToolBarItemUpload
                    action={button.action}
                    disabled={button.disabled}
                    help={button.help}
                    helpZone={button.helpZone}
                    key={(Math.random() + 1).toString(36).substring(index)}
                    label={button.label}
                  />
                )
            )}
          </div>
        )}
        {width >= DIMENSION_PHONE && (
          <ToolBarSearchField
            onSearch={(searchValue: string) =>
              getMembers(
                selectedNuid,
                currentTabType(membersTab),
                1,
                membersState.membersParams.maxPerPage,
                searchValue
              )
            }
            value={membersState.membersParams.search || ''}
            label={selectedEvent ? 'participantSearch' : 'memberSearch'}
          />
        )}
      </div>
      <div className={`${CLASSNAME}_list`}>
        {!pageIsLoading && (
          <MembersList
            getMembers={getMembers}
            handleItemSelect={handleSelectMember}
            isTableView={isTableView}
            itemType={currentTabType(membersTab)}
            maxPerPage={membersState.membersParams.maxPerPage}
            membersState={membersState}
            membersTab={membersTab}
            onSelectionModelChange={handleSelectMember}
            selectedNuid={selectedNuid}
            selectionModel={selectedMembers}
          />
        )}
      </div>
    </div>
  );
};

MembersPage.displayName = 'MembersPage';

export default MembersPage;
