import React, { useState } from 'react';
import TopLoadingBar from 'react-top-loading-bar';
import { Button } from '@material-ui/core';
import { CancelTokenSource } from 'axios';

interface ProgressBarProps {
  loadingProgress: number;
  // onUploadCanceled?: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ loadingProgress }) => {
 //  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

  // const handleCancelUpload = () => {
  //   if (cancelTokenSource) {
  //     cancelTokenSource.cancel('Upload annulé par l\'utilisateur');
  //     setCancelTokenSource(null);
  //     if (onUploadCanceled) {
  //       onUploadCanceled();
  //     }
  //   }
  // };

  // Ajoutez la logique d'upload ici en utilisant axios et un cancel token
  // Exemple :
  // const uploadData = async () => {
  //   const source = axios.CancelToken.source();
  //   setCancelTokenSource(source);

  //   try {
  //     await axios.post('URL_DE_VOTRE_API', data, {
  //       onUploadProgress: (progressEvent) => {
  //         setLoadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  //       },
  //       cancelToken: source.token,
  //     });
  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       console.log('Upload annulé:', error.message);
  //     } else {
  //       // Gérer les autres erreurs ici
  //     }
  //   }
  // };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TopLoadingBar
        progress={loadingProgress}
        height={3}
        color="#007bff"
      />
      <Button
        onClick={() => {}}
        variant="contained"
        color="secondary"
        style={{ marginLeft: '10px' }}
      >
        Annuler l'upload
      </Button>
    </div>
  );
};

export default ProgressBar;
