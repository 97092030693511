import {
  addActuality as addActualityAPI,
  getActualities as getActualitiesAPI,
  removeActuality as removeActualityAPI,
  updateActuality as updateActualityAPI,
} from '../_api/actualityApi';
import {
  actions as actualityActions,
  initialState as actualityInitialState,
  reducer as actualityReducer,
} from '../_slice/actualitySlice';
import {
  ActualityType,
  GetActualityParams,
  RemoveActualityParams,
  UpdateActualityParams,
} from '../_types/actualityTypes';
import displayError from '../helpers/displayError';
import { sessionContext } from './useSession';
import React from 'react';
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

const useActuality = () => {
  const [actualityState, dispatch] = React.useReducer(actualityReducer, actualityInitialState);
  const { setError, setMessage, setPageIsLoading } = React.useContext(sessionContext);

  const changeActualitiesPage = (newPage: number) => {
    dispatch(actualityActions.setActualitiesPage(newPage));
  };
  const changeActualitiesMaxPerPage = (newMaxPerPage: number) => {
    dispatch(actualityActions.setActualitiesMaxPerPage(newMaxPerPage));
  };

  // CRUD actions
  const addActuality = async (communityOrGroupId: string, params: any, eventNuid?: string) => {
    setPageIsLoading(true);

    const formData = new FormData();
    formData.append('type', params.type);
    formData.append('communityNuid', communityOrGroupId);
    formData.append('category', params.category);
    formData.append('message', params.message);
    formData.append('status', params.status);
    if (params?.targetMember) {
      formData.append('targetMember', params.targetMember);
    }
    if (eventNuid) {
      formData.append('eventNuid', eventNuid);
    }
    if (params?.picture && !params?.pictures) {
      formData.append('picture', params.picture);
    }
    if (isArray(params?.pictures) && !isEmpty(params?.pictures)) {
      params.pictures.forEach((picture: any, index: number) => {
        formData.append(`picturesFile[${index}]`, picture.file);
      });
    }
    if (isArray(params?.documents) && !isEmpty(params?.documents)) {
      params.documents.forEach((document: any, index: number) => {
        formData.append(`documentsFile[${index}]`, document.file);
      });
    }
    if (isArray(params?.videos) && !isEmpty(params?.videos)) {
      params.videos.forEach((video: any, index: number) => {
        formData.append(`videosFile[${index}]`, video.file);
      });
    }

    try {
      await addActualityAPI(formData);
      setMessage('actuality:actualityCreated');
    } catch (error: any) {
      displayError(error, setError);
    }
    setPageIsLoading(false);
  };

  const getActualities = async (
    communityOrGroupId: string,
    eventNuid: string | null,
    category: string[],
    type: ActualityType,
    page = 1,
    maxPerPage = 10,
    search = ''
  ) => {
    const params: GetActualityParams = {
      type,
      page,
      maxPerPage,
      search,
      communityNuid: communityOrGroupId,
      eventNuid: eventNuid || undefined,
      categories:
        (category.length === 1 && category[0] === 'all') || category.length === 0 ? null : category,
    };
    try {
      const data = await getActualitiesAPI(params);
      dispatch(actualityActions.setActualities(data.content.actualities));
      dispatch(actualityActions.setTotal(data.content.total));
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const updateActuality = async (
    communityOrGroupId: string,
    type: ActualityType,
    params: UpdateActualityParams,
    eventNuid?: string,
    axiosConfig?: any
  ) => {
    setPageIsLoading(true);

    const formData = new FormData();
    formData.append('type', type);
    formData.append('actualityNuid', params.nuid);
    formData.append('communityNuid', communityOrGroupId);
    formData.append('category', params.category);
    formData.append('message', params.message);
    formData.append('status', params.status);
    if (params?.targetMember) {
      formData.append('targetMember', params.targetMember);
    }
    if (eventNuid) {
      formData.append('eventNuid', eventNuid);
    }
    if (params?.picture && !params?.pictures) {
      formData.append('picture', params.picture);
    }
    if (isArray(params?.pictures) && !isEmpty(params?.pictures)) {
      params.pictures.forEach((picture: any, index) => {
        if (typeof picture === 'string') {
          formData.append(`picturesUrl[${index}]`, picture);
        } else {
          if (picture.file) {
            formData.append(`picturesFile[${index}]`, picture.file, picture.file.name);
          } else if (picture.url) {
            formData.append(`picturesUrl[${index}]`, picture.url);
          }
        }
      });
    }
    if (isArray(params?.documents) && !isEmpty(params?.documents)) {
      params.documents.forEach((document: any, index) => {
        if (typeof document === 'string') {
          formData.append(`documentsUrl[${index}]`, document);
        } else {
          if (document.file) {
            formData.append(`documentsFile[${index}]`, document.file, document.file.name);
          } else if (document.url) {
            formData.append(`documentsUrl[${index}]`, document.url);
          }
        }
      });
    }
    if (isArray(params?.videos) && !isEmpty(params?.videos)) {
      params?.videos.forEach((video: any, index) => {
        if (typeof video === 'string') {
          formData.append(`videosUrl[${index}]`, video);
        } else {
          if (video.file) {
            formData.append(`videosFile[${index}]`, video.file, video.file.name);
          } else if (video.url) {
            formData.append(`videosUrl[${index}]`, video.url);
          }
        }
      });
    }

    try {
      await updateActualityAPI(formData, type === ActualityType.cl, axiosConfig);
      setMessage('actuality:actualityUpdated');
    } catch (error: any) {
      displayError(error, setError);
    }
    setPageIsLoading(false);
  };
  const removeActuality = async (communityOrGroupId: string, params: any, eventNuid?: string) => {
    const paramsData: RemoveActualityParams = {
      actualityNuid: params.nuid,
      communityNuid: communityOrGroupId,
      eventNuid: eventNuid || undefined,
    };
    try {
      await removeActualityAPI(paramsData);
      setMessage('actuality:actualityDeleted');
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  return {
    actualityState,
    // Actions link to page / tab
    changeActualitiesPage,
    changeActualitiesMaxPerPage,
    // CRUD actions
    addActuality,
    getActualities,
    updateActuality,
    removeActuality,
  };
};

export default useActuality;
