const membersFr = {
  acceptMembership_one: "Vous allez accepter une demande d'adhésion",
  acceptMembership_other: "Vous allez accepter {{count}} demandes d'adhésion",
  actionAddContact: 'Ajouter un contact',
  actionImport: 'Importer mes membres',
  actionImportContact: 'Importer des contacts',
  actionInvite: 'Inviter un membre',
  actionInviteParticipants: 'inviter un participant',
  actionInviteList: 'Importer un fichier',
  actionInviteThisMember: 'Inviter ce membre',
  actionMembershipAccept: 'Accepter les demandes',
  actionMembershipRefuse: 'Refuser les demandes',
  actionPromote: 'Changer en Community Leader',
  actionPromoteGroupLeader: 'Changer en Group Leader',
  actionPublish: 'Publier ma communauté',
  actionReInvite: 'Relancer une invitation',
  actionRevoke: 'Changer en membre',
  'already membership': 'Je suis déjà membre',
  alreadyMember_zero: 'Invitation non-envoyée (déjà membre)',
  alreadyMember_one: 'Invitation non-envoyée (déjà membre)',
  alreadyMember_other: 'Invitations non-envoyées (déjà membre)',
  alreadyInvited_zero: 'Nouveau membre relancé',
  alreadyInvited_one: 'Nouveau membre relancé',
  alreadyInvited_other: 'Nouveaux membres relancés',
  alreadyMember: 'Déjà membre',
  alreadyInvited: 'Déjà invité',
  blockInvitationEmail: 'Envoyer personnellement mes invitations',
  blockMemberTitle: 'Bloquer l\'accès aux membres',
  blockMemberText: 'Vous allez bloquer l\'accès à l\'application aux membres sélectionnés.',
  contactList: 'Mon annuaire Shadow',
  defaultInvitationEmail: 'Envoyer automatiquement mes invitations',
  deleted_one: 'Invitation supprimé',
  deleted_other: 'Invitations supprimés',
  deleteInvitation: "Supprimer l'invitation",
  deleteInvitations_one: 'Vous allez supprimer une invitation',
  deleteInvitations_other: 'Vous allez supprimer {{count}} invitations',
  noMemberYet: 'pas encore de membre...',
  noParticipantYet: 'pas encore de participant...',
  noInvitationYet: "pas encore d'invitation...",
  noRequestYet: 'pas encore de demande...',
  formatHelp1:
    '1. Créez un fichier Excel avec les colonnes suivantes, dans l’ordre : \n' +
    '\t - Prénom (obligatoire)\n' +
    '\t - Nom (obligatoire)\n' +
    '\t - Adresse e-mail (obligatoire)\n' +
    '\t - Entreprise\n' +
    '\t - Fonction\n' +
    '\t - Statut\n' +
    '\t - Sous-communautés : créées au préalable, rédigées à l’identique, séparées par des point-virgules “;” et sans espace (ex : exemple1;exemple2;exemple3)\n' +
    '\t - Numéro de téléphone : au format international sans le symbole “+” (ex: 33601020304)\n' +
    '\t - Date d\'entrée : au format JJ/MM/AAAA\n' +
    '\t - Date personnalisée : au format JJ/MM/AAAA\n\n' +
    'A savoir : \n' +
    '\t- N\'ajoutez pas d\'entête aux colonnes.\n' +
    '\t- Seules les 3 premières colonnes sont obligatoires : prénom, nom et adresse e-mail.\n\n',
  formatHelp2:
    '2. Enregistrez / téléchargez votre fichier au format .csv avec comme séparateur de champ : “Valeurs séparées par des virgules (.csv)”\n\n',
  importInvitationHelp3:
    '3. Par défaut, les mails d\'invitation sont automatiquement envoyés\n' +
    '\t - Pour autoriser un membre à accéder à votre communauté sans lui envoyer un e-mail d’invitation automatique, cochez en bas de la liste d’importation "Envoyer personnellement mes invitations" avant de cliquer sur le bouton “Inviter”.',
  information: 'Je souhaite avoir des informations',
  infoParticipantsTab: 'retrouvez ici les participants qui ont rejoint votre événement.',
  infoMemberTab: 'Retrouvez ici les membres qui ont rejoint votre communauté.',
  infoInvitationEventTab: "retrouvez ici les participants invités mais qui n'ont pas encore rejoint votre événement.",
  infoInvitationTab: "Retrouvez ici les membres invités mais qui n'ont pas encore rejoint votre communauté.",
  infoContactTab: 'En tant que Community Leader, accédez à votre annuaire "Shadow" qui vous permet d\'accéder à tous les contacts de votre carnet d\'adresses pour leur envoyer un message ou pour les mettre en relation (Connector) avec un membre de votre communauté, sans les inviter à rejoindre votre communauté.',
  infoMembershipEventTab: 'retrouvez ici les demandes des utilisateurs qui souhaitent rejoindre votre événement.',
  infoMembershipTab: 'Retrouvez ici les demandes des utilisateurs qui souhaitent rejoindre votre communauté.',
  invited: 'Invité',
  invitationDate: 'Invité',
  inscriptionDate: 'Embarqué',
  invitationError: "Erreur d'invitation",
  invitationList: 'Invitations en attente',
  inviteMember: 'Inviter un membre',
  inviteMembers: 'Inviter des nouveaux membres',
  inviteMembersInEvent: "Inviter des nouveaux membres dans l'événement:",
  inviteMembersInGroup: 'Inviter des nouveaux membres dans la sous-communauté:',
  inviteMembersHelp: "Format d'importation des membres",
  inviteNewMember: 'Inviter un nouveau membre',
  invitationError_zero: 'Erreur sur membre invité',
  invitationError_one: 'Erreur sur membre invité',
  invitationError_other: 'Erreur sur membres invités',
  invited_zero: 'Nouveau membre invité',
  invited_one: 'Nouveau membre invité',
  invited_other: 'Nouveaux membres invités',
  memberList: 'Membres',
  membershipsList: 'Demandes en attente',
  'new membership': 'Je veux devenir membre',
  notCommunityMember: 'Pas membre de la communauté',
  notCommunityMember_zero: 'non membre de la communauté',
  notCommunityMember_one: 'non membre de la communauté',
  notCommunityMember_other: 'non membres de la communauté',
  nextInvite: 'à rejoindre votre espace communautaire dans l’application.',
  nextPublish: 'Voulez-vous publier votre communauté maintenant ?',
  noDescription: '(pas de biographie)',
  objectivesMessageOnHover: '% des membres ont cet objectif',
  promote: 'Promouvoir un membre en référent',
  promoteMember_one: 'Êtes-vous sûr de vouloir promouvoir un membre ?',
  promoteMember_other: 'Êtes-vous sûr de vouloir promouvoir {{count}} membres ?',
  refuseMembership_one: "Vous allez refuser une demande d'adhésion",
  refuseMembership_other: "Vous allez refuser {{count}} demandes d'adhésion",
  reInviteMembersTitle: 'Relancer des invitations',
  reInviteMembersText_one: 'Vous allez envoyer à nouveau une invitation',
  reInviteMembersText_other: 'Vous allez envoyer à nouveau {{count}} invitations',
  removeInvitations: 'Supprimer des invitations',
  deleteProfilFromCommunity_one: 'Êtes-vous sûr de vouloir supprimer un membre de votre communauté ?',
  deleteProfilFromCommunity_other: 'Êtes-vous sûr de vouloir supprimer {{count}} membres de votre communauté ?',
  removeProfilFromEntity_one: 'Êtes-vous sûr de vouloir désinscrire un membre de votre {{entity}} ?',
  removeProfilFromEntity_other: 'Êtes-vous sûr de vouloir désinscrire {{count}} membres de votre {{entity}} ?',
  removeProfilFromEvent_one: 'Êtes-vous sûr de vouloir désinscrire un participant de votre événement ?',
  removeProfilFromEvent_other: 'Êtes-vous sûr de vouloir désinscrire {{count}} participants de votre événement ?',
  requestMembership: "Demande d'adhésion",
  requiredFields: 'publiez votre {{entity}} pour commencer à inviter des membres',
  requiredFieldsEvent: 'publiez votre événement pour commencer à inviter des participants',
  revoke: 'Révoquer un référent',
  revokeMember_one: 'Êtes-vous sûr de vouloir révoquer un référent ?',
  revokeMember_other: 'Êtes-vous sûr de vouloir révoquer {{count}} référents ?',
  startInvite: 'Commencez par inviter vos membres',
  startPublish: 'Vous devez publier votre communauté avant de pouvoir inviter des membres',
  groupNeedToBePublished: 'la sous-communauté doit être publiée avant de pouvoir inviter des membres',
  selectedEvent: 'événement sélectionné',
  selectedGroup: 'sous-communauté sélectionnée',
  unblockMemberTitle: 'Débloquer l\'accès aux membres',
  unblockMemberText: 'Vous allez débloquer l\'accès à l\'application aux membres sélectionnés.',
  whyInactiveExportButton: 'la liste à exporter doit contenir au minimum un membre',
  whyInactiveExportButtonParticipant: 'la liste à exporter doit contenir au minimum un participant',
  waitingInvitation: 'La création des invitations est en cours.\nMerci de patienter',
  whyInactiveInvitationButton: 'Editez et publiez votre communauté pour commencer à inviter des membres',
  whyInactiveInvitationButtonParticipant: 'Editez et publiez votre événement pour commencer à inviter des participants',
  whyInactiveSelectButton: 'Vous devez sélectionner au moins un membre pour activer cette fonction',
  whyInactiveSelectButtonParticipant: 'vous devez sélectionner au moins un participant pour activer cette fonction',
  whyInactiveInviteInGroupSelectButton: 'Vous devez sélectionner au moins un membre et avoir au moins une sous-communauté pour activer cette fonction',
  whyInactiveInviteInEventSelectButton: 'Vous devez sélectionner au moins un membre et avoir au moins un événement pour activer cette fonction',
  notPublished: '(non publié)',
};

export default membersFr;
