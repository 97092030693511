import {
  getGridDateOperators,
  GridColDef,
  GridFilterInputValue,
  GridFilterOperator,
  GridRenderCellParams, useGridApiContext
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import React from "react";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export const getColumnsDef = (): GridColDef[] => {
  const { t } = useTranslation(['message']);

  const status = [
    {value: 'not_processed', label: t('message:not_processed')},
    {value: 'in_progress', label: t('message:in_progress')},
    {value: 'completed', label: t('message:completed')},
    {value: 'not_completed', label: t('message:not_completed')},
  ];

  const personFilterOperators: GridFilterOperator[] = [
    {
      label: t('message:contains'),
      value: 'contains',
      getApplyFilterFn: () => null,
      InputComponent: GridFilterInputValue,
    },
  ];

  const sumFilterOperators: GridFilterOperator[] = [
    {
      label: '=',
      value: 'equal',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
    {
      label: '!=',
      value: 'notEqual',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
    {
      label: '>',
      value: 'superior',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
    {
      label: '>=',
      value: 'superiorEqual',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
    {
      label: '<',
      value: 'inferior',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
    {
      label: '<=',
      value: 'inferiorEqual',
      getApplyFilterFn:() => null,
      InputComponent: GridFilterInputValue,
    },
  ];

  const formatDate = (value: Date | undefined) => {
    if (value === undefined) {
      return '';
    }
    return new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(value);
  };

  const customRenderCell = (params: GridRenderCellParams) => {
    const apiRef = useGridApiContext();
    return (
      <div style={{ display: "flex", width: "100%", alignItems: "center", textAlign: params.field === 'amount' ? "right" : "left" }}>
        <span style={{ flexGrow: 1 }}>
          {params.field !== 'status' && params.formattedValue}
          {params.field === 'status' &&
            (params.colDef?.valueOptions as {label: string, value: string}[])?.find(
              (item) => params.value === item.label
            )?.label}
        </span>
        <IconButton size="small" onClick={() => apiRef.current.startCellEditMode({
          id: params.id,
          field: params.field,
          initialValue: (params.colDef?.valueOptions as {label: string, value: string}[])?.find(
            (item) => params.value === item.label
            )?.value
        })}>
          <EditIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  return [
    {
      field: "authorName",
      type: "string",
      headerName: "reco_authorName",
      width: 250,
      sortable: true,
      editable: false,
      align: "left",
      filterable: true,
      headerAlign: "center",
      hideSortIcons: false,
      disableExport: false,
      disableColumnMenu: false,
      hideable: false,
      filterOperators: personFilterOperators,
    },
    {
      field: 'targetName',
      type: 'string',
      headerName: 'reco_targetName',
      width: 250,
      sortable: true,
      editable: false,
      filterable: true,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: false,
      disableColumnMenu: false,
      hideable: false,
      filterOperators: personFilterOperators,
    },
    {

      field: 'date',
      type: 'date',
      headerName: 'reco_date',
      width: 150,
      sortable: true,
      editable: false,
      filterable: true,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: false,
      disableColumnMenu: false,
      hideable: false,
      filterOperators: getGridDateOperators().filter(
        (operator) =>
          operator?.value === 'is' ||
          operator?.value === 'not' ||
          operator?.value === 'after' ||
          operator?.value === 'onOrAfter' ||
          operator?.value === 'before' ||
          operator?.value === 'onOrBefore',
      ),
      renderCell: (params: GridRenderCellParams<Date>) => formatDate(params.value),
    },
    {
      field: 'object',
      type: 'string',
      headerName: 'reco_object',
      sortable: false,
      editable: true,
      filterable: false,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      hideable: false,
      renderCell:  (params: GridRenderCellParams<Date>) => customRenderCell(params),
    },
    {
      field: 'status',
      headerName: 'reco_status',
      width: 150,
      sortable: true,
      editable: true,
      type: 'singleSelect',
      valueOptions: status,
      valueGetter: ({ value, colDef }) => {
        const option = (colDef?.valueOptions as {label: string, value: string}[])?.find(
          ({ value: optionValue }) => value === optionValue
        );
        return option?.label;
      },

      align: 'left',
      headerAlign: 'center',
      hideSortIcons: false,
      filterable: true,
      disableColumnMenu: false,
      hideable: false,
      renderCell:  (params: GridRenderCellParams<Date>) => customRenderCell(params),
    },
    {
      field: 'amount',
      type: 'number',
      headerName: 'reco_amount',
      width: 150,
      sortable: true,
      editable: true,
      filterable: true,
      align: 'right',
      headerAlign: 'center',
      hideSortIcons: false,
      disableColumnMenu: false,
      hideable: false,
      filterOperators: sumFilterOperators,
      renderCell:  (params: GridRenderCellParams<Date>) => customRenderCell(params),
    },
  ]
}