import theme from '../../../pages/theme';
import './index.scss';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface CustomBarProps {
  data: any[];
  totalMembers: number;
  yAxisSize?: number;
  xAxisType?: 'category' | 'number';
  yAxisType?: 'category' | 'number';
  layout?: 'horizontal' | 'vertical';
  limit?: number;
}

const CustomBar: React.FC<CustomBarProps> = ({
  data,
  totalMembers,
  yAxisSize = 150,
  xAxisType = 'number',
  yAxisType = 'category',
  layout = 'vertical',
  limit = 10,
}) => {
  const { t } = useTranslation(['members']);

  const [newData, setNewData] = React.useState<{ label: string; value: number }[]>([]);

  React.useEffect(() => {
    const newD: { label: string; value: number }[] = [];
    data.sort((a, b) => {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      return 0;
    });
    data.slice(0, limit).forEach((d) => {
      newD.push({
        label: d.label,
        value: d.value,
      });
    });
    setNewData(newD);
  }, [limit, data]);

  const YtickFormatter = (value: string) => {
    const limitLength = 30;
    if (value.length < limitLength) {
      return capitalize(value);
    }
    return truncate(capitalize(value), {
      length: limitLength,
    });
  };

  const XtickFormatter = (value: number) => `${value}`;

  const CustomTooltip = ({ active, payload }: any) => {
    let total = 0;
    if (payload && payload.length !== 0) {
      total = payload[0] ? Math.round((payload[0]?.value * 100) / totalMembers) : 0;
    }
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          <p>
            {total > 100 ? '100' : total}
            {capitalize(t('objectivesMessageOnHover'))}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={10} layout={layout} data={newData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis allowDecimals={false} type={xAxisType} tickFormatter={XtickFormatter} domain={[0]} />
        <YAxis
          width={yAxisSize}
          tickFormatter={YtickFormatter}
          type={yAxisType}
          dataKey="label"
          color={theme.palette.secondary.dark}
        />
        <Tooltip content={<CustomTooltip active payload />} />
        <Bar dataKey="value" fill={theme.palette.primary.main} />
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBar.displayName = 'CustomTabBar';
export default CustomBar;
