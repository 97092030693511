import SuccessButton from '../../components/generic/SuccessButton';
import { GET_SECURITY_COMMUNITY_EXIST } from '../../config/api';
import apiRest, { AxiosCancelToken } from '../../helpers/apiRest';
import getDefaultStyles from '../getDefaultStyles';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CommunityNameStep = (props) => {
  const { lastStep, nextStep, data } = props;
  const { t } = useTranslation(['words', 'register']);
  const [name, setName] = useState(data.name);
  const [isValidLength, setIsValidLength] = useState(false);
  const [isValidName, setIsValidName] = useState(false);

  const validate = (value = '') => {
    if (value.length > 0) {
      const headers = {
        'X-Device': 'desktop',
      };
      apiRest()
        .get(
          `${GET_SECURITY_COMMUNITY_EXIST}/${value}`,
          { headers },
          {
            cancelToken: new AxiosCancelToken((c) => {
              window.cancel = c;
            }),
          }
        )
        .then((response) => {
          setIsValidName(!response.data.content.exist);
        });
    }
    setIsValidLength(value.length >= 5);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  let helperText = '';
  if (!isValidLength) {
    helperText = t('register:communityNameTooShort');
  } else if (!isValidName) {
    helperText = t('register:communityExist');
  }

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    validate(name);
  }, [name]);

  return (
    <>
      <div className={classes.question}>{t('register:enterCommunityName')}</div>
      <div className={classes.content}>
        <TextField
          className={classes.formControl}
          error={name !== undefined && (!isValidLength || !isValidName)}
          id="name"
          label={t('Name')}
          value={name || ''}
          helperText={name !== undefined && (!isValidLength || !isValidName) ? helperText : ''}
          onChange={(event) => handleChange(event)}
        />
      </div>
      <div className={classes.buttons}>
        {!lastStep && (
          <SuccessButton
            size="large"
            onClick={() => nextStep({ name })}
            disabled={!isValidName || !isValidLength}
            label={t('words:next')}
          />
        )}
      </div>
    </>
  );
};

CommunityNameStep.defaultProps = {
  data: {},
};

CommunityNameStep.propTypes = {
  lastStep: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityNameStep;
