import { Avatar, Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Event, Group } from '@material-ui/icons';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const TagCommunities = (props) => {
  const { items, small, center, entity } = props;
  const useStyles = makeStyles(() => ({
    root: {
      padding: 2,
      borderRadius: 4,
    },
    container: {
      display: 'flex',
      justifyContent: center ? 'center' : 'flex-start',
      flexWrap: 'wrap',
    },
  }));
  const classes = useStyles();

  const avatar = (item) => {
    if (entity === 'event') {
      return item?.coverPicture ? (
        <Avatar
          size={small ? 'small' : 'medium'}
          className={classes.root}
          alt={item?.name || ''}
          src={item?.coverPicture || ''}
        />
      ) : (
        <Avatar size={small ? 'small' : 'medium'} className={classes.root}>
          <Event />
        </Avatar>
      );
    } else if (!item?.logo) {
      switch (entity) {
        case 'group':
          return (
            <Avatar size={small ? 'small' : 'medium'} className={classes.root}>
              <Group />
            </Avatar>
          );
        default:
          return (
            <Avatar size={small ? 'small' : 'medium'} className={classes.root} alt={''} src={''} />
          );
      }
    } else {
      return (
        <Avatar
          size={small ? 'small' : 'medium'}
          className={classes.root}
          alt={item?.name || ''}
          src={item?.logo || ''}
        />
      );
    }
  };

  return (
    <div className={clsx(classes.selectEmpty, classes.container)}>
      {items.length > 0 &&
        items.map((item) => (
          <Fragment key={item?.nuid}>
            <Paper elevation={3} style={{ margin: 4 }}>
              <Tooltip title={item?.name || ''} arrow>
                {avatar(item)}
              </Tooltip>
            </Paper>
          </Fragment>
        ))}
    </div>
  );
};

TagCommunities.defaultProps = {
  items: [],
  small: false,
  center: false,
  entity: 'community',
};

TagCommunities.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  small: PropTypes.bool,
  center: PropTypes.bool,
  isGroup: PropTypes.bool,
  entity: PropTypes.string,
};

export default TagCommunities;
