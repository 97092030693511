const accessTypeArray = [
  {
    value: 'accessType_public',
    key: 'public',
  },
  {
    value: 'accessType_public_validation',
    key: 'public_validation',
  },
  {
    value: 'accessType_private',
    key: 'private',
  },
  {
    value: 'accessType_private_hidden',
    key: 'private_hidden',
  },
];

export default accessTypeArray;
