import { sessionContext } from '../../_hook/useSession';
import TabPanel from '../../components/generic/TabPanel';
import { POST_SECURITY_CREATE_LIMITED_USER, POST_SECURITY_LOGIN } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { emailTest, isDev, passwordTest } from '../../helpers/Tools';
import getDefaultStyles from '../getDefaultStyles';
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const AccountStep = (props) => {
  const { nextStep, gotoStep, data } = props;
  const { setToken } = React.useContext(sessionContext);
  const { t } = useTranslation(['register', 'error']);
  const [firstName, setFirstName] = useState(data.firstName || null);
  const [lastName, setLastName] = useState(data.lastName || null);
  const [email, setEmail] = useState(data.email || (isDev() && 'laurent@superconnectr.com') || '');
  const [password, setPassword] = useState((isDev() && 'aaaa1111') || null);
  const [cgu, setCGU] = useState(data.cgu || false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(password && password.length > 6);
  const [showPassword, setShowPassword] = useState(false);
  const [tabValue, setTabValue] = useState(data.tabValue || 0);
  const [waiting, setWaiting] = useState(false);

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleValidateEmail = (event) => {
    const { value } = event.target;
    setIsEmailValid(emailTest(value));
  };

  const handleChangeFirstName = (event) => {
    const { value } = event.target;
    setFirstName(value);
  };

  const handleChangeLastName = (event) => {
    const { value } = event.target;
    setLastName(value);
  };

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleChangePassword = (event, check = false) => {
    const { value } = event.target;
    setPassword(value);
    if (check) {
      setIsPasswordValid(passwordTest(value));
    } else {
      setIsPasswordValid(value.length >= 1);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeCGU = (event) => {
    const { checked } = event.target;
    setCGU(checked);
  };

  const goToCGU = () => {
    window.open('https://superconnectr.com/terms-of-service/', '_blank').focus();
  };

  const goToPrivacyPolicy = () => {
    window.open('https://superconnectr.com/privacy-policy-app/', '_blank').focus();
  };

  const handleKeyPressCGU = (event) => {
    if (event.key === 'Enter' && isEmailValid) {
      goToCGU();
    }
  };

  const createAccount = () => {
    setWaiting(true);
    const user = {
      email: email.toLowerCase(),
      password,
      firstName,
      lastName,
    };
    apiRest()
      .post(POST_SECURITY_CREATE_LIMITED_USER, user)
      .then(() => {
        toast.info(t('activationCodeSent'));
        nextStep({
          email,
          password,
          firstName,
          lastName,
          tabValue,
          cgu,
        });
      })
      .catch((error = null) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'userExist';
            break;
          default:
            key = 'unknownError';
        }
        toast.error(t(`error:${key}`));
        setWaiting(false);
      });
  };

  async function connect() {
    const params = { email, password, fromDashboard: true };
    setWaiting(true);
    apiRest()
      .post(POST_SECURITY_LOGIN, params)
      .then((response) => {
        const { user, token, refreshToken } = response.data.content;
        setToken(token);
        gotoStep(
          {
            email,
            password,
            tabValue,
            user,
            token,
            refreshToken,
          },
          3
        );
        toast.info(t('register:welComeAfterConnect', { firstName: user.firstName }));
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -9002:
            key = 'badCredentials';
            break;
          case -1003:
            key = 'emailNotValidated';
            break;
          default:
            key = 'unknownError';
        }
        toast.error(t(`error:${key}`));
        setWaiting(false);
      });
  }

  const a11yProps = (value) => ({
    id: `simple-tab-${value}`,
    'aria-controls': `simple-tabpanel-${value}`,
  });

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    actionButton: {
      textAlign: 'center',
      paddingTop: '20px',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: '50px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '20px',
      },
    },
    label: {
      lineHeight: 1,
    },
    warning: {
      fontSize: '12px',
      fontStyle: 'italic',
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.warning}>{t('register:accountWarning')}</div>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          variant="fullWidth"
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="disabled tabs example"
          centered
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab
            wrapped
            label={
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {t('dontHaveAccount')}
              </Typography>
            }
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...a11yProps(0)}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab
            wrapped
            label={
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {t('haveAccount')}
              </Typography>
            }
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <div className={classes.content}>
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          <div>
            <TextField
              className={classes.formControl}
              error={firstName !== null && firstName.length < 1}
              id="firstName"
              label={t('words:firstName')}
              value={firstName || ''}
              onChange={handleChangeFirstName}
            />
          </div>
          <div>
            <TextField
              className={classes.formControl}
              error={lastName !== null && lastName.length < 1}
              id="lastName"
              label={t('words:lastName')}
              value={lastName || ''}
              onChange={handleChangeLastName}
            />
          </div>
          <div>
            <TextField
              className={classes.formControl}
              error={email != null && !isEmailValid}
              id="email"
              type="email"
              label={t('words:email')}
              value={email || ''}
              helperText={email && !isEmailValid ? t('register:badFormat') : ''}
              onChange={handleChangeEmail}
              onBlur={handleValidateEmail}
            />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="password-label">{t('words:password')}</InputLabel>
              <Input
                id="password"
                error={password && !isPasswordValid}
                label={t('words:password')}
                type={showPassword ? 'text' : 'password'}
                value={password || ''}
                onChange={(event) => handleChangePassword(event, true)}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className={classes.warning}>{t('register:passwordDetail')}</div>
            </FormControl>
          </div>
          <div style={{ paddingTop: 10 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox size="small" checked={cgu} onChange={handleChangeCGU} name="cgu" />
                }
                classes={{ label: classes.label }}
                label={
                  <span style={{ fontSize: '12px' }}>
                    <span>{t('register:cguAccept1')}</span>
                    &nbsp;
                    <span
                      tabIndex={0}
                      onKeyPress={handleKeyPressCGU}
                      role="link"
                      onClick={goToCGU}
                      style={{ textDecoration: 'underline' }}
                    >
                      {t('register:cguAccept2')}
                    </span>
                    <span>
                      &nbsp;
                      {t('register:andThe')}
                      &nbsp;
                    </span>
                    <span
                      onKeyPress={handleKeyPressCGU}
                      tabIndex={0}
                      role="link"
                      onClick={goToPrivacyPolicy}
                      style={{ textDecoration: 'underline' }}
                    >
                      {t('register:privacyPolicy')}
                    </span>
                  </span>
                }
              />
            </FormGroup>
          </div>
          <div className={classes.actionButton}>
            {waiting ? (
              <CircularProgress />
            ) : (
              <Button
                color="primary"
                size="medium"
                variant="contained"
                disabled={!isEmailValid || !isPasswordValid || !firstName || !lastName || !cgu}
                onClick={() => createAccount()}
              >
                {t('register:createAccount')}
              </Button>
            )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <div>
            <TextField
              className={classes.formControl}
              error={email !== null && !isEmailValid}
              id="email"
              type="email"
              label={t('words:email')}
              value={email || ''}
              helperText={email && !isEmailValid ? t('register:badFormat') : ''}
              onChange={handleChangeEmail}
            />
            <div className={classes.warning}>{t('register:emailWarning')}</div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="password-label">{t('words:password')}</InputLabel>
              <Input
                id="password"
                error={password !== null && !isPasswordValid}
                label={t('words:password')}
                type={showPassword ? 'text' : 'password'}
                value={password || ''}
                onChange={handleChangePassword}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className={classes.warning}>{t('register:passwordWarning')}</div>
            </FormControl>
          </div>
          <div className={classes.actionButton}>
            {waiting ? (
              <CircularProgress />
            ) : (
              <Button
                color="primary"
                size="medium"
                variant="contained"
                disabled={!isEmailValid || !isPasswordValid}
                onClick={() => connect()}
              >
                {t('register:connectMe')}
              </Button>
            )}
          </div>
        </TabPanel>
      </div>
    </>
  );
};

AccountStep.defaultProps = {
  data: {},
};

AccountStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  gotoStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default AccountStep;
