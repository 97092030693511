import { frFR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: '#F29C49',
          color: 'white',
        },
      },
      MuiChip: {
        colorPrimary: {
          backgroundColor: '#F29C49',
          color: 'white',
        },
        deleteIconColorPrimary: {
          backgroundColor: 'transparent',
          color: 'white',
        },
        deleteIconColorSecondary: {
          backgroundColor: 'transparent',
          color: 'white',
        },
      },
      MuiIcon: {
        colorPrimary: {
          backgroundColor: '#F29C49',
          color: 'white',
        },
      },
      MuiBadge: {
        root: {
          fontFamily: '"Baloo 2"',
          fontSize: '1.1em',
        },
        colorPrimary: {
          backgroundColor: '#F29C49',
          color: 'white',
        },
      },
      MuiSvgIcon: {
        colorPrimary: {
          backgroundColor: 'transparent',
          color: '#F29C49',
        },
      },
      MuiAutocomplete: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#F29C49',
            color: 'white',
          },
          '&[data-focus="true"]': {
            backgroundColor: '#F29C49',
            color: 'white',
          },
          '&:active': {
            backgroundColor: '#F29C49',
            color: 'white',
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '35px',
        },
      },
      MuiListItem: {
        root: {
          width: 'auto',
        },
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: '#F29C49',
            color: 'white',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#F29C49',
          },
        },
      },
      MuiSelect: {
        outlined: {
          margin: 0,
          padding: 12,
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: 'translate(14px, 14px) scale(1)',
        },
      },
      MuiFormControl: {
        marginDense: {
          marginTop: 0,
          marginBottom: 4,
        },
      },
    },
    palette: {
      background: {
        default: 'white',
      },
      cancel: {
        main: '#767676',
        light: '#EFEFEF',
      },
      primary: {
        main: '#F29C49',
        light: '#EAD6C5',
      },
      secondary: {
        main: '#545454',
        light: '#EFEFEF',
        dark: '#A0A0A0',
      },
      success: {
        main: '#6AA84F',
        light: '#9DDB82',
        dark: '#37751C',
      },
      error: {
        main: '#FF7070',
      },
      disabled: {
        main: '#A0A0A0',
      },
      text: {
        primary: '#545454',
      },
      action: {
        selected: '#545454',
      },
      skill: {
        main: '#FE5C44',
      },
      objective: {
        main: '#6FBFFC',
      },
      focus: {
        main: '#FAD38B',
      },
      actualityCategory: {
        actuality: {
          main: '#F29C49',
        },
        member: {
          main: '#30CAF2',
        },
        'new member': {
          main: '#30CAF2',
        },
        'mutual aid': {
          main: '#F2C655',
        },
        event: {
          main: '#F25F3D',
        },
        community: {
          main: '#F29C49',
        },
        partner: {
          main: '#6430F2',
        },
        hr: {
          main: '#61F26E',
        },
        resource: {
          main: '#666666',
        },
      },
    },
    typography: {
      fontFamily: ['Montserrat', 'sans-serif', 'cursive'].join(','),
    },
  },
  frFR
);

export default theme;
