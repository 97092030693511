import theme from '../../pages/theme';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

const DangerButton = (props) => {
  const { label, onClick, disabled } = props;
  const style = {
    margin: theme.spacing(1),
    backgroundColor: !disabled && theme.palette.error.main,
    color: !disabled && 'white',
  };

  return (
    <Button
      style={style}
      disabled={disabled}
      onClick={onClick}
      color="primary"
      size="small"
      variant="contained"
    >
      {label}
    </Button>
  );
};

DangerButton.defaultProps = {
  disabled: false,
};

DangerButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default DangerButton;
