import theme from '../../pages/theme';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

const TagList = (props) => {
  const { items, color, deletable, handleUpdate, small, negative, squared, bold, center } = props;
  const [tags, setTags] = useState(items);
  const useStyles = makeStyles(() => ({
    root: {
      margin: 2,
      fontWeight: bold ? 500 : 400,
      color: negative ? 'white' : color,
      backgroundColor: negative ? color : 'white',
      borderRadius: squared && 4,
    },
    container: {
      display: 'flex',
      justifyContent: center ? 'center' : 'flex-start',
      flexWrap: 'wrap',
    },
  }));
  const classes = useStyles();

  const handleDelete = (tag) => {
    const filtered = tags.filter((value) => value.key !== tag.key);
    setTags(filtered);
    handleUpdate(filtered);
  };

  useEffect(() => {
    setTags(items);
  }, [items]);

  return (
    <div className={clsx(classes.selectEmpty, classes.container)}>
      {items.length > 0 &&
        items.map((item) => (
          <Fragment key={item.key}>
            {deletable ? (
              <Chip
                size={small ? 'small' : 'medium'}
                className={classes.root}
                label={item.value}
                onDelete={() => handleDelete(item)}
                variant={negative ? 'default' : 'outlined'}
              />
            ) : (
              <Chip
                size={small ? 'small' : 'medium'}
                className={classes.root}
                label={item.value}
                variant={negative ? 'default' : 'outlined'}
              />
            )}
          </Fragment>
        ))}
    </div>
  );
};

TagList.defaultProps = {
  color: theme.palette.primary.main,
  items: [],
  deletable: false,
  handleUpdate: null,
  small: false,
  negative: false,
  squared: false,
  bold: false,
  center: false,
};

TagList.propTypes = {
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  deletable: PropTypes.bool,
  handleUpdate: PropTypes.func,
  small: PropTypes.bool,
  bold: PropTypes.bool,
  negative: PropTypes.bool,
  squared: PropTypes.bool,
  center: PropTypes.bool,
};

export default TagList;
