import theme from '../../pages/theme';
import { Button, withStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

const CancelButton = (props) => {
  const { label, onClick, disabled } = props;

  const ColorButton = withStyles(() =>
    !disabled
      ? {
          root: {
            color: theme.palette.cancel.main,
            backgroundColor: 'white',
            borderColor: theme.palette.cancel.main,
            '&:hover': {
              color: 'white',
              backgroundColor: theme.palette.cancel.main,
              borderColor: theme.palette.cancel.main,
            },
          },
        }
      : {
          root: {
            color: theme.palette.cancel.light,
            backgroundColor: 'white',
            borderColor: theme.palette.cancel.light,
          },
        }
  )(Button);

  return (
    <ColorButton
      disabled={disabled}
      onClick={onClick}
      color="secondary"
      size="small"
      variant="outlined"
    >
      {label}
    </ColorButton>
  );
};

CancelButton.defaultProps = {
  disabled: false,
};

CancelButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;
