import { sessionContext } from '../../_hook/useSession';
import CommunityGroupForm from '../../components/form/CommunityGroupForm';
import React from 'react';
import { useLocation } from 'react-router';

const CLASSNAME = 'community';

const Community: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const edit = params.get('edit');

  // useSession
  const { sessionState, setPageIsLoading } = React.useContext(sessionContext);
  const { selectedCommunity } = sessionState;
  const communityIsPublished = selectedCommunity?.published === 1 || false;

  // @ts-ignore
  const [isCreation, setIsCreation] = React.useState(location?.state?.isCreation ?? false);

  React.useEffect(() => {
    if (location?.state) {
      // @ts-ignore
      setIsCreation(location.state.isCreation);
    }
  }, [location]);

  React.useEffect(() => {
    setPageIsLoading(true);
    if (selectedCommunity) {
      setPageIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  return (
    <div className={`${CLASSNAME}`}>
      <CommunityGroupForm
        formParams={{ edit }}
        item={isCreation ? null : selectedCommunity}
        isGroup={false}
        isPublish={isCreation ? false : communityIsPublished}
        isEdit={!isCreation}
        onClickBackButton={() => setIsCreation(false)}
      />
    </div>
  );
};

Community.displayName = 'Community';
export default Community;
