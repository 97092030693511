import getDefaultStyles from '../../pages/getDefaultStyles';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { createStyles, Theme } from "@mui/material";

interface LoaderProps {
  size?: number;
  color?: string;
}

const Loader = (props: LoaderProps) => {
  const {
    size = 2,
    color = 'white'
  } = props;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      ...getDefaultStyles(theme),
      colorPrimary: {
        color,
      },
    })
  );

  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress
        size={`${size}rem`}
        color="primary"
        classes={{ colorPrimary: classes.colorPrimary }}
      />
    </div>
  );
};

export default Loader;
