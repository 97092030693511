import logo from '../../assets/images/Logo_SuperConnectr.png';
import ActionButton from '../../components/generic/ActionButton';
import { POST_SECURITY_CHANGE_PASSWORD } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as QueryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const { t } = useTranslation(['connect', 'words']);
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const params = QueryString.parse(location.search);
  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    textField: {
      width: '25ch',
    },
    root: {
      borderRadius: 10,
      textAlign: 'center',
      width: '350px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(10),
      padding: theme.spacing(5),
    },
    forgotten: {
      fontSize: '0.9em',
      fontWeight: 600,
    },
    margin: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const handleTextChange = (event) => {
    const { value } = event.target;
    if (value) {
      switch (event.target.name) {
        case 'password':
          setPassword(value);
          setIsPasswordValid(value.length >= 8 && value === confirmPassword);
          break;
        case 'confirmPassword':
          setConfirmPassword(value);
          setIsPasswordValid(value.length >= 8 && value === password);
          break;
        default:
          break;
      }
    }
  };

  function changePassword() {
    setWaiting(true);
    const data = { password, token: params.code };
    apiRest()
      .post(POST_SECURITY_CHANGE_PASSWORD, data)
      .then(() => {
        toast.success(t('connect:passwordChanged'));
        navigate('/', { replace: true });
      })
      .catch(() => {
        setWaiting(false);
        toast.error('error:unknownError');
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isPasswordValid) {
      changePassword();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  document.getElementsByTagName('body')[0].style = 'background-color: #F29C49';
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.root} elevation={5}>
        <div>
          <img style={{ width: '90%' }} src={logo} alt="logo SuperConnectr" />
          <p style={{ fontSize: '1.5em', fontWeight: 600 }}>{t('words:welcome')}</p>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel
              htmlFor="password-label"
              className={classes.margin}
              style={{ fontWeight: 600 }}
            >
              {t('words:password')}
            </InputLabel>
            <Input
              className={classes.margin}
              id="password"
              label={t('words:password')}
              onKeyPress={handleKeyPress}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handleTextChange}
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel
              htmlFor="confirm-password-label"
              className={classes.margin}
              style={{ fontWeight: 600 }}
            >
              {t('words:password')}
            </InputLabel>
            <Input
              className={classes.margin}
              id="confirm-password"
              label={t('words:password')}
              onKeyPress={handleKeyPress}
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleTextChange}
              name="confirmPassword"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {waiting && <CircularProgress />}
          {!waiting && (
            <ActionButton
              bold
              size="large"
              onKeyPress={handleKeyPress}
              disabled={!isPasswordValid}
              onClick={changePassword}
              label={t('words:toSend')}
            />
          )}
        </div>
      </Paper>
    </>
  );
};

export default ResetPassword;
