import useCommunity from '../../_hook/useCommunity';
import { sessionContext } from '../../_hook/useSession';
import { DIMENSION_PHONE } from '../../constants/dimensions.constants';
import getIsPro from '../../constants/getIsPro';
import useWindowDimensions from '../../helpers/getWindowDimensions';
import ForgetPassword from '../../pages/connect/ForgetPassword';
import ResetPassword from '../../pages/connect/ResetPassword';
import SignIn from '../../pages/connect/signIn';
import RegisterStepper from '../../pages/register/RegisterStepper';
import routes, { RouteSelection } from '../../pages/routes';
import ResumeScreen from '../generic/modal/ResumeScreen';
import Header from '../header';
import PageLoader from '../pageLoader';
import SideBar from '../sideBar';
import './index.scss';
import { Close, Menu } from '@material-ui/icons';
import { useJsApiLoader } from '@react-google-maps/api';
// @ts-ignore
import { presets, Root } from 'mui-layout';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CLASSNAME = 'layout';

const Layout: React.FC = () => {
  const { width } = useWindowDimensions();
  const config = presets.createFixedLayout();

  // Maps
  const [libraries] = React.useState(['places']);
  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // @ts-ignore
    libraries,
  });

  // UseSession
  const { sessionState, setMapsIsLoading, resetSessionMessages } = React.useContext(sessionContext);
  const {
    error,
    message,
    pageIsLoading,
    result,
    user,
    selectedCommunity,
    selectedEvent,
    selectedGroup,
  } = sessionState;

  // useCommunity
  const { communityState } = useCommunity();
  const { communitiesInformation } = communityState;
  const { communities } = communitiesInformation;

  // State for resume screen
  const [openResult, setOpenResult] = React.useState(false);

  const closeResults = () => {
    setOpenResult(false);
    resetSessionMessages();
  };

  React.useEffect(() => {
    if (result) {
      setOpenResult(true);
    }
    if (message) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
      toast.clearWaitingQueue();
      if (toast.length > 2) {
        toast.dismiss();
      }
    }
    resetSessionMessages();
  }, [error, message, resetSessionMessages, result]);

  React.useEffect(() => {
    setMapsIsLoading(isLoaded);
  }, [isLoaded]);

  const getSelectionRoute = () => {
    if (selectedEvent) {
      return RouteSelection.EVENT_SELECTED;
    } else if (selectedGroup) {
      return RouteSelection.GROUP_SELECTED;
    } else {
      return RouteSelection.COMMUNITY_SELECTED;
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  React.useEffect(() => {
    if (width <= DIMENSION_PHONE) {
      setIsOpen(false);
      setIsCollapsed(false);
    }
    if (width >= DIMENSION_PHONE) {
      setIsOpen(true);
      setIsCollapsed(true);
    }
  }, [width]);

  return (
    <div className={CLASSNAME}>
      <ResumeScreen
        title="results"
        onClose={closeResults}
        open={openResult}
        data={result !== null ? (result as any) : undefined}
      />
      <ToastContainer
        className={`${CLASSNAME}_toastContainer`}
        autoClose={5000}
        closeOnClick
        draggable={false}
        hideProgressBar
        limit={1}
        newestOnTop
        pauseOnHover
        position="top-center"
        rtl={false}
      />
      <Root config={config}>
        {!user || !communities ? (
          <Routes>
            <Route path="/register" element={<RegisterStepper />} />
            <Route path="/" element={<SignIn />} />
            <Route path="/forgotten" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <div className={isOpen ? `${CLASSNAME}_root` : `${CLASSNAME}_rootClose`}>
            {width <= DIMENSION_PHONE ? (
              isOpen && (
                <div className={`${CLASSNAME}_mobileSideBar`}>
                  {isOpen && (
                    <Close
                      className={`${CLASSNAME}_closeIcon`}
                      color="primary"
                      fontSize="medium"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setIsCollapsed(true);
                      }}
                    />
                  )}
                  <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                </div>
              )
            ) : (
              <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            )}
            <div className={`${CLASSNAME}_content`}>
              <div className={`${CLASSNAME}_selector`}>
                <div className={width <= DIMENSION_PHONE ? `${CLASSNAME}_selector_mobile` : ''}>
                  {width <= DIMENSION_PHONE && (
                    <>
                      {!isOpen && (
                        <Menu
                          color="primary"
                          fontSize="large"
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setIsCollapsed(false);
                          }}
                        />
                      )}
                      <span className={'font-paragraph-semiBold'}>
                        {selectedEvent?.name || selectedGroup?.name || selectedCommunity?.name}
                      </span>
                    </>
                  )}
                  <Header />
                </div>
              </div>
              <div className={`${CLASSNAME}_page`}>
                <Routes>
                  {routes(
                    getSelectionRoute(),
                    getIsPro(selectedCommunity),
                    selectedCommunity?.chatApplicationId,
                  ).map((route) => (
                      <Route
                        path={route.url}
                        element={
                          <PageLoader
                            classNames={`${CLASSNAME}_loader`}
                            isActive={pageIsLoading}
                            child={<route.component />}
                          />
                        }
                        key={route.id}
                      />
                  ))}
                </Routes>
              </div>
            </div>
          </div>
        )}
      </Root>
    </div>
  );
};

Layout.displayName = 'Layout';
export default Layout;
