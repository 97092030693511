const messageFr = {
  // A
  alreadyInvited: 'Certains utilisateurs ont déjà été invités',
  authorizeMembersPublish: 'Autoriser les membres à publier',
  authorizeMembersComment: 'Autoriser les membres à commenter',
  authorizeMembersCreateDiscussionGroups: 'Autoriser les membres à créer des groupes de discussion',
  // B
  blockedMembers_zero: 'Aucun membre n\'à été bloqué',
  blockedMembers_one: 'Un membre à été bloqué',
  blockedMembers_other: '{{ count }} membres ont été bloqués',
  // C
  communityCreated: 'Félicitations, votre communauté est créée',
  communityPublished: 'Félicitations, votre communauté est publiée',
  communityUpdated: 'Félicitations, votre communauté est mise à jour',
  contactImported_one: 'un contact importé',
  contactImported_other: '{{ count }} contacts importés',
  contactRemove_one: 'un contact a été retiré de la communauté',
  contactRemove_other: '{{count}} contacts ont été retirés de la communauté',
  contains: 'contient',
  createNewMemberActuality: 'Créer une actualité pour chaque nouveau membre',
  createNewAttendeeActuality: 'Créer une actualité pour chaque nouveau participant',
  // D
  displayDirectory: "afficher l'annuaire des membres dans la communauté",
  // E
  enableMessaging: 'Activer la messagerie',
  eventCancelled: 'votre événement à bien été annulé',
  eventCreated: 'Félicitations, votre événement est créé',
  eventDeleted: 'votre événement à bien été supprimé',
  eventUpdated: 'Félicitations, votre événement est mis à jour',
  eventIsEnabled: 'Événement activé',
  eventUserRemoved_one: 'un participant à été désinscrit de l\'événement',
  eventUserRemoved_other: '{{count}} participants ont été désinscrits de l\'événement',
  // G
  groupCreated: 'Félicitations, votre sous-communauté est créée',
  groupChannelCreated: 'Le groupe de discussion à bien été créé',
  groupChannelDeleted: 'Le groupe de discussion à bien été supprimé',
  groupDeleted: 'La sous-communauté à bien été supprimée',
  groupMemberRemove_one: 'un membre à été désinscrit de la sous-communauté',
  groupMemberRemove_other: '{{count}} membres ont été désinscrits de la sous-communauté',
  groupPublished: 'Félicitations, votre sous-communauté est publiée',
  groupIsEnabled: 'Sous-communauté activée',
  groupUpdated: 'Félicitations, votre sous-communauté est mise à jour',
  // M
  memberDelete_one: 'un membre a été supprimé de la communauté',
  memberDelete_other: '{{count}} membres ont été supprimés de la communauté',
  memberRemove_one: 'un membre a été désinscrit de la communauté',
  memberRemove_other: '{{count}} membres ont été désinscrits de la communauté',
  membershipAccepted: 'Adhésions acceptées',
  membershipRefused: 'Adhésions refusées',
  mergeMembers: 'Afficher par défaut tous les membres invités dans l’application :',
  mergeMembers2: '“membres” avec compte créé et membres avec “invitations en attente”',
  // R
  recoDeleted_one: 'La recommandation à bien été supprimée.',
  recoDeleted_other: 'Les {{count}} recommandations ont bien été supprimées',
  recoDeletedError: 'Un problème à eu lieu lors de la suppression des recommandations.',
  recoModalTitle_one: 'Supprimer une recommandation',
  recoModalTitle_other: 'Supprimer des recommandations',
  recoModalConfirmation_one: 'Êtes-vous sûr de vouloir supprimer une recommandation ?',
  recoModalConfirmation_other: 'Êtes-vous sûr de vouloir supprimer {{count}} recommandations ?',
  roleUpdateToReferent: 'La promotion a eu lieu',
  not_processed: 'Non traité',
  in_progress: 'En cours',
  completed: 'Réalisé',
  not_completed: 'Non réalisé',
  // U
  unblockedMembers_zero: 'Aucun membre n\'à été débloqué',
  unblockedMembers_one: 'Un membre à été débloqué',
  unblockedMembers_other: '{{ count }} membres ont été débloqués',
  unknownMembers_zero: 'aucun membre introuvable',
  unknownMembers_one: 'un membre introuvable',
  unknownMembers_other: '{{ count }} membres introuvables',
  userInvited_one: "L'utilisateur a bien été invité.",
  userInvited_other: 'Les utilisateurs ont bien été invités.',
  userListInvited: "Votre fichier d'invités a bien été enregistré",
  userReInvited: 'Votre relance a bien été envoyée',
};

export default messageFr;
