import { SuperConnectrIcon } from '../../../../constants/assets/Logo';
import React from 'react';

const ORANGE = '#F29C49';
const GREY = '#545454';

const getGrades = (value: number) => {
  const result: string[] = [];
  for (let i = 0; i < 10; i++) {
    result.push(i < value * 2 - 0.5 ? ORANGE : GREY);
  }
  const grades = [];
  for (let i = 0; i < 10; i += 2) {
    grades.push(<SuperConnectrIcon key={i} leftFill={result[i]} rightFill={result[i + 1]} />);
  }
  return grades;
};

export default getGrades;
