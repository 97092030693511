import {
  BlockMembersParams,
  GetMembersParams,
  InviteMembersParams,
  MembershipParams,
  RemoveMembersParams,
  UpdateMembersParams
} from "../_types/memberTypes";
import { DeleteInvitationParams } from '../_types/sessionTypes';
import apiRest from '../helpers/apiRest';

// Get members (guests, members, memberships)
const guestsApi = '/api/admin/community/invitations_list';
const membersApi = '/api/admin/community/members_list';
const membershipApi = '/api/admin/community/membership_request_list';
// Stats
const membersStatsApi = '/api/admin/community/members_stats';
const eventMembersStatsApi = '/api/admin/event/members_stats';
// Actions
const acceptMembershipApi = '/api/admin/community/membership/accept';
const acceptMembershipEventApi = '/api/admin/event/membership/accept';
const deleteInvitationApi = 'api/admin/community/invitation/delete';
const eventInviteApi = '/api/admin/event/invite';
const inviteMembersApi = '/api/referent/community/invite';
const refuseMembershipApi = '/api/admin/community/membership/refuse';
const refuseMembershipEventApi = '/api/admin/event/membership/refuse';
const removeMembersApi = '/api/admin/community/user/remove';
const deleteMembersApi = '/api/admin/community/user/delete';
const updateMembersRoleApi = '/api/admin/community/user/update_role';
const blockMembersApi = '/api/admin/community/user/block';

// Get members (guests, members, memberships)
export enum MembersType {
  guests = 'guests',
  members = 'members',
  memberships = 'memberships',
}

const getMembersRoute = (type: MembersType) => {
  switch (type) {
    case MembersType.guests:
      return guestsApi;
    case MembersType.members:
      return membersApi;
    case MembersType.memberships:
      return membershipApi;
    default:
      return '';
  }
};

const getMembers = async (type: MembersType, params: GetMembersParams) => {
  const response = await apiRest().post(getMembersRoute(type), params);
  const { data } = response;

  return data;
};

// Stats
const getMembersStats = async (nuid: string, isEvent = false) => {
  const response = await apiRest().get(
    `${isEvent ? eventMembersStatsApi : membersStatsApi}/${nuid}`
  );
  const { data } = response;

  return data;
};

// Actions
const acceptMembership = async (params: MembershipParams) => {
  if (params.eventNuid) {
    delete params.communityNuid;
  }
  const response = await apiRest().post(
    params.eventNuid ? acceptMembershipEventApi : acceptMembershipApi,
    params
  );
  const { data } = response;

  return data;
};

const deleteInvitations = async (params: DeleteInvitationParams) => {
  const response = await apiRest().post(deleteInvitationApi, params);
  const { data } = response;

  return data;
};

const inviteMembers = async (params: InviteMembersParams) => {
  if (params.eventNuid) {
    delete params.communityNuid;
    delete params.groupNuid;
  }
  const response = await apiRest().post(
    params.eventNuid ? eventInviteApi : inviteMembersApi,
    params
  );
  const { data } = response;

  return data;
};

const refuseMembership = async (params: MembershipParams) => {
  if (params.eventNuid) {
    delete params.communityNuid;
  }
  const response = await apiRest().post(
    params.eventNuid ? refuseMembershipEventApi : refuseMembershipApi,
    params
  );
  const { data } = response;

  return data;
};

const removeMembers = async (params: RemoveMembersParams) => {
  const response = await apiRest().post(removeMembersApi, params);
  const { data } = response;

  return data;
};

const deleteMembers = async (params: RemoveMembersParams) => {
  const response = await apiRest().post(deleteMembersApi, params);
  const { data } = response;

  return data;
};

const blockMembers = async (params: BlockMembersParams) => {
  const response = await apiRest().post(blockMembersApi, params);
  const { data } = response;

  return data;
};

const updateMembersRole = async (params: UpdateMembersParams) => {
  const response = await apiRest().post(updateMembersRoleApi, params);
  const { data } = response;

  return data;
};

export {
  // Get members (guests, members, memberships)
  getMembers,
  // Stats
  getMembersStats,
  // Actions
  acceptMembership,
  deleteInvitations,
  inviteMembers,
  refuseMembership,
  removeMembers,
  deleteMembers,
  updateMembersRole,
  blockMembers,
};
