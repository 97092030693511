import './index.scss';
import React, {useEffect, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import EMPTY_IMAGE from '../../../assets/images/empty_image.png';
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {getUrls} from "../../../helpers/Tools";
import FallbackImg from "../../generic/FallbackImg";

pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js';

interface FileCardProps {
  id: number;
  url: string;
  size?:  'small' | 'medium' | 'large';
  select: (id: number) => void;
  selected: boolean;
  mimeType: string;
}

const CLASSNAME = 'ImageCard';

const FileCard: React.FC<FileCardProps> = ({
  id,
  url,
  select,
  selected,
  size = 'medium',
  mimeType,
}) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [urls, setUrls] = useState(getUrls(url));
  const [pageNumber, setPageNumber] = useState<number>(1);
  const width = size === 'small' ? 60 : size === 'medium' ? 80 : 100;
  const mimeCategory = mimeType ? mimeType.split('/')[0] : 'image';

  const toggleViewer = () => {
    setIsOpen((value) => !value);
  };

  function onDocumentLoadSuccess({numPages}: { numPages: number }) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    setUrls(getUrls(url));
  }, [url]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div
      className={selected ? `${CLASSNAME} ${CLASSNAME}_${size} ${CLASSNAME}_selected` : `${CLASSNAME} ${CLASSNAME}_${size}`}
    >
      {isEmpty(url) && (
        <img
          className={`${CLASSNAME}_img`}
          style={{ opacity: 0.23 }}
          src={EMPTY_IMAGE}
          alt={''}
        />
      )}
      {!isEmpty(url) && mimeCategory === 'image' && (
        <FallbackImg
          className={`${CLASSNAME}_img`}
          src={urls[0] || url}
          onClick={() => select(id)}
          fallbackSrc={url}/>
      )}
      {!isEmpty(url) && mimeType === 'application/pdf' && (
        <div onClick={() => select(id)}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess} className={`${CLASSNAME}_${size}`}>
            <Page pageNumber={1} width={width} />
          </Document>
        </div>
      )}
      {!isEmpty(url) && mimeCategory === 'video' && (
        <div onClick={() => select(id)}>
          <video
            width={size}
            height={size}
            src={url}
            className={`${CLASSNAME}_${size}`}
          />
        </div>
      )}
      {!isEmpty(url) && (
        <div className={`${CLASSNAME}_icon-container`} onClick={toggleViewer}>
          <span className={`${CLASSNAME}_icon`}>&#128269;</span>
        </div>
      )}

      {isOpen && mimeCategory === 'image' && (
        <div onClick={toggleViewer} className={`${CLASSNAME}_modal-overlay`}>
          <img
            src={urls[1]}
            alt={''}
            className={`${CLASSNAME}_full-image`}
          />
        </div>
      )}
      {isOpen && mimeCategory === 'video' && (
        <div onClick={toggleViewer} className={`${CLASSNAME}_modal-overlay`}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            width="640"
            height="360"
            src={url}
            controls
            className={`${CLASSNAME}_full-image`}
          />
        </div>
      )}

      {isOpen && mimeType === 'application/pdf' && (
        <div onClick={toggleViewer} className={`${CLASSNAME}_modal-overlay`}>
          <div className={`${CLASSNAME}_modal-content`} onClick={(e) => e.stopPropagation()}>
            <button onClick={toggleViewer}>Fermer</button>
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} width={600} />
            </Document>
            <div>
              <button type="button" onClick={previousPage} disabled={pageNumber <= 1}>
                Page précédente
              </button>
              <span>
              Page {pageNumber || (numPages ? 1 : "--")} sur {numPages || "--"}
            </span>
              <button
                type="button"
                onClick={nextPage}
                disabled={pageNumber >= (numPages || 0)}
              >
                Page suivante
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default FileCard;
