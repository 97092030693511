import { Member, MembersParams, MemberState, MemberStats } from '../_types/memberTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: MemberState = {
  membersStats: {
    totalContacts: 0,
    totalInvitations: 0,
    totalMembers: 0,
    totalMemberships: 0,
    totalNewMembers: 0,
  },
  members: { total: 0, users: [] },
  memberships: { total: 0, users: [] },
  guests: { total: 0, users: [] },

  membersParams: {
    page: 1,
    maxPerPage: 50,
    search: '',
  },
};

const reducers = {
  setMembersStats: (state: any, action: PayloadAction<MemberStats>): void => {
    set(state, 'membersStats', action.payload);
  },
  setMembers: (state: any, action: PayloadAction<{ total: number; users: Member[] }>): void => {
    set(state, 'members', action.payload);
  },
  setMemberships: (state: any, action: PayloadAction<{ total: number; users: Member[] }>): void => {
    set(state, 'memberships', action.payload);
  },
  setGuests: (state: any, action: PayloadAction<{ total: number; users: Member[] }>): void => {
    set(state, 'guests', action.payload);
  },

  setMembersParams: (state: any, action: PayloadAction<MembersParams>): void => {
    set(state, 'membersParams', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'member',
  initialState,
  reducers,
});
