const apiEn = {
  // Member
  firstName: 'prénom',
  lastName: 'nom',
  company: 'entreprise',
  currentPosition: 'poste actuel',
  localisation: 'localisation',
  activitySector: "secteur d'activité",
  roles: 'roles',
  communities: 'communautés',
  email: 'email',
  skills: 'compétences',
  objectives: 'objectifs',
};

export default apiEn;
