import { EventCategory, EventsParams, EventState } from '../_types/eventTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: EventState = {
  eventsInformation: {
    total: 0,
    events: [],
  },

  eventsParams: {
    page: 1,
    maxPerPage: 50,
    search: '',
    desc: false,
    addGroupEvents: false,
  },
  eventCategories: [],
};

const reducers = {
  setEventsInformation: (
    state: any,
    action: PayloadAction<{ total: number; eventsList: any[] }>
  ): void => {
    set(state, 'eventsInformation', action.payload);
  },
  setEventsParams: (state: any, action: PayloadAction<EventsParams>): void => {
    set(state, 'eventsParams', action.payload);
  },
  setEventCategories: (state: any, action: PayloadAction<EventCategory[]>): void => {
    set(state, 'eventCategories', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'events',
  initialState,
  reducers,
});
