const registerFr = {
  accountValidated: 'Merci ! Votre compte a bien été validé.',
  accountWarning:
    "Créez un compte si vous n'avez pas encore de compte sur l'application mobile.\n" +
    "Vous avez déjà un compte sur l'application mobile ? Créez votre communauté avec les mêmes identifiants.",
  activationCodeSent: 'Un mail avec un code de validation à été envoyé à votre adresse',
  activitySector: "Secteur d'activité",
  activitySectorFocus: "Rechercher un secteur d'activité.",
  badFormat: 'Le format est incorrect',
  benefit1: 'Activez votre communauté pour inviter vos membres',
  benefit2: 'Référencez et augmentez la visibilité de votre communauté',
  benefit3: 'Développez votre communauté avec des demandes de nouveaux membres',
  benefit4:
    "Publiez des actualités sur votre communauté, vos événements, vos membres et leurs besoins d'entraide",
  benefits: 'Pourquoi publier votre communauté ?',
  cguAccept1: "J'accepte les",
  cguAccept2: "Conditions Générales d'Utilisation",
  andThe: 'et la',
  privacyPolicy: 'Politique de Confidentialité',
  choosePlan: ' puis choisissez le plan qui convient le plus à votre communauté ! ',
  codeNotMatching: 'Ce code ne correspond pas',
  communityBook: 'dans l’annuaire des communautés de l’application SuperConnectr.',
  communityDescriptionTooShort: 'Vous devez saisir au moins 20 caractères.',
  communityExist: 'Ce nom est déjà pris',
  communityInvitation: 'Inviter un Community Leader',
  communityNameTooShort: 'Le nom de la communauté doit faire au moins 5 caractères',
  communityRegisterStep10: 'Créer ma communauté',
  communityRegisterStep11: 'Créer ma communauté',
  // communityRegisterStep12: 'Mon équipe de Community Leaders',
  communityRegisterStep12: 'Les mots-clés de votre communauté',
  communityRegisterStep13: 'Présentation de ma communauté',
  communityRegisterStep14: '',
  communityRegisterStep15: 'Bienvenue sur SuperConnectr !',
  communityRegisterStep1: 'Bienvenue !',
  communityRegisterStep2: 'Pour commencer, créons un compte.',
  communityRegisterStep3: 'Valider mon Email',
  communityRegisterStep4: 'Créer ma communauté',
  communityRegisterStep5: 'Créer ma communauté',
  communityRegisterStep6: 'Créer ma communauté',
  communityRegisterStep7: 'Créer ma communauté',
  communityRegisterStep8: 'Créer ma communauté',
  communityRegisterStep9: 'Créer ma communauté',
  communitySaved: 'Votre communauté est créée !',
  communityTeamTitle: 'Ajouter un Community Leader :',
  communityVisibility: 'Votre communauté est-elle publique ou privée ?',
  connectionError: "Nous n'avons pas pu nous connecter au serveur.\nMerci de ré-essayer plus tard.",
  connectMe: 'Me connecter',
  createAccount: 'Créer mon compte',
  createdCommunityText: 'Votre communauté {{communityName}} est créée.',
  createdCommunityTitle: 'Bravo !',
  dashboardWarning:
    "Privilégiez un accès à partir de votre ordinateur pour accéder à la plateforme d'animation de communauté SuperConnectr. Merci d'avance pour votre bienveillance, notre équipe fait évoluer régulièrement la plateforme pour vous permettre d'animer encore plus facilement votre communauté.",
  clWarning:
    "Par défaut, vous êtes déjà Community Leader. Le Community Leader est l'animateur référent de votre communauté.\n" +
    'Vous pouvez inviter un ou plusieurs Community Leaders à rejoindre votre équipe.\n' +
    "Les Community Leaders recevront une invitation par e-mail pour accéder au tableau de bord d'administration et d'animation de votre communauté",
  descriptionTitle:
    'Ajoutez une présentation de votre communauté pour valoriser votre identité et votre mission :',
  dontHaveAccount: 'Créer un compte',
  editMyCommunity: 'Editer ma communauté',
  emailAddress: 'Adresse e-mail',
  emailNotFound: 'Email introuvable ou invalide',
  emailVerification: 'Vérifier mon email',
  emailWarning: "Saisir le même e-mail que sur votre compte à partir de l'application mobile",
  endOfCommunityCreation: 'La création de votre communauté est terminée.',
  endOfCommunityCreationMobile:
    'La création de votre communauté est terminée.\n Rendez-vous sur community.superconnectr.com avec votre ordianteur portable ou de biureau pour gérer vos membres et vos actualités.',
  enterAccountInfo: 'Saisissez vos informations :',
  enterCommunityName: 'Quel est le nom de votre communauté ?',
  enterDescription: 'Rédiger une présentation de ma communauté...',
  errorLoadingDictionary: 'Une erreur à eu lieu lors du chargement des dictionnaires.',
  focusTitle:
    'Ajoutez les compétences / centres d’intérêt représentatifs de votre communauté pour être identifié plus facilement par les utilisateurs :',
  goWithLive: 'Continuer avec LIVE gratuitement',
  haveAccount: "j'ai déjà un compte sur l'App",
  invitations_one: 'Félicitations ! Vous avez invité un Community Leader à rejoindre votre communauté.',
  invitations_other: 'Félicitations ! Vous avez invité {{count}} Community Leaders à rejoindre votre communauté',
  iUnderstand: "J'ai compris",
  later: 'Plus tard',
  members: 'Nombre de membres',
  mobilLastText:
    'Nous vous recommandons de privilégier un accès à partir de votre ordinateur pour animer confortablement votre communauté.\n\n' +
    "Invitez vos premiers membres, publiez vos actualités et éditez votre communauté à partir de votre tableau de bord d'animation.\n\n" +
    'Merci de votre compréhension.',
  noItem: 'pas de résultat',
  passwordDetail: 'Créer un mot de passe de 8 caractères minimum avec au moins 1 chiffre',
  passwordWarning:
    "Saisir le même mot de passe que sur votre compte à partir de l'application mobile",
  postalCodeTown: 'Code postal, ville',
  premiumFeatures:
    'Profitez de toutes les fonctionnalités premium de SuperConnectr gratuitement pendant 1 mois',
  referenceMyCommunity: 'Publier maintenant',
  acceptCommunityPublication: 'J’accepte que ma communauté soit publiée',
  referenceSuccess:
    'Félicitation !\nVotre communauté est référencée dans l’annuaire des communautés sur l’application SuperConnectr.',
  registerCommunityNow: 'Vous pouvez désormais publier votre communauté.',
  registerCommunityLater:
    'Editez votre communauté pour ajouter un logo et / ou une bannière avant de publier votre communauté.',
  selectActivitySector: 'Dans quel secteur évolue votre communauté ?',
  selectCategory: 'Quelle est la catégorie de votre communauté ?',
  selectFocus: 'Sélectionnez ou ajoutez un mot-clé',
  selectLocalisation: 'Dans quelle zone géographique se situe votre communauté ?',
  selectLogo: 'Ajoutez votre logo pour personnaliser le profil de votre communauté :',
  selectCoverPicture: 'Ajoutez votre bannière pour personnaliser le profil de votre communauté :',
  selectMembers: 'Combien de membres compte votre communauté ?',
  unknownError: 'Une erreur à eu lieu. Merci de ressayer plus tard.',
  uploadLogo: 'Sélectionnez une image',
  uploadCoverPicture: 'Sélectionnez une image',
  userExist: 'Un compte existe déjà avec cet email',
  welComeAfterConnect: 'Bienvenue {{firstName}} !',
  welcomeText:
    "Nous allons vous accompagner à travers quelques étapes pour créer votre communauté professionnelle dans l'application SuperConnectr.",
  welcomeWarning:
    'Note : vous pourrez modifier à tout moment les informations saisies après la création de votre communauté',
};

export default registerFr;
