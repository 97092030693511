import { sessionContext } from '../../_hook/useSession';
import logo from '../../assets/images/icon_superconnectr.png';
import getIsPro from '../../constants/getIsPro';
import routes, { RouteSelection } from '../../pages/routes';
import Tag from '../user/Tag';
import './index.scss';
import Profile from './profile';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Event, ExitToApp } from '@material-ui/icons';
import { upperCase } from 'lodash';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

const CLASSNAME = 'sideBar';

interface SideBarProps {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({ isCollapsed, setIsCollapsed }) => {
  const { t } = useTranslation('words');
  const location = useLocation();
  const navigate = useNavigate();

  // UseSession
  const { sessionState, logout } = React.useContext(sessionContext);
  const { user, selectedCommunity, selectedEvent, selectedGroup } = sessionState;

  const handleLogout = () => {
    logout();
    localStorage.clear();
    navigate('/');
  };

  const getSelectionRoute = () => {
    if (selectedEvent) {
      return RouteSelection.EVENT_SELECTED;
    } else if (selectedGroup) {
      return RouteSelection.GROUP_SELECTED;
    } else {
      return RouteSelection.COMMUNITY_SELECTED;
    }
  };

  return (
    <div className={CLASSNAME}>
      <img className={`${CLASSNAME}_logo`} alt="company logo" src={logo} />
      {selectedEvent ? (
        selectedEvent.coverPicture === null ? (
          <Event className={`${CLASSNAME}_avatar`} color={'primary'} />
        ) : (
          <Tag item={selectedEvent} />
        )
      ) : (
        <Profile user={user} collapsed={isCollapsed} />
      )}
      <div className={`${CLASSNAME}_menu`}>
        <List>
          {routes(
            getSelectionRoute(),
            getIsPro(selectedCommunity),
            selectedCommunity?.chatApplicationId,
          ).map(
            (route: any) =>
              route.show && (
                <Link className={`${CLASSNAME}_link`} to={route.url} key={route.name}>
                  <ListItem
                    className={`${CLASSNAME}_listItem`}
                    button
                    selected={location.pathname === route.url}
                  >
                    <ListItemIcon className={`${CLASSNAME}_listItemIcon`}>
                      <Tooltip title={upperCase(t(route.name))} arrow>
                        <route.icon
                          color={location.pathname === route.url ? 'primary' : 'secondary'}
                        />
                      </Tooltip>
                    </ListItemIcon>
                    {!isCollapsed ? (
                      <ListItemText
                        className={
                          location.pathname === route.url
                            ? `${CLASSNAME}_selectedText`
                            : `${CLASSNAME}_text`
                        }
                        disableTypography
                        primary={<span>{capitalize(t(route.name))}</span>}
                      />
                    ) : (
                      <ListItemText />
                    )}
                  </ListItem>
                </Link>
              )
          )}
        </List>
        <div className={`${CLASSNAME}_logout`}>
          <ListItem button onClick={handleLogout}>
            <Tooltip title={upperCase(t('logout'))} arrow>
              <ListItemIcon className={`${CLASSNAME}_listItemIcon`}>
                <ExitToApp />
              </ListItemIcon>
            </Tooltip>
            {!isCollapsed && (
              <ListItemText
                className={`${CLASSNAME}_text`}
                disableTypography
                primary={<span>{t('logout')}</span>}
              />
            )}
          </ListItem>
        </div>
      </div>
      <span className={`${CLASSNAME}_version`}>{`v${process.env.REACT_APP_VERSION}`}</span>
      <div className={`${CLASSNAME}_iconButton`}>
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
    </div>
  );
};

SideBar.displayName = 'SideBar';
export default SideBar;
