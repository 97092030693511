import React, {useEffect, useState} from 'react';

interface FallbackImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  fallbackSrc: string;
}

const FallbackImg: React.FC<FallbackImgProps> = (
  { src, fallbackSrc, alt, ...otherProps }
) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => { setImageSrc(src)}, [src]);

  const handleError = () => {
    setImageSrc(fallbackSrc);
  };

  return <img src={imageSrc} alt={alt} onError={handleError} {...otherProps} />;
};

export default FallbackImg;
