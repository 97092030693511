class WebStorageManagement {
  static getValue(key, defaultValue = null) {
    const data = localStorage.getItem(key);
    if (data === null || data === undefined) {
      return defaultValue;
    }
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }

  static setValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeKey(key) {
    localStorage.removeItem(key);
  }
}

export default WebStorageManagement;
