import { sessionContext } from '../../../_hook/useSession';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TutoCommunityCreated = () => {
  const { t } = useTranslation(['layout']);
  const { sessionState } = React.useContext(sessionContext);
  const { user, selectedCommunity } = sessionState;
  const [userNoApp, setUserNoApp] = useState(false);
  const [communityNotPublished, setCommunityNotPublished] = useState(false);

  useEffect(() => {
    if (user.status !== 'USER VALIDATED') {
      setUserNoApp(true);
    }
    if (!selectedCommunity.published) {
      setCommunityNotPublished(true);
    }
  }, [user.status, selectedCommunity.published]);

  return (
    <span>
      {userNoApp && <p style={{ fontWeight: 'bold' }}>{t('layout:downloadApp')}</p>}
      <p style={{ whiteSpace: 'break-spaces' }}>{t('layout:appAndDashboardDetail')}</p>
      {communityNotPublished ? (
        <>
          <p style={{ fontWeight: 'bold' }}>{t('layout:publishYourCommunityTitle')}</p>
          <p style={{ whiteSpace: 'break-spaces' }}>{t('layout:publishYourCommunity')}</p>
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>{t('layout:animateYourCommunityTitle')}</p>
          <p style={{ whiteSpace: 'break-spaces' }}>{t('layout:animateYourCommunity')}</p>
        </>
      )}
    </span>
  );
};

export default TutoCommunityCreated;
