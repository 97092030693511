import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CategoryTag = (props) => {
  const { category, color, onClick } = props;
  const { t } = useTranslation('words');
  const useStyles = makeStyles(() => ({
    chipColor: {
      background: color,
      color: 'white',
    },
  }));
  const classes = useStyles();

  return (
    <Chip
      size="medium"
      className={classes.chipColor}
      label={category === 'community' ? t('communityActuality') : t(category)}
      onClick={onClick ? () => onClick(category) : null}
      color="default"
    />
  );
};

CategoryTag.defaultProps = {
  onClick: null,
};

CategoryTag.propTypes = {
  category: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CategoryTag;
