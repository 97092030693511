import { MembersType } from '../../../../_api/membersApi';
import { sessionContext } from '../../../../_hook/useSession';
import { MemberState } from '../../../../_types/memberTypes';
import defaultAvatar from '../../../../assets/images/default_avatar.png';
import EmptyState from '../../../../components/EmptyState';
import ScPaginator from '../../../../components/generic/paginator';
import Table from '../../../../components/table';
import loadDataInTable from '../../../../components/table/loadDataInTable';
import Profile from '../../../../components/user/Profile';
import TagCommunities from '../../../../components/user/TagCommunities';
import { DIMENSION_PHONE } from '../../../../constants/dimensions.constants';
import useWindowDimensions from '../../../../helpers/getWindowDimensions';
import './index.scss';
import { Avatar, Tooltip } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { GridColDef } from '@mui/x-data-grid';
import capitalize from 'lodash/capitalize';
import moment from 'moment/moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'membersList';

interface MembersListProps {
  getMembers: (
    nuid: string,
    type: MembersType,
    page: number,
    maxPerPage: number,
    search: string
  ) => void;
  membersState: MemberState;
  selectedNuid: string;
  isTableView: boolean;
  membersTab: number;
  itemType: any;
  maxPerPage: number;
  // selected elements
  selectionModel: any[];
  handleItemSelect: (element: any) => void;
  onSelectionModelChange: any;
}

const MembersList: React.FC<MembersListProps> = ({
  getMembers,
  membersState,
  selectedNuid,
  isTableView,
  membersTab,
  itemType,
  maxPerPage,
  selectionModel,
  handleItemSelect,
  onSelectionModelChange,
}) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation(['words', 'members']);

  // useSession
  const { sessionState } = React.useContext(sessionContext);
  const { selectedEvent, selectedGroup, selectedCommunity } = sessionState;
  const communityLeaders = selectedCommunity?.communityLeaders;
  const showRole = itemType === MembersType.members;
  const isCL = (id: string) =>
    showRole &&
    communityLeaders?.find((elt: any) => elt.nuid === id) !== undefined;

  const conditions = (columnName: string, clmns: GridColDef[]) => {
    const MyRenderCell = React.memo((params: any) => {
      switch (columnName) {
        case 'img':
          return <Avatar src={params?.row?.img || defaultAvatar} alt="" />;
        case 'communities':
          return (
            <div className="scrollableContentInTable">
              <TagCommunities items={params?.value} />
            </div>
          );
        case 'groups':
          return (
            <div className="scrollableContentInTable">
              <TagCommunities items={params?.value} />
            </div>
          );
        case 'professionalActivity':
          return (
            <Tooltip title={params?.value?.value ?? ''} arrow>
              <p className="pInTable">{params?.value?.value}</p>
            </Tooltip>
          );
        case 'inscriptionDate':
          return (
            <p className="pInTable">
              {params?.value && capitalize(moment(params?.value).format('L'))}
            </p>
          );
        case 'createdDate':
          return (
            <p className="pInTable">
              {params?.value && capitalize(moment(params?.value).format('L'))}
            </p>
          );
        case 'status':
          return (
            <p className="pInTable">
              {params?.value === 'USER BLOCKED' && <RemoveCircleOutline color="error" fontSize="small" />}
            </p>
          );
        case 'updatedDate':
          return (
            <p className="pInTable">
              {params?.value && capitalize(moment(params?.value).format('L'))}
            </p>
          );
        default:
          return <></>;
      }
    });

    if (!clmns.find((c) => c.field === columnName)) {
      switch (columnName) {
        case 'status':
          clmns.push({
            field: columnName,
            headerName: '',
            width: 50,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'img':
          clmns.push({
            field: columnName,
            headerName: '',
            width: 50,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'communities':
          clmns.push({
            field: columnName,
            headerName: 'member_communities',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'groups':
          clmns.push({
            field: columnName,
            headerName: 'member_groups',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'role':
          clmns.push({
            field: columnName,
            headerName: 'member_role',
            width: 100,
          });
          break;
        case 'ProfessionalActivity':
          clmns.push({
            field: columnName,
            headerName: 'member_professionalActivity',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'inscriptionDate':
          clmns.push({
            field: columnName,
            headerName: 'member_inscriptionDate',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'createdDate':
          clmns.push({
            field: columnName,
            headerName: 'member_createdDate',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        case 'updatedDate':
          clmns.push({
            field: columnName,
            headerName: 'member_updatedDate',
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => <MyRenderCell {...params} />,
          });
          break;
        default:
          break;
      }
    }
  };

  // Columns, rows & selectedRows
  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  const [rows, setRows] = React.useState([]);

  // Management of page
  const [elementsPerPage, setElementsPerPage] = React.useState(maxPerPage);

  //
  const [newCurrentList, setNewCurrentList] = React.useState(membersState.members);

  const handleChangeItemsPerPage = (newItemsPerPage: number) => {
    setElementsPerPage(newItemsPerPage);
    getMembers(selectedNuid, itemType, 1, newItemsPerPage, '');
  };

  const handleChangePage = (newPage: number) => {
    getMembers(
      selectedNuid,
      itemType,
      newPage,
      membersState.membersParams.maxPerPage,
      membersState.membersParams.search
    );
  };

  React.useEffect(() => {
    switch (itemType) {
      case MembersType.guests:
        setNewCurrentList(membersState.guests);
        break;
      case MembersType.memberships:
        setNewCurrentList(membersState.memberships);
        break;
      default:
        setNewCurrentList(membersState.members);
        break;
    }
  }, [itemType, membersState.guests, membersState.members, membersState.memberships]);

  const [selectionModelForTable, setSelectionModelForTable] = React.useState<number[]>([]);

  // Create columns and load rows
  React.useEffect(() => {
    const newElements = [...newCurrentList.users];
    newCurrentList.users.forEach((element: any, index: number) => {
      newElements[index] = {
        ...element,
        // eslint-disable-next-line no-nested-ternary
        role:
          selectedGroup?.nuid && element.isAdmin
            ? 'GL'
            : isCL(element?.nuid)
              ? 'CL'
              : t('words:member'),
      };
    });
    const dataColumns = [
      'img',
      membersTab === 0 ? 'role' : '',
      membersTab === 0 ? 'status' : '',
      'email',
      'firstName',
      'lastName',
      'currentPosition',
      'company',
      'professionalActivity',
      membersTab === 0 ? 'groups' : '',
      membersTab === 0 ? 'inscriptionDate' : '',
      membersTab === 0 ? 'createdDate' : '',
      membersTab === 1 ? 'updatedDate' : '',
    ];
    loadDataInTable(newElements, dataColumns, setColumns, setRows, conditions, 'member');

    const indexes: number[] = [];
    newCurrentList.users.forEach((user, index) => {
      selectionModel.forEach((selection) => {
        if (selection.nuid === user.nuid) {
          indexes.push(index);
        }
      });
    });

    setSelectionModelForTable(indexes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCurrentList, selectionModel]);

  // selection
  const selectionModelChange = (selections: any) => {
    onSelectionModelChange(undefined, selections, newCurrentList.users);
  };

  const emptyStateMessage = () => {
    switch (membersTab) {
      case 1:
        return t('members:noInvitationYet');
      case 2:
        return t('members:noRequestYet');
      default:
        return selectedEvent ? t('members:noParticipantYet') : t('members:noMemberYet');
    }
  };

  React.useEffect(() => {
    setElementsPerPage(maxPerPage);
  }, [maxPerPage]);

  return (
    <div className={CLASSNAME}>
      {isTableView && width >= DIMENSION_PHONE ? (
        <div className={`${CLASSNAME}_table`}>
          <Table
            page={membersState.membersParams.page - 1}
            columns={columns}
            rows={rows}
            rowCount={newCurrentList.total}
            selectionModel={selectionModelForTable}
            pageSize={elementsPerPage}
            onPageSizeChange={handleChangeItemsPerPage}
            onPageChange={handleChangePage}
            onSelectionModelChange={selectionModelChange}
            noRowsMessage={<EmptyState message={emptyStateMessage()} />}
          />
        </div>
      ) : (
        <div
          className={`${CLASSNAME}_cards`}
          style={{
            justifyContent: newCurrentList.users.length > 0 ? 'flex-start' : 'center',
          }}
        >
          {newCurrentList.users.length > 0 ? (
            newCurrentList.users.map((element: any) => {
              const elementIsSelected =
                selectionModel?.find((elm) => elm?.nuid === element.nuid) !== undefined;
              return (
                <Profile
                  key={element.nuid}
                  item={element}
                  handleSelected={() => handleItemSelect(element)}
                  isSelected={elementIsSelected}
                  isRoleVisible={showRole}
                />
              );
            })
          ) : (
            <EmptyState message={emptyStateMessage()} />
          )}
        </div>
      )}
      <ScPaginator
        totalItems={newCurrentList.total}
        returnChangePage={handleChangePage}
        returnChangeRowsPerPage={handleChangeItemsPerPage}
        page={membersState.membersParams.page}
        rowsPerPage={elementsPerPage}
      />
    </div>
  );
};

MembersList.displayName = 'MembersList';

export default MembersList;
