import theme from '../../pages/theme';
import Loader from '../generic/Loader';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

const WaitingForm = (props) => {
  const { message } = props;
  const useStyles = makeStyles(() => ({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    message: {
      whiteSpace: 'pre-wrap',
      paddingBottom: theme.spacing(2),
      textAlign: 'center',
    },
    loader: {
      margin: 'auto',
      textAlign: 'center',
      width: 'min-content',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.message}>{message}</div>
      <div className={classes.loader}>
        <Loader size={2} color={theme.palette.primary.main} />
      </div>
    </div>
  );
};

WaitingForm.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WaitingForm;
