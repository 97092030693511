import emptyActualities from '../../../assets/images/emptyActualities.png';
import emptyAgora from '../../../assets/images/emptyAgora.png';
import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActualitiesEmptyStateProps {
  isAgora?: boolean;
}

const CLASSNAME = 'actualitiesEmptyState';

const ActualitiesEmptyState: React.FC<ActualitiesEmptyStateProps> = ({ isAgora = false }) => {
  const { t } = useTranslation(['actuality']);

  return (
    <div className={CLASSNAME}>
      <div>
        <img
          alt={isAgora ? 'emptyAgora' : 'emptyActualities'}
          src={isAgora ? emptyAgora : emptyActualities}
        />
      </div>
      <h1>{isAgora ? t('emptyAgoraQuote') : t('emptyActualitiesQuote')}</h1>
      <p>{isAgora ? t('emptyAgoraAuthor') : t('emptyActualitiesAuthor')}</p>
      <h4>{!isAgora && t('emptyActualitiesMessage')}</h4>
    </div>
  );
};

ActualitiesEmptyState.displayName = 'ActualitiesEmptyState';

export default ActualitiesEmptyState;
