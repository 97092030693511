import './index.scss';
import { Avatar, Paper, Tooltip } from '@material-ui/core';
import React from 'react';

const CLASSNAME = 'tag';

interface TagProps {
  item: any;
}

const Tag: React.FC<TagProps> = ({ item }) => {
  return (
    <div className={CLASSNAME}>
      <Paper elevation={3}>
        <Tooltip title={item.name} arrow>
          <Avatar className={`${CLASSNAME}_avatar`} alt={item.name} src={item.coverPicture} />
        </Tooltip>
      </Paper>
    </div>
  );
};

export default Tag;
