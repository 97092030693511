import './index.scss';
import {filesList} from "./types";
import React, {useState} from 'react';
import Gallery from "./gallery";
import {ActionPanel, Directions} from "./actionPanel";
import {useTranslation} from "react-i18next";
import {mimeTypesToExtensions} from "../../helpers/Tools";
import mimelite from "mime/lite";

interface FilesUploaderProps {
  files: filesList[];
  classNames?: string;
  title: string;
  onChange(data: filesList[]): any;
  maxFiles: number;
  maxSize: number;
  mimeTypes?: string[];
}

interface FilesUploaderStates {
  files: filesList[];
  index: number;
  totalSelected: number;
}

const CLASSNAME = 'ImagesUploader';

const FilesUploader: React.FC<FilesUploaderProps> = ({
  files,
  title,
  onChange,
  maxFiles= 10,
  maxSize,
  mimeTypes = ['image/png', 'image/jpg', 'image/gif'],
}) => {
  const extensions = mimeTypesToExtensions(mimeTypes);
  const { t } = useTranslation(['words', 'layout']);
  const [states, setStates] = useState<FilesUploaderStates>({
    files: [],
    index: 0,
    totalSelected: 0,
  });
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);

  const updateSelected = (files: filesList[]) => {
    setStates({
      ...states,
      files: files,
      totalSelected: files.reduce((acc, currentValue) => acc + Number(currentValue.selected), 0),
    });
  }
  const updateFilesList = (files: FileList) => {
    const messagesFileType: string[] = [];
    const messagesSize: string[] = [];
    const currentFiles = states.files;
    let currentIndex = states.index;
    Array.from(files).forEach((file: File) => {
      if (currentIndex < maxFiles) {
        if (!mimeTypes.includes(file.type)) {
          messagesFileType.push(file.name);
        } else if (file.size >= maxSize) {
          messagesSize.push(file.name);
        } else {
          currentFiles[currentIndex] = {
            id: currentIndex++,
            selected: false,
            file: file,
            url: URL.createObjectURL(file),
            mimeType: file.type,
          };
        }
      }
    });
    let errorMessages = '';
    if (messagesFileType.length > 0) {
      errorMessages = ` => ${t('layout:notAcceptedFileType')} : ${messagesFileType.join(', ')}`;
    }
    if (messagesSize.length > 0) {
      errorMessages = ` => ${t('layout:tooLarge')} : ${messagesSize.join(', ')}`;
    }
    if (errorMessages !== '') {
      setErrorMessage(errorMessages);
      setTimeout(() => {
        setErrorMessage('')
      }, 10000);
      return;
    }
    setStates({...states, index: currentIndex, files: currentFiles});
    onChange(currentFiles.filter((elt) => elt.url !== null));
  }
  const addItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event?.target?.files) {
      updateFilesList(event.target.files);
    }
  }

  const deleteAll = () => {
    setStates({
      ...states,
      files: Array(maxFiles).fill({id: null, url: null, selected: false, file: null, mimeType: null}),
      index: 0,
      totalSelected: 0,
    })
    onChange([]);
  }

  const deleteSelected = () => {
    const cleanedFiles = states.files.filter((elt) => !elt.selected);
    const updatedStates = {
      ...states,
      index: cleanedFiles.reduce((acc, currentValue) => acc + Number(currentValue.id !== null), 0),
      files: [
        ...cleanedFiles.map((elt, index) => {
          if (elt.url) {
            elt.id = index;
          }
          return elt;
        }),
        ...Array(Math.max(maxFiles - cleanedFiles.length, 0)).fill({id: null, url: null, selected: false, file: null, mimeType: null})
      ],
      totalSelected: 0,
    }
    setStates(updatedStates);
    onChange(updatedStates.files.filter((elt) => elt.url !== null));
  }

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(event.type === "dragEnter" || event.type === "dragover");
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      updateFilesList(event.dataTransfer.files);
    }
  };

  React.useEffect(() => {
    const decoratedFiles = [
      ...files.map((item, index) => {
        if (item?.url) {
          return item;
        }
        return (
          {url: item, id: index, file: null, selected: false, mimeType: mimelite.getType(item)}
        );
      }),
      ...Array(
        Math.max(maxFiles - files.length, 0)).fill(
          {id: null, url: null, selected: false, file: null, mimeType: null}
      )
    ];

    const index = files?.length || 0;
    setStates({
      ...states,
      files: decoratedFiles,
      index: index,
    });
  }, [files])

  return (
    <div className={CLASSNAME}>
      <p className={`${CLASSNAME}_title`}>{title}</p>
      <div
        className={ dragActive ? `${CLASSNAME}_drop-zone ${CLASSNAME}_drop-zone_drag-over` : `${CLASSNAME}_drop-zone`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <p>{t('actuality:dropZone')}</p>
      </div>
      <div className={`${CLASSNAME}_inner`}>
        <div className={`${CLASSNAME}_inner_pictures`}>
          <Gallery files={states.files} parentSelect={updateSelected} displayEmpty={false}/>
        </div>
        <div>
          <ActionPanel
            direction={Directions.column}
            actions={[
              {
                label: t('add'),
                type: 'file',
                acceptedFiles: extensions,
                multiple: maxFiles > 1,
                disabled: states.index === maxFiles,
                action: addItem,
              },
              {
                label: `${t('delete')} (${states.totalSelected})`,
                disabled: states.totalSelected === 0,
                action: deleteSelected,
              },
              {
                label: t('deleteAll'),
                disabled: states.index === 0,
                action: deleteAll,
              },
            ]}
          />
        </div>
      </div>
      <div className={`${CLASSNAME}_message`}>
        {`
          ${t('layout:maxFiles', {total: maxFiles})} |  
          ${t('layout:acceptedExtensions', {extensions: extensions})} |  
          ${t('layout:sizeMax', {size: (maxSize / 1000000).toFixed(2)})} Mo
        `}
      </div>
      <div className={`${CLASSNAME}_error-message`}>{errorMessage}</div>

    </div>
  );
}

FilesUploader.displayName = 'FilesUploader';

export default FilesUploader;
