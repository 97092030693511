import theme from '../../pages/theme';
import Loader from './Loader';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

const ActionButton = (props) => {
  const { label, onClick, disabled, size, bold, href, waiting, outlined } = props;

  return (
    <Button
      style={{ marginRight: theme.spacing(1), fontWeight: bold ? 600 : 400 }}
      disabled={disabled}
      onClick={onClick}
      color="primary"
      size={size}
      href={href}
      variant={outlined ? 'outlined' : 'contained'}
    >
      {waiting ? <Loader size={1} /> : label}
    </Button>
  );
};

ActionButton.defaultProps = {
  disabled: false,
  onClick: null,
  size: 'small',
  bold: false,
  waiting: false,
  href: null,
  outlined: false,
};

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  waiting: PropTypes.bool,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ActionButton;
