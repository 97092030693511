import CancelButton from '../../CancelButton';
import './index.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ResumeScreenItem {
  text: string;
  value: number;
}

interface ResumeScreenProps {
  title: string;
  data?: ResumeScreenItem[];
  open: boolean;

  onClose(): void;
}

const CLASSNAME = 'resumeScreen';

const ResumeScreen: React.FC<ResumeScreenProps> = ({ title, data, onClose, open }) => {
  const { t } = useTranslation(['members', 'words']);

  const [newData, setNewData] = React.useState<ResumeScreenItem[] | undefined>(undefined);

  React.useEffect(() => {
    if (data !== undefined) {
      setNewData(data);
    }
  }, [data]);

  return (
    <div className={CLASSNAME}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={500}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="resume-modal" classes={{ root: `${CLASSNAME}_title` }}>
          {t(`words:${title}`)}
        </DialogTitle>
        <DialogContent style={{ maxHeight: 300 }}>
          {newData &&
            newData?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={`${CLASSNAME}_item`} key={index}>
                <div className={`${CLASSNAME}_text`}>
                  {t(`members:${item.text}`, { count: item.value })}
                </div>
                <div className={`${CLASSNAME}_value`}>{item.value}</div>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <CancelButton label={t('words:close')} onClick={onClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResumeScreen;
