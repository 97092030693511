import { ActualityAndAgoraCategory } from './sessionTypes';

export enum ActualityType {
  cl = 'cl',
  member = 'member',
}

export enum ActualityStatus {
  published = 'published',
}

export interface Actuality {
  category: ActualityAndAgoraCategory;
  comments: number;
  createdDate: string;
  likes: number;
  link: string | null;
  message: string;
  nuid: string;
  picture?: string;
  pictures?: string[];
  documents?: string[];
  video?: string;
  publicationDate: string | null;
  status: ActualityStatus;
  targetMember: any;
  type: ActualityType;
  updatedDate: string;
}

export interface ActualityCompletedInformation {
  nuid: string;
  actuality: Actuality;
  publisher: {
    nuid: string;
    firstName: string;
    lastName: string;
    company: string;
  };
  target: null;
}

// Params for CRUD
export interface AddActualityParams {
  category: string;
  communityNuid?: string;
  eventNuid?: string;
  message: string;
  picture?: any;
  pictures?: string[],
  documents?: string[];
  videos?: string;
  status: ActualityStatus;
  targetMember: any;
}

export interface GetActualityParams {
  categories: string[] | null;
  communityNuid?: string | null;
  eventNuid?: string | null;
  maxPerPage: number;
  page: number;
  search: string;
  type: ActualityType;
}

export interface UpdateActualityParams extends AddActualityParams {
  actualityNuid: string;
  type: ActualityType;
}

export interface RemoveActualityParams {
  actualityNuid: string;
  communityNuid?: string;
  eventNuid?: string;
}

// State for actuality reducer
export interface ActualityState {
  actualities: ActualityCompletedInformation[];
  actualitiesMaxPerPage: number;
  actualitiesPage: number;
  total: number;
}
