import useEvent from '../../_hook/useEvent';
import useGroup from '../../_hook/useGroup';
import { sessionContext } from '../../_hook/useSession';
import { DIMENSION_PHONE } from '../../constants/dimensions.constants';
import getIsPro from '../../constants/getIsPro';
import useWindowDimensions from '../../helpers/getWindowDimensions';
import TagCommunities from '../user/TagCommunities';
import './index.scss';
import { CircularProgress, IconButton, Menu, MenuItem, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Bookmark, Close } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { Autocomplete } from '@material-ui/lab';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { UpdateEventParams } from "../../_types/eventTypes";
import CustomButton from "./CustomButton";

const CLASSNAME = 'header';

const Header: React.FC = () => {
  const { t } = useTranslation(['words', 'message']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const edit = params.get('edit');
  if (edit) {
    params.delete('edit');
    navigate(params.toString(), { replace: true });
  }

  // responsive
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = React.useState(false);

  // useSession
  const { sessionState, setGroup, setEvent, updateCommunityOrGroup, updateSelectedCommunity } =
    React.useContext(sessionContext);
  const { communities, selectedCommunity, selectedEvent, selectedGroup } = sessionState;

  // useGroup
  const { groupState, getGroups } = useGroup();
  const { groupsInformation, groupsParams } = groupState;
  const { groups } = groupsInformation;

  // useEvents
  const { eventState, getEvents, updateEvent } = useEvent();
  const { eventsInformation, eventsParams } = eventState;
  const { events } = eventsInformation;

  // Elements
  const displayCommunityDropdown = selectedCommunity?.whiteLabel === "no";

  const hideEntityDropdown =
    selectedCommunity &&
    !selectedEvent &&
    !selectedGroup;

  const buttonIsDisable = (entity: 'event' | 'group' | 'community') => {
    switch (entity) {
      case 'community':
        if ((selectedEvent || selectedGroup) && selectedCommunity?.parameters?.admin) {
          return false;
        } else if (!selectedCommunity?.parameters?.admin) {
          return true;
        } else {
          return true;
        }
      case 'group':
        return !selectedEvent;
      case 'event':
        return true;
      default:
        return false;
    }
  };

  // Settings
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isPublishActuality, setIsPublishActuality] = React.useState(false);
  const [isCommentEnabled, setIsCommentEnabled] = React.useState(true);
  const [isMessagingEnabled, setIsMessagingEnabled] = React.useState(true);
  const [isNewMemberActuality, setIsNewMemberActuality] = React.useState(false);
  const [isNewAttendeeActuality, setIsNewAttendeeActuality] = React.useState(false);
  const [isMerge, setIsMerge] = React.useState(false);
  const [isCreateDiscussionGroup, setIsCreateDiscussionGroups] = React.useState(false);
  const [isDisplayDirectory, setIsDisplayDirectory] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChangePublishActuality = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsPublishActuality(event.target.checked);
    if (selectedEvent) {
      const params = {
        name: selectedEvent.name,
        nuid: selectedEvent.nuid,
        communityNuid: selectedOne.nuid,
        membersPublicationStatus: event.target.checked ? 'authorized' : 'refused',
      };
      await updateEvent(params as UpdateEventParams);
    } else {
      await updateCommunityOrGroup(
        {
          nuid: selectedOne.nuid,
          membersPublicationStatus: event.target.checked ? 'authorized' : 'refused',
          type: selectedOne.type,
        },
        false,
        false,
        event.target.checked,
        true
      );
    }
  };

  const handleChangeEnabled = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsEnabled(event.target.checked);
    if (selectedEvent) {
      const params = {
        name: selectedEvent.name,
        nuid: selectedEvent.nuid,
        communityNuid: selectedOne.nuid,
        enabled: event.target.checked,
      };
      await updateEvent(params as UpdateEventParams);
    } else {
      await updateCommunityOrGroup(
        {
          nuid: selectedOne.nuid,
          enabled: event.target.checked,
          type: selectedOne.type,
        },
        false,
        false,
        event.target.checked,
        true
      );
    }
  };

  const handleChangeComment = async (event: any) => {
    setAnchorEl(null);
    setIsCommentEnabled(event.target.checked);
    await updateCommunityOrGroup(
      {
        nuid: selectedCommunity.nuid,
        membersCommentEnabled: event.target.checked,
        type: selectedCommunity.type,
      },
      false,
      false,
      event.target.checked,
      true
    );
  };

  const handleChangeMessaging = async (event: any) => {
    setAnchorEl(null);
    setIsMessagingEnabled(event.target.checked);
    await updateCommunityOrGroup(
      {
        nuid: selectedCommunity.nuid,
        messagingEnabled: event.target.checked,
        type: selectedCommunity.type,
      },
      false,
      false,
      event.target.checked,
      true
    );
  };

  const handleChangeNewMemberActuality = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsNewMemberActuality(event.target.checked);
    await updateCommunityOrGroup(
      {
        nuid: selectedOne.nuid,
        createNewMemberActuality: event.target.checked,
        type: selectedOne.type,
      },
      false,
      false,
      false,
      true
    );
  };

  const handleChangeNewAttendeeActuality = async (event: any) => {
    setAnchorEl(null);
    setIsNewAttendeeActuality(event.target.checked);
    await updateEvent(
      {
        communityNuid: selectedGroup?.nuid || selectedCommunity?.nuid,
        nuid: selectedEvent.nuid,
        createNewAttendeeActuality: event.target.checked,
      } as UpdateEventParams,
    );
  };

  const handleChangeMerge = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsMerge(event.target.checked);
    await updateCommunityOrGroup(
      {
        nuid: selectedOne.nuid,
        mergeInvitations: event.target.checked,
        type: selectedOne.type,
      },
      false,
      false,
      false,
      true
    );
  };

  const handleChangeDisplayDirectory = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsDisplayDirectory(event.target.checked);
    await updateCommunityOrGroup(
      {
        nuid: selectedCommunity.nuid,
        displayDirectory: event.target.checked,
        type: selectedOne.type,
      },
      false,
      false,
      false,
      true
    );
  };

  const handleChangeCreateDiscussionGroup = async (event: any) => {
    setAnchorEl(null);
    const selectedOne = selectedGroup || selectedCommunity;
    setIsCreateDiscussionGroups(event.target.checked);
    if (!selectedEvent) {
      await updateCommunityOrGroup(
        {
          nuid: selectedOne.nuid,
          membersDiscussionGroupStatus: event.target.checked,
          type: selectedOne.type,
        },
        false,
        false,
        false,
        true
      );
    } else {
      await updateEvent(
        {
          communityNuid: selectedOne.nuid,
          nuid: selectedEvent.nuid,
          membersDiscussionGroupStatus: event.target.checked,
        } as UpdateEventParams
      )
    }
  };

  const Settings = () => {
    if (selectedCommunity && !selectedEvent && !selectedGroup) {
      return (
        <Menu
          className={`${CLASSNAME}_menu`}
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:actualities')} : `}</p>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isPublishActuality ?? false} color="primary" onChange={handleChangePublishActuality} />
            <p>{t('message:authorizeMembersPublish')}</p>
          </MenuItem>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isCommentEnabled ?? false} color="primary" onChange={handleChangeComment} />
            <p>{t('message:authorizeMembersComment')}</p>
          </MenuItem>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isNewMemberActuality ?? false} color="primary" onChange={handleChangeNewMemberActuality} />
            <p>{t('message:createNewMemberActuality')}</p>
          </MenuItem>
          <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:directory')} : `}</p>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch
              checked={isMerge ?? false}
              color="primary"
              onChange={handleChangeMerge}
            />
            <div>
              <p>{t('message:mergeMembers')}</p>
              <p>{t('message:mergeMembers2')}</p>
            </div>
          </MenuItem>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch
              checked={isDisplayDirectory ?? false}
              color="primary"
              onChange={handleChangeDisplayDirectory}
            />
            <p>{capitalize(t('message:displayDirectory'))}</p>
          </MenuItem>

          <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:messaging')} : `}</p>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch
              checked={isMessagingEnabled ?? false}
              color="primary"
              onChange={handleChangeMessaging}
            />
            <div>
              <p>{t('message:enableMessaging')}</p>
            </div>
          </MenuItem>

          { selectedCommunity?.chatApplicationId && isMessagingEnabled && (
            <>
              <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:discussionGroups')} : `}</p>
              <MenuItem className={`${CLASSNAME}_menuItem`} dense>
                <Switch
                  checked={isCreateDiscussionGroup ?? false}
                  color="primary"
                  onChange={handleChangeCreateDiscussionGroup}
                />
                <div>
                  <p>{t('message:authorizeMembersCreateDiscussionGroups')}</p>
                </div>
              </MenuItem>
            </>
          )}
        </Menu>
      );
    }
    if (!selectedEvent && selectedGroup) {
      return (
        <Menu
          className={`${CLASSNAME}_menu`}
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <p className={`${CLASSNAME}_menuTitle`}>{`${t('layout:enablingGroup')} : `}</p>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isEnabled ?? false} color="primary" onChange={handleChangeEnabled} />
            <p>{t('message:groupIsEnabled')}</p>
          </MenuItem>
          <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:actualities')} : `}</p>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isPublishActuality ?? false} color="primary" onChange={handleChangePublishActuality} />
            <p>{t('message:authorizeMembersPublish')}</p>
          </MenuItem>
          <MenuItem className={`${CLASSNAME}_menuItem`} dense>
            <Switch checked={isNewMemberActuality ?? false} color="primary" onChange={handleChangeNewMemberActuality} />
            <p>{t('message:createNewMemberActuality')}</p>
          </MenuItem>
        </Menu>
      );
    }
    return (
      <Menu
        className={`${CLASSNAME}_menu`}
        id="settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <p className={`${CLASSNAME}_menuTitle`}>{`${t('words:actualities')} : `}</p>
        <MenuItem className={`${CLASSNAME}_menuItem`} dense>
          <Switch checked={isPublishActuality ?? false} color="primary" onChange={handleChangePublishActuality} />
          <p>{t('message:authorizeMembersPublish')}</p>
        </MenuItem>
        <MenuItem className={`${CLASSNAME}_menuItem`} dense>
          <Switch checked={isNewAttendeeActuality ?? false} color="primary" onChange={handleChangeNewAttendeeActuality} />
          <p>{t('message:createNewAttendeeActuality')}</p>
        </MenuItem>
      </Menu>
    );
  }

  // Actions
  const SEARCH_MAX_PER_PAGE = 7;
  const [searchWithScrollMaxPerPage, setSearchWithScrollMaxPerPage] = React.useState(SEARCH_MAX_PER_PAGE);
  const [options, setOptions] = React.useState<any[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(true);

  const handleScroll = async (event: React.SyntheticEvent) => {
    const node = event.currentTarget;
    if (
      (selectedEvent || selectedGroup) &&
      node.scrollTop + node.clientHeight === node.scrollHeight
    ) {
      const total = selectedEvent ? eventsInformation.total : groupsInformation.total;
      if (searchWithScrollMaxPerPage < total) {
        const newValue = searchWithScrollMaxPerPage + searchWithScrollMaxPerPage;
        selectedEvent
          ? await getEvents({
              communityNuid: selectedGroup ? selectedGroup.nuid : selectedCommunity?.nuid,
              searchString: eventsParams.search,
              page: 1,
              maxPerPage: newValue,
            })
          : await getGroups({
              communityNuid: selectedCommunity?.nuid,
              page: 1,
              maxPerPage: newValue,
              searchString: groupsParams.search,
              isAdmin: !selectedCommunity?.parameters?.admin,
            });
        setSearchWithScrollMaxPerPage(newValue);
      }
    }
  };

  const getEntity = () => {
    if (selectedEvent) {
      return 'event';
    } else if (selectedGroup) {
      return 'group';
    }
    return 'community';
  };

  const handleClick = async (item: any, entity: 'event' | 'group' | 'community') => {
    switch (entity) {
      case 'event':
        if (item?.nuid !== selectedEvent?.nuid || !selectedEvent) {
          setEvent(item);
        }
        break;
      case 'group':
        if (item?.nuid !== selectedGroup?.nuid || !selectedGroup) {
          setGroup(item);
        }
        setEvent(null);
        break;
      case 'community':
        if (item?.nuid !== selectedCommunity?.nuid) {
          setEvent(null);
          setGroup(null);
          await updateSelectedCommunity(item?.nuid);
        } else if (item?.parameters?.admin) {
          setEvent(null);
          setGroup(null);
          await updateSelectedCommunity(item?.nuid);
        }
        break;
      default:
        break;
    }
    setSearchWithScrollMaxPerPage(SEARCH_MAX_PER_PAGE);
    setIsDropdownVisible(false);
    navigate('/');
  };

  const onInputChange = async (
    event: any,
    searchValue: string,
    entity: 'event' | 'group' | 'community'
  ) => {
    if (event.type === 'change') {
      if (entity === 'event') {
        await getEvents({
          communityNuid: selectedGroup ? selectedGroup.nuid : selectedCommunity?.nuid,
          searchString: searchValue,
          page: 1,
          maxPerPage: SEARCH_MAX_PER_PAGE,
        });
      } else if (entity === 'group') {
        await getGroups({
          communityNuid: selectedCommunity.nuid,
          page: 1,
          maxPerPage: SEARCH_MAX_PER_PAGE,
          searchString: searchValue,
          isAdmin: !selectedCommunity?.parameters?.admin
        });
      }
    }
  };

  const updateOptionsMenu = (selected: any) => {
    setIsEnabled(selected?.enabled);
    setIsPublishActuality(selected?.membersPublicationStatus === 'authorized');
    setIsCommentEnabled(selected?.membersCommentEnabled);
    setIsMessagingEnabled(selected?.messagingEnabled);
    setIsNewMemberActuality(selected?.createNewMemberActuality ?? true);
    setIsMerge(selected?.mergeInvitations);
    setIsDisplayDirectory(selected?.displayDirectory);
    setIsCreateDiscussionGroups(selected?.membersDiscussionGroupStatus)
  }
  
  // EFFECTS
  React.useEffect(() => {
    (async () => {
      const selected = selectedGroup || selectedCommunity;
      if (selected && !selectedEvent) {
        await getGroups(
          {
            communityNuid: selectedCommunity.nuid,
            page: 1,
            maxPerPage: SEARCH_MAX_PER_PAGE,
            searchString: '',
            isAdmin: !selectedCommunity?.parameters?.admin
          },
          true
        );
        await getEvents({
          communityNuid: selected.nuid,
          searchString: '',
          page: 1,
          maxPerPage: SEARCH_MAX_PER_PAGE,
        });
        if(!selectedEvent) {
          updateOptionsMenu(selected);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity?.nuid, selectedGroup?.nuid, selectedEvent?.nuid]);

  // Effect for GL only
  React.useEffect(() => {
    if (selectedCommunity && !selectedGroup) {
      getGroups(
        {
          communityNuid: selectedCommunity.nuid,
          page: 1,
          maxPerPage: SEARCH_MAX_PER_PAGE,
          searchString: '',
          isAdmin: !selectedCommunity?.parameters?.admin,
        },
        true,
        true
      ).then((result) => {
        if (result.success) {
          if (!selectedCommunity?.parameters?.admin && result?.content?.total > 0) {
            setGroup(result?.content?.groups[0]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity?.nuid]);

  React.useEffect(() => {
    if (selectedEvent) {
      updateOptionsMenu(selectedEvent);
      setOptions(
        events.filter((item) => {
          return item.nuid !== selectedEvent.nuid;
        }) || []
      );
    } else if (selectedGroup) {
      setOptions(
        groups.filter((item) => {
          return item.nuid !== selectedGroup.nuid;
        }) || []
      );
    } else if (selectedCommunity) {
      setOptions(
        communities
          ? [...communities].filter(
              (community) =>
                community.parameters?.admin || (!community.parameters?.admin && getIsPro(community))
            )
          : []
      );
    } else {
      setOptions([]);
    }
    setIsDropdownVisible(true);
  }, [
    communities,
    events,
    groups,
    selectedCommunity?.nuid,
    selectedEvent?.nuid,
    selectedGroup?.nuid
  ]);

  return (
    <div className={CLASSNAME}>
      {width <= DIMENSION_PHONE ? (
        isOpen ? (
          <Close
            className={`${CLASSNAME}_topIcon`}
            color="primary"
            fontSize="medium"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <Bookmark
            className={`${CLASSNAME}_topIcon`}
            color="primary"
            fontSize="medium"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )
      ) : (
        <></>
      )}
      {isOpen || width >= DIMENSION_PHONE ? (
        <div className={`${CLASSNAME}_root`}>
          <div className={`${CLASSNAME}_left`}>
            {/*selectedCommunity*/}
            {selectedCommunity && (
              <CustomButton
                classNameCondition={!selectedGroup && !selectedEvent}
                highlight={!selectedGroup && !selectedEvent}
                entity={selectedCommunity}
                entityName={'community'}
                isDisabled={buttonIsDisable('community')}
                handleClick={handleClick}
              />
            )}
            {/*selectedGroup*/}
            {selectedGroup && (
              <CustomButton
                classNameCondition={selectedEvent}
                highlight={!selectedEvent}
                entity={selectedGroup}
                entityName={'group'}
                isDisabled={buttonIsDisable('group')}
                handleClick={handleClick}
              />
            )}
            {/*selectedEvent*/}
            {selectedEvent && (
              <CustomButton
                highlight={true}
                entity={selectedEvent}
                entityName={'event'}
                isDisabled={buttonIsDisable('event')}
                handleClick={handleClick}
              />
            )}
          </div>
          <div className={`${CLASSNAME}_right`}>
            <div className={`${CLASSNAME}_right_dropdown`}>
              {displayCommunityDropdown && isDropdownVisible && (
                <Autocomplete
                  blurOnSelect
                  disableClearable
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) =>
                    option.nuid === value.nuid && option.name === value.name
                  }
                  id="selectedCommunity"
                  onChange={async (event, newValue) => {
                    await handleClick(newValue, 'community');
                  }}
                  onInputChange={(event, newValue) => onInputChange(event, newValue, getEntity())}
                  options={
                    communities
                      ? [...communities].filter(
                          (community) =>
                            community.nuid !== selectedCommunity?.nuid &&
                            (community.parameters?.admin ||
                              (!community.parameters?.admin && getIsPro(community)))
                        )
                      : []
                  }
                  renderInput={(p) => (
                    <div className={`${CLASSNAME}_option`} key={p.id}>
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...p}
                        variant="standard"
                        label={capitalize(t(`words:myCommunities`))}
                        placeholder={capitalize(t(`words:myCommunities`))}
                      />
                    </div>
                  )}
                  renderOption={(option: any) => (
                    <div className={`${CLASSNAME}_option`}>
                      <TagCommunities items={[option] || []} entity={getEntity()} />
                      <p>{option.name}</p>
                    </div>
                  )}
                />
              )}
              {hideEntityDropdown ? (
                <></>
              ) : (
                <>
                  {isDropdownVisible ? (
                    <Autocomplete
                      blurOnSelect
                      disableClearable
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) =>
                        (option.nuid === value.nuid && option.name === value.name) || false
                      }
                      id="selectedEntity"
                      ListboxProps={{
                        onScroll: handleScroll,
                      }}
                      onChange={async (event, newValue) => {
                        await handleClick(newValue, getEntity());
                      }}
                      onInputChange={(event, newValue) =>
                        onInputChange(event, newValue, getEntity())
                      }
                      options={options}
                      renderInput={(p) => (
                        <div className={`${CLASSNAME}_option`} key={p.id}>
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...p}
                            variant="standard"
                            label={capitalize(t(`words:my${capitalize(getEntity())}s`))}
                            placeholder={capitalize(t(`words:my${capitalize(getEntity())}s`))}
                          />
                        </div>
                      )}
                      renderOption={(option: any) => {
                        const disabled = !option.enabled ? `(${t('words:disabled')})` : '';
                        return (
                          <div className={`${CLASSNAME}_option`}>
                            <TagCommunities items={[option] || []} entity={getEntity()} />
                            <p>{`${option.name} ${disabled}`}</p>
                          </div>
                        )
                      }}
                      value={null}
                    />
                  ) : (
                    <CircularProgress className={`${CLASSNAME}_circularProgress`} />
                  )}
                </>
              )}
            </div>
            <IconButton
              className={`${CLASSNAME}_settings`}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <SettingsIcon />
            </IconButton>
            <Settings />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

Header.displayName = 'Header';
export default Header;
