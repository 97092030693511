import AccountStep from './AccountStep';
import CommunityActivitySectorStep from './CommunityActivitySectorStep';
import CommunityCategoryStep from './CommunityCategoryStep';
import CommunityCoverPictureStep from './CommunityCoverPictureStep';
import CommunityCreatedStep from './CommunityCreatedStep';
import CommunityDescriptionStep from './CommunityDescriptionStep';
import CommunityFocusStep from './CommunityFocusStep';
import CommunityLocalisationStep from './CommunityLocalisationStep';
import CommunityLogoStep from './CommunityLogoStep';
import CommunityMembersStep from './CommunityMembersStep';
import CommunityNameStep from './CommunityNameStep';
import CommunityReferencingStep from './CommunityReferencingStep';
import LastStepMobile from './LastStepMobile';
import ValidateEmailStep from './ValidateEmailStep';
import WelcomeStep from './WelcomeStep';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const getSteps = () => [
  'communityRegisterStep1',
  'communityRegisterStep2',
  'communityRegisterStep3',
  'communityRegisterStep4',
  'communityRegisterStep5',
  'communityRegisterStep6',
  'communityRegisterStep7',
  'communityRegisterStep8',
  'communityRegisterStep9',
  'communityRegisterStep10',
  // 'communityRegisterStep11',
  'communityRegisterStep11',
  'communityRegisterStep12',
  'communityRegisterStep13',
  'communityRegisterStep14',
  'communityRegisterStep15',
];

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return {
        picture:
          'https://scv2-static-data-prod-community-image-prod.s3.eu-central-1.amazonaws.com/Nouveau-Logo-Superconnectr-standard.png',
        component: WelcomeStep,
      };
    case 1:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/alex-knight-j4uuKnN43_M-unsplash-600.jpg',
        component: AccountStep,
      };
    case 2:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/alex-knight-j4uuKnN43_M-unsplash-600.jpg',
        component: ValidateEmailStep,
      };
    case 3:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/priscilla-du-preez-697316-unsplash-600.jpg',
        component: CommunityNameStep,
      };
    case 4:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/kevin-bessat-aXCMk9ZSg6w-unsplash-600.jpg',
        component: CommunityCategoryStep,
      };
    case 5:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/headway-5QgIuuBxKwM-unsplash-600.jpg',
        component: CommunityActivitySectorStep,
      };
    case 6:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/Networking-essentiel-600.jpg',
        component: CommunityLocalisationStep,
      };
    case 7:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/community-event-600.jpg',
        component: CommunityMembersStep,
      };
    case 8:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/dashboard-commununity-superconnectr-600.jpg',
        component: CommunityCreatedStep,
      };
    case 9:
      return {
        picture:
          'https://scv2-static-data-prod-community-image-prod.s3.eu-central-1.amazonaws.com/screenshot_logo.png',
        component: CommunityLogoStep,
      };
    case 10:
      return {
        picture:
          'https://scv2-static-data-prod-community-image-prod.s3.eu-central-1.amazonaws.com/screenshot_coverpicture.png',
        component: CommunityCoverPictureStep,
      };
    // case 11:
    //   return {
    //     picture: 'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/joanna-kosinska-1_CMoFsPfso-unsplash-600.jpg',
    //     component: CommunityTeamStep,
    //   };
    case 11:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/mimi-thian-lp1AKIUV3yo-unsplash-600.jpg',
        component: CommunityFocusStep,
      };
    case 12:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/mimi-thian-vdXMSiX-n6M-unsplash-600.jpg',
        component: CommunityDescriptionStep,
      };
    case 13:
      return {
        picture:
          'https://sc-dashboard-static-data-dev.s3.eu-central-1.amazonaws.com/mimi-thian-vdXMSiX-n6M-unsplash-600.jpg',
        component: CommunityReferencingStep,
      };
    case 14:
      return {
        picture:
          'https://scv2-static-data-prod-community-image-prod.s3.eu-central-1.amazonaws.com/screenshot_dashboard.png',
        component: LastStepMobile,
      };
    default:
      return 'Undefined stepIndex';
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    marginBottom: '50px',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '98vh',
      width: '95%',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5px',
      marginBottom: '5px',
    },
  },
  pictureZone: {
    width: '100%',
    textAlign: 'center',
  },
  picture: {
    maxWidth: '100%',
    height: 'auto',
  },
  component: {
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5px',
    },
  },
  step: {
    width: '100%',
    paddingLeft: '70px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  index: {
    color: theme.palette.primary.main,
    fontSize: '1.2em',
    fontWeight: 'bolder',
  },
  title: {
    fontFamily: '"Baloo 2"',
    fontSize: '1.8em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4em',
      marginTop: 10,
    },
    textAlign: 'center',
  },
}));

const RegisterStepper = () => {
  const [data, setData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation('register');
  const classes = useStyles();
  const steps = getSteps();
  const step = getStepContent(activeStep);

  const updateData = (componentData) => {
    const updatedData = data;
    updatedData[activeStep] = componentData;
    setData(updatedData);
  };

  const handleNext = (componentData) => {
    updateData(componentData);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (componentData) => {
    updateData(componentData);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoto = (componentData, newStep) => {
    updateData(componentData);
    setActiveStep(newStep);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.pictureZone}>
        <img className={classes.picture} src={step.picture} alt="" />
      </div>
      <div className={classes.step}>
        {t(steps[activeStep]) !== '' && (
          <div className={classes.header}>
            <div>
              <h1 className={classes.title}>{t(steps[activeStep])}</h1>
            </div>
          </div>
        )}
        <div className={classes.component}>
          <step.component
            backStep={handleBack}
            nextStep={handleNext}
            gotoStep={handleGoto}
            firstStep={activeStep <= 2}
            lastStep={activeStep === steps.length}
            data={data[activeStep]}
            allData={data}
            prevData={activeStep > 1 ? data[activeStep - 1] : {}}
          />
        </div>
      </div>
    </Paper>
  );
};

export default RegisterStepper;
