import { AddEventParams, GetEventParams, UpdateEventParams } from '../_types/eventTypes';
import apiRest from '../helpers/apiRest';

// Categories
const eventCategoriesApi = '/api/admin/event/categories';
// CRUD for event
const eventCreateApi = '/api/admin/event/create';
const eventSearchApi = '/api/free/event/search';
const eventUpdateApi = '/api/admin/event/update';
const eventDeleteApi = '/api/admin/event/delete';
const eventDetachUsers = '/api/admin/event/detach-users';

// Categories
const getEventCategories = async () => {
  const response = await apiRest().get(eventCategoriesApi);
  const { data } = response;

  return data;
};

// CRUD for event
const addEvent = async (params: AddEventParams) => {
  const response = await apiRest().post(eventCreateApi, params);
  const { data } = response;

  return data;
};

const getEvents = async (params: GetEventParams) => {
  if (params.eventNuid) {
    delete params.communityNuid;
  }
  const response = await apiRest().post(eventSearchApi, params);
  const { data } = response;

  return data;
};

const updateEvent = async (params: UpdateEventParams) => {
  const response = await apiRest().post(eventUpdateApi, params);
  const { data } = response;

  return data;
};

const deleteEvent = async (eventNuid: string) => {
  const response = await apiRest().post(eventDeleteApi, { eventNuid: eventNuid });
  const { data } = response;

  return data;
};

const detachUserEvent = async (eventNuid: string, userNuids: string[]) => {
  const response = await apiRest().post(
    eventDetachUsers,
    {eventNuid, userNuids}
  );
  const { data } = response;

  return data;
};

export {
  // Categories
  getEventCategories,
  // CRUD for event
  addEvent,
  getEvents,
  updateEvent,
  deleteEvent,
  detachUserEvent,
};
