import CancelButton from './CancelButton';
import SuccessButton from './SuccessButton';
import './yesNoModal.scss';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { DisabledAction } from "../../pages/members/types";

export enum Sizes {
  'sm' = 'sm', 'xs' = 'xs', 'md' = 'md', 'lg' = 'lg', 'xl' = 'xl'
}

interface YesNoModalProps {
  changeHeight?:  boolean;
  customActions?: React.ReactElement;
  form?: ReactElement;
  formParams?:  {[cle: string]: never;};
  fullWidth?:  boolean;
  item?: {[cle: string]: never;} | null;
  maxHeight?: number;
  noAction?:  (...args: never[]) => void;
  noLabel?: string;
  open: boolean;
  size?: Sizes|false|undefined;
  specialAction?: (params: States) => never;
  specialActionCustomState?: string;
  specialActionDisabled?: DisabledAction
  specialActionHidden?: boolean;
  specialActionLabel?: string;
  text?: ReactNode;
  title: string;
  waiting?:  boolean;
  waitingForm?: React.ReactElement;
  yesAction: (params: States) => void;
  yesActionCustomState?: string;
  yesActionCustomStates?: string[];
  yesActionDisabled?: DisabledAction;
  yesIsDisabledManually?:  boolean;
  yesLabel?: string;
}

interface States {
  isValid: boolean;
  [cle: string]: boolean|never;
}

const initialStates: States = {
  isValid: false
}

const YesNoModal = (props: YesNoModalProps) => {
  const {
    changeHeight = false,
    customActions = null,
    form = null,
    formParams = {},
    fullWidth = false,
    item = null,
    maxHeight = 500,
    noAction = null,
    noLabel = null,
    open,
    size = 'sm',
    specialAction = null,
    specialActionCustomState = null,
    specialActionDisabled = 'none',
    specialActionHidden = false,
    specialActionLabel = null,
    text = null,
    title,
    waiting = false,
    waitingForm = null,
    yesAction,
    yesActionCustomState = null,
    yesActionCustomStates = [],
    yesActionDisabled = 'none',
    yesIsDisabledManually = undefined,
    yesLabel,
  } = props;

  const [states, setStates] = useState(initialStates);
  const [yesDisabled, setYesDisabled] = useState(!!form);
  const [specialDisabled, setSpecialDisabled] = useState(!!form);
  const handleNoAction = () => {
    if (noAction) {
      noAction();
    }
  };

  const handleYesAction = () => {
    yesAction(states);
  };

  const handleSpecialAction = () => {
    if (specialAction) {
      specialAction(states);
    }
  };

  const updateButtonStatus = () => {
    if (yesActionDisabled === 'allStates') {
      setYesDisabled(!states.isValid);
    } else if (yesActionDisabled === 'none') {
      setYesDisabled(false);
    } else if (yesActionDisabled === 'custom') {
      if (yesActionCustomState && Array.isArray(yesActionCustomStates) && states[yesActionCustomState]) {
        setYesDisabled(false);
      }
    }
    if (specialActionDisabled === 'allStates') {
      setSpecialDisabled(!states.isValid);
    } else if (specialActionDisabled === 'none') {
      setSpecialDisabled(false);
    } else if (specialActionDisabled === 'custom') {
      if (specialActionCustomState && states[specialActionCustomState]) {
        setSpecialDisabled(false);
      }
    }
  };

  const handleStatesChange = (newStates: States) => {
    setStates({ ...states, ...newStates });
  };

  useEffect(() => {
    if (!isEmpty(states)) {
      updateButtonStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, yesDisabled, yesIsDisabledManually]);

  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onClose={() => {
        if (noAction) {
          handleNoAction();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={500}
      maxWidth={size}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        style={{
          maxHeight,
          paddingTop: 0,
        }}
        className={changeHeight ? 'dialog' : ''}
      >
        {text && (
          <DialogContentText id="alert-dialog-description" component="div" style={{ whiteSpace: 'break-spaces' }}>
            {text}
          </DialogContentText>
        )}
        {!waiting &&
          form &&
          React.cloneElement(form, {
            exportStates: handleStatesChange,
            item,
            formParams,
          })}
        {waiting && waitingForm && React.cloneElement(waitingForm)}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        {customActions &&
          React.cloneElement(customActions, {
            exportStates: handleStatesChange,
          })}
      </DialogActions>
      <DialogActions>
        {noAction && !waiting && <CancelButton onClick={handleNoAction} label={noLabel ?? ''} />}
        {!waiting && specialAction && !specialActionHidden && (
          <SuccessButton
            outlined
            onClick={handleSpecialAction}
            disabled={specialDisabled}
            label={specialActionLabel ?? ''}
          />
        )}
        {!waiting && (
          <SuccessButton
            onClick={handleYesAction}
            disabled={yesIsDisabledManually !== undefined ? yesIsDisabledManually : yesDisabled}
            label={yesLabel ?? ''}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default YesNoModal;
