import {
  addEvent as addEventAPI,
  deleteEvent as deleteEventAPI,
  getEventCategories as getEventCategoriesAPI,
  getEvents as getEventsAPI,
  updateEvent as updateEventAPI,
  detachUserEvent as detachUserEventApi,
} from '../_api/eventApi';
import {
  initialState as eventInitialState,
  reducer as eventReducer,
  actions as eventsActions,
} from '../_slice/eventSlice';
import {
  AddEventParams,
  EventCategory,
  EventsParams,
  GetEventParams,
  UpdateEventParams
} from "../_types/eventTypes";
import displayError from '../helpers/displayError';
import WebStorageManagement from '../helpers/WebStorageManager';
import { sessionContext } from './useSession';
import React from 'react';

const useEvent = () => {
  const [eventState, dispatch] = React.useReducer(eventReducer, eventInitialState);

  // useSession
  const { sessionState, setError, setMessage, setEvent, setPageIsLoading } =
    React.useContext(sessionContext);

  // Params
  const setEventsParams = (newParams: EventsParams) => {
    dispatch(eventsActions.setEventsParams(newParams));
  };

  // Event categories
  const setEventCategories = (newCategories: EventCategory[]) => {
    dispatch(eventsActions.setEventCategories(newCategories));
  };
  const getEventCategories = async () => {
    try {
      const data = await getEventCategoriesAPI();
      setEventCategories(data.content);
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // CRUD actions
  const addEvent = async (params: AddEventParams) => {
    setPageIsLoading(true);
    try {
      await addEventAPI(params).then(() => setPageIsLoading(false));
      setMessage('message:eventCreated');
    } catch (error: any) {
      setPageIsLoading(true);
      displayError(error, setError);
    }
  };

  const getEvents = async (params: GetEventParams, isUpdate = false) => {
    try {
      setEventsParams({
        page: params.page,
        maxPerPage: params.maxPerPage,
        search: params.searchString || '',
        desc: params.desc || false,
        orderBy: params.orderBy || null,
        addGroupEvents: params.addGroupEvents || false,
      });
      const data = await getEventsAPI(params);
      if (isUpdate) {
        const currentSelectedEvent = sessionState.selectedEvent;
        let newSelectedEvent;
        if (currentSelectedEvent == null) {
          newSelectedEvent = data.content[0];
        } else {
          const targetEvent = data.content.events.filter(
            (item: any) => item.nuid === currentSelectedEvent.nuid
          );
          if (targetEvent.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            newSelectedEvent = targetEvent[0];
          } else {
            newSelectedEvent = {};
          }
        }
        WebStorageManagement.setValue('selectedEvent', newSelectedEvent);
        setEvent(newSelectedEvent);
      } else {
        dispatch(eventsActions.setEventsInformation(data.content));
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const updateEvent = async (params: UpdateEventParams) => {
    setPageIsLoading(true);
    updateEventAPI(params)
      .then(() => {
        getEvents(
          {
            maxPerPage: eventState.eventsParams.maxPerPage,
            page: eventState.eventsParams.page,
            communityNuid: params.communityNuid,
            eventNuid: params.nuid,
          },
          true
        );
        if (params.status === 'cancelled') {
          setMessage('message:eventCancelled');
        }
        setMessage('message:eventUpdated');
      })
      .catch((error: any) => {
        displayError(error, setError);
      });
      setPageIsLoading(false);
  };

  const deleteEvent = async (eventNuid: string) => {
    try {
      await deleteEventAPI(eventNuid);
      setMessage('message:eventDeleted');
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // Effect for EventPage
  const loadEventsPageEventData = async (communityOrGroupNuid: string | null, eventNuid = null) => {
    const params: GetEventParams = {
      communityNuid: communityOrGroupNuid,
      eventNuid: eventNuid,
      maxPerPage: eventState.eventsParams.maxPerPage,
      page: eventState.eventsParams.page,
      addGroupEvents: true,
    };

    await getEventCategories();
    await getEvents(params, false);
  };

  return {
    eventState,
    // Event params
    setEventsParams,
    // Event categories
    setEventCategories,
    getEventCategories,
    // CRUD actions
    addEvent,
    getEvents,
    updateEvent,
    deleteEvent,
    // Effects
    loadEventsPageEventData,
  };
};

export default useEvent;
