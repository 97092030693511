import useEvent from '../../../../_hook/useEvent';
import useGroup from '../../../../_hook/useGroup';
import { Community } from '../../../../_types/communityTypes';
import Tag from '../../../../components/user/Tag';
import './index.scss';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'importMembersInForm';

interface ImportMembersInGroupFormProps {
  selectedCommunity: Community;
  setItem: Function;
  isEvent?: boolean;
}

const ImportMembersInForm: React.FC<ImportMembersInGroupFormProps> = ({
  selectedCommunity,
  setItem,
  isEvent = false,
}) => {
  const { t } = useTranslation(['members']);

  // useGroup
  const { groupState, getGroups } = useGroup();
  const { groupsInformation, groupsParams } = groupState;
  const { groups } = groupsInformation;

  // useEvent
  const { eventState, getEvents } = useEvent();
  const { eventsInformation, eventsParams } = eventState;
  const { events } = eventsInformation;

  const onInputChange = async (event: unknown, searchValue: string) => {
    isEvent
      ? await getEvents({
          communityNuid: selectedCommunity.nuid,
          maxPerPage: eventsParams.maxPerPage,
          page: eventsParams.page,
        })
      : await getGroups({
          communityNuid: selectedCommunity.nuid,
          page: 1,
          maxPerPage: 6,
          searchString: searchValue,
          isAdmin: !selectedCommunity.parameters?.admin,
        });
  };

  const handleSearchSelect = (event: any, newValue: any) => {
    if (newValue) {
      setItem(newValue);
    } else {
      setItem(null);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (selectedCommunity) {
        isEvent
          ? await getEvents({
              communityNuid: selectedCommunity.nuid,
              maxPerPage: eventsParams.maxPerPage,
              page: eventsParams.page,
            })
          : await getGroups(
              {
                communityNuid: selectedCommunity.nuid,
                page: 1,
                maxPerPage: 6,
                searchString: '',
                isAdmin: !selectedCommunity.parameters?.admin,
              },
              true
            );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  const getStatus = (option: any) => {
    if (isEvent) {
      if (option.status !== 'open') {
        return t('members:notPublished');
      }
    } else if (option.published === 0 || !option.published) {
      return t('members:notPublished');
    }
    return '';
  };

  return (
    <>
      <Autocomplete
        ListboxProps={{
          onScroll: async (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (node.scrollTop + node.clientHeight === node.scrollHeight) {
              isEvent
                ? await getEvents({
                    communityNuid: selectedCommunity.nuid,
                    maxPerPage: eventsParams.maxPerPage * 2,
                    page: 1,
                    searchString: eventsParams.search,
                    addGroupEvents: true,
                  })
                : await getGroups({
                    communityNuid: selectedCommunity?.nuid,
                    page: 1,
                    maxPerPage: groupsParams.maxPerPage * 2,
                    searchString: groupsParams.search,
                    isAdmin: !selectedCommunity?.parameters?.admin,
                  });
            }
          },
        }}
        id="selectedGroupToInvite"
        options={isEvent ? [...events] : [...groups]}
        getOptionLabel={(option) => `${option.name}`}
        onInputChange={(event, newValue) => onInputChange(event, newValue)}
        limitTags={2}
        onChange={handleSearchSelect}
        getOptionDisabled={(option) =>
          isEvent ? option.status !== 'open' : option.published === 0 || !option.published
        }
        renderInput={(p) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...p}
            variant="standard"
            label={
              isEvent
                ? capitalize(t('members:selectedEvent'))
                : capitalize(t('members:selectedGroup'))
            }
            placeholder={
              isEvent
                ? capitalize(t('members:selectedEvent'))
                : capitalize(t('members:selectedGroup'))
            }
          />
        )}
        renderOption={(option: any) => (
          <div className={`${CLASSNAME}_option`}>
            <Tag item={option || null} />
            <p>
              {option.name} {getStatus(option)}
            </p>
          </div>
        )}
      />
    </>
  );
};

ImportMembersInForm.displayName = 'ImportMembersInForm';

export default ImportMembersInForm;
