import {
  GetActualityParams,
  RemoveActualityParams,
} from '../_types/actualityTypes';
import apiRest from '../helpers/apiRest';

// CRUD for actuality
const createActualityApi = '/api/admin/community/actuality/create';
const actualitySearchApi = '/api/admin/community/actuality/search';
const updateAdminActualityApi = '/api/admin/community/actuality/update';
const updateMemberActualityApi = '/api/community/actuality/update';
const deleteActualityApi = '/api/admin/community/actuality/delete';

// CRUD for actuality
const addActuality = async (params: FormData) => {
  const response = await apiRest().post(createActualityApi, params);
  const { data } = response;

  return data;
};

const getActualities = async (params: GetActualityParams) => {
  const response = await apiRest().post(actualitySearchApi, params);
  const { data } = response;

  return data;
};

const updateActuality = async (params: FormData, isAdmin: boolean, axiosConfig: any) => {
  const response = await apiRest().post(
    isAdmin ? updateAdminActualityApi : updateMemberActualityApi,
    params,
    axiosConfig,
  );
  const { data } = response;

  return data;
};

const removeActuality = async (params: RemoveActualityParams) => {
  const response = await apiRest().post(deleteActualityApi, params);
  const { data } = response;

  return data;
};

export {
  // CRUD for actuality
  addActuality,
  getActualities,
  updateActuality,
  removeActuality,
};
