import useMember from '../../_hook/useMember';
import { sessionContext } from '../../_hook/useSession';
import { CardSingleNumber, CardSingleNumberProps } from '../../components/chart/CardSingleNumber';
import CustomBar from '../../components/chart/CustomBar';
import MemberProfileList from '../../components/member/MemberProfile/MemberProfileList';
import { HOME_PAGE_CARDS_COLORS } from '../../constants/colors.constants';
import EventHeader from '../events/EventHeader';
import './index.scss';
import Welcome from './welcome';
import { Button } from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const CLASSNAME = 'home';

const HomePage: React.FC = () => {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();
  const goToMembersPage = (tab: number) => {
    navigate('/members', {
      state: {
        tab: tab,
      },
    });
  };
  window.onpopstate = () => {
    navigate('/');
  };

  // useSession
  const { sessionState, loadHomePageSessionData, setPageIsLoading } =
    React.useContext(sessionContext);
  const { user, selectedCommunity, selectedGroup, selectedEvent } = sessionState;
  const selectedNuid = selectedEvent?.nuid || selectedGroup?.nuid || selectedCommunity?.nuid;
  const isAdmin = (selectedCommunity?.parameters?.admin ?? false) || (selectedGroup?.isAdmin ?? false);
  const isSuperAdmin = user.roles.find((item: string) => item === 'ROLE_SUPER_ADMIN');
  const canCreateNewCommunity =
    selectedCommunity?.whiteLabel === 'yes' && !selectedGroup && !selectedEvent && isSuperAdmin;

  // useMember
  const { membersState, loadHomePageMemberData } = useMember();
  const { membersStats } = membersState;

  // data
  const cardData: CardSingleNumberProps[] = [
    {
      action: () => goToMembersPage(0),
      color: HOME_PAGE_CARDS_COLORS.totalMembersColors,
      label: selectedEvent ? t('totalParticipants') : t('totalMembers'),
      value: membersStats.totalMembers,
    },
    {
      action: () => goToMembersPage(1),
      color: HOME_PAGE_CARDS_COLORS.membersOnHoldColors,
      label: selectedEvent ? t('participantsOnHold') : t('membersOnHold'),
      value: membersStats.totalInvitations,
    },
    {
      action: () => goToMembersPage(0),
      color: HOME_PAGE_CARDS_COLORS.monthlyConnectionsColors,
      label: selectedEvent ? t('numberOfInvitedParticipants') : t('numberOfInvitedMembers'),
      subLabel: t('last30Days'),
      value: membersStats.totalNewMembers,
    },
    {
      color: HOME_PAGE_CARDS_COLORS.averagelinkforceColors,
      isLinkforce: true,
      label: t('averagelinkforce'),
      value: sessionState.linkforce,
    },
  ];
  const plotData = Object.keys(sessionState.objectives).map((key) => ({
    label: key,
    value: sessionState.objectives[key],
  }));

  // Effect
  React.useEffect(() => {
    setPageIsLoading(true);
    if (selectedNuid) {
      (async () => {
        await loadHomePageSessionData({
          nuid: selectedNuid,
          isAdmin: isAdmin,
        });
        await loadHomePageMemberData({
          nuid: selectedNuid,
          isAdmin: isAdmin,
        });
        setPageIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNuid]);

  return (
    <div className={CLASSNAME}>
      {selectedEvent ? (
        <EventHeader event={selectedEvent} />
      ) : (
        <div className={`${CLASSNAME}_topContainer`}>
          <Welcome
            userName={user.firstName}
            communityName={selectedCommunity?.name}
            groupName={selectedGroup?.name}
            validationCode={selectedCommunity?.validationCode}
          />
          {canCreateNewCommunity && (
            <div className={`${CLASSNAME}_createButton`}>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/community', {
                    state: {
                      isCreation: true,
                    },
                  });
                }}
              >
                {t('layout:newCommunity')}
              </Button>
            </div>
          )}
        </div>
      )}
      <div className={`${CLASSNAME}_cards`}>
        {cardData.map((data) => (
          <CardSingleNumber {...data} key={`${data.label}${data.value}`} />
        ))}
      </div>
      <div className={`${CLASSNAME}_bottomContainer`}>
        <div className={`${CLASSNAME}_membersLists`}>
          <MemberProfileList
            action={() => goToMembersPage(0)}
            label={selectedEvent ? t('lastEmbeddedParticipants') : t('lastEmbeddedMembers')}
            members={sessionState.lastOnboardedUsers}
            isEvent={!!selectedEvent}
          />
          <MemberProfileList
            action={() => goToMembersPage(1)}
            label={selectedEvent ? t('lastInvitedParticipants') : t('lastInvitedMembers')}
            members={sessionState.lastGuestedUsers}
            isEvent={!!selectedEvent}
          />
        </div>
        <div className={`${CLASSNAME}_plotContainer`}>
          <span className="font-paragraph-bold">{capitalize(t('objectivesOfTheMeeting'))}</span>
          {plotData.length > 0 ? (
            <CustomBar data={plotData} totalMembers={membersStats.totalMembers} />
          ) : (
            <span>{t('members:noMemberYet')}</span>
          )}
        </div>
      </div>
    </div>
  );
};

HomePage.displayName = 'HomePage';

export default HomePage;
