import apiRest from '../helpers/apiRest';
import { SearchRecoParams, UpdateReco, DeleteRecos } from "../_types/recoTypes";

const recoSearchApi = '/api/admin/reco/search';
const recoUpdateApi = '/api/admin/reco/update';
const recoDeleteApi = '/api/admin/reco/delete';

const searchRecos = async (params: SearchRecoParams) => {
  const response = await apiRest().post(recoSearchApi, params);
  const { data } = response;

  return data;
};

const updateReco = async (params: UpdateReco) => {
  const response = await apiRest().post(recoUpdateApi, params);
  const { data } = response;

  return data;
};

const deleteRecos = async (params: DeleteRecos) => {
  const response = await apiRest().post(recoDeleteApi, params);
  const { data } = response;

  return data;
};

export {
  searchRecos,
  updateReco,
  deleteRecos
};
