const wordsFr = {
  // A
  accept: 'Accepter',
  accessType_private: 'privé (sur invitation)',
  accessType_private_hidden: 'privé secret (invisible sur invitation)',
  accessType_public: 'public (ouvert)',
  accessType_public_validation: 'semi-public (avec validation)',
  actualities: 'Actualités',
  actuality: 'Général',
  add: 'ajouter',
  all: 'Tout',
  appName: "Nom de l'application",
  // B
  banner: 'Bannière',
  block: 'Bloquer',
  // C
  cancel: 'Annuler',
  category: 'Catégorie',
  close: 'Fermer',
  community: 'Communauté',
  communityActuality: 'Général',
  company: 'Société',
  congrats: 'Félicitations !',
  connection: 'Connexion',
  continue: 'Continuer',
  conversations: 'Conversations',
  country: 'Pays',
  create: 'Créer',
  currentPosition: 'Poste actuel',
  customDate: 'Date personnalisée',
  // D
  delete: 'supprimer',
  deleteAll: 'tout supprimer',
  discussions: 'Publications',
  discussionGroups: 'Groupes de discussion',
  directory: 'Annuaire',
  disabled: 'Désactivé',
  draft: 'brouillon',
  // E
  edit: 'édition',
  email: 'E-mail',
  entryDate: 'Date d\'entrée',
  error: 'erreur',
  event: 'Événement',
  events: 'Événements',
  export: 'exporter',
  // F
  feedbacks: 'Feedbacks',
  finalize: 'Terminer',
  firstName: 'Prénom',
  free: 'gratuit',
  // G
  group: 'sous-communauté',
  groups: 'Sous-communautés',
  // H
  hr: 'RH',
  home: 'Accueil',
  // I
  import: 'Importer',
  Important: 'Important',
  in: 'dans',
  invite: 'Inviter',
  inviteInEvent: 'Inviter dans un événement',
  inviteInGroup: 'Inviter dans une sous-communauté',
  isWhiteLabel: 'Marque blanche',
  // L
  lastName: 'Nom',
  later: 'Plus tard',
  link: 'URL',
  live: 'live',
  logout: 'Déconnexion',
  // M
  member: 'Membre',
  members: 'Membres',
  message: 'Message',
  messaging: 'Messagerie',
  mutualAid: 'Entraide',
  myGroups: 'mes sous-communautés',
  myCommunities: 'mes communautés',
  myEvents: 'mes événements',
  // N
  name: 'nom',
  Name: 'Nom',
  next: 'Suivant',
  no: 'non',
  notPublished: 'non publié',
  // O
  objectives: 'Objectifs',
  // P
  partner: 'Partenaire',
  participants: 'participants',
  password: 'Mot de passe',
  phoneNumber: 'Téléphone',
  previous: 'Précédent',
  private: 'Privée',
  pro: 'professionnel',
  public: 'Publique',
  publish: 'Publier',
  published: 'Publié',
  publisher: 'Publié par',
  // R
  recos: 'Recommandations',
  refuse: 'Refuser',
  resource: 'Ressource',
  results: 'résultats',
  // S
  save: 'Enregistrer',
  search: 'Rechercher',
  settings: 'Réglages',
  skills: 'Compétences',
  start: 'Démarrer',
  status: 'Statut',
  selectedCommunity: 'communauté sélectionnée',
  selectedGroup: 'sous-communauté sélectionnée',
  subscriptionPlan: "plan d'abonnement",
  // T
  toSend: 'Envoyer',
  total: 'Total',
  // U
  unblock: 'Débloquer',
  unsubscribe: 'Désinscrire',
  // V
  venue: 'lieu',
  // W
  waiting: 'en attente',
  welcome: 'Bienvenue',
  whiteLabelName: 'Nom de la marque blanche',
  // Y
  yes: 'oui',
};

export default wordsFr;
