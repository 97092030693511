import apiRest from '../helpers/apiRest';

const communitiesApi = '/api/admin/user/communities';

const getCommunities = async () => {
  const response = await apiRest().get(`${communitiesApi}`);
  const { data } = response;

  return data;
};

export { getCommunities };
