import SuccessButton from '../../components/generic/SuccessButton';
import TagList from '../../components/generic/TagList';
import { GET_SECURITY_DICTIONARY } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { isDev } from '../../helpers/Tools';
import getDefaultStyles from '../getDefaultStyles';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityFocusStep = (props) => {
  const { lastStep, nextStep, data } = props;
  const { t } = useTranslation(['words', 'register']);
  const [selectedFocus, setSelectedFocus] = useState(
    data.selectedFocus || (isDev() && [{ key: 'Actress', value: 'Actrice' }]) || []
  );
  const [focusItems, setFocusItems] = useState(data.focusItems || []);

  const getDictionary = () => {
    const headers = {
      'X-Device': 'desktop',
    };
    apiRest()
      .get(`${GET_SECURITY_DICTIONARY}/skill`, { headers })
      .then((response) => {
        const focusList = response.data.content;
        setFocusItems(focusList);
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'register:errorLoadingDictionary';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
      });
  };

  const handleDelete = (tags) => {
    setSelectedFocus(tags);
  };

  const handleChange = (value) => {
    if (value) {
      if (!focusItems.find((item) => item.key === value.key)) {
        const newEntry = { value, key: value, category: null };
        setFocusItems([...focusItems, newEntry]);
        setSelectedFocus([...selectedFocus, newEntry]);
      } else if (selectedFocus.find((item) => item.key === value.key) === undefined) {
        setSelectedFocus((prevState) => [...prevState, value]);
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
      },
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getDictionary();
    }
    return () => {
      mounted = false;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.question}>{t('register:focusTitle')}</div>
      <div className={classes.content}>
        <FormControl className={classes.formControl}>
          {!focusItems && <CircularProgress />}
          {focusItems && (
            <>
              <Autocomplete
                freeSolo
                className={classes.selectEmpty}
                filterSelectedOptions
                id="select-activity-sector"
                options={focusItems.map((item) => ({
                  key: item.key,
                  value: item.value,
                }))}
                onChange={(event, newValue) => handleChange(newValue)}
                getOptionLabel={(option) => option.value || ''}
                getOptionSelected={(option, value) => value.value === option.value}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField {...params} label={t('register:selectFocus')} variant="outlined" />
                )}
              />
            </>
          )}
        </FormControl>
      </div>
      <div className={classes.selectEmpty}>
        <TagList items={selectedFocus} deletable handleUpdate={handleDelete} negative />
      </div>
      <div className={classes.buttons}>
        {!lastStep && (
          <SuccessButton
            size="large"
            onClick={() => nextStep({ selectedFocus, focusItems })}
            disabled={selectedFocus.length === 0}
            label={t('words:next')}
          />
        )}
      </div>
    </>
  );
};

CommunityFocusStep.defaultProps = {
  data: {
    activitySectorItems: [],
  },
};

CommunityFocusStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityFocusStep;
