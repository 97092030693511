import {
  actualityEn,
  actualityFr,
  apiEn,
  apiFr,
  connectEn,
  connectFr,
  errorEn,
  errorFr,
  eventEn,
  eventFr,
  homeEn,
  homeFr,
  layoutEn,
  layoutFr,
  membersEn,
  membersFr,
  messageEn,
  messageFr,
  registerEn,
  registerFr,
  wordsEn,
  wordsFr,
} from './locales';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        api: apiEn,
        connect: connectEn,
        event: eventEn,
        register: registerEn,
        words: wordsEn,
        members: membersEn,
        layout: layoutEn,
        home: homeEn,
        actuality: actualityEn,
        error: errorEn,
        message: messageEn,
      },
      fr: {
        api: apiFr,
        connect: connectFr,
        event: eventFr,
        register: registerFr,
        words: wordsFr,
        members: membersFr,
        layout: layoutFr,
        home: homeFr,
        actuality: actualityFr,
        error: errorFr,
        message: messageFr,
      },
    },
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
