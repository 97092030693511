import AppTooltip from '../../helpers/AppToolTip';
import ActionButton from './ActionButton';
import CancelButton from './CancelButton';
import DangerButton from './DangerButton';
import * as PropTypes from 'prop-types';
import React from 'react';
import theme from '../../pages/theme';
import { Button } from '@material-ui/core';
import Loader from './Loader';

const ToolBarItemButton = (props) => {
  const { label, disabled, action, type, loading, help, style, acceptedFiles, multiple } = props;

  function GetButton() {
    switch (type) {
      case 'danger':
        return <DangerButton label={label} onClick={action} disabled={disabled} />;
      case 'cancel':
        return <CancelButton label={label} onClick={action} disabled={disabled} />;
      case 'file':
        return (
          <Button
            style={{ marginRight: theme.spacing(1), fontWeight: 400 }}
            disabled={disabled}
            color="primary"
            variant='contained'
            component='label'
            size='small'
          >
            {label}
            <input
              type="file"
              onChange={action}
              disabled={disabled}
              accept={acceptedFiles}
              multiple={multiple}
              style={{ display: "none" }}
            />
          </Button>
        );
      case 'action':
      default:
        return <ActionButton waiting={loading} label={label} onClick={action} disabled={disabled} />;
    }
  }

  return (
    <div style={style}>
    {help ?
      <AppTooltip title={help} arrow>
        <span>
          <GetButton />
        </span>
      </AppTooltip>
     :<GetButton />
    }
    </div>
  )
};

ToolBarItemButton.defaultProps = {
  disabled: false,
  type: 'action',
  loading: false,
  help: null,
  style: {},
  handleFile: null,
  multiple: false,
};

ToolBarItemButton.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['action', 'cancel', 'danger', 'file']),
  loading: PropTypes.bool,
  handleFile: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
};

export default ToolBarItemButton;
