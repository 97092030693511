const homeEn = {
  averagelinkforce: 'average Linkforce',
  inviteMember: 'invite a member',
  last30Days: 'last 30 days',
  lastInvitedMembers: 'last invited members',
  lastEmbeddedMembers: 'last embedded members',
  lastNewsOn: 'last news on',
  numberOfInvitedMembers: 'Number of invited members',
  membersOnHold: 'members on hold',
  monthlyConnections: 'monthly connections',
  objectivesOfTheMeeting: 'objectives of meetings',
  totalConnection: 'Connexions',
  totalMembers: 'total members',
  totalMessage: 'Messages',
  totalUser: 'Members',
  welcomeMessage: 'welcome',
  validationCode: 'Community validation code :',
};

export default homeEn;
