import { ActualityCompletedInformation, ActualityState } from '../_types/actualityTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: ActualityState = {
  actualities: [],
  total: 0,

  actualitiesMaxPerPage: 10,
  actualitiesPage: 1,
};

const reducers = {
  setActualities: (state: any, action: PayloadAction<ActualityCompletedInformation[]>): void => {
    set(state, 'actualities', action.payload);
  },
  setTotal: (state: any, action: PayloadAction<number>): void => {
    set(state, 'total', action.payload);
  },

  setActualitiesMaxPerPage: (state: any, action: PayloadAction<number>): void => {
    set(state, 'actualitiesMaxPerPage', action.payload);
  },
  setActualitiesPage: (state: any, action: PayloadAction<number>): void => {
    set(state, 'actualitiesPage', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'actuality',
  initialState,
  reducers,
});
