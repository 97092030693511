const homeFr = {
  // A
  averagelinkforce: 'Linkforce moyen',
  // C
  communityName: 'Nom de la communauté',
  CommunityPresentation: 'Présentation de la communauté',
  copy: 'Copier',
  copied: 'Copié',
  // G
  groupPresentation: 'Présentation de la sous-communauté',
  // I
  inviteMember: 'inviter un membre',
  // L
  last30Days: '30 derniers jours',
  lastEmbeddedMembers: 'derniers membres intégrés',
  lastEmbeddedParticipants: 'derniers participants intégrés',
  lastInvitedMembers: 'derniers membres invités',
  lastInvitedParticipants: 'derniers participants invités',
  lastNewsOn: 'Les dernières informations de',
  // M
  membersOnHold: 'membres en attente',
  monthlyConnections: 'connexions mensuelles',
  mostActiveUser: 'Membre le plus actif',
  // N
  numberOfInvitedMembers: 'Nombre de nouveaux membres',
  numberOfInvitedParticipants: 'nombre de nouveaux participants',
  // O
  objectivesOfTheMeeting: 'objectifs de rencontres',
  // P
  participantsOnHold: 'participants en attente',
  phone: 'Téléphone',
  // T
  totalConnection: 'Connexions',
  totalMembers: 'total des membres',
  totalMessage: 'Messages',
  totalParticipants: 'total des participants',
  totalUser: 'Membres',
  // U
  urlWebSite: 'URL du site web',
  urlFacebook: 'URL Facebook',
  urlInstagram: 'URL Instagram',
  urlX: 'URL X',
  urlTikTok: 'URL TikTok',
  urlSnapChat: 'URL SnapChat',
  urlLinkedin: 'URL Linkedin',
  // V
  validationCode: "Code d'accès de la communauté :",
  // W
  welcomeMessage: 'bonjour',
};

export default homeFr;
