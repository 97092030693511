import { Session } from './_hook/useSession';
import Layout from './components/layout/index';
import theme from './pages/theme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

moment.locale('fr');
const App = () => (
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Session>
          <Router>
            <Layout />
          </Router>
        </Session>
      </LocalizationProvider>
    </ThemeProvider>
  </>
);

export default App;
