import { Community } from '../_types/communityTypes';
import { Group } from '../_types/groupTypes';
import { LoginParams } from '../_types/sessionTypes';
import apiRest, { AxiosCancelToken } from '../helpers/apiRest';

// Actions to log in
const clCommunities = '/security/get_cl_communities';
const loginApi = '/security/login';
// Dictionaries
const dictionariesApi = '/security/dictionaries';
const dictionaryApi = '/security/dictionary';
const searchLocalisationApi = '/security/search_localisation';
// Actions on community/group
const createCommunityApi = '/api/free/community/create';
const updateCommunityApi = '/api/admin/community/update';
// Get entity infos.
// Infos community/group
const lastGuestedUsersApi = '/api/admin/home/community/lastguestedusers';
const lastOnboardedUsersApi = '/api/admin/home/community/lastonboardedusers';
const objectivesApi = '/api/admin/home/community/objectives';
const linkforceApi = '/api/admin/home/community/linkforce';
// Infos event
const eventLastGuestedUsersApi = '/api/admin/home/event/lastguestedusers';
const eventLastOnboardedUsersApi = '/api/admin/home/event/lastonboardedusers';
const eventObjectivesApi = '/api/admin/home/event/objectives';
const eventLinkforceApi = '/api/admin/home/event/linkforce';

// Actions to log in
const login = async (params: LoginParams) => {
  const response = await apiRest().post(loginApi, params);
  const { data } = response;

  return data;
};

const getClCommunities = async (d: { key: string; email: string }) => {
  const response = await apiRest(true).post(clCommunities, d);
  const { data } = response;

  return data;
};

// Dictionaries
const getDictionaries = async () => {
  const response = await apiRest(true).get(`${dictionariesApi}`);
  const { data } = response;

  return data;
};

const getDictionary = async (dictionary: string) => {
  const response = await apiRest(true).get(`${dictionaryApi}/${dictionary}`);
  const { data } = response;

  return data;
};

const searchLocalisation = async (countryCode: string, search: string) => {
  const headers = {
    'X-Device': 'desktop',
  };
  const response = await apiRest(true).get(
    `${searchLocalisationApi}/${countryCode}/${search}`,
    { headers },
    // @ts-ignore
    {
      cancelToken: new AxiosCancelToken((c) => {
        // @ts-ignore
        window.cancel = c;
      }),
    }
  );
  const { data } = response;

  return data;
};

// Actions on community/group
const createCommunityOrGroup = async (d: Community | Group) => {
  const response = await apiRest().post(createCommunityApi, d);
  const { data } = response;

  return data;
};

const updateCommunityOrGroup = async (d: Community | Group | null) => {
  const response = await apiRest().post(updateCommunityApi, d);
  const { data } = response;

  return data;
};

// Get entity infos
const getLastGuestedUsers = async (nuid: string, isEvent = false) => {
  const response = await apiRest().get(
    `${isEvent ? eventLastGuestedUsersApi : lastGuestedUsersApi}/${nuid}`
  );
  const { data } = response;

  return data;
};

const getLastOnboardedUsers = async (nuid: string, isEvent = false) => {
  const response = await apiRest().get(
    `${isEvent ? eventLastOnboardedUsersApi : lastOnboardedUsersApi}/${nuid}`
  );
  const { data } = response;

  return data;
};

const getObjectives = async (nuid: string, isEvent = false) => {
  const response = await apiRest().get(`${isEvent ? eventObjectivesApi : objectivesApi}/${nuid}`);
  const { data } = response;

  return data;
};

const getLinkforce = async (nuid: string, isEvent = false) => {
  const response = await apiRest().get(`${isEvent ? eventLinkforceApi : linkforceApi}/${nuid}`);
  const { data } = response;

  return data;
};

export {
  // Actions to log in
  login,
  getClCommunities,
  // Dictionaries
  getDictionaries,
  getDictionary,
  searchLocalisation,
  // Actions on community/group
  createCommunityOrGroup,
  updateCommunityOrGroup,
  // Get entity infos.
  getLastGuestedUsers,
  getLastOnboardedUsers,
  getObjectives,
  getLinkforce,
};
