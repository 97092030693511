import SearchField from '../form/SearchField';
import { useTheme } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

const ToolBarSearchField = (props) => {
  const { color, label, value, onSearch } = props;
  const theme = useTheme();

  return (
    <div style={{ marginRight: theme.spacing(2) }}>
      <SearchField onSearch={onSearch} value={value} color={color} label={label} />
    </div>
  );
};

ToolBarSearchField.defaultProps = {
  value: '',
  color: 'secondary',
  label: null,
};

ToolBarSearchField.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default ToolBarSearchField;
