import { convertCSVToArray } from 'convert-csv-to-array';

function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function dayDiff(d1, d2) {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay));
}

function weekDiff(d1, d2) {
  return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}

function removeUndefinedValue(object) {
  for (let i = 0; i < object.length; i += 1) {
    if (object[i].value === undefined || object[i].value === 'undefined') {
      object.splice(i, 1);
      i -= 1;
    }
  }
  return object;
}

class SortSelectOptions {
  static sortFunction(param) {
    const compare = (a, b) => {
      if (a.label.localeCompare(b.label) < b.label.localeCompare(a.label)) {
        return -1;
      }
      if (a.label.localeCompare(b.label) > b.label.localeCompare(a.label)) {
        return 1;
      }
      return 0;
    };
    return param.sort(compare);
  }
}

/**
 * @param user
 * @returns {boolean}
 */
function isAuthorizedMember(user) {
  const authorizedRoles = ['ROLE_SUPER_ADMIN'];
  const { roles } = user;
  const result = authorizedRoles.filter((value) => roles.indexOf(value) > -1);
  return result.length > 0;
}

/**
 * @param user
 * @returns {boolean}
 */
function isAuthorizedAdmin(user) {
  const authorizedRoles = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'];
  const { roles } = user;
  const result = authorizedRoles.filter((value) => roles.indexOf(value) > -1);
  return result.length > 0;
}

function csv2json(csv) {
  if (csv) {
    const lines = csv.split('\n');
    const result = [];
    const headers = lines[0].split(',');
    for (let i = 1; i < lines.length; i += 1) {
      const obj = {};
      const currentLine = lines[i].split(',');
      if (headers.length === currentLine.length) {
        for (let j = 0; j < headers.length; j += 1) {
          obj[headers[j].trim()] = currentLine[j].trim();
        }
        result.push(obj);
      }
    }
    return JSON.stringify(result);
  }
  return null;
}

function CSVToArray(source, columns) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const data = `${columns}\n${reader.result}\n`;
      const converted = convertCSVToArray(data, {
        type: 'array',
        separator: ',',
        header: false,
      });
      resolve(converted);
    };
    reader.onerror = reject;
    reader.readAsText(source);
  });
}

function removeDuplicates(data, field) {
  const seen = [];
  let value;
  return data.filter((item) => {
    if (typeof field === 'number') {
      value = item[field] ?? null;
    } else {
      value = item?.[field] ?? null;
    }
    if (seen.find((elt) => elt === value)) {
      return false;
    }
    seen.push(value);
    return true;
  });
}

function encodeImageFileAsURL(source) {
  if (!source) {
    return;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(source);
  });
}

function isUrlValid(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // Protocole
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // Domaine
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // Adresse IP
    '(\\:\\d+)?'+ // Port facultatif
    '(\\/[-a-z\\d%_.~+]*)*'+ // Segment d'URI
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // Chaîne de requête
    '(\\#[-a-z\\d_]*)?$','i'); // Ancre
  return !!pattern.test(str);
}

function isBase64(str) {
  const pattern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return pattern.test(str);
}

const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const emailTest = (value) =>
  value && value.length && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
const passwordTest = (value) => value && value.match(/^(?=.*[A-Za-z])(?=.*\d).{8,32}$/) !== null;


function mimeTypesToExtensions(mimeTypes) {
  const specialCases = {
    'image/jpeg': '.jpg',
    'image/svg+xml': '.svg',
    'text/plain': '.txt',
    'video/quicktime': '.mov',
  };

  return mimeTypes.map((mimeType) => {
    if (specialCases[mimeType]) {
      return specialCases[mimeType];
    }

    const [, subtype] = mimeType.split('/');
    return `.${subtype}`;
  });
}

function addThumbSuffixToImageUrl(imageUrl) {
  if (!imageUrl) {
    return null;
  }
  const dotPosition = imageUrl.lastIndexOf('.');

  if (dotPosition === -1) {
    return imageUrl + '_thumb';
  }

  const leftPart = imageUrl.slice(0, dotPosition);
  const rightPart = imageUrl.slice(dotPosition);

  return leftPart + '_thumb' + rightPart;
}

function getUrls(url) {
  if (!url) {
    return [null, null];
  }
  if (url.startsWith('blob')) {
    return [null, url];
  }

  const thumbString = '_thumb';
  const extensionSeparator = '.';
  const pathSeparator = '/';

  const lastPathSeparatorIndex = url.lastIndexOf(pathSeparator);
  const lastExtensionSeparatorIndex = url.lastIndexOf(extensionSeparator);

  if (lastPathSeparatorIndex < 0 || lastExtensionSeparatorIndex < 0 || lastPathSeparatorIndex > lastExtensionSeparatorIndex) {
    throw new Error("L'URL de l'image n'est pas valide.");
  }

  const basename = url.slice(lastPathSeparatorIndex + 1, lastExtensionSeparatorIndex);
  const extension = url.slice(lastExtensionSeparatorIndex);
  const directory = url.slice(0, lastPathSeparatorIndex + 1);

  let originalUrl, thumbUrl;

  if (basename.endsWith(thumbString)) {
    originalUrl = directory + basename.slice(0, -thumbString.length) + extension;
    thumbUrl = url;
  } else {
    originalUrl = url;
    thumbUrl = directory + basename + thumbString + extension;
  }

  return [thumbUrl, originalUrl];
}

function formatDate(dateString, language) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  // Détection de la langue du navigateur
  const locale = language ?? navigator.language;

  // Formatage de la date selon la locale
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export {
  monthDiff,
  dayDiff,
  weekDiff,
  SortSelectOptions,
  removeUndefinedValue,
  isAuthorizedAdmin,
  isAuthorizedMember,
  csv2json,
  emailTest,
  encodeImageFileAsURL,
  CSVToArray,
  passwordTest,
  isDev,
  removeDuplicates,
  isUrlValid,
  isBase64,
  mimeTypesToExtensions,
  addThumbSuffixToImageUrl,
  getUrls,
  formatDate
};
