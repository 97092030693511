import SelectField from '../../components/form/SelectField';
import SuccessButton from '../../components/generic/SuccessButton';
import { GET_SECURITY_DICTIONARY, POST_COMMUNITY_CREATE } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { isDev } from '../../helpers/Tools';
import WebStorageManagement from '../../helpers/WebStorageManager';
import getDefaultStyles from '../getDefaultStyles';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityMembersStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep, data, allData } = props;
  const { t } = useTranslation(['words', 'register']);
  const token = WebStorageManagement.getValue('token');
  const [members, setMembers] = useState(data.members || (isDev() && 'default-logo.png-1-99'));
  const [waiting, setWaiting] = useState(false);
  const [membersItems, setMembersItems] = useState(data.membersItems || []);

  const getDictionary = () => {
    const headers = {
      'X-Device': 'desktop',
    };
    apiRest().defaults.headers.common['Auth-Token'] = token;
    apiRest()
      .get(`${GET_SECURITY_DICTIONARY}/communitySize`, { headers })
      .then((response) => {
        const communitySize = response.data.content;
        setMembersItems(communitySize);
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'register:errorLoadingDictionary';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
      });
  };

  const handleChange = (newValue) => {
    setMembers(newValue.key);
  };

  const saveCommunity = () => {
    setWaiting(true);
    const { country, postalCode, town, localisation } = allData[6];
    const community = {
      name: allData[3].name,
      category: allData[4].category,
      published: false,
      public: true,
      type: 'community',
      size: members,
      localisation: {
        countryCode: country,
        postalCode,
        town,
        longitude: localisation ? localisation.longitude : null,
        latitude: localisation ? localisation.latitude : null,
      },
      activitySector: allData[5].activity.key,
    };
    apiRest()
      .post(POST_COMMUNITY_CREATE, community)
      .then((response) => {
        const newCommunity = response.data.content;
        nextStep({ members, membersItems, communityNuid: newCommunity.nuid });
      })
      .catch((error = null) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          default:
            key = 'unknownError';
        }
        toast.error(t(key));
        setWaiting(false);
      });
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getDictionary();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.question}>{t('register:selectMembers')}</div>
      <div className={classes.content}>
        <SelectField
          style={{ marginTop: 10 }}
          value={members || ''}
          label={t('words:members')}
          id="category"
          onSelect={handleChange}
          data={membersItems}
        />
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => backStep({ members, membersItems })}
          >
            {t('words:previous')}
          </Button>
        )}
        {!lastStep &&
          (waiting ? (
            <CircularProgress />
          ) : (
            <SuccessButton
              size="large"
              onClick={saveCommunity}
              disabled={!members}
              label={t('words:next')}
            />
          ))}
      </div>
    </>
  );
};

CommunityMembersStep.defaultProps = {
  data: {},
};

CommunityMembersStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  allData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CommunityMembersStep;
