import defaultAvatar from '../../../assets/images/default_avatar.png';
import './index.scss';
import React from 'react';

interface MemberProfileProps {
  firstName: string | null;
  lastName: string | null;
  img: string | null;
}

const CLASSNAME = 'memberProfile';

const MemberProfile: React.FC<MemberProfileProps> = ({ firstName, lastName, img }) => (
  <div className={CLASSNAME}>
    <img className={`${CLASSNAME}_image`} src={img || defaultAvatar} alt={`${firstName} avatar`} />
    <div className={`${CLASSNAME}_information`}>
      <span className="font-paragraph-semiBold">
        {firstName} {lastName}
      </span>
    </div>
  </div>
);

MemberProfile.displayName = 'MemberProfile';
export default MemberProfile;
