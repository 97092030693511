import { CommunitiesInformation, CommunityState } from '../_types/communityTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: CommunityState = {
  communitiesInformation: {
    total: 0,
    communities: [],
  },
};

const reducers = {
  setCommunitiesInformation: (state: any, action: PayloadAction<CommunitiesInformation>): void => {
    set(state, 'communitiesInformation', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'communities',
  initialState,
  reducers,
});
