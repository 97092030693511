import ActionButton from '../../components/generic/ActionButton';
import CancelButton from '../../components/generic/CancelButton';
import getDefaultStyles from '../getDefaultStyles';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep } = props;
  const { t } = useTranslation(['register', 'words']);

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    warning: {
      paddingTop: 20,
      fontStyle: 'italic',
      textAlign: 'center',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
      },
      justifyContent: firstStep && lastStep ? 'space-between' : 'center',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        <div>{t('register:welcomeText')}</div>
      </div>
      <div className={classes.warning}>{t('register:welcomeWarning')}</div>
      <div className={classes.buttons}>
        {!firstStep && <CancelButton size="large" onClick={backStep} label={t('previous')} />}
        {!lastStep && <ActionButton size="large" onClick={nextStep} label={t('words:start')} />}
      </div>
    </>
  );
};

WelcomeStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default WelcomeStep;
