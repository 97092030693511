import isEmpty from 'lodash/isEmpty';

const getNewObject = (newObj, oldObj) => {
  if (isEmpty(oldObj)) return newObj;
  let diff = {};
  for (const key in oldObj) {
    if (oldObj[key] !== newObj[key]) {
      diff[key] = newObj[key];
    }
  }

  if (Object.keys(diff).length > 0) return diff;

  return oldObj;
};

export default getNewObject;
