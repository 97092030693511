const eventFr = {
  // A
  accessType: "type d'accés",
  // B
  banner: 'bannière d’illustration',
  // C
  category: "catégorie de l'événement",
  cancelEvent: "annuler l'événement",
  confirmBack: 'êtes-vous sur de vouloir annuler vos modifications?',
  confirmCancel: "êtes-vous sur de vouloir annuler l'événement?",
  confirmRemove: "êtes-vous sur de vouloir supprimer l'événement?",
  createEvent: "création de l'événement",
  // D
  dateEndError: 'entrez une date de fin ultérieure à la date de début.',
  dateStartError: 'entrez une date de début antérieure à la date de fin.',
  description: 'description',
  // E
  editEvent: "édition de l'événement",
  endDate: 'date et heure de fin',
  event: 'événement',
  // H
  helperTitle: 'promouvoir l’événement (uniquement sur un événement public)',
  helpForPromote_part1: 'Nous alertons vos membres grâce à :',
  helpForPromote_part2: '- une push notification sur smartphone',
  helpForPromote_part3: '- une publication automatique dans le fil d’actualités de la communauté ou de la sous-communauté.',
  helpForPromote_part4: '- un emailing automatique de promotion est diffusé aux membres de la communauté ou de la sous-communauté.',
  helpForVariablePrice: 'en attente de texte',
  // M
  maxAttendees: 'nombre d’entrées disponibles',
  maxAttendeesError:
    'le nombre d’entrées disponibles ne peux pas être inférieur au nombre de participants',
  // N
  noEventYet: "pas encore d'évènements...",
  // P
  price: 'prix',
  promote: "promouvoir l'événement ?",
  // R
  requiredFields: "* Champs obligatoires pour publier l'événement",
  // S
  startDate: 'date et heure de début',
  status: 'statut',
  // T
  title: "nom de l'événement",
  totalMembers: 'nombre de participants inscrits',
  type: "type de l'événement",
  // U
  urlError: "le lien doit être au format 'https://www.mywebsite.com'",
  urlTicketing: 'lien billetterie de l’événement (eventbrite, weezevent, helloasso, yurplan...)',
  urlVisio: 'lien visio de l’événement (livestorm, zoom, teams, meet...)',
  urlEvent: 'lien du site de l’événement',
  // V
  variablePrice: 'ajouter “à partir de”',
  // W
  whereIsEvent: "où se passe l'événement ?",
  // Status
  draft: 'brouillon',
  open: 'ouvert',
  full: 'complet',
  ended: 'terminé',
  cancelled: 'annulé',
};

export default eventFr;
