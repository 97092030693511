import { GridColDef } from '@mui/x-data-grid';

const loadDataInTable = (
  data: any[],
  dataColumns: string[],
  setColumns: (value: any) => void,
  setRows: (value: any) => void,
  conditions: (columnName: string, columns: GridColDef[]) => void = () => {},
  traduction: string
) => {
  setColumns([]);
  setRows([]);
  const keysList: string[] = [];
  const clmns: GridColDef[] = [];
  if (data) {
    if (data.length > 0) {
      data?.forEach((d) => {
        Object.keys(d)?.forEach((key) => {
          if (keysList.indexOf(key) === -1) {
            keysList.push(key);
          }
        });
      });
      dataColumns?.forEach((columnName) => {
        if (keysList?.find((key) => key === columnName)) {
          if (conditions) {
            conditions(columnName, clmns);
          }
          if (!clmns?.find((c) => c?.field === columnName)) {
            clmns.push({
              editable: true,
              field: columnName,
              headerName: traduction + '_' + columnName,
            });
          }
        }
      });
      setColumns(clmns);
      const rws: any = [];
      Object.entries(data).forEach(([key, value]) => {
        rws[key] = value;
      });
      rws.forEach((row: any, index: number) => {
        rws[index] = { id: index, ...row };
      });
      setRows(rws);
    }
  }
};

export default loadDataInTable;
