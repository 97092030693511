import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'memberHelpZone';

interface MemberHelpZoneProps {
  isImport?: boolean;
  isInvite?: boolean;
}

const MemberHelpZone: React.FC<MemberHelpZoneProps> = ({ isImport, isInvite }) => {
  const { t } = useTranslation(['members']);
  return (
    <div className={CLASSNAME}>
      {isImport && (
        <>
          <p>{t('members:importIntroHelp1')}</p>
          <p>{t('members:importIntroHelp2')}</p>
          <p>
            <span>{t('members:formatHelp1')}</span>
            <span>{t('members:formatHelp2')}</span>
          </p>
          <p>{t('members:importContactHelp3')}</p>
        </>
      )}
      {isInvite && (
        <>
          <p>
            <span>{t('members:formatHelp1')}</span>
            <span>{t('members:formatHelp2')}</span>
          </p>
          <p>{t('members:importInvitationHelp3')}</p>
        </>
      )}
    </div>
  );
};

MemberHelpZone.displayName = 'MemberHelpZone';

export default MemberHelpZone;
