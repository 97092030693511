export const HOME_PAGE_CARDS_COLORS = {
  totalMembersColors: '#F29C49',
  membersOnHoldColors: '#565656',
  monthlyConnectionsColors: '#37DAC6',
  averagelinkforceColors: '#F29C49',
};

export const PRIMARY_COLOR = '#f29c49';
export const CHECKED_COLOR = '#6AA84F'

