const displayError = (error: any, setError: (message: string | null, option?: any) => void) => {
  if (error?.response?.data) {
    const { fail } = error.response.data;
    setError(`${fail}`);
  } else {
    setError('connect:unknownError');
  }
};

export default displayError;
