import { getCommunities as getCommunitiesApi } from '../_api/communityApi';
import {
  actions as communitiesActions,
  initialState as communityInitialState,
  reducer as communityReducer,
} from '../_slice/communitySlice';
import { CommunitiesInformation } from '../_types/communityTypes';
import displayError from '../helpers/displayError';
import { sessionContext } from './useSession';
import React from 'react';

const useCommunity = () => {
  const [communityState, dispatch] = React.useReducer(communityReducer, communityInitialState);

  // useSession
  const { setError } = React.useContext(sessionContext);

  // Set states
  const setCommunitiesInformation = (communitiesInformation: CommunitiesInformation | null) => {
    dispatch(
      communitiesActions.setCommunitiesInformation({
        total: communitiesInformation?.total || 0,
        communities: communitiesInformation?.communities || null,
      })
    );
  };

  // get action
  const getCommunities = async (isReturnData = false) => {
    try {
      const data = await getCommunitiesApi();
      setCommunitiesInformation({
        total: data?.content?.length,
        communities: data?.content,
      });
      if (isReturnData) {
        return data.content;
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  return {
    communityState,
    // Set states
    setCommunitiesInformation,
    // get action
    getCommunities,
  };
};

export default useCommunity;
