import { MemberProfileListType } from '../../../../_types/memberTypes';
import EmptyState from '../../../EmptyState';
import MemberProfile from '../index';
import './index.scss';
import capitalize from 'lodash/capitalize';
import React, { ReactNode } from 'react';

interface MemberProfileListProps {
  action?: () => void;
  label: string;
  members: MemberProfileListType[] | undefined;
  limit?: number;
  child?: ReactNode;
  isEvent?: boolean;
}

const CLASSNAME = 'profileList';

const MemberProfileList: React.FC<MemberProfileListProps> = ({
  action,
  label,
  members,
  limit = 10,
  child,
  isEvent = false,
}) => (
  <div className={CLASSNAME}>
    <button className="font-paragraph-bold" onClick={action}>
      {capitalize(label)}
    </button>
    {members && members.length > 0 ? (
      members
        .slice(0, limit)
        .map(({ firstName, lastName, img, nuid }) => (
          <MemberProfile key={nuid} firstName={firstName} lastName={lastName} img={img} />
        ))
    ) : (
      <EmptyState isHome isEvent={isEvent} />
    )}
    {child}
  </div>
);

MemberProfileList.displayName = 'MemberProfileList';
export default MemberProfileList;
