import { Button, withStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

const SuccessButton = (props) => {
  const { label, onClick, disabled, size, outlined } = props;

  const ColorButton = withStyles((theme) =>
    !disabled
      ? {
          root: {
            color: outlined ? theme.palette.success.main : 'white',
            backgroundColor: outlined ? 'white' : theme.palette.success.main,
            borderColor: theme.palette.success.main,
            '&:hover': {
              color: 'white',
              backgroundColor: outlined ? theme.palette.success.light : theme.palette.success.dark,
              borderColor: theme.palette.success.main,
            },
          },
        }
      : {
          root: {
            color: outlined ? theme.palette.disabled : 'white',
            backgroundColor: outlined ? 'white' : theme.palette.disabled,
            borderColor: theme.palette.disabled,
          },
        }
  )(Button);

  return (
    <ColorButton
      variant={outlined ? 'outlined' : 'contained'}
      disabled={disabled}
      onClick={onClick}
      size={size}
    >
      {label}
    </ColorButton>
  );
};

SuccessButton.defaultProps = {
  disabled: false,
  size: 'small',
  outlined: false,
};

SuccessButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func.isRequired,
  outlined: PropTypes.bool,
};

export default SuccessButton;
