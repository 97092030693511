import React from "react";
import { deleteGroup as deleteGroupAPI, getGroups as getGroupsAPI } from "../_api/groupApi";
import {
  actions as groupsActions,
  initialState as groupInitialState,
  reducer as groupReducer
} from "../_slice/groupSlice";
import { GetGroupParams, GroupsParams } from "../_types/groupTypes";
import displayError from "../helpers/displayError";
import { sessionContext } from "./useSession";

const useGroup = () => {
  const [groupState, dispatch] = React.useReducer(groupReducer, groupInitialState);

  // useSession
  const { setError } = React.useContext(sessionContext);

  // Params
  const setGroupsParams = (newParams: GroupsParams) => {
    dispatch(groupsActions.setGroupsParams(newParams));
  };

  const deleteGroup = async (groupNuid: string) => {
    return await deleteGroupAPI(groupNuid);
  }

  // Get action
  const getGroups = async (
    params: GetGroupParams,
    isChangingTotal = false,
    returnValue = false
  ) => {
    try {
      setGroupsParams({
        page: params.page,
        maxPerPage: params.maxPerPage,
        search: params.searchString || "",
        desc: params.desc || false,
        orderBy: params.orderBy || null,
        isAdmin: params.isAdmin || false
      });
      const data = await getGroupsAPI(params);
      if (isChangingTotal) {
        dispatch(
          groupsActions.setGroupsInformation({
            groups: data?.content?.groups,
            total: data?.content?.total
          })
        );
      } else {
        dispatch(
          groupsActions.setGroupsInformation({
            ...groupState.groupsInformation,
            groups: data?.content?.groups
          })
        );
      }
      if (returnValue) {
        return data;
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  return {
    groupState,
    // Params
    setGroupsParams,
    // Get action
    getGroups,
    deleteGroup
  };
};

export default useGroup;
