const errorFr = {
  // A
  actualityMessageTooShort: 'Le message doit faire au minimum 20 caractères',
  // B
  badCredentials: 'Identifiant inconnu ou mauvais mot de passe',
  badCsvError: "Le fichier n'est pas au bon format",
  //
  connexionError: 'Une erreur à eu lieu, merci de vérifier votre connexion.',
  // E
  emailBadFormat: "L'e-mail n'est pas valide",
  // F
  fileSizeError: 'Image trop lourde',
  fileTypeError: 'Extension non supportée',
  // G
  genericError: 'Une erreur à eu lieu. Merci de réessayer plus tard',
  // N
  noBlank: 'Cette valeur ne peux pas être vide',
  // U
  unknownError: 'Une erreur à eu lieu. Merci de réessayer plus tard.',
  urlBadFormat: "L'URL n'est pas valide",
  // Y
  youCannotChangeYourRole: 'Vous ne pouvez pas changer votre rôle',
  youCannotRemoveYourself: 'Vous ne pouvez pas vous supprimer vous-même',
  youCannotBlockYourself: 'Vous ne pouvez pas vous bloquer vous-même',
};

export default errorFr;
