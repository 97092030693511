import React from 'react';

interface SuperConnectrIconProps {
  leftFill: string;
  rightFill: string;
}

export const SuperConnectrIcon: React.FC<SuperConnectrIconProps> = ({ leftFill, rightFill }) => (
  <svg
    width="37"
    height="21"
    viewBox="0 0 37 21"
    fill={leftFill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8125 8.86719C23.7734 8.62109 23.6836 8.39844 23.543 8.19531C23.4023 7.99219 23.2265 7.83203 23.0078 7.71094C22.793 7.59375 22.5625 7.52734 22.3164 7.51172C22.0664 7.5 21.832 7.54297 21.6015 7.64062C21.2812 7.76562 20.9922 7.94922 20.7422 8.1875C20.0039 8.92188 19.5664 9.80469 19.4297 10.832C19.375 11.5547 19.1797 12.2383 18.8476 12.8867C18.5117 13.5312 18.0664 14.0859 17.5078 14.5508C16.4531 15.3984 15.25 15.8125 13.9023 15.7969L10.457 15.8164C9.05467 15.8398 7.8203 15.3945 6.75389 14.4883C6.1367 13.9492 5.66795 13.3047 5.34764 12.5469C5.03123 11.7891 4.89452 11 4.93748 10.1797C4.96483 9.83594 5.02342 9.49219 5.10936 9.16016C5.1992 8.82422 5.3203 8.5 5.47264 8.1875C5.62108 7.87109 5.80077 7.57812 6.0078 7.29688C6.21483 7.01953 6.4453 6.76172 6.70311 6.52344C6.95702 6.28906 7.23045 6.07813 7.52733 5.89453C7.8203 5.71094 8.1328 5.55469 8.45702 5.42969C8.77733 5.30469 9.11327 5.21094 9.45311 5.14844C9.79295 5.08594 10.1367 5.05469 10.4844 5.05859L11.7617 5.05469C12.0351 5.05078 12.2383 4.92969 12.3672 4.6875C12.957 3.59375 13.6953 2.61328 14.5859 1.74609C14.7812 1.53906 14.8281 1.30078 14.7265 1.03906C14.6289 0.773439 14.4375 0.628906 14.1562 0.601562H10.6015C5.14061 0.617189 0.499984 4.97266 0.464828 10.4375C0.457015 11.8125 0.722641 13.1289 1.26952 14.3867C1.81639 15.6445 2.59373 16.7383 3.60155 17.6641C4.55077 18.5195 5.62108 19.1719 6.81639 19.6211C8.0117 20.0664 9.24998 20.2812 10.5273 20.2617L13.8437 20.25C15.0781 20.2578 16.2734 20.0508 17.4336 19.625C18.5937 19.1953 19.6406 18.5781 20.5781 17.7695C21.6367 16.8359 22.4609 15.7266 23.043 14.4375C23.6289 13.1523 23.918 11.8008 23.9219 10.3945C23.9258 9.88281 23.8867 9.37109 23.8125 8.86719Z"
      fill={leftFill}
    />
    <path
      d="M33.7188 3.4101C32.8008 2.48822 31.7422 1.78119 30.5391 1.289C29.336 0.792908 28.082 0.55072 26.7774 0.558533L22.9844 0.570251C21.7149 0.570251 20.4883 0.808533 19.3086 1.28119C18.1289 1.75775 17.0781 2.43744 16.1563 3.31635C16.1563 3.31635 16.125 3.3515 16.1133 3.36322C15.3867 4.06244 14.7813 4.85931 14.2969 5.74603L14.2188 5.82416C13.582 7.01556 13.211 8.28119 13.0977 9.62494C12.9844 10.9648 13.1445 12.2734 13.5742 13.5429C14.4649 13.5624 15.2774 13.3242 16.0195 12.8281C16.7149 12.2851 17.1133 11.5781 17.2149 10.707C17.375 9.10931 18.0235 7.74213 19.1602 6.60541C19.6641 6.1015 20.2461 5.71088 20.9063 5.43353C21.5664 5.16009 22.25 5.02338 22.9649 5.01947L26.7578 5.00775C27.1094 5.00775 27.461 5.039 27.8047 5.10931C28.1524 5.17572 28.4883 5.27728 28.8125 5.4101C29.1406 5.54681 29.4492 5.71088 29.7422 5.90619C30.0352 6.1015 30.3047 6.32025 30.5547 6.57025C30.8008 6.82025 31.0235 7.08978 31.2188 7.38275C31.4141 7.67572 31.5781 7.98431 31.7149 8.31244C31.8477 8.63666 31.9492 8.9726 32.0156 9.32025C32.082 9.664 32.1172 10.0156 32.1172 10.3671C32.1133 10.7187 32.0781 11.0703 32.0078 11.4179C31.9414 11.7617 31.836 12.0976 31.6992 12.4257C31.5625 12.7538 31.3985 13.0624 31.1992 13.3554C31.0039 13.6523 30.7813 13.9218 30.5313 14.1718C30.2774 14.4218 30.0078 14.6484 29.7149 14.8437C29.418 15.0429 29.1094 15.207 28.7813 15.3437C28.457 15.4804 28.1172 15.582 27.7735 15.6523C27.4258 15.7226 27.0781 15.7578 26.7227 15.7578L24.8867 15.7656C24.1797 17.1679 23.2383 18.3906 22.0586 19.4335C21.8125 19.6796 21.5274 19.8788 21.25 20.082C21.8008 20.1796 22.3555 20.2265 22.9141 20.2265L26.7188 20.207C27.6875 20.2031 28.6406 20.0585 29.5703 19.7734C30.5 19.4882 31.3711 19.0742 32.1797 18.5312C32.9883 17.9882 33.6992 17.3398 34.3203 16.5859C34.9375 15.832 35.4336 15.0078 35.8086 14.1093C36.1836 13.2109 36.418 12.2773 36.5195 11.3124C36.6172 10.3437 36.5742 9.38666 36.3867 8.43353C36.1992 7.48041 35.8828 6.57806 35.4258 5.7226C34.9727 4.86322 34.4024 4.09369 33.7188 3.4101Z"
      fill={rightFill}
    />
  </svg>
);
