import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { ListItemButton } from "@mui/material";
import { InputAdornment, List, ListItemText, TextField } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import { Search } from "@material-ui/icons";
import { GoogleMap, Marker } from "@react-google-maps/api";
import './index.scss';
import { ILocalisation } from "../../../_types/generalTypes";
import { useTranslation } from "react-i18next";
import { sessionContext } from "../../../_hook/useSession";
import isEmpty from "lodash/isEmpty";

const CLASSNAME = 'googleMaps';

interface GoogleMapsProps {
  searchTitle: string;
  localisation: ILocalisation;
  updateHandler: (params:ILocalisation | null) => void;
}

const GoogleMaps = (props: GoogleMapsProps) => {
  const { searchTitle, localisation, updateHandler } = props;
  const { t } = useTranslation(['event', 'layout', 'words']);
  const { sessionState } = React.useContext(sessionContext);
  const { mapsIsLoading } = sessionState;

  const [states, setStates] = React.useState<ILocalisation>(localisation);

  const getLocalisationName = () => {
    if (
      localisation?.address1 &&
      localisation?.address2 &&
      !localisation?.address2.includes(localisation?.address1)
    ) {
      return `${localisation?.address1}, ${localisation?.address2}`;
    } else if (localisation?.address2) {
      return `${localisation?.address2}`;
    } else if (localisation?.address1) {
      return `${localisation?.address1}`;
    } else if (localisation?.country) {
      return localisation.country;
    } else {
      return '';
    }
  };

  const defaultLocalisation = React.useMemo(() => ({ lat: 45.764043, lng: 4.835659 }), []);
  const {value, suggestions: { data }, setValue } = usePlacesAutocomplete({});

  const handleInputForLocalisation = (e: { target: { value: string } }) => {
    const { value } = e.target;
    if (isEmpty(value)) {
      setStates({
        countryCode: null,
        postalCode: null,
        town: null,
        address1: null,
        address2: null,
        longitude: 0,
        latitude: 0,
        googleUrl: null,
        country: null
      });
      updateHandler(null);
    }
    setValue(value);
  };

  const handleSelectForLocalisation =
    // @ts-ignore
    ({ description, structured_formatting }) =>
      async () => {
        setValue(description, false);
        const results = await getGeocode({ address: description });
        const { lat, lng } = getLatLng(results[0]);
        const { long_name: postalCode = '' } =
          results[0].address_components.find((c) => c.types.includes('postal_code')) || {};
        const { short_name: countryCode = '' } =
          results[0].address_components.find((c) => c.types.includes('country')) || {};
        const { long_name: country = '' } =
          results[0].address_components.find((c) => c.types.includes('country')) || {};
        const { long_name: town = '' } =
          results[0].address_components.find((c) => c.types.includes('locality')) || {};
        const { formatted_address } = results[0];

        const firstAddress = results[0].formatted_address;
        const newStates = ({
          ...states,
          countryCode: countryCode,
          country: country,
          postalCode: postalCode,
          town: town,
          address1: structured_formatting.main_text,
          address2: firstAddress.substring(0, firstAddress.indexOf(',')) === structured_formatting.main_text
              ? null
              : firstAddress.substring(0, firstAddress.indexOf(',')),
          longitude: lng,
          latitude: lat,
          googleUrl: `https://www.google.com/maps/place/${encodeURI(formatted_address)}`,
        });
        setStates(newStates);
        updateHandler(newStates);
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItemButton
          className={`${CLASSNAME}_element`}
          key={place_id}
          onClick={handleSelectForLocalisation(suggestion)}
        >
          <ListItemText primary={main_text} secondary={secondary_text} />
        </ListItemButton>
      );
    });

  React.useEffect(() => {
    setStates(localisation);
    setValue(getLocalisationName());
  }, [localisation]);

  return (
    <div className={`${CLASSNAME}_localisation`}>
      <p className={`${CLASSNAME}_miniTitle`}>{capitalize(t('words:venue'))} :</p>
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleInputForLocalisation}
        placeholder={capitalize(searchTitle)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <div className={`${CLASSNAME}_localisation_render`}>
        <div className={`${CLASSNAME}_localisation_suggestion`}>
          <List component="nav">{renderSuggestions()}</List>
        </div>
        <div className={`${CLASSNAME}_localisation_map`}>
          {mapsIsLoading ? (
            <GoogleMap
              zoom={15}
              center={{
                lat: states?.latitude || defaultLocalisation.lat,
                lng: states?.longitude || defaultLocalisation.lng,
              }}
              mapContainerClassName={`${CLASSNAME}_map`}
            >
              {states?.latitude && (
                <Marker
                  position={{
                    lat: states?.latitude,
                    lng: states?.longitude,
                  }}
                />
              )}
            </GoogleMap>
          ) : (
            <div className={`${CLASSNAME}_error`}>
              <span>{t('error:unknownError')}...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GoogleMaps;