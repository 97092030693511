import {
  acceptMembership as acceptMembershipAPI,
  deleteInvitations as deleteInvitationsAPI,
  getMembers as getMembersAPI,
  getMembersStats as getMembersStatsAPI,
  inviteMembers as inviteMembersAPI,
  MembersType,
  refuseMembership as refuseMembershipAPI,
  removeMembers as removeMembersAPI,
  deleteMembers as deleteMembersAPI,
  updateMembersRole as updateMembersRoleAPI,
  blockMembers as blockMembersApi,
} from '../_api/membersApi';
import {
  actions as memberActions,
  initialState as memberInitialState,
  reducer as memberReducer,
} from '../_slice/memberSlice';
import {
  BlockMembersParams,
  GetMembersParams,
  InviteMembersParams,
  MembershipParams,
  MembersParams,
  RemoveMembersParams,
  UpdateMembersParams
} from "../_types/memberTypes";
import { DeleteInvitationParams } from '../_types/sessionTypes';
import displayError from '../helpers/displayError';
import { sessionContext } from './useSession';
import React from 'react';
import { DetachUserEventParams } from "../_types/eventTypes";
import { detachUserEvent as detachUserEventApi } from "../_api/eventApi";

const useMember = () => {
  const [membersState, dispatch] = React.useReducer(memberReducer, memberInitialState);

  // useSession
  const { sessionState, setError, setMessage, setResult } = React.useContext(sessionContext);

  // Params
  const setMembersPage = (newPage: number) => {
    dispatch(
      memberActions.setMembersParams({
        page: newPage,
        maxPerPage: membersState.membersParams.maxPerPage,
        search: '',
      })
    );
  };
  const setMembersParams = (newParams: MembersParams) => {
    dispatch(memberActions.setMembersParams(newParams));
  };

  // Reload
  const reloadData = async (nuid: string | undefined, type: MembersType) => {
    if (nuid) {
      await getMembersStats(nuid);
      await getMembers(nuid, type);
    }
  };

  // Get members
  const getMembersStats = async (id: string) => {
    try {
      const data = await getMembersStatsAPI(id, !!sessionState.selectedEvent);
      dispatch(memberActions.setMembersStats(data.content));
    } catch (error: any) {
      displayError(error, setError);
    }
  };
  const getMembers = async (
    nuid: string,
    type = MembersType.members,
    page = 1,
    maxPerPage = 50,
    search = '',
    isModifyingStates = true
  ) => {
    const params: GetMembersParams = {
      page,
      maxPerPage,
      search,
      entityNuid: nuid,
    };
    if (type === MembersType.guests || type === MembersType.memberships) {
      params.status = 'waiting';
    }
    try {
      const data = await getMembersAPI(type, params);
      if (isModifyingStates) {
        switch (type) {
          case MembersType.members:
            dispatch(memberActions.setMembers(data.content));
            break;
          case MembersType.guests:
            dispatch(memberActions.setGuests(data.content));
            break;
          case MembersType.memberships:
            dispatch(memberActions.setMemberships(data.content));
            break;
          default:
            break;
        }
        setMembersParams({
          page: params.page,
          maxPerPage: params.maxPerPage,
          search: params.search,
        });
      } else {
        return data?.content?.users;
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  // Actions with members
  const acceptMembership = async (params: MembershipParams) => {
    try {
      await acceptMembershipAPI(params);
      await reloadData(params.eventNuid || params.communityNuid, MembersType.memberships);
      setMessage('message:membershipAccepted');
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const deleteInvitations = async (params: DeleteInvitationParams) => {
    try {
      const data = await deleteInvitationsAPI(params);
      if (data.success) {
        const objectArray = Object.entries(data.content);
        const d: { text: string; value: number }[] = [];
        objectArray.forEach(([key, value]) => {
          d.push({ text: key, value: (value as number) || 0 });
        });
        setResult(d);
        await reloadData(params.eventNuid || params.communityNuid, MembersType.guests);
      } else {
        setError('error:genericError');
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const inviteMembers = async (params: InviteMembersParams, isReload = true) => {
    try {
      const data = await inviteMembersAPI(params);
      if (data.success) {
        const objectArray = Object.entries(data.content);
        const d: { text: string; value: number }[] = [];
        objectArray.forEach(([key, value]) => {
          const v = value as string[];
          d.push({ text: key, value: v.length || 0 });
        });
        setResult(d);
        if (isReload) {
          await reloadData(
            params?.eventNuid || params?.groupNuid || params?.communityNuid || '',
            MembersType.members
          );
        }
      } else {
        setError('error:genericError');
      }
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const refuseMembership = async (params: MembershipParams) => {
    try {
      await refuseMembershipAPI(params);
      await reloadData(params.eventNuid || params.communityNuid, MembersType.memberships);
      setMessage('message:membershipRefused');
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const updateMembersRole = async (params: UpdateMembersParams) => {
    try {
      await updateMembersRoleAPI(params);
      await reloadData(params.groupNuid || params.communityNuid, MembersType.members);
      setMessage('message:roleUpdateToReferent');
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const removeMembers = async (
    params: RemoveMembersParams,
    membersTab: number,
    isGroup: boolean
  ) => {
    try {
      await removeMembersAPI(params).then((response: any) => {
        if (response.success) {
          if (membersTab === 0) {
            if (isGroup) {
              setMessage('message:groupMemberRemove', {
                count: response.content.removed,
              });
            } else {
              setMessage('message:memberRemove', { count: response.content.removed });
            }
          } else {
            setMessage('message:contactRemove', { count: response.content.removed });
          }
        }
      });
      await reloadData(params.eventNuid || params.communityNuid, MembersType.members);
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const deleteMembers = async (
    params: RemoveMembersParams,
  ) => {
    try {
      await deleteMembersAPI(params).then((response: any) => {
        if (response.success) {
          setMessage('message:memberDelete', { count: response.content.removed });
        }
      });
      await reloadData(params.eventNuid || params.communityNuid, MembersType.members);
    } catch (error: any) {
      displayError(error, setError);
    }
  };

  const blockMembers = async (
    params: BlockMembersParams,
    membersTab: number,
  ) => {
    const data = await blockMembersApi(params);
    if (data?.content?.blocked > 0) {
      setMessage('message:blockedMembers', { count: data.content.blocked });
    }
    if (data?.content?.unblocked > 0) {
      setMessage('message:unblockedMembers', { count: data.content.unblocked });
    }
    if (data?.content?.unknown > 0) {
      setError('message:unknownMembers', { count: data.content.unknown });
    }
    await reloadData(sessionState.selectedCommunity.nuid, MembersType.members);
  }

  const detachUserEvent = async (params: DetachUserEventParams) => {
    try {
      const data = await detachUserEventApi(params.eventNuid, params.userNuids);
      setMessage('message:eventUserRemoved', {
        count: data.content.removed,
      });
      await reloadData(params.eventNuid, MembersType.members);
    } catch (error: any) {
      displayError(error, setError);
    }
  }

  // Effect for HomePage
  const loadHomePageMemberData = async ({ nuid, isAdmin }: { nuid: string; isAdmin: boolean }) => {
    if (nuid && isAdmin) {
      await getMembersStats(nuid);
    }
  };
  // Effect for MemberPage
  const loadMembersPageMemberData = async (nuid: string, isAdmin: boolean) => {
    if (nuid && isAdmin) {
      await getMembersStats(nuid);
      await getMembers(nuid);
      await getMembers(nuid, MembersType.guests);
      await getMembers(nuid, MembersType.memberships);
    }
  };

  return {
    membersState,
    setMembersPage,
    setMembersParams,
    // Get members
    getMembersStats,
    getMembers,
    // Actions with members
    acceptMembership,
    deleteInvitations,
    inviteMembers,
    refuseMembership,
    updateMembersRole,
    detachUserEvent,
    // Only usable in MembersPage
    removeMembers,
    deleteMembers,
    blockMembers,
    // Effects
    loadHomePageMemberData,
    loadMembersPageMemberData,
  };
};

export default useMember;
