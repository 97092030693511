import { useTheme } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

const ToolBarItemInfo = (props) => {
  const { label, colorLabel, value, colorValue, valuePosition, labelStyle } = props;
  const theme = useTheme();

  return (
    <div style={{ marginRight: theme.spacing(2) }}>
      {value && valuePosition === 'left' && (
        <span style={{ color: colorValue, fontWeight: '600' }}>
          {value}
          &nbsp;
        </span>
      )}
      <span style={{ color: colorLabel, ...labelStyle }}>{label}</span>
      {value && valuePosition === 'right' && (
        <span style={{ color: colorValue, fontWeight: '600' }}>
          &nbsp;
          {value}
        </span>
      )}
    </div>
  );
};

ToolBarItemInfo.defaultProps = {
  colorLabel: 'primary',
  colorValue: 'primary',
  value: null,
  valuePosition: 'left',
  labelStyle: null,
};

ToolBarItemInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  colorLabel: PropTypes.string,
  colorValue: PropTypes.string,
  valuePosition: PropTypes.oneOf(['left', 'right']),
  labelStyle: PropTypes.objectOf(PropTypes.any),
};

export default ToolBarItemInfo;
