import AppTooltip from '../../helpers/AppToolTip';
import theme from '../../pages/theme';
import YesNoModal from './YesNoModal';
import { Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ToolBarItemUpload = (props) => {
  const { label, action, color, disabled, helpZone, help } = props;
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const { t } = useTranslation(['members']);

  const handleChange = (event) => {
    action(event.target.files[0]);
  };

  const helpHandle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setIsHelpOpen(true);
  };

  return (
    <>
      <AppTooltip title={help} arrow>
        <div>
          <label htmlFor="upload">
            <input
              style={{ display: 'none' }}
              id="upload"
              name="upload"
              type="file"
              onChange={(event) => handleChange(event)}
              disabled={disabled}
            />
            <Button
              endIcon={helpZone && <HelpIcon onClick={helpHandle} />}
              style={{ marginRight: theme.spacing(1) }}
              color={color}
              variant="contained"
              component="span"
              size="small"
              disabled={disabled}
            >
              {label}
            </Button>
          </label>
        </div>
      </AppTooltip>
      <YesNoModal
        size="md"
        yesAction={() => setIsHelpOpen(false)}
        yesLabel={t('words:close')}
        text={helpZone}
        title={t('members:inviteMembersHelp')}
        open={isHelpOpen}
      />
    </>
  );
};

ToolBarItemUpload.defaultProps = {
  color: 'primary',
  disabled: false,
  help: '',
  helpZone: null,
};

ToolBarItemUpload.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  color: PropTypes.string,
  help: PropTypes.string,
  disabled: PropTypes.bool,
  helpZone: PropTypes.objectOf(PropTypes.any),
};

export default ToolBarItemUpload;
