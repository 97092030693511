import { TextField } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import validation from 'validate.js';
import SelectField from "../../../../components/form/SelectField";
import { sessionContext } from "../../../../_hook/useSession";

const NewMemberForm = (props) => {
  const { exportStates, setYesDisabled, setParentState } = props;
  const { t } = useTranslation(['words', 'error', 'api']);
  const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    currentPosition: '',
    professionalActivity: null,
  };
  const { sessionState } = React.useContext(sessionContext);
  const { dictionaries } = sessionState;
  const [states, setStates] = useState(initialState);
  const [errorStates, setErrorStates] = useState(initialState);
  const constraints = {
    email: { presence: { allowEmpty: false }, email: true },
    firstName: { presence: { allowEmpty: false } },
    lastName: { presence: { allowEmpty: false } },
    company: {},
    currentPosition: {},
  };

  const updateParentStates = (id, value) => {
    const errors = validation.validate(
      {
        ...states,
        [id]: value,
      },
      constraints
    );
    exportStates({
      ...states,
      [id]: value,
      isValid: errors === undefined,
    });

  }

  const handleChange = (event) => {
    setStates({ ...states, [event.target.id]: event.target.value });
    updateParentStates(event.target.id, event.target.value);
  };

  const onSelect = (event, id) => {
    setStates({ ...states, [id]: event.key });
    updateParentStates(id, event.value);
  };

  const handleValidation = (event) => {
    const errors = validation.validate({ [event.target.id]: event.target.value }, constraints);
    setErrorStates({
      ...initialState,
      [event.target.id]: errors[event.target.id],
    });
  };

  React.useEffect(() => {
    if (
      setYesDisabled &&
      states.email !== '' &&
      states.firstName !== '' &&
      states.lastName !== ''
    ) {
      setYesDisabled(false);
    }
    if (states !== undefined && setParentState) {
      setParentState(states);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  return (
    <>
      <TextField
        autoFocus
        error={states.email !== '' && errorStates.email !== undefined && errorStates.email !== ''}
        required
        variant="outlined"
        id="email"
        label={t('words:email')}
        type="email"
        style={{ minHeight: '50px' }}
        fullWidth
        helperText={errorStates.email ? t('error:emailBadFormat') : ' '}
        onChange={handleChange}
        onBlur={handleValidation}
      />
      <TextField
        error={errorStates.firstName !== '' && errorStates.firstName !== undefined}
        required
        id="firstName"
        variant="outlined"
        label={t('words:firstName')}
        type="text"
        helperText={errorStates.firstName ? t('error:noBlank') : ' '}
        style={{ minHeight: '50px' }}
        fullWidth
        onChange={handleChange}
        onBlur={handleValidation}
      />
      <TextField
        error={errorStates.lastName !== '' && errorStates.lastName !== undefined}
        required
        id="lastName"
        variant="outlined"
        label={t('words:lastName')}
        type="text"
        helperText={errorStates.lastName ? t('error:noBlank') : ' '}
        style={{ minHeight: '50px' }}
        fullWidth
        onChange={handleChange}
        onBlur={handleValidation}
      />
      <TextField
        id="company"
        label={t('words:company')}
        type="text"
        variant="outlined"
        fullWidth
        style={{ minHeight: '50px' }}
        helperText={' '}
        onChange={handleChange}
        onBlur={handleValidation}
      />
      <TextField
        id="currentPosition"
        label={t('words:currentPosition')}
        type="text"
        variant="outlined"
        fullWidth
        onChange={handleChange}
        style={{ minHeight: '50px' }}
        helperText={' '}
        onBlur={handleValidation}
      />
      <SelectField
        id="professionalActivity"
        label={t('api:member_professionalActivity')}
        data={dictionaries?.professionalActivity}
        value={states.professionalActivity || ''}
        onSelect={onSelect}
      />    </>
  );
};

NewMemberForm.defaultProps = {
  exportStates: () => {},
};

NewMemberForm.propTypes = {
  exportStates: PropTypes.func,
  setYesDisabled: PropTypes.func,
  setParentState: PropTypes.func,
};

export default NewMemberForm;
