const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const POST_SECURITY_LOGIN = '/security/login';
export const POST_SECURITY_REFRESH_TOKEN = '/security/refresh_token';
export const POST_SECURITY_CREATE_LIMITED_USER = '/security/user/create_limited';
export const POST_SECURITY_VALIDATE_EMAIL = '/security/validate_email';
export const POST_SECURITY_ASK_PASSWORD_CHANGE = '/security/change_password_email';
export const POST_SECURITY_CHANGE_PASSWORD = '/security/change_password';
export const GET_SECURITY_DICTIONARY = '/security/dictionary';
export const GET_SECURITY_LOCALISATION = '/security/search_localisation';
export const GET_SECURITY_COMMUNITY_EXIST = '/security/community/exist';

export const POST_COMMUNITY_CREATE = '/api/free/community/create';
export const POST_COMMUNITY_UPDATE = '/api/admin/community/update';
export const POST_COMMUNITY_INVITE = '/api/referent/community/invite';

export default API_BASE_URL;
