import SuccessButton from '../../components/generic/SuccessButton';
import getDefaultStyles from '../getDefaultStyles';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CommunityCreatedStep = (props) => {
  const { lastStep, nextStep, allData } = props;
  const { t } = useTranslation(['words', 'register']);
  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    warning: {
      paddingTop: 70,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 20,
      },
      fontStyle: 'italic',
      textAlign: 'center',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
      },
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.title} ${classes.selectEmpty}`}>
        {t('register:createdCommunityTitle')}
      </div>
      <div className={`${classes.subTitle} ${classes.selectEmpty}`}>
        {t('register:createdCommunityText', { communityName: allData[3].name })}
      </div>
      <div className={classes.warning}>
        <span style={{ fontWeight: 600 }}>{t('words:Important')}</span>
        &nbsp;:&nbsp;
        {t('register:dashboardWarning')}
      </div>
      <div className={classes.buttons} style={{ paddingTop: 30 }}>
        {!lastStep && <SuccessButton size="large" onClick={nextStep} label={t('words:continue')} />}
      </div>
    </>
  );
};

CommunityCreatedStep.propTypes = {
  lastStep: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  allData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CommunityCreatedStep;
