import useGroup from '../../_hook/useGroup';
import { sessionContext } from '../../_hook/useSession';
import EmptyState from '../../components/EmptyState';
import CommunityGroupForm from '../../components/form/CommunityGroupForm';
import ScPaginator from '../../components/generic/paginator';
import ToolBarItemButton from '../../components/generic/ToolBarItemButton';
import ToolBarSearchField from '../../components/generic/ToolBarSearchField';
import Table from '../../components/table';
import loadDataInTable from '../../components/table/loadDataInTable';
import TagCommunities from '../../components/user/TagCommunities';
import { DIMENSION_PHONE } from '../../constants/dimensions.constants';
import getIsPro from '../../constants/getIsPro';
import useWindowDimensions from '../../helpers/getWindowDimensions';
import './index.scss';
import { Tooltip } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import capitalize from 'lodash/capitalize';
import moment from 'moment/moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { FaCheck } from "react-icons/fa";
import { CHECKED_COLOR } from "../../constants/colors.constants";

const CLASSNAME = 'groups';

const GroupsPage: React.FC = () => {
  const { t } = useTranslation(['layout', 'words']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const edit = params.get('edit');

  // responsive
  const { width } = useWindowDimensions();

  // useSession
  const { sessionState, setGroup, setPageIsLoading } = React.useContext(sessionContext);
  const { selectedGroup, selectedCommunity, pageIsLoading } = sessionState;
  const groupIsPublished = selectedGroup?.published === 1 || false;

  // useGroup
  const { groupState, getGroups, setGroupsParams } = useGroup();
  const { groupsInformation, groupsParams } = groupState;

  // STATES
  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  const [rows, setRows] = React.useState([]);
  const [elementsPerPage, setElementsPerPage] = React.useState(50);
  const [isCreation, setIsCreation] = React.useState(false);

  const conditions = (columnName: string, clmns: GridColDef[]) => {
    const MyRenderCell = React.memo((p: any) => {
      switch (columnName) {
        case 'logo':
          return <TagCommunities items={[p?.row] || []} entity={'group'} />;
        case 'createdDate':
          return (
            <Tooltip title={p?.value || ''} arrow>
              <p className="pInTable">{p?.value && capitalize(moment(p?.value).format('ll'))}</p>
            </Tooltip>
          );
        case 'published':
          return <>{p?.value ? capitalize(t('words:published')) : capitalize(t('words:draft'))}</>;
        case 'accessType':
          return (
            <p className="pInTable">
              {capitalize(p.value ? t(`words:accessType_${p.value}`) : '')}
            </p>
          );
        default:
          return <></>;
      }
    });

    if (!clmns.find((c) => c.field === columnName)) {
      switch (columnName) {
        case 'logo':
          clmns.push({
            field: columnName,
            headerName: '',
            width: 75,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (p) => <MyRenderCell {...p} />,
          });
          break;
        case 'accessType':
          clmns.push({
            field: columnName,
            headerName: 'group_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (p) => <MyRenderCell {...p} />,
          });
          break;
        case 'createdDate':
          clmns.push({
            field: columnName,
            headerName: 'group_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (p) => <MyRenderCell {...p} />,
          });
          break;
        case 'published':
          clmns.push({
            width: 100,
            field: columnName,
            headerName: 'group_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (p) => <MyRenderCell {...p} />,
          });
          break;
        case 'enabled':
          clmns.push({
            width: 100,
            field: columnName,
            headerName: 'group_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                {params.value && <FaCheck color={CHECKED_COLOR} size={16} />}
              </p>
            ),
          });
          break;
        default:
          break;
      }
    }
  };
  const [sortModel, setSortModel] = React.useState<{ field: string; sort: GridSortDirection }[]>([
    {
      field: 'createdDate',
      sort: 'desc',
    },
  ]);
  const sortableColumns = ['name', 'accessType', 'createdDate'];

  // ACTIONS
  const handleSearch = async (searchValue: string) => {
    await getGroups(
      {
        communityNuid: selectedCommunity.nuid,
        desc: groupsParams.desc,
        maxPerPage: groupsParams.maxPerPage,
        orderBy: groupsParams.orderBy,
        page: 1,
        searchString: searchValue,
        isAdmin: !selectedCommunity?.parameters?.admin
      },
      true
    );
  };

  const handleChangePage = async (newPage: number) => {
    await getGroups(
      {
        communityNuid: selectedCommunity.nuid,
        desc: groupsParams.desc,
        maxPerPage: groupsParams.maxPerPage,
        orderBy: groupsParams.orderBy,
        page: newPage,
        searchString: groupsParams.search,
        isAdmin: !selectedCommunity?.parameters?.admin
      },
      true
    );
  };

  const handleChangeItemsPerPage = async (newItemsPerPage: number) => {
    setElementsPerPage(newItemsPerPage);
    await getGroups(
      {
        communityNuid: selectedCommunity.nuid,
        desc: groupsParams.desc,
        maxPerPage: newItemsPerPage,
        orderBy: groupsParams.orderBy,
        page: 1,
        searchString: groupsParams.search,
        isAdmin: !selectedCommunity?.parameters?.admin
      },
      true
    );
  };

  const handleSelection = (selection: number) => {
    const item = groupsInformation.groups[selection];
    if (item) {
      if (item?.nuid !== selectedGroup?.nuid || !selectedGroup) {
        setGroup(item);
        navigate('/');
      }
    }
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
    await getGroups({
      communityNuid: selectedCommunity.nuid,
      desc: model[0]?.sort === 'desc',
      maxPerPage: groupsParams.maxPerPage,
      orderBy: model[0]?.field,
      page: 1,
      searchString: groupsParams.search,
      isAdmin: !selectedCommunity?.parameters?.admin
    });
  };

  // EFFECTS
  React.useEffect(() => {
    if (selectedCommunity) {
      setPageIsLoading(true);
      (async () => {
        setGroupsParams({
          page: 1,
          maxPerPage: 50,
          search: '',
          desc: true,
          orderBy: 'createdDate',
          isAdmin: !selectedCommunity?.parameters?.admin
        });
        if (!selectedGroup) {
          await getGroups(
            {
              communityNuid: selectedCommunity.nuid,
              maxPerPage: groupsParams.maxPerPage,
              page: 1,
              isAdmin: !selectedCommunity?.parameters?.admin
            },
            true
          );
        }
        setPageIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  // Create columns and load rows
  React.useEffect(() => {
    const dataColumns = ['logo', 'name', 'accessType', 'totalMembers', 'published', 'enabled', 'createdDate'];
    loadDataInTable(
      groupsInformation.groups,
      dataColumns,
      setColumns,
      setRows,
      conditions,
      'group'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsInformation?.total, sortModel]);

  React.useEffect(() => {
    setElementsPerPage(groupsParams.maxPerPage);
  }, [groupsParams?.maxPerPage]);

  if (getIsPro(selectedCommunity)) {
    return (
      <>
        {!pageIsLoading && (
          <div className={CLASSNAME}>
            {!selectedGroup && !isCreation && <h1>{capitalize(t('words:groups'))}</h1>}
            {!selectedGroup && !isCreation ? (
              <div className={`${CLASSNAME}_tableContainer`}>
                <div className={`${CLASSNAME}_topBar`}>
                  {width >= DIMENSION_PHONE && (
                    <ToolBarSearchField
                      onSearch={handleSearch}
                      value={groupsParams.search}
                      label={'groupSearch'}
                    />
                  )}
                  <ToolBarItemButton
                    label={capitalize(t('layout:createGroup'))}
                    action={() => setIsCreation(true)}
                  />
                </div>
                <div className={`${CLASSNAME}_table`}>
                  <Table
                    checkboxSelection={false}
                    columns={columns}
                    disableSelectionOnClick={false}
                    noRowsMessage={
                      <EmptyState image={<Group />} message={t('layout:noGroupYet')} />
                    }
                    onPageChange={handleChangePage}
                    onPageSizeChange={handleChangeItemsPerPage}
                    onSelectionModelChange={handleSelection}
                    onSortModelChange={(newSortModel) => handleSortModelChange(newSortModel)}
                    page={groupsParams.page - 1}
                    pageSize={elementsPerPage}
                    rowCount={groupsInformation.total}
                    rows={rows}
                    sortModel={sortModel}
                    // sortableColumns={sortableColumns}
                  />
                </div>
                <ScPaginator
                  totalItems={groupsInformation.total}
                  returnChangePage={handleChangePage}
                  returnChangeRowsPerPage={handleChangeItemsPerPage}
                  page={groupsParams.page}
                  rowsPerPage={elementsPerPage}
                />
              </div>
            ) : (
              <div className={`${CLASSNAME}_formContainer`}>
                <CommunityGroupForm
                  formParams={{ edit }}
                  item={isCreation ? null : selectedGroup}
                  isGroup
                  isPublish={isCreation ? false : groupIsPublished}
                  isEdit={!isCreation}
                  onClickBackButton={() => setIsCreation(false)}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

GroupsPage.displayName = 'GroupsPage';
export default GroupsPage;
