const layoutFr = {
  // A
  acceptedExtensions: 'Extensions acceptées : {{extensions}}',
  accessType: 'Accessibilité',
  addPictures: 'Ajoutez des images',
  addDocument: 'Ajoutez un document',
  addVideo: 'Ajoutez une vidéo',
  animateYourCommunity:
    'Dès maintenant, commencez à inviter vos membres et à publier vos actualités.\n\n' +
    "Vous pouvez éditer votre communauté à partir du stylo d'édition en haut de votre écran.",
  animateYourCommunityTitle: 'Commencez à animer votre communauté.',
  appAndDashboardDetail:
    "L'application SuperConnectr permet d'accéder à l'annuaire des membres, la messagerie privée, le Connector pour mettre en relation, votre fil d'actualités, votre communauté référencée dans l'application...\n\n" +
    "Le tableau de bord, uniquement accessible partir de votre ordinateur, permet d'administrer votre communauté, publier vos actualités, inviter et gérer vos membres.",
  // C
  community: 'communauté',
  communityName: 'Nom de la communauté',
  communityPresentation: 'présentation de la communauté',
  communitySize: 'Taille de la communauté',
  confirmBack: 'êtes-vous sur de vouloir annuler vos modifications ?',
  confirmGroupChannelDeletion:
    'Êtes-vous sûr de vouloir supprimer le groupe de discussion ?\n\n' +
    "Vous perdrez tout l’historique de discussion",
  confirmGroupDelete:
    'En supprimant cette sous-communauté, vous comprenez que :\n\n' +
    '- les actualités seront supprimées\n' +
    '- les événements seront supprimés\n' +
    '- l’annuaire des membres n’existera plus\n' +
    '- les invitations en attentes et les demandes en attente seront supprimées\n' +
    '- le groupe de discussion de la sous-communauté sera supprimé\n' +
    '- la sous-communauté ne sera plus visible par aucun membre de la communauté\n\n' +
    'Important : la suppression de cette sous-communauté ne pourra pas être annulée',
  confirmGroupDeleteTitle: 'Êtes-vous sûr de vouloir supprimer cette sous-communauté ?',
  confirmRemoveUser: 'Désinscrire un membre',
  confirmDeleteUser: 'Suppression de membres',
  confirmRemoveAttendee: 'Désinscrire un participant',
  coverFormat: 'Format 1200 x 600',
  coverPicture: 'Bannière',
  createChannel: 'Créer le groupe de discussion',
  createCommunity: 'Créer une nouvelle communauté',
  createEvent: 'créer un événement',
  createGroup: 'Créer une sous-communauté',
  contactDetails: 'Coordonnées',
  // D
  deleteChannel: 'Supprimer le groupe de discussion',
  deleteConfirmation: 'Confirmez votre choix',
  deletedUserName: "Ce profil n'existe plus",
  description: 'Description:',
  downloadApp:
    "Téléchargez l'application mobile SuperConnectr sur iOS ou Google Play.\n" +
    "Inscrivez-vous sur l'app avec les mêmes identifiants pour créer votre compte et votre profil.",
  // E
  editCommunity: 'Édition de la communauté',
  editGroup: 'Édition de la sous-communauté',
  enablingGroup: 'Activation de la sous-communauté',
  event: 'événement',
  eventSearch: 'rechercher un événement',
  // G
  group: 'sous-communauté',
  groupName: 'Nom de la sous-communauté',
  groupPresentation: 'présentation de la sous-communauté',
  groupSearch: 'rechercher une sous-communauté',
  groupSize: 'Taille de la sous-communauté',
  // I
  itemsByPage: 'Éléments par page',
  iUnderstand: "J'ai compris",
  // K
  knownMore: 'En savoir plus:',
  // L
  loading: 'chargement',
  logoFormat: 'Format Carré (comme sur vos réseaux sociaux habituels)',
  // M
  maxFiles: '{{total}} fichier(s) maximum',
  memberSearch: 'rechercher un membre par nom, prénom, entreprise, compétence...',
  myBanner: 'Ma bannière',
  myGroups: 'Mes sous-communautés',
  myLogo: 'mon logo',
  // N
  newCommunity: 'Créer une communauté',
  newGroup: 'Créer une sous-communauté',
  noGroupYet: 'pas encore de sous-communauté...',
  notAcceptedFileType: 'Type de fichier non accepté',
  notSpecified: 'non renseigné',
  // P
  participantSearch: 'rechercher un participant par nom, prénom, entreprise, compétence...',
  publishYourCommunityEdit:
    "Publiez ou éditez votre communauté à tout moment à partir du stylo d'édition en haut de votre écran.",
  publishYourCommunity:
    'Publiez votre communauté pour inviter vos membres et publier vos actualités.\n\n' +
    "Éditez votre communauté à partir du stylo d'édition en haut de votre écran.",
  publishYourCommunityTitle: 'Commencez par publier votre communauté.',
  // R
  recoSearch: 'Rechercher des recommandations',
  recoDelete: 'Supprimer',
  recommendedSize: 'Format recommandé : {{size}}',
  requiredFieldsCommunity: '* Champs obligatoires pour publier la communauté',
  requiredFieldsGroup: '* Champs obligatoires pour publier',
  // S
  selectCoverPicture: 'Choisissez une bannière',
  selectCoverPictureRequired: 'Choisissez une bannière *',
  selectedItems_one: 'élément sélectionné',
  selectedItems_other: 'éléments sélectionnés',
  selectImage: 'Sélectionnez une image',
  selectLogo: 'Choisissez un logo',
  selectLogoRequired: 'Choisissez un logo *',
  selectedProfilsWithCount_one: 'Profil sélectionné',
  selectedProfilsWithCount_other: 'Profils sélectionnés',
  selectTemplatePicture: "Choisissez l'entête des Emails",
  showLess: '... réduire',
  showMore: '... voir plus',
  skillAndFocus: "Tags thématiques :",
  sizeImage: 'extensions : GIF, JPG, PNG',
  sizeMax: 'Taille maximum : {{size}}',
  socialNetworks: 'Réseaux sociaux',
  // T
  templatePicture: 'Bannière Email',
  tooLarge: 'Le fichier dépasse la taille maximum autorisée',
  //z W
  whyNotPublish: 'Éditez et publiez votre communauté pour commencer à publier des actualités.',
  whereIsCommunity: 'Adresse de la communauté ?',
  whereIsGroup: 'Adresse de la sous-communauté ?',
};

export default layoutFr;
