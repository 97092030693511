import getDefaultStyles from '../../pages/getDefaultStyles';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InputCode = (props) => {
  const { checkValue } = props;
  const [code, setCode] = useState('');
  const { t } = useTranslation('register');
  const regexpNumber = /^[0-9-\b]+$/;

  const handleChangeCode = (event) => {
    const { value } = event.target;
    if (regexpNumber.test(value)) {
      if (code.length === 2 && value.length === 3) {
        setCode(`${value}-`);
      } else if (code.length === 3 && value.length === 4) {
        setCode(`${code}-${value.substr(value.length - 1)}`);
      } else if (code.length === 5 && value.length === 4) {
        setCode(code.substring(0, value.length - 1));
      } else {
        setCode(value);
      }
      if (value.length === 7) {
        checkValue(value.replace('-', ''));
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    input: {
      fontFamily: 'Courier New',
      fontSize: '2.5em',
      fontWeight: 'bolder',
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <TextField
        InputProps={{
          classes: {
            input: classes.input,
          },
          maxLength: 7,
          size: 7,
        }}
        error={code.length !== 6}
        id="code"
        label={t('code')}
        value={code || ''}
        onChange={handleChangeCode}
      />
    </div>
  );
};

InputCode.propTypes = {
  checkValue: PropTypes.func.isRequired,
};

export default InputCode;
