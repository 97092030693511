import SuccessButton from '../../components/generic/SuccessButton';
import { GET_SECURITY_DICTIONARY, GET_SECURITY_LOCALISATION } from '../../config/api';
import apiRest, { AxiosCancelToken } from '../../helpers/apiRest';
import getDefaultStyles from '../getDefaultStyles';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityLocalisationStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep, data } = props;
  const { t } = useTranslation(['words', 'register']);
  const [country, setCountry] = useState(data.country || 'FR');
  const [town, setTown] = useState(data.town);
  const [postalCode, setPostalCode] = useState(data.postalCode);
  const [localisation, setLocalisation] = useState(data.localisation);
  const [localisationResult, setLocalisationResult] = useState(data.localisationResult || []);
  const [countryItems, setCountryItems] = useState(data.countryItems);

  const getDictionary = () => {
    const headers = {
      'X-Device': 'desktop',
    };
    apiRest()
      .get(`${GET_SECURITY_DICTIONARY}/country`, { headers })
      .then((response) => {
        const countries = response.data.content;
        setCountryItems(countries);
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'register:errorLoadingDictionary';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
      });
  };

  const getLocalisation = (event, value, reason) => {
    if (value.length > 2 && reason === 'input') {
      const headers = {
        'X-Device': 'desktop',
      };
      apiRest()
        .get(
          `${GET_SECURITY_LOCALISATION}/${country}/${value}`,
          { headers },
          {
            cancelToken: new AxiosCancelToken((c) => {
              window.cancel = c;
            }),
          }
        )
        .then((response) => {
          setLocalisation(null);
          setLocalisationResult(
            response.data.content.map((result) => ({
              postalCode: result.postalCode,
              title: `${result.postalCode}, ${result.town}`,
              town: result.town,
              longitude: result.longitude,
              latitude: result.latitude,
            }))
          );
        })
        .catch(() => {
          toast.error(t('register:connectionError'));
        });
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setCountry(value);
    setTown(null);
    setLocalisation(null);
    setLocalisationResult([]);
  };

  const handleChangeTown = (event, value) => {
    if (value) {
      setTown(value.town);
      setPostalCode(value.postalCode);
      setLocalisation(value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getDictionary();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.question}>{t('register:selectLocalisation')}</div>
      <div className={classes.content}>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-country-label">{t('words:country')}</InputLabel>
          {!countryItems && <CircularProgress />}
          {countryItems && (
            <Select
              className={classes.selectEmpty}
              labelId="select-country-label"
              id="select-category"
              value={country || ''}
              onChange={handleChangeCountry}
            >
              {countryItems.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <Autocomplete
          value={localisation || null}
          options={localisationResult}
          getOptionLabel={(option) => option.title}
          id="auto-complete"
          onChange={(event, newValue) => {
            handleChangeTown(event, newValue);
          }}
          onInputChange={(event, newValue, reason) => {
            getLocalisation(event, newValue, reason);
          }}
          autoComplete
          includeInputInList
          noOptionsText={t('register:noItem')}
          disabled={!country}
          renderInput={(params) => (
            <TextField
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...params}
              label={t('register:postalCodeTown')}
              margin="normal"
            />
          )}
        />
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() =>
              backStep({
                country,
                town,
                postalCode,
                localisation,
                countryItems,
                localisationResult,
              })
            }
          >
            {t('words:previous')}
          </Button>
        )}
        {!lastStep && (
          <SuccessButton
            size="large"
            onClick={() =>
              nextStep({
                country,
                town,
                postalCode,
                localisation,
                countryItems,
                localisationResult,
              })
            }
            disabled={!country}
            label={t('words:next')}
          />
        )}
      </div>
    </>
  );
};

CommunityLocalisationStep.defaultProps = {
  data: {
    countryItems: [],
  },
};

CommunityLocalisationStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityLocalisationStep;
