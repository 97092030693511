import { Community } from '../_types/communityTypes';
import { Group } from '../_types/groupTypes';

const getPublishedStatus = (event: any, group: Group | null, community: Community | null) => {
  if (event) {
    return event?.status !== 'draft';
  } else if (group) {
    return group?.published;
  } else {
    return community?.published;
  }
};

export default getPublishedStatus;
