import ActionButton from '../../components/generic/ActionButton';
import getDefaultStyles from '../getDefaultStyles';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LastStepMobile = () => {
  const { t } = useTranslation(['register', 'words']);

  const onClickExternalLink = (url) => {
    window.open(url, '_self').focus();
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    warning: {
      paddingTop: 20,
      fontStyle: 'italic',
      textAlign: 'center',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px',
      },
      justifyContent: 'center',
    },
    content: {
      textAlign: 'center',
      fontSize: '1.2em',
      width: '100%',
      paddingTop: '10px',
      whiteSpace: 'pre-wrap',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>{t('register:mobilLastText')}</div>
      <div className={classes.buttons}>
        <ActionButton
          size="large"
          onClick={() => {
            onClickExternalLink('https://superconnectr.com');
          }}
          label={t('register:iUnderstand')}
        />
      </div>
    </>
  );
};

export default LastStepMobile;
