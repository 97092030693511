import logo from '../../assets/images/Logo_SuperConnectr.png';
import ActionButton from '../../components/generic/ActionButton';
import { POST_SECURITY_ASK_PASSWORD_CHANGE } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import './index.scss';
import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CLASSNAME = 'forgetPassword';

const ForgetPassword = () => {
  const location = useLocation();
  const { t } = useTranslation(['connect', 'words']);
  const [newEmail, setNewEmail] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    textField: {
      width: '25ch',
    },
    root: {
      borderRadius: 10,
      textAlign: 'center',
      width: '450px',
      padding: theme.spacing(5),
    },
    form: {
      marginBottom: theme.spacing(2),
    },
    margin: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const handleChange = (event) => {
    const { value } = event.target;
    setNewEmail(value);
    setIsEmailValid(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null);
  };

  function askResetPassword() {
    setWaiting(true);
    try {
      apiRest()
        .post(POST_SECURITY_ASK_PASSWORD_CHANGE, {
          email: newEmail,
          fromDashboard: true,
        })
        .then(() => {
          toast.info(t('connect:viewMailForPasswordChange'));
          navigate('/', {
            replace: true,
          });
        });
    } catch (error) {
      if (error?.response?.data) {
        const { fail } = error.response.data;
        toast.error(t(`${fail}`));
      } else {
        toast.error(t('connect:unknownError'));
      }
    }

    setWaiting(false);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isEmailValid) {
      askResetPassword();
    }
  };

  React.useEffect(() => {
    if (location?.state) {
      // @ts-ignore
      setNewEmail(location.state.email);
    }
  }, [location]);

  document.getElementsByTagName('body')[0].style = 'background-color: #F29C49';
  const classes = useStyles();
  return (
    <div className={CLASSNAME}>
      <Paper className={classes.root} elevation={5}>
        <div>
          <img style={{ width: '90%' }} src={logo} alt="logo SuperConnectr" />
          <Button className={`${CLASSNAME}_btnBack`} variant="text" onClick={() => navigate(-1)}>
            <ArrowBack />
          </Button>
          <p
            style={{
              fontSize: '1.5em',
              fontWeight: 600,
            }}
          >
            {t('connect:reinitializePassword')}
          </p>
          <FormControl fullWidth className={classes.form}>
            <InputLabel
              htmlFor="email-label"
              className={classes.margin}
              style={{ fontWeight: 600 }}
            >
              {t('words:email')}
            </InputLabel>
            <Input
              className={classes.margin}
              error={newEmail !== '' && !isEmailValid}
              id="email"
              label={t('words:email')}
              onKeyPress={handleKeyPress}
              value={newEmail}
              onChange={handleChange}
              name="email"
            />
          </FormControl>
          {waiting && <CircularProgress />}
          {!waiting && (
            <ActionButton
              bold
              size="large"
              onKeyPress={handleKeyPress}
              disabled={!isEmailValid}
              onClick={askResetPassword}
              label={t('words:toSend')}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default ForgetPassword;
