const wordsEn = {
  home: 'Accueil',
  members: 'Membres',
  actualities: 'Actualités',
  groups: 'Sous-communautés',
  feedbacks: 'Feedbacks',
  'mutual aid': 'Entraide',
};

export default wordsEn;
