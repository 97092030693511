import apiRest from '../helpers/apiRest';
import {CreateChannelParams, DeleteChannelParams} from "../_types/channelTypes";

const createChannelApi = '/api/admin/channel/create';
const deleteChannelApi = '/api/admin/channel/delete';

const createChannel = async (params: CreateChannelParams) => {
  const response = await apiRest().post(createChannelApi, params);
  const { data } = response;

  return data;
};

const deleteChannel = async (params: DeleteChannelParams) => {
  const response = await apiRest().post(deleteChannelApi, params);
  const { data } = response;

  return data;
};

export { createChannel, deleteChannel };
