import { User } from '../../_types/memberTypes';
import theme from '../../pages/theme';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActualityMemberProps {
  user: User;
}

const ActualityMember: React.FC<ActualityMemberProps> = ({ user }) => {
  const { t } = useTranslation(['layout']);

  const initials =
    (user.firstName ? user.firstName.charAt(0) : '?') +
    (user.lastName ? user.lastName.charAt(0) : '?');
  let name = `${user.firstName || ''} ${user.lastName || ''}`;
  if (name === '') {
    name = t('layout:deletedUserName');
  }

  // @ts-ignore
  const useStyles = makeStyles(() => ({
    container: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      borderTop: '1px solid lightGrey',
      borderBottom: '1px solid lightGrey',
    },
    company: {
      fontSize: 12,
      fontWeight: 'bolder',
      color: theme.palette.secondary,
    },
    currentPosition: {
      fontSize: 12,
      color: theme.palette.secondary,
    },
    name: {
      color: 'black',
      fontWeight: 'bolder',
      fontSize: 12,
    },
    textZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar aria-label="recipe" src={user.img}>
        {initials}
      </Avatar>
      <div className={classes.textZone}>
        <Typography variant="subtitle2" className={classes.name}>
          {name}
        </Typography>
        {user.currentPosition && (
          <Typography variant="subtitle2" className={classes.currentPosition}>
            {user.currentPosition}
          </Typography>
        )}
        {user.company && (
          <Typography variant="subtitle2" className={classes.company}>
            {user.company}
          </Typography>
        )}
      </div>
    </div>
  );
};

ActualityMember.displayName = 'ActualityMember';
export default ActualityMember;
