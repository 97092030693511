import { ReactElementLike } from 'prop-types';
import React, { ReactElement } from "react";

export interface MembersButton {
  action: (x: any) => void;
  disabled: boolean;
  help: string;
  helpZone?: React.ReactNode;
  isUploadButton?: boolean;
  isVisible?: boolean;
  label: string;
  membersTab: number;
  type?: string;
}

export interface MembersTab {
  badgeTitle: string;
  isInvisible?: boolean;
  stat: number;
  title: string;
}

export interface MembersUploadButton {
  action: (...args: any[]) => any;
  disabled: boolean;
  help?: string;
  helpZone: { [x: string]: any } | null | undefined;
  label: string;
  membersTab: number;
}

export enum DisabledAction {
  'none' = 'none',
  'allStates' = 'allStates',
  'custom' = 'custom',
}

export interface ModalButton {
  customActions?: ReactElement;
  form?: ReactElement;
  noLabel: string;
  nodeText?: React.ReactNode;
  text?: string;
  title: string;
  waiting?: boolean;
  waitingForm?: ReactElement;
  yesAction: (...args: any[]) => any;
  yesActionDisabled?: DisabledAction;
  yesIsDisabledManually?: boolean;
  yesLabel: string;
}

export type ModalType =
  | 'acceptMembersModal'
  | 'deleteInvitationsModal'
  | 'inviteMembersInEventModal'
  | 'inviteMembersInGroupModal'
  | 'inviteMembersListModal'
  | 'reInviteMembersModal'
  | 'refuseMembersModal'
  | 'removeModal'
  | 'userBlockModal'
  | 'userUnblockModal'
  | 'updateMembersRoleToAdminModal'
  | 'updateMembersRoleToMemberModal';
