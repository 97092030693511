import theme from '../../pages/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import React from 'react';

const SelectField = (props) => {
  const { data, label, id, isRequired, value, onSelect, style, error } = props;

  const useStyles = makeStyles(() => ({
    formControl: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: 'white !important',
    },
  }));
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} style={style} error={error}>
      <InputLabel required={isRequired ?? false} id={`${id}-label`}>
        {label}
      </InputLabel>
      {data && (
        <Select
          labelId={`${id}-label`}
          id={id}
          name={id}
          autoWidth
          label={label}
          value={value || ''}
          onChange={(event) => {
            onSelect(
              {
                key: event.target.value,
                value: data.filter((elt) => elt.key === event.target.value)[0].value,
              },
              id
            );
          }}
        >
          {data.map((elt) => (
            <MenuItem key={elt.key} value={elt.key} classes={{ selected: classes.selected }}>
              {elt.value}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

SelectField.defaultProps = {
  value: null,
  data: [],
  error: false,
  isRequired: false,
};

SelectField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default SelectField;
