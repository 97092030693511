const apiFr = {
  // AccessType
  accessType_private: 'privé (sur invitation)',
  accessType_private_hidden: 'privé secret (invisible sur invitation)',
  accessType_public: 'public (ouvert)',
  accessType_public_validation: 'semi-public (avec validation)',

  // Event
  event_accessType: 'Type',
  event_createdDate: 'Date de création',
  event_endDate: 'Date de fin',
  event_name: 'Événement',
  event_registeredParticipants: 'Participants inscrits',
  event_startDate: 'Date de début',
  event_status: 'Statut',
  event_isFull: 'Complet',
  event_totalMembers: 'Participants',

  // Group
  group_accessType: "Type d'accès",
  group_createdDate: 'Date de création',
  group_name: 'Sous-communauté',
  group_published: 'Publié',
  group_enabled: 'Activé',
  group_size: 'Taille',
  group_totalMembers: 'Membres',

  // Member
  member_activitySector: "Secteur d'activité",
  member_communities: 'Communautés',
  member_company: 'Entreprise',
  member_createdDate: 'Invité',
  member_updatedDate: 'Invité',
  member_inscriptionDate: 'Embarqué',
  member_currentPosition: 'Poste actuel',
  member_email: 'Email',
  member_firstName: 'Prénom',
  member_groups: 'Sous-communautés',
  member_img: 'Image de profil',
  member_lastName: 'Nom',
  member_localisation: 'Localisation',
  member_objectives: 'Objectifs',
  member_professionalActivity: 'Statut',
  member_role: 'Role',
  member_roles: 'Roles',
  member_skills: 'Compétences',
  member_customDate: 'Date personnalisée',
  member_entryDate: 'Date d\'entrée',

  // Reco
  reco_authorName: 'Auteur',
  reco_targetName: 'Destinataire',
  reco_date: 'Date',
  reco_object: 'Objet',
  reco_status: 'Statut',
  reco_amount: 'Montant (€)'

};

export default apiFr;
