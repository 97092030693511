import App from './App';
import './i18n/i18n';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://1304380f9ea641b4803deea15eaa592d@o343374.ingest.sentry.io/4504253789831168",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,
});

ReactDOM.render(<App />, document.getElementById('root'));
