import { sessionContext } from '../../_hook/useSession';
import { regexHttpsHard, regexHttpsLight } from '../../constants/regex';
import WebStorageManagement from '../../helpers/WebStorageManager';
import Loader from '../generic/Loader';
import DisplayLink from '../links/DisplayLink';
import ActualityActions from './ActualityActions';
import ActualityFooter from './ActualityFooter';
import ActualityHeader from './ActualityHeader';
import ActualityMember from './ActualityMember';
import './index.scss';
import { Card, CardContent, Typography } from '@material-ui/core';
import he from 'he';
import { getLinkPreview } from 'link-preview-js';
import { replace, truncate } from 'lodash';
import capitalize from 'lodash/capitalize';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import ImageGrid from './imageGrid';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const CLASSNAME = 'actuality';

const Actuality = (props) => {
  const BASE_URL = 'https://iqqda8f3kh.execute-api.eu-west-3.amazonaws.com/dev?url=';
  const expHttp = regexHttpsLight;
  const regexHttps = regexHttpsHard;
  const urlScrapped = [];

  const { item, handleSelected, handleUpdate, handleDelete, color } = props;
  const { actuality, publisher, target, nuid } = item;
  const { sessionState } = React.useContext(sessionContext);
  const { user } = sessionState;
  const editable =
    item.actuality.type === 'cl' ||
    (item.actuality.type === 'member' && user.nuid === item.publisher.nuid);
  const { t } = useTranslation(['actuality', 'layout']);

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [expandedLines, setExpandedLines] = useState(false);
  const [showMore, setShowMore] = useState(false);


  function parseLink() {
    setIsLoading(true);
    if (!WebStorageManagement.getValue(`actualityData_${nuid}`)) {
      getLinkPreview(`${BASE_URL}${urlScrapped[0]}`, {
        headers: {
          apiKey: '',
        },
      })
        .then((data) => {
          WebStorageManagement.setValue(`actualityData_${nuid}`, {
            title: data.title,
            images: data.images,
          });
          setPreview(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setPreview(WebStorageManagement.getValue(`actualityData_${nuid}`));
      setIsLoading(false);
    }
    setShowMore(truncate(actuality.message,{ length: 150, omission: '' }) !== actuality.message
    );
  }

  useEffect(() => {
    function updateParentWidth() {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    }
    updateParentWidth(); // Initialize width on mount
    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  useEffect(() => {
    const cleanText = replace(actuality.message, /<[^>]+>/g, '');
    const httpArray = cleanText.split(expHttp);
    httpArray.map((row) => {
      if (row.match(regexHttps)) {
        urlScrapped.push(row);
      }

      return urlScrapped;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actuality.nuid]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (actuality.link) {
      setIsLoading(true);
      if (!WebStorageManagement.getValue(`actualityData_${nuid}`)) {
        getLinkPreview(`${BASE_URL}${actuality.link}`, {
          headers: {
            apiKey: '',
          },
        })
          .then((data) => {
            WebStorageManagement.setValue(`actualityData_${nuid}`, {
              title: data.title,
              images: data.images,
            });
            setPreview(data);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        setPreview(WebStorageManagement.getValue(`actualityData_${nuid}`));
        setIsLoading(false);
      }
    } else if (urlScrapped && Object.keys(urlScrapped).length > 0) {
      let mounted = true;
      if (mounted) {
        parseLink();
      }
      return () => {
        mounted = false;
      };
    }
    setShowMore(truncate(actuality.message, { length: 150, omission: '' }) !== actuality.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actuality.nuid]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const handleLineExpand = () => {
    setExpandedLines((prevState) => !prevState);
  };

  const getHostName = (link) => {
    if (link) {
      const url = new URL(link);
      return url.hostname;
    }
    return '';
  };

  // eslint-disable-next-line no-nested-ternary
  const imageToDisplay = () => {
    if (!isEmpty(actuality.pictures)) {
      return actuality.pictures;
    } else if (!isEmpty(actuality.picture)) {
        return [actuality.picture];
    } else if(!isEmpty(urlScrapped) || actuality.link || !isEmpty(preview)) {
      if (preview.images) {
        return [preview.images[0]];
      }
    }
    return [];
  };
  const messageActu = () =>
    expandedLines ? actuality.message : truncate(actuality.message, { length: 150, separator: ' ' });
  return (
    <Card className={CLASSNAME} variant="outlined">
      <ActualityActions
        nuid={nuid}
        status={actuality.status}
        firstName={publisher.firstName}
        lastName={publisher.lastName}
        editAction={actuality.link || !editable ? undefined : handleUpdate}
        deleteAction={handleDelete}
      />
      <ActualityHeader
        color={color}
        category={capitalize(actuality?.customCategory?.translation)}
        publishedDate={actuality.updatedDate}
        onClick={() => handleSelected(item)}
        className={`${CLASSNAME}_headerRoot`}
      />
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={`${CLASSNAME}_cardContent`} ref={parentRef}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={`${CLASSNAME}_textContent`}
            >
              <DisplayLink text={messageActu()} />
            </Typography>
            {showMore && (
              <Typography
                variant="body2"
                className={`${CLASSNAME}_showMore`}
                onClick={handleLineExpand}
                align="right"
                display="block"
              >
                {t(expandedLines ? 'layout:showLess' : 'layout:showMore')}
              </Typography>
            )}
            {!isEmpty(imageToDisplay()) && <ImageGrid imageUrls={imageToDisplay()} />}
            {!isEmpty(imageToDisplay()) && (!isEmpty(preview)) && (
              <div className={`${CLASSNAME}_contentFooter`}>
                <Typography variant="subtitle2" className={`${CLASSNAME}_contentText`}>
                  {preview.title && he.decode(preview.title)}
                </Typography>
                <Typography variant="subtitle2" className={`${CLASSNAME}_link`}>
                  {actuality.link
                    ? getHostName(actuality.link)
                    : getHostName(urlScrapped[0])}
                </Typography>
              </div>
            )}
            {!isEmpty(actuality.document) && (
              <Document file={actuality.document} className={`${CLASSNAME}_size`}>
                <Page pageNumber={1} width={parentWidth} />
              </Document>
            )}
            {!isEmpty(actuality.video) && (
              <video
                width={parentWidth}
                src={actuality.video}
              />
            )}
            {target && <ActualityMember user={target} />}
          </div>
        )}
      </CardContent>
      <div className={`${CLASSNAME}_footer`}>
        <ActualityFooter likes={actuality.likes} comments={actuality.comments} />
      </div>
    </Card>
  );
};

Actuality.defaultProps = {
  handleUpdate: null,
  handleDelete: null,
  color: '',
};

Actuality.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelected: PropTypes.func.isRequired,
  color: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default Actuality;
