import React from "react";
import { Community } from "../../_types/communityTypes";
import { Group } from "../../_types/groupTypes";
import { Button } from "@material-ui/core";
import TagCommunities from "../user/TagCommunities";
import { makeStyles } from "@material-ui/core/styles";
import { IEvent } from "../../_types/eventTypes";
import { useTranslation } from "react-i18next";
import './customButton.scss';

type CustomButtonProps = {
  classNameCondition?: boolean;
  highlight: boolean;
  entity: Community | Group | IEvent;
  entityName: 'event' | 'group' | 'community';
  isDisabled: boolean;
  handleClick: ( item: Community | Group | IEvent, entity: 'event' | 'group' | 'community') => Promise<void>;
}

const CLASSNAME = 'customButton';

const CustomButton = (props: CustomButtonProps) => {
  const {
    entityName,
    classNameCondition,
    entity,
    isDisabled,
    highlight,
    handleClick
  } = props;

  const { t } = useTranslation(['words']);
  const useStyles = makeStyles((theme) => ({
    customDisabled: {
      color: `${highlight ? theme.palette.primary.main : theme.palette.secondary.main} !important`,
    },
  }));
  const classes = useStyles();
  const disabled = entityName === 'group' && !entity?.enabled ? `(${t('words:disabled')})` : '';

  return (
    <div
      className={classNameCondition ? `${CLASSNAME}_element` : `${CLASSNAME}_element_selected`}
    >
      <Button
        color={highlight ? 'primary' : 'secondary'}
        classes={{
          disabled: classes.customDisabled,
        }}
        startIcon={entity && <TagCommunities items={[entity] || []} entity={entityName} />}
        style={{ fontWeight: 'bold' }}
        onClick={() => handleClick(entity, entityName)}
        disabled={isDisabled}
      >
        {entity && `${entity.name} ${disabled}`}
      </Button>
    </div>
  );
}

export default CustomButton;
