import SuccessButton from '../../components/generic/SuccessButton';
import { POST_COMMUNITY_UPDATE } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { encodeImageFileAsURL } from '../../helpers/Tools';
import getDefaultStyles from '../getDefaultStyles';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityDescriptionStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep, data, allData } = props;
  const { t } = useTranslation(['words', 'register']);
  const [description, setDescription] = useState(data.name);
  const [waiting, setWaiting] = useState(false);
  const [isValidLength, setIsValidLength] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setDescription(value);
    setIsValidLength(value.length >= 20);
  };

  const updateCommunity = async () => {
    setWaiting(true);
    const community = {
      fromDashboard: true,
      nuid: allData[7].communityNuid,
      description,
      skills: allData[11].selectedFocus.map((item) => item.key),
    };
    if (allData[9].logo !== undefined) {
      community.logo = await encodeImageFileAsURL(allData[9].logo);
    }
    if (allData[10].coverPicture !== undefined) {
      community.coverPicture = await encodeImageFileAsURL(allData[10].coverPicture);
    }
    apiRest()
      .post(POST_COMMUNITY_UPDATE, community)
      .then(() => {
        nextStep({ description });
      })
      .catch((error = null) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
        setWaiting(false);
      });
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '50px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.question}>{t('register:descriptionTitle')}</div>
      <div className={classes.content}>
        <TextField
          className={classes.formControl}
          error={description && !isValidLength}
          id="description"
          multiline
          rowsMax={4}
          rows={4}
          label={t('register:enterDescription')}
          value={description || ''}
          helperText={
            description && !isValidLength ? t('register:communityDescriptionTooShort') : ''
          }
          onChange={(event) => handleChange(event)}
        />
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => backStep({ description })}
          >
            {t('previous')}
          </Button>
        )}
        {!lastStep &&
          (waiting ? (
            <CircularProgress />
          ) : (
            <SuccessButton
              size="large"
              onClick={updateCommunity}
              disabled={description && description.length < 20}
              label={description ? t('words:next') : t('words:later')}
            />
          ))}
      </div>
    </>
  );
};

CommunityDescriptionStep.defaultProps = {
  data: {},
};

CommunityDescriptionStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  allData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CommunityDescriptionStep;
