import { Theme } from '@mui/material';

interface DefaultStyles {
  [key: string]: object;
}

export default function getDefaultStyles(theme: Theme): DefaultStyles {
  return {
    title: {
      fontSize: '2em',
      fontWeight: 'bolder',
      textAlign: 'center',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5em',
        marginTop: 0,
      },
    },
    leftTitle: {
      fontSize: '2em',
      fontWeight: 'bolder',
      textAlign: 'left',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5em',
      },
    },
    subTitle: {
      fontSize: '1.6em',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
    },
    leftSubTitle: {
      fontSize: '1.6em',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
    },
    question: {
      textAlign: 'center',
      fontSize: '1.4em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
    },
    content: {
      fontSize: '1.2em',
      width: '100%',
      paddingTop: theme.spacing(1),
      whiteSpace: 'pre-wrap',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '20px',
      },
    },
    loader: {
      marginTop: (theme.spacing(1) as unknown as number) / 2,
      marginBottom: (theme.spacing(1) as unknown as number) / 2,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    formControl: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    wideTopMarge: {
      marginTop: theme.spacing(4),
    },
    wideLeftMarge: {
      marginLeft: theme.spacing(4),
    },
    scBox: {
      width: '100%',
      border: 'solid 1px lightGray',
      backgroundColor: 'white',
    },
    toolBox: {
      width: '100%',
      borderBottom: 'solid 1px lightGray',
      backgroundColor: 'white',
    },
    scBoxNoBorder: {
      width: '100%',
      backgroundColor: 'white',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  };
}
