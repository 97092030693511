import renameKeys from '../../helpers/renameKeys';
import './index.scss';
import { Tooltip } from "@material-ui/core";
import {
  DataGrid,
  frFR,
  GridCallbackDetails,
  GridColDef, GridFilterModel, GridRenderCellParams,
  GridSortModel, GridRowModel
} from "@mui/x-data-grid";
import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'table';

interface TableProps {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  disableSelectionOnClick?: boolean;
  isLoading?: boolean;
  disableColumnFilter: boolean;
  disableColumnMenu: boolean;
  noRowsMessage?: ReactNode;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSelectionModelChange?: any;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void
  onCellEditStop?: any;
  onCellEditCommit?: any;
  page: number;
  pageSize?: number;
  rowCount: number;
  rows: any[];
  rowsPerPageOptions?: number[];
  selectionModel?: any[];
  sortableColumns?: string[];
  sortModel?: { field: string; sort: GridSortDirection }[];
  processRowUpdate?: (newRow: GridRowModel, oldRow?: GridRowModel) => GridRowModel;
}


const Table: React.FC<TableProps> = ({
  checkboxSelection = true,
  columns,
  disableSelectionOnClick = true,
  disableColumnMenu = true,
  disableColumnFilter = true,
  isLoading = false,
  noRowsMessage,
  onPageChange,
  onPageSizeChange,
  onSelectionModelChange,
  onSortModelChange,
  onFilterModelChange,
  onCellEditStop,
  onCellEditCommit,
  processRowUpdate,
  page,
  pageSize = 10,
  rowCount,
  rows,
  rowsPerPageOptions = [10, 20, 50, 100],
  selectionModel,
  sortableColumns = [],
  sortModel,
}) => {
  const { t } = useTranslation(['api']);

  const loading = React.useState(false)[0];
  const [newColumns, setNewColumns] = React.useState<any[]>([]);
  const [newRows, setNewRows] = React.useState<any[]>([]);

  const MyRenderCell = React.memo((params: GridRenderCellParams) => {
    if (typeof params.value !== 'object') {
      const { align } = params.colDef;
      return (
        <>
          <Tooltip title={params?.value ?? ''} arrow>
            <p
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: align,
              }}
            >
              {params?.value}
            </p>
          </Tooltip>
        </>
      );
    }
    return <></>;
  });

  React.useEffect(() => {
    const columnWithoutDuplicates: GridColDef[] = [];
    columns.forEach((column) => {
      if (!columnWithoutDuplicates.find((col) => col.headerName === column.headerName)) {
        columnWithoutDuplicates.push(column);
      }
    });

    const newClmns: GridColDef[] = [];
    columns.forEach((column, index) => {
      newClmns.push({
        ...column,
        field: t(`api:${columns[index].field}`),
        headerName: t(`api:${columns[index].headerName}`),
        width: column.width,
        flex: column.width ? 0 : 1,
        sortable: !!sortableColumns?.find((element) => element === column.field),
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderCell: column.renderCell || ((params) => <MyRenderCell {...params} />),
      });
    });
    setNewColumns(newClmns);

    const newKeys: any = {};
    if (rows && rows.length > 0) {
      rows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          newKeys[key] = t(key);
        });
      });
    }
    const rowsWithNewKeys: any[] = [];
    rows.forEach((row) => {
      rowsWithNewKeys.push(renameKeys(row, newKeys));
    });

    const rowsWithNewValues: any[string] = [];
    rowsWithNewKeys.forEach((row) => {
      let newValues = {};
      Object.entries(row).forEach(([key, value]) => {
        newValues[key] = value?.value || value;
      });
      rowsWithNewValues.push(newValues);
    });
    setNewRows(rowsWithNewValues);
  }, [columns, rows, t]);

  return (
    <div className={CLASSNAME}>
      <DataGrid
        checkboxSelection={checkboxSelection}
        columns={newColumns}
        components={{
          NoRowsOverlay: () => <>{noRowsMessage}</>,
        }}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        disableSelectionOnClick={disableSelectionOnClick}
        hideFooterPagination
        keepNonExistentRowsSelected
        loading={isLoading || loading}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        onPageChange={(newPage) => onPageChange(newPage + 1)}
        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
        onSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        onCellEditStop={onCellEditStop}
        onCellEditCommit={onCellEditCommit}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        rows={newRows}
        rowsPerPageOptions={rowsPerPageOptions}
        selectionModel={selectionModel}
        sortModel={sortModel}
        sortingMode="server"
        filterMode="server"
        hideFooter={true}
        disableColumnSelector={true}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
      />
    </div>
  );
};

Table.displayName = 'Table';
export default Table;
