import theme from '../../pages/theme';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActualityFooterProps {
  likes: number;
  comments: number;
}

const ActualityFooter: React.FC<ActualityFooterProps> = ({ likes = 0, comments = 0 }) => {
  const { t } = useTranslation(['actuality']);
  const useStyles = makeStyles(() => ({
    container: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 2,
      paddingBottom: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 12,
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <ThumbUpIcon color="primary" fontSize="small" style={{ marginRight: 8 }} />
        <Typography variant="subtitle2">{likes}</Typography>
      </div>
      <div className={classes.container}>
        <Typography variant="subtitle2">{t('actuality:comments', { count: comments })}</Typography>
      </div>
    </div>
  );
};

ActualityFooter.displayName = 'ActualityFooter';
export default ActualityFooter;
