import useActuality from "../../_hook/useActuality";
import useMember from "../../_hook/useMember";
import { sessionContext } from "../../_hook/useSession";
import { ActualityType } from "../../_types/actualityTypes";
import { ActualityAndAgoraCategory } from "../../_types/sessionTypes";
import Actuality from "../../components/actuality/Actuality";
import WaitingForm from "../../components/form/WaitingForm";
import ItemsList from "../../components/generic/itemsList/ItemsList";
import ToolBarItemButton from "../../components/generic/ToolBarItemButton";
import YesNoModal, { Sizes } from "../../components/generic/YesNoModal";
import getPublishedStatus from "../../helpers/getPublishedStatus";
import EventHeader from "../events/EventHeader";
import ActualityForm from "./ActualityForm";
import ActualitiesEmptyState from "./emptyState";
import "./index.scss";
import { ActualityFilter } from "./type";
import { Chip } from "@material-ui/core";
import { upperCase } from "lodash";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../components/generic/progressBar";
import { DisabledAction } from "../members/types";
import getEnabledStatus from "../../helpers/getEnabledStatus";

interface ActualitiesProps {
  isAgora: boolean;
}

const CLASSNAME = 'actualities';

const Actualities: React.FC<ActualitiesProps> = ({ isAgora = false }) => {
  const { t } = useTranslation(['words', 'layout', 'message']);

  // useSession
  const { sessionState, setPageIsLoading } = React.useContext(sessionContext);
  const { pageIsLoading, selectedCommunity, selectedEvent, selectedGroup } = sessionState;
  const selectedNuid = selectedEvent?.nuid || selectedGroup?.nuid || selectedCommunity?.nuid;

  // useMember
  const { membersState, getMembers } = useMember();

  // useActuality
  const {
    actualityState,
    addActuality,
    changeActualitiesPage,
    changeActualitiesMaxPerPage,
    getActualities,
    removeActuality,
    updateActuality,
  } = useActuality();
  const { actualities, actualitiesMaxPerPage, actualitiesPage } = actualityState;

  // Modals
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  // Items
  const [itemToUpdate, setItemToUpdate] = React.useState(null);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState<ActualityFilter[]>([]);

  // users
  const [usersTotal, setUsersTotal] = React.useState<number | null>(null);

  // progressBar
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Constants
  const actualityType = isAgora ? ActualityType.member : ActualityType.cl;

  // Modals actions
  const openUpdateModal = (item: any) => {
    setItemToUpdate(item);
    setIsUpdateModalOpen(true);
  };
  const closeUpdateModal = () => {
    setItemToUpdate(null);
    setIsUpdateModalOpen(false);
  };
  const openDeleteModal = (item: any) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setItemToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const updateData = async (newPage: number, itemsPerPage: number, search: string) => {
    changeActualitiesPage(newPage);
    changeActualitiesMaxPerPage(itemsPerPage);
    const returnedFilters: string[] = [];
    selectedFilters.forEach((f: ActualityFilter) => {
      if (f.isSelected) {
        returnedFilters.push(f.key);
      }
    });
    await getActualities(
      selectedGroup?.nuid || selectedCommunity?.nuid,
      selectedEvent?.nuid,
      returnedFilters,
      isAgora ? ActualityType.member : ActualityType.cl,
      newPage,
      itemsPerPage,
      search
    );
  };

  // Filter
  const selectFilter = async (filter: ActualityFilter, isRefresh = false) => {
    const filters = selectedFilters;
    if (filter.key !== 'all') {
      filters[filters.findIndex((f: ActualityFilter) => f.key === filter.key)].isSelected =
        !filter?.isSelected;
      filters[filters.findIndex((f: ActualityFilter) => f.key === 'all')].isSelected = false;
    } else {
      filters.forEach((f: ActualityFilter) => {
        filters[filters.findIndex((fil: ActualityFilter) => fil.key === f.key)].isSelected =
          f.key === 'all';
      });
    }
    if (filters.find((f: ActualityFilter) => f.isSelected) === undefined) {
      filters[filters.findIndex((f: ActualityFilter) => f.key === 'all')].isSelected = true;
    }
    setSelectedFilters([...filters]);
    if (isRefresh) {
      await updateData(actualityState.actualitiesPage, actualityState.actualitiesMaxPerPage, '');
    }
  };

  const filterButtonAction = async (isApply: boolean) => {
    const filters = selectedFilters;
    if (isApply) {
      filters[filters.findIndex((f: ActualityFilter) => f.key === 'all')].isSelected = false;
    } else {
      filters.forEach((f: ActualityFilter) => {
        filters[filters.findIndex((fil: ActualityFilter) => fil.key === f.key)].isSelected =
          f.key === 'all';
      });
    }
    const thereAreNoSelectedOne = [];
    filters.forEach((filter) => {
      if (filter.isSelected) {
        thereAreNoSelectedOne.push(filter);
      }
    });
    if (thereAreNoSelectedOne.length === 0) {
      await selectFilter(
        {
          isSelected: true,
          key: 'all',
          translation: 'all',
          color: '#f29c49',
        },
        false
      );
    }
    setSelectedFilters(filters);

    const returnedFilters: string[] = [];
    selectedFilters.forEach((f: ActualityFilter) => {
      if (f.isSelected) {
        returnedFilters.push(f.key);
      }
    });

    await getActualities(
      selectedGroup?.nuid || selectedCommunity?.nuid,
      selectedEvent?.nuid,
      returnedFilters,
      isAgora ? ActualityType.member : ActualityType.cl,
      actualitiesPage,
      actualitiesMaxPerPage
    );
  };

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    setLoadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  };

  // Actions
  const changeActuality = async (params: any) => {
    await updateActuality(
      selectedGroup?.nuid || selectedCommunity?.nuid,
      actualityType,
      params,
      selectedEvent?.nuid,
      onUploadProgress
    );

    await selectFilter(
      {
        isSelected: false,
        key: params.category,
        translation: params.category,
        color: params.color,
      },
      true
    );
    closeUpdateModal();
  };

  const createActuality = async (params: any) => {
    await addActuality(selectedGroup?.nuid || selectedCommunity?.nuid, params, selectedEvent?.nuid);
    await selectFilter(
      {
        isSelected: false,
        key: params.category,
        translation: params.category,
        color: params.color,
      },
      true
    );
    setIsCreateModalOpen(false);
  };

  const deleteActuality = async () => {
    await removeActuality(
      selectedGroup?.nuid || selectedCommunity?.nuid,
      itemToDelete,
      selectedEvent?.nuid
    );
    await updateData(actualityState.actualitiesPage, actualityState.actualitiesMaxPerPage, '');
    closeDeleteModal();
  };

  // Effects
  React.useEffect(() => {
    (async () => {
      setPageIsLoading(true);
      if (
        (selectedCommunity && selectedCommunity.parameters?.admin) ||
        (selectedGroup && selectedGroup.parameters?.admin)
      ) {
        await getActualities(
          selectedGroup?.nuid || selectedCommunity?.nuid,
          selectedEvent?.nuid,
          ['all'],
          isAgora ? ActualityType.member : ActualityType.cl,
          actualitiesPage,
          actualitiesMaxPerPage
        );
        await getMembers(selectedNuid);
      }
      setPageIsLoading(false);
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedNuid]);

  React.useEffect(() => {
    setUsersTotal(membersState?.members?.total);
  }, [membersState.members]);

  React.useEffect(() => {
    const disabledCategories = ['new member', 'new attendee', 'new event'];
    const filters: ActualityFilter[] = [];
    if (selectedCommunity) {
      const element =
        isAgora && selectedCommunity.agoraCategories?.length > 0
          ? selectedCommunity.agoraCategories
          : selectedCommunity.actualityCategories?.length > 0 &&
            selectedCommunity.actualityCategories;
      element?.forEach((c: ActualityAndAgoraCategory) => {
        if (!disabledCategories.includes(c.key)) {
          filters.push({
            isSelected: false,
            key: c.key,
            translation: c.translation,
            color: c.color,
          });
        }
      });
    }
    setSelectedFilters([
      {
        isSelected: true,
        key: 'all',
        translation: 'tout',
        color: '#f29c49',
      },
      ...filters,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgora, selectedCommunity, selectedGroup]);

  return (
    <div className={CLASSNAME}>
      { loadingProgress > 0 && loadingProgress < 100 && <ProgressBar loadingProgress={loadingProgress} />}
      <YesNoModal
        fullWidth
        size={Sizes.lg}
        yesAction={createActuality}
        noAction={() => setIsCreateModalOpen(false)}
        form={
          <ActualityForm
            fields={
              isAgora ? selectedCommunity?.agoraCategories : selectedCommunity?.actualityCategories
            }
            communityOrGroupId={selectedNuid}
            total={usersTotal}
          />
        }
        yesLabel={t('words:publish')}
        yesActionDisabled={DisabledAction.allStates}
        noLabel={t('words:cancel')}
        title={t('actuality:createActuality')}
        open={isCreateModalOpen}
        waitingForm={<WaitingForm message={t('actuality:waitingActuality')} />}
        waiting={pageIsLoading}
        changeHeight
      />
      <YesNoModal
        fullWidth
        yesAction={changeActuality}
        yesActionDisabled={DisabledAction.allStates}
        noAction={closeUpdateModal}
        form={
          <ActualityForm
            fields={
              isAgora ? selectedCommunity?.agoraCategories : selectedCommunity?.actualityCategories
            }
            communityOrGroupId={selectedNuid}
            total={usersTotal}
          />
        }
        size={Sizes.lg}
        item={itemToUpdate}
        yesLabel={t('words:save')}
        noLabel={t('words:cancel')}
        title={t('actuality:updateActuality')}
        open={isUpdateModalOpen}
        waitingForm={<WaitingForm message={t('actuality:waitingActuality')} />}
        waiting={pageIsLoading}
      />
      <YesNoModal
        size={Sizes.md}
        yesAction={deleteActuality}
        noAction={closeDeleteModal}
        item={itemToDelete}
        yesLabel={t('words:delete')}
        noLabel={t('words:cancel')}
        title={t('actuality:deleteActuality')}
        text={<p>{t('actuality:deleteActualityConfirm')}</p>}
        open={isDeleteModalOpen}
      />
      <div className={`${CLASSNAME}_toolBox`}>
        {selectedEvent && <EventHeader event={selectedEvent} />}
        <div className={`${CLASSNAME}_title`}>
          <h1>
            {isAgora
              ? capitalize(t('words:discussions'))
              : selectedEvent
              ? upperCase(t('words:live')) + ' !'
              : capitalize(t('words:actualities'))}
          </h1>
          {!isAgora && !getPublishedStatus(selectedEvent, selectedGroup, selectedCommunity) && (
            <p>
              {' - '}
              {capitalize(
                t('actuality:requiredFields', {
                  entity: selectedEvent ? 'événement' : selectedGroup ? 'sous-communauté' : 'communauté',
                })
              )}
            </p>
          )}
        </div>
        <span className={`${CLASSNAME}_filterLabel`}>
          {capitalize(t('actuality:filterActualities'))}
        </span>
        <div className={`${CLASSNAME}_actions`}>
          <div className={`${CLASSNAME}_tags`}>
            {selectedFilters.map((f: ActualityFilter) => (
              <Chip
                key={f.key}
                style={{ backgroundColor: f.isSelected ? f.color : '#fff' }}
                variant="default"
                className={f.isSelected ? `${CLASSNAME}_tagSelected` : `${CLASSNAME}_tag`}
                label={capitalize(f.translation)}
                onClick={async () => {
                  await selectFilter(f);
                  await filterButtonAction(true);
                }}
              />
            ))}
          </div>
          {!isAgora && (
            <div className={`${CLASSNAME}_buttons`}>
              <ToolBarItemButton
                label={capitalize(t(`actuality:publishActuality`))}
                action={() => setIsCreateModalOpen(true)}
                disabled={
                  !getPublishedStatus(selectedEvent, selectedGroup, selectedCommunity) ||
                  !getEnabledStatus(selectedEvent, selectedGroup, selectedCommunity)
                }
                help={
                  !getPublishedStatus(selectedEvent, selectedGroup, selectedCommunity)
                    ? t('layout:whyNotPublish')
                    : null
                }
              />
            </div>
          )}
        </div>
      </div>
      {!pageIsLoading && (
        <div className={`${CLASSNAME}_list`}>
          {actualities?.length === 0 ? (
            <ActualitiesEmptyState isAgora={isAgora} />
          ) : (
            <ItemsList
              items={actualities}
              categories={
                isAgora
                  ? selectedCommunity?.agoraCategories
                  : selectedCommunity?.actualityCategories
              }
              totalItems={actualityState.total}
              component={Actuality}
              updateData={updateData}
              currentPage={actualitiesPage}
              maxPerPage={actualitiesMaxPerPage}
              handleUpdateItem={openUpdateModal}
              handleDeleteItem={openDeleteModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

Actualities.displayName = 'ActualitiesList';

export default Actualities;
