import { GroupsInformation, GroupsParams, GroupState } from '../_types/groupTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

export const initialState: GroupState = {
  groupsInformation: {
    total: 0,
    groups: [],
  },
  groupsParams: {
    page: 1,
    maxPerPage: 50,
    search: '',
    isAdmin: true,
    desc: true,
    orderBy: 'createdDate',
  },
};

const reducers = {
  setGroupsInformation: (state: any, action: PayloadAction<GroupsInformation>): void => {
    set(state, 'groupsInformation', action.payload);
  },
  setGroupsParams: (state: any, action: PayloadAction<GroupsParams>): void => {
    set(state, 'groupsParams', action.payload);
  },
};

export const { actions, reducer } = createSlice({
  name: 'groups',
  initialState,
  reducers,
});
