import SuccessButton from '../../components/generic/SuccessButton';
import getDefaultStyles from '../getDefaultStyles';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CommunityLogoStep = (props) => {
  const sizeMax = 200000;
  const { firstStep, lastStep, backStep, nextStep, data } = props;
  const [logo, setLogo] = useState(data.logo);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation(['words', 'register', 'layout']);
  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  const handleChange = (event) => {
    const { files } = event.target;
    setLogo(files[0]);
    if (files[0].size <= sizeMax) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <div className={classes.question}>{t('register:selectLogo')}</div>
      <div>{`${t('layout:sizeMax', { size: '200 Ko' })}, ${t('layout:logoFormat')}`}</div>
      <div className={classes.content}>
        <label htmlFor="upload-photo">
          <input
            style={{ display: 'none' }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            onChange={(event) => handleChange(event)}
          />

          <Button color="secondary" variant="contained" component="span">
            {t('register:uploadLogo')}
          </Button>
          {logo && (
            <div className={classes.logoContainer}>
              <div>
                <img src={URL.createObjectURL(logo)} alt="selected logo" width="200px" />
              </div>
              <div>
                <ul>
                  <li>{logo.name}</li>
                  <li style={{ color: isValid ? 'green' : 'red' }}>
                    {`${!isValid ? t('layout:tooLarge') : ''} ${Math.round(logo.size / 1000)} Ko`}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </label>
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => backStep({ logo })}
          >
            {t('words:previous')}
          </Button>
        )}
        {!lastStep && (
          <SuccessButton
            size="large"
            onClick={() => nextStep({ logo })}
            label={isValid ? t('words:next') : t('words:later')}
          />
        )}
      </div>
    </>
  );
};

CommunityLogoStep.defaultProps = {
  data: {},
};

CommunityLogoStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityLogoStep;
