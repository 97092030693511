import SuccessButton from '../../components/generic/SuccessButton';
import { GET_SECURITY_DICTIONARY } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import { isDev } from '../../helpers/Tools';
import getDefaultStyles from '../getDefaultStyles';
import { Button, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CommunityActivitySectorStep = (props) => {
  const { firstStep, lastStep, backStep, nextStep, data } = props;
  const { t } = useTranslation(['words', 'register']);
  const [activitySectorItems, setActivitySectorItems] = useState(data.activitySectorItems);
  const [activity, setActivity] = useState(
    data.activity || (isDev() && { key: 'General', value: 'Général' })
  );
  const [inputValue, setInputValue] = useState('');

  const getDictionary = () => {
    const headers = {
      'X-Device': 'desktop',
    };
    apiRest()
      .get(`${GET_SECURITY_DICTIONARY}/activitySector`, { headers })
      .then((response) => {
        const activitySector = response.data.content;
        setActivitySectorItems(activitySector);
      })
      .catch((error) => {
        const { errorCode } = error && error.response !== undefined && error.response.data;
        let key;
        switch (errorCode) {
          case -1:
            key = 'register:errorLoadingDictionary';
            break;
          default:
            key = 'register:unknownError';
        }
        toast.error(t(key));
      });
  };

  const handleChange = (value) => {
    if (value !== null) {
      setActivity(value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    ...getDefaultStyles(theme),
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getDictionary();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.question}>{t('register:selectActivitySector')}</div>
      <div className={classes.content}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            className={classes.selectEmpty}
            id="select-activity-sector"
            value={activity || null}
            inputValue={inputValue}
            options={activitySectorItems.map((item) => ({
              key: item.key,
              value: item.value,
            }))}
            onChange={(event, newValue) => handleChange(newValue)}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.value || ''}
            getOptionSelected={(option, value) => value.value === option.value}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            renderInput={(params) => (
              <TextField {...params} label={t('register:activitySectorFocus')} variant="outlined" />
            )}
          />
        </FormControl>
      </div>
      <div className={classes.buttons}>
        {!firstStep && (
          <Button
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => backStep({ activity, activitySectorItems })}
          >
            {t('words:previous')}
          </Button>
        )}
        {!lastStep && (
          <SuccessButton
            size="large"
            disabled={!activity}
            onClick={() => nextStep({ activity, activitySectorItems })}
            label={t('words:next')}
          />
        )}
      </div>
    </>
  );
};

CommunityActivitySectorStep.defaultProps = {
  data: {
    activitySectorItems: [],
  },
};

CommunityActivitySectorStep.propTypes = {
  firstStep: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default CommunityActivitySectorStep;
