import defaultAvatar from '../../assets/images/default_avatar.png';
import './index.scss';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'membersEmptyState';

interface MembersEmptyStateProps {
  isHome?: boolean;
  isEvent?: boolean;
  message?: string;
  image?: any;
}

const EmptyState: React.FC<MembersEmptyStateProps> = ({
  isHome = false,
  isEvent = false,
  message,
  image,
}) => {
  const { t } = useTranslation(['members']);
  return (
    <div
      className={CLASSNAME}
      style={{
        justifyContent: isHome ? 'start' : 'center',
      }}
    >
      {isHome ? (
        (isEvent && t('noParticipantYet')) || t('noMemberYet')
      ) : (
        <div>
          {image ? (
            <div className={`${CLASSNAME}_avatar`}>{image}</div>
          ) : (
            <img alt={message || 'noMemberYet'} src={defaultAvatar} />
          )}
          <h4>{capitalize(message) || (isEvent && t('noParticipantYet')) || t('noMemberYet')}</h4>
        </div>
      )}
    </div>
  );
};

EmptyState.displayName = 'EmptyState';

export default EmptyState;
