import './index.scss';
import { TablePagination } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ScPaginatorProps {
  page: number;
  returnChangePage: Function;
  returnChangeRowsPerPage: Function;
  rowsPerPage: number;
  totalItems: number;
}

const CLASSNAME = 'paginator';

const ScPaginator: React.FC<ScPaginatorProps> = ({
  page,
  returnChangePage,
  returnChangeRowsPerPage,
  rowsPerPage,
  totalItems,
}) => {
  const { t } = useTranslation('layout');

  const handleChangePage = (event: Event, newPage: number) => {
    returnChangePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    returnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <div className={CLASSNAME}>
      <TablePagination
        component="div"
        count={totalItems || 0}
        labelRowsPerPage={t('itemsByPage')}
        page={page - 1}
        onPageChange={handleChangePage as any}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
};

ScPaginator.displayName = 'ScPaginator';

export default ScPaginator;
