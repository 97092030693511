import { regexHttpsLight, regexMail } from '../../constants/regex';
import theme from '../../pages/theme';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const DisplayLink = (props) => {
  const { text } = props;
  const expHttp = regexHttpsLight;
  const expMail = regexMail;
  const cleanText = text?.replace(/<[^>]+>/g, '');
  const httpResultArray = cleanText?.split(expHttp);
  const useStyles = makeStyles(() => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      border: 'none',
      background: 'none',
      display: 'flex',
      textAlign: 'start',
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();
  return httpResultArray?.map((row) => {
    if (row.match(expHttp) !== null) {
      return (
        <span key={row}>
          <button className={classes.link} onClick={() => window.open(row, '_blank')?.focus()}>
            {row}
          </button>
        </span>
      );
    }
    const mailResultArray = row.split(expMail);
    return mailResultArray.map((itemMail) => {
      if (itemMail.match(expMail) !== null) {
        return (
          <span key={itemMail}>
            <a className={classes.link} href={`mailto:${itemMail}`}>
              {itemMail}
            </a>
          </span>
        );
      }
      return <span key={itemMail}>{itemMail}</span>;
    });
  });
};

export default DisplayLink;
