import React from 'react';
import './index.scss';
import {addThumbSuffixToImageUrl} from "../../../helpers/Tools";
import * as url from "url";
import FallbackImg from "../../generic/FallbackImg";

interface ImageGridProps {
  imageUrls: string[];
}

const ImageGrid: React.FC<ImageGridProps> = ({ imageUrls }) => {
  const allUrls = imageUrls.map((url) => (
    {
      thumbnailUrl: addThumbSuffixToImageUrl(url),
      url: url,
    })
  );
  const remainingImagesCount = imageUrls.length > 5 ? imageUrls.length - 5 : 0;
  const renderImage = (source: string, fallbackSource: string, index: number, style: string) => (
    <div key={index} className="image-wrapper">
      <FallbackImg src={source} fallbackSrc={fallbackSource} className={style} alt={`image-${index}`} />
      {index === 4 && remainingImagesCount > 0 && (
        <div className="remaining-images">
          <span className="remaining-images-text">+{remainingImagesCount}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className="container">
      <div className="row">
        {allUrls.slice(0, 2).map((url, index) => renderImage(url.thumbnailUrl, url.url, index, 'image'))}
      </div>
      {imageUrls.length > 2 && (
        <div className="row">
          {allUrls.slice(2, 5).map((url, index) => renderImage(url.thumbnailUrl, url.url, index + 2, 'image'))}
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
