import { sessionContext } from '../../_hook/useSession';
import SuccessButton from '../../components/generic/SuccessButton';
import { POST_COMMUNITY_UPDATE } from '../../config/api';
import apiRest from '../../helpers/apiRest';
import getDefaultStyles from '../getDefaultStyles';
import theme from '../theme';
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const CommunityReferencingStep = (props) => {
  const { allData, nextStep } = props;
  const { t } = useTranslation(['words', 'register']);
  const [waiting, setWaiting] = useState(false);
  const { setUser, getAdminCommunities } = React.useContext(sessionContext);
  const navigate = useNavigate();

  async function goHome(edit = false) {
    if (isMobile) {
      nextStep();
    } else {
      setWaiting(true);
      let indice = 2;
      if (allData[1].user !== undefined) {
        indice = 1;
      }
      setUser({
        user: allData[indice].user,
        token: allData[indice].token,
        refreshToken: allData[indice].refreshToken,
      });

      await getAdminCommunities(true);
      let path = '/?new=true';
      if (edit) {
        path = '/?edit=true';
      }
      navigate(path);
    }
  }

  async function updateCommunity(published) {
    setWaiting(true);
    const community = {
      fromStepper: true,
      fromDashboard: true,
      nuid: allData[7].communityNuid,
      published,
    };
    try {
      await apiRest().post(POST_COMMUNITY_UPDATE, community);
      if (published) {
        toast.success(t('register:referenceSuccess'));
      }
    } catch (error) {
      const { errorCode } = error && error.response !== undefined && error.response.data;
      let key;
      switch (errorCode) {
        default:
          key = 'register:unknownError';
      }
      toast.error(t(key));
      setWaiting(false);
    }
  }

  const isSmallScreen = useMediaQuery((currentTheme) => currentTheme.breakpoints.down('xs'));
  const communityComplete =
    allData[9] && allData[9].logo && allData[10] && allData[10].coverPicture;

  const useStyles = makeStyles((currentTheme) => ({
    ...getDefaultStyles(currentTheme),
    warning: {
      paddingTop: 20,
      fontStyle: 'italic',
      textAlign: 'center',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '100px',
      textAlign: 'center',
      color: theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
      },
      justifyContent: isMobile && !communityComplete ? 'center' : 'space-between',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>{t('words:congrats')}</div>
      <div
        className={`${classes.subTitle} ${classes.selectEmpty}`}
        style={{ fontWeight: 'bolder' }}
      >
        {t('register:endOfCommunityCreation')}
      </div>
      <div className={classes.selectEmpty}>
        <span className={classes.content}>
          {communityComplete
            ? t('register:registerCommunityNow')
            : t('register:registerCommunityLater')}
        </span>
      </div>
      <div
        className={classes.subTitle}
        style={{ fontWeight: 400, textAlign: 'left', paddingTop: '1.2em' }}
      >
        {t('register:benefits')}
      </div>
      <div className={classes.content} style={{ paddingTop: 0 }}>
        <ul>
          <li>{t('register:benefit1')}</li>
          <li>{t('register:benefit2')}</li>
          <li>{t('register:benefit3')}</li>
          <li>{t('register:benefit4')}</li>
        </ul>
      </div>
      <div className={classes.warning}>
        <span style={{ fontWeight: 600 }}>{t('words:Important')}</span>
        &nbsp;:&nbsp;
        {t('register:dashboardWarning')}
      </div>
      <div className={classes.buttons} style={{ paddingTop: '1em' }}>
        <Button
          style={
            isMobile
              ? { backgroundColor: theme.palette.success.main, color: 'white' }
              : { backgroundColor: 'white', color: theme.palette.success.main }
          }
          variant={isMobile ? 'contained' : 'outlined'}
          size={isSmallScreen ? 'small' : 'large'}
          onClick={() => {
            updateCommunity(false);
            goHome(false).then();
          }}
          disabled={waiting}
        >
          {!communityComplete ? t('words:next') : t('register:later')}
        </Button>
        {!isMobile && !communityComplete && (
          <SuccessButton
            color="primary"
            size={isSmallScreen ? 'small' : 'large'}
            onClick={() => {
              updateCommunity(false);
              goHome(true).then();
            }}
            label={t('register:editMyCommunity')}
          />
        )}
        {communityComplete &&
          (waiting ? (
            <CircularProgress />
          ) : (
            <SuccessButton
              color="primary"
              size={isSmallScreen ? 'small' : 'large'}
              onClick={() => {
                updateCommunity(true);
                goHome(false).then();
              }}
              label={t('register:referenceMyCommunity')}
            />
          ))}
      </div>
    </>
  );
};

CommunityReferencingStep.propTypes = {
  allData: PropTypes.arrayOf(PropTypes.any).isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default CommunityReferencingStep;
