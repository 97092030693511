import theme from '../../../theme';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ImportMembersFormFooter = (props) => {
  const { exportStates, sendEmail, setSendEmail } = props;
  const { t } = useTranslation(['words', 'members']);
  const useStyles = makeStyles(() => ({
    error: {
      backgroundColor: theme.palette.error.light,
    },
    optionsZone: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },
    radio: {
      paddingTop: 2,
      paddingBottom: 2,
    },
    radioLabelContainer: {
      fontSize: 13,
    },
    radioLabel: {
      marginBottom: 4,
    },
  }));
  const classes = useStyles();

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setSendEmail(value);
  };

  useEffect(() => {
    exportStates({ sendEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.optionsZone}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="defaultEmail"
          name="default"
          value={sendEmail}
          onChange={handleChangeEmail}
        >
          <FormControlLabel
            classes={{ root: classes.radioLabel, label: classes.radioLabelContainer }}
            value="default"
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={<span>{t('members:defaultInvitationEmail')}</span>}
          />
          <FormControlLabel
            classes={{ root: classes.radioLabel, label: classes.radioLabelContainer }}
            value="blocked"
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={<span>{t('members:blockInvitationEmail')}</span>}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

ImportMembersFormFooter.defaultProps = {
  exportStates: () => {},
  sendEmail: () => {},
  setSendEmail: 'default',
};

ImportMembersFormFooter.propTypes = {
  exportStates: PropTypes.func,
  sendEmail: PropTypes.any,
  setSendEmail: PropTypes.any,
};

export default ImportMembersFormFooter;
