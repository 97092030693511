import './index.scss';
import React, { ReactNode } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

interface PageLoaderProps {
  isActive: boolean;
  classNames?: string;
  child: ReactNode;
}

const CLASSNAME = 'pageLoader';

const PageLoader: React.FC<PageLoaderProps> = ({ isActive, classNames = '', child }) => {
  const fadeSpeed = 200;
  return (
    <>
      {/* @ts-ignore*/}
      <LoadingOverlay
        active={isActive}
        spinner
        fadeSpeed={fadeSpeed}
        className={isActive ? `${CLASSNAME}_noScroll ${classNames}` : `${CLASSNAME} ${classNames}`}
      >
        {child}
      </LoadingOverlay>
    </>
  );
};

PageLoader.displayName = 'PageLoader';
export default PageLoader;
