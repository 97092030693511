const connectFr = {
  // B
  badCredentials: 'Identifiant inconnu',
  // C
  confidentialityAccount: 'Politique de Confidentialité',
  createCommunity: 'Créer une communauté',
  // E
  emailNotValidated: 'Vous devez valider votre email avant de pouvoir vous connecter',
  // F
  forgottenPassword: 'Mot de passe oublié',
  // O
  onlyAdmin: 'Seul un administrateur peut se connecter au tableau de bord',
  // P
  passwordChanged: 'Le mot de passe à bien été changé.',
  passwordHelper: 'Longueur minimale: 8 caractères',
  // R
  reinitializePassword: 'Réinitialiser mon mot de passe',
  // S
  selectACommunity: 'Sélectionnez une communauté',
  selectAnApplication: 'Sélectionnez une application',
  // U
  unknownError: 'Une erreur à eu lieu. Vérifiez que vous êtes bien connecté à Internet',
  // V
  viewMailForPasswordChange:
    'Un e-mail pour réinitialiser votre mot de passe vient de vous être envoyé',
};

export default connectFr;
