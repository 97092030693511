import baseURL, { POST_SECURITY_REFRESH_TOKEN } from '../config/api';
import StorageManager from './WebStorageManager';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { sha256 } from 'js-sha256';

export const AxiosCancelToken = axios.CancelToken;

window.cancel = null;

function apiRest(isSecurity = false) {
  const currentToken = StorageManager.getValue('token');
  const selectedCommunity = StorageManager.getValue('selectedCommunity');
  const isWhiteLabel = selectedCommunity?.whiteLabel === 'yes';
  const whiteLabelName = selectedCommunity?.whiteLabelName || null;
  const communityName = selectedCommunity?.name;
  const api = isSecurity
    ? axios.create({
        baseURL,
        timeout: 900000,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'white-label': isWhiteLabel,
          'community-name': whiteLabelName || communityName || 'superconnectr',
          from: 'dashboard',
        },
      })
    : axios.create({
        baseURL,
        timeout: 900000,
        headers: {
          'auth-token': currentToken,
          'Content-Type': 'application/json; charset=utf-8',
          'white-label': isWhiteLabel,
          'community-name': whiteLabelName || communityName || 'superconnectr',
          from: 'dashboard',
        },
      });

  const body = {
    token: StorageManager.getValue('token'),
    refreshToken: StorageManager.getValue('refreshToken'),
  };

  const refreshAuthLogic = (failedRequest) =>
    axios
      .post(`${baseURL}${POST_SECURITY_REFRESH_TOKEN}`, body, {
        headers: {
          'auth-token': currentToken,
          'Content-Type': 'application/json; charset=utf-8',
          'validation-code': sha256(JSON.stringify(body) + process.env.REACT_APP_SYSTEM_API_KEY),
        },
      })
      .then((tokenRefreshResponse) => {
        const { token, refreshToken } = tokenRefreshResponse.data.content;
        StorageManager.setValue('token', token);
        StorageManager.setValue('refreshToken', refreshToken);
        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers['auth-token'] = token;

        return Promise.resolve();
      });

  createAuthRefreshInterceptor(api, refreshAuthLogic, {
    statusCodes: [403],
  });

  api.interceptors.request.use(
    (config) => {
      let data;
      switch (config.method) {
        case 'get':
          data = encodeURI(config.url.trim());
          break;
        default:
          data = JSON.stringify(config.data);
          break;
      }
      const newConfig = config;
      newConfig.headers['Validation-Code'] = sha256(data + process.env.REACT_APP_SYSTEM_API_KEY);

      return newConfig;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          StorageManager.removeKey('token');
          StorageManager.removeKey('refreshToken');
          StorageManager.removeKey('user');
          StorageManager.removeKey('communities');
          StorageManager.removeKey('selectedCommunity');
          StorageManager.removeKey('selectedEvent');
          if (
            window.document.location.pathname !== '/' &&
            window.document.location.pathname !== '/register'
          ) {
            window.document.location.replace('/');
          } else {
            window.document.location.reload();
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export default apiRest;
