import AppTooltip from '../../../helpers/AppToolTip';
import ActionButton from '../ActionButton';
import CancelButton from '../CancelButton';
import DangerButton from '../DangerButton';
import { ButtonType } from './types';
import React from 'react';

interface ToolBarItemButtonProps {
  label: string;
  isDisabled?: boolean;
  action: () => void;
  type?: ButtonType;
  isLoading?: boolean;
  help?: string;
}

export const ToolBarItemButton: React.FC<ToolBarItemButtonProps> = ({
  label,
  isDisabled = false,
  action,
  type = ButtonType.action,
  isLoading = false,
  help = null,
}) => {
  const GetButton = () => {
    switch (type) {
      case ButtonType.danger:
        return <DangerButton label={label} onClick={action} disabled={isDisabled} />;
      case ButtonType.cancel:
        return <CancelButton label={label} onClick={action} disabled={isDisabled} />;
      default:
        return (
          <ActionButton waiting={isLoading} label={label} onClick={action} disabled={isDisabled} />
        );
    }
  };

  return (
    <>
      {help ? (
        <AppTooltip title={help} arrow>
          <span>
            <GetButton />
          </span>
        </AppTooltip>
      ) : (
        <GetButton />
      )}
    </>
  );
};

ToolBarItemButton.displayName = 'ToolBarItemButton';
