import { sessionContext } from '../../_hook/useSession';
import theme from '../../pages/theme';
import CategoryTag from '../generic/CategoryTag';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface ActualityHeaderProps {
  publishedDate: string;
  category: string;
  color: string;
}

const ActualityHeader: React.FC<ActualityHeaderProps> = ({ publishedDate, category, color }) => {
  const { sessionState } = React.useContext(sessionContext);
  const { selectedCommunity } = sessionState;

  const displayDate = new Date(publishedDate);

  const useStyles = makeStyles(() => ({
    container: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerRight: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    small: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar src={selectedCommunity.logo} className={classes.small} />
      <div className={classes.headerRight}>
        <CategoryTag category={category} color={color} />
        <Typography variant="caption" display="block" gutterBottom align="center">
          {displayDate.toLocaleDateString()}
        </Typography>
      </div>
    </div>
  );
};

ActualityHeader.displayName = 'ActualityHeader';
export default ActualityHeader;
