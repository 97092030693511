import { EventCompletedInformation } from '../../../_types/eventTypes';
import ICON_EVENT from '../../../assets/images/icon_event.png';
import './index.scss';
import { Paper } from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASSNAME = 'eventHeader';

interface EventHeaderProps {
  event: EventCompletedInformation;
}

const EventHeader: React.FC<EventHeaderProps> = ({ event }) => {
  const { t } = useTranslation(['event']);
  return (
    <div className={CLASSNAME}>
      <Paper className={`${CLASSNAME}_logo`} elevation={3}>
        <img src={ICON_EVENT} alt={'icon_event'} />
      </Paper>
      <div className={`${CLASSNAME}_texts`}>
        <h1>{capitalize(t('event'))}</h1>
        <h2>{event.name}</h2>
      </div>
    </div>
  );
};

export default EventHeader;
